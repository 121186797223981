import classNames from 'classnames';
import React, { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as LockIcon } from './assets/lockWhite.svg';
// import { ReactComponent as LockIcon } from './assets/lock.svg';
import { ReactComponent as Menu3PointsIcon } from '@ui/icons/menu3Points.svg';
import { ReactComponent as StorageIcon } from '@ui/icons/storage.svg';

import "./styles.less";

interface OwnProps {
  fileImageNumber: number;
  title: string;
  linkPath: string;
  filesCount?: number;
  locked?: boolean;
  withMenu3PointsIcon?: boolean;
}

type FacultyFileProps = OwnProps;

const FacultyFile: FC<FacultyFileProps> = ({
  fileImageNumber,
  title,
  linkPath,
  filesCount = 0,
  locked = false,
  withMenu3PointsIcon= true,
}) => {
  const { push } = useHistory();

  const onClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    push(linkPath);
  }

  const fullFileName = require(`./assets/file${fileImageNumber}.png`);

  return (

    <div key={title}>
      <button
        className={
          classNames(
            "programs-item",
            `file${fileImageNumber}`,
          )
        }
        onClick={locked ? undefined : onClickHandler}
      >
        <div className="programs-item-shadow"></div>
        <img src={fullFileName} alt="" />
        {locked && <div className='locked'>
          <LockIcon />
        </div>}
        <div className="programs-item-content">
          <div className="programs-item-head">
            <div className="programs-item-title">
              {title}
            </div>
            {withMenu3PointsIcon && (
              <div className="programs-item-setting">
                <Menu3PointsIcon />
              </div>
            )}
          </div>
          {filesCount > 0 && (
            <div className="programs-item-footer">
              <div className="programs-item-head">
                <StorageIcon />
                <div>
                  {filesCount} видео
                </div>
              </div>
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

export default FacultyFile;
