import i18next from 'i18next';
import React from 'react';

export default function getTransactionDetailTable(record) {
  const { t } = i18next;

  if (!record.info) return null;

  let data = {};
  try {
    const prepare = (
      record.info
        .replaceAll(/\n\s*/g, ',')
        .replaceAll(/,\)/g, '}')
        .replaceAll(/Array[\n,]\([\n,]?/g, '{')
        .replaceAll(/ => ([a-zA-Zа\\]*?) Object[\n,]\([\n,]?/g, ' => {[_t] => $1,')
        .replaceAll(/([a-zA-Z\\]+):([a-zA-Z\\]+)/g, "$1#$2")
        .replaceAll(/([a-zA-Z\\]+):([a-zA-Z\\]+)/g, "$1#$2")
        .replaceAll(/"/g, "'")
        .replaceAll(/\\/g, "/")
        .replaceAll(/ => ((0x)?[a-fA-F0-9]{32,64})/g, ' => "$1"')
        .replaceAll(/([a-zA-Zа-яА-Я]+[')]?)([,}])/g, '$1"$2')
        .replaceAll(/ => ([a-zA-Zа-яА-Я]+['(]?)/g, ' => "$1')
        .replaceAll(/\[(.*?)\] => /g, '"$1":')
        .replaceAll(/,"[a-zA-Z_]+?":,/g, ',')
        .replaceAll(/":,/g, '":"",')
        .replaceAll(/":(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}),/g, '":"$1",')
        .replaceAll(/":([,}])/g, '":""$1')
        .replaceAll(/([a-zA-Z0-9]{32,64})([\],)}])/g, '$1"$2')
        .replace(/,$/, '')
        .trim('\n')
    );
    // console.log({
    //   _f: 'ShortTransactions-renderInfo-80',
    //   info: record.info,
    //   prepare,
    // });

    data = JSON.parse(prepare);

  } catch (e) {
    console.error({
      _f: 'getTransactionDetailTable-40',
      error: e,
      info: record.info,
    });
  }

  const result = {};

  if (Object.keys(data).length > 0) {
    switch (record.transaction_type_id) {
      case 1:
        // "Покупка продукта",
        if (data?.product_name) result.product_name = data?.product_name;
        break;
      case 2:
        // "Продление подписки",
        break;
      case 3:
        // "Ручная корректировка",
        break;
      case 4:
        // "Поступление средств",
        break;
      case 5:
        // "Вывод средств",
        if (data?.withdrawal_id) result.withdrawal_id = data?.withdrawal_id;
        if (data?.amount_user) result.amount_user = data?.amount_user;
        if (data?.amount_commission) result.amount_commission = data?.amount_commission;
        if (data?.amount) result.amount = data?.amount;
        if (data?.toAddress) result.to_address = data?.toAddress;
        break;
      case 6:
        // "Комиссия за вывод средств",
        break;
      case 7:
        // "Доход от продаж",
        if (data?.buyer_id) result.buyer_id = data?.buyer_id;
        if (data?.product_name) {
          result.transaction_type = 'Покупка пакета';
          result.product_name = data?.product_name;
        }
        if (data?.project_name) {
          result.transaction_type = 'Участие в проекте';
          result.project_name = data?.project_name;
        }
        if (data?.price_with_sale) result.price_with_sale = data?.price_with_sale;
        if (data?.percent) result.percent = data?.percent;
        if (data?.pv) result.pv = data?.pv;
        break;
      case 8:
        // "Дивидендный доход",
        if (data?.buyer_id) result.buyer_id = data?.buyer_id;
        if (data?.product_name) {
          result.transaction_type = 'Покупка пакета';
          result.product_name = data?.product_name;
        }
        if (data?.project_name) {
          result.transaction_type = 'Участие в проекте';
          result.project_name = data?.project_name;
        }
        if (data?.price_with_sale) result.price_with_sale = data?.price_with_sale;
        if (data?.dividend) result.dividend = data?.dividend;
        if (data?.pv) result.pv = data?.pv;
        break;
      // case 9:
      //   // "Перевод остаток по маркетингу на счет компании",
      //   break;
      case 10:
        // "Комиссия за вход/исход транзакцию",
        if (data?.withdrawal_id) result.withdrawal_id = data?.withdrawal_id;
        if (data?.['payIn.tr_id']) result.payIn_id = data?.['payIn.tr_id'];
        break;
      // case 11:
      //   // "Комиссия за вход/исход транзакцию (зачисление админу)",
      //   break;
      // case 12:
      //   // "Перевод с кошелька на кошелек (balance)",
      //   break;
      // case 13:
      //   // "Перевод с кошелька на кошелек (real_balance)",
      //   break;
      // case 14:
      //   // "Перевод на кошелек Админа после пополнения",
      //   break;
      // case 15:
      //   // "Уменьшение баланса кошелька Админа после вывода",
      //   break;
      case 16:
        // "Комиссия за пополнение средств",
        break;
      case 17:
        // "Обновление продукта",
        if (data?.upgrade_name) result.upgrade_name = data?.upgrade_name;
        if (data?.product_name) result.product_name = data?.product_name;
        break;
      case 18:
        // "Получение % от прибыли компании",
        break;
      case 19:
        // "Отмена транзакции",
        if (data?.['old transaction']?.id) result.old_id = data?.['old transaction']?.id;
        break;
      case 20:
        // "Комиссия за пополнение средств",
        break;
      case 21:
        // "Комиссия за вывод средств",
        if (data?.withdrawal_id) result.withdrawal_id = data?.withdrawal_id;
        if (data?.toAddress) result.to_address = data?.toAddress;
        break;
      // case 22:
      //   // "Комиссия за пополнение средств (зачисление Админу)",
      //   break;
      // case 23:
      //   // "Комиссия за вывод средств (зачисление Админу)",
      //   break;
      case 24:
        // "Повышение уровня пользователя по маркетингу",
        result.level_start = record.level_start;
        result.level_end = record.level_end;
        break;
      case 25:
        // "Ручная корректировка",
        break;
      case 26:
        // "Оплата заявки",
        if (data?.project_by_user_id) result.project_by_user_id = data?.project_by_user_id;
        if (data?.project_name) result.project_name = data?.project_name;
        if (data?.project_pull_id) result.project_pull_id = data?.project_pull_id;
        if (data?.amount_user) result.amount_user = data?.amount_user;
        if (data?.amount_commission) result.amount_commission = data?.amount_commission;
        if (data?.amount) result.amount = data?.amount;
        break;
      // case 27:
      //   // "Перечисление комиссии по проекту",
      //   break;
      // case 28:
      //   // "Отправка пула для участия в проекта",
      //   break;
      case 29:
        // "Смарт-контракт",
        if (data?.project_by_user_id) result.project_by_user_id = data?.project_by_user_id;
        if (data?.project_name) result.project_name = data?.project_name;
        if (data?.project_pull_id) result.project_pull_id = data?.project_pull_id;
        if (data?.amount_user) result.amount_user = data?.amount_user;
        break;
      // case 30:
      //   // "Для экспертов",
      //   break;
      // case 31:
      //   // "Для Базового курса",
      //   break;
      // case 32:
      //   // "Перечисление накопленной комиссии по экспертам",
      //   break;
      // case 33:
      //   // "Перечисление накопленной комиссии за пополнение базового курса",
      //   break;
      // case 34:
      //   // "Конвертация валюты из USDT в TRX для комиссии",
      //   break;
      // case 35:
      //   // "Оплата серверов",
      //   break;
      case 36:
        // "Вывод средств Ethereum",
        if (data?.withdrawal_id) result.withdrawal_id = data?.withdrawal_id;
        // if (data?.amount_user) result.amount_user = data?.amount_user;
        // if (data?.amount_commission) result.amount_commission = data?.amount_commission;
        // if (data?.amount) result.amount = data?.amount;
        if (data?.toAddress) result.to_address = data?.toAddress;
        break;
      case 37:
        // "Отмена вывода средств Ethereum",
        break;
      default:
        break;
    }
  }

  return Object.keys(result).length === 0
    ? null
    : Object.keys(result).map((x) => (
      <table className="userInfo" key={`table_${record.id}_${record.user_address_id}_transaction_info`}>
        <tbody>
        <tr>
          <td>{t(`TRANSACTION_DETAIL_${x.toUpperCase()}`)}</td>
          <td>{result[x]}</td>
        </tr>
        </tbody>
      </table>
    ));
}
