import Burger from '@common/burger';
import { handleMoveToElement, openInNewTab } from '@components/landing/utils';
import { SOULMATE_ECOSYSTEM } from '@src/constants';
// import { FORM_LOGIN, FORM_REGISTRATION } from '@src/constants';
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// import { ReactComponent as SoulMateLogoHeaderIcon } from '@components/landing/icons/soulMateLogoHeader.svg';
import { ReactComponent as SoulMateLogoHeaderIcon } from '@components/landing/icons/logo_white.svg';
// import { ReactComponent as LogoutIcon } from '@ui/icons/logout.svg';

import './styles.less';

class LandingHeader extends Component {
  render() {
    // const { setFormType } = this.props;

    return (
      <div className="header">
        <div className="wrap">
          <div className="header-logo">
            <SoulMateLogoHeaderIcon className="w-[110px] h-[68px]" />
          </div>
          <div className="header-menu">
            <button className="gta a" onClick={() => handleMoveToElement('.d5')}>Эволюционная цель</button>
            <button className="gta a" onClick={() => handleMoveToElement('.d12')}>Клуб Амбассадоров</button>
            <button className="gta a" onClick={() => openInNewTab(SOULMATE_ECOSYSTEM)}>WhitePaper</button>
            {/*<Link className="gta" onClick={() => handleMoveToElement('.d17')}>Вебинар</Link>*/}
            {/*<div className='!hidden lg:!flex'>
              <button
                className="header-btn gta !mr-5 !opacity-100"
                data-anchor=".d10"
                onClick={() => setFormType(FORM_REGISTRATION)}
              >
                Регистрация
              </button>
              <button
                className="header-btn gta !mr-5 !opacity-100"
                data-anchor=".d10"
                onClick={() => setFormType(FORM_LOGIN)}
              >
                Вход
              </button>
            </div>*/}
          </div>
          <Burger />
        </div>
        {/*<div className="wrap !p-0 lg:!hidden">
          <button
            className="header-btn gta"
            data-anchor=".d10"
            onClick={() => setFormType(FORM_REGISTRATION)}
          >
            Регистрация
          </button>
          <button
            className="header-btn gta"
            data-anchor=".d10"
            onClick={() => setFormType(FORM_LOGIN)}
          >
            Вход
          </button>
        </div>*/}
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
// //   appStage: state.appStage,
// //   notificationsCounter: state.notifications.count_new,
// //   notifications: state.notifications.notificationsData,
// //   userInfo: state.user.info,
// });
//
// const mapDispatchToProps = {
//   // setAppStageData: setAppStage,
//   // clearUserInfo: setClearUserExtraInfo(),
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(
//   withTranslation()(
//     withRouter(
//       LandingHeader
//     )
//   )
// );

// export default withTranslation()(
//   withRouter(
//     TopBar
//   )
// );

export default LandingHeader;
