import React from 'react';
import LandingHeader from '@components/landing/header';
import LandingPage from '@components/landing/page';
import LandingFooter from '@components/landing/footer';

import '@ui/styles/base.css';
import '@ui/styles/components.css';
import '@ui/styles/utilities.css';
import '@ui/styles/global.css';

import "./styles.less";

const LandingLayout = ({ setFormType }) => {
  return (
    <div className='landing-page'>
      <LandingHeader setFormType={setFormType} />
      <LandingPage setFormType={setFormType} />
      <LandingFooter setFormType={setFormType} />
    </div>
  );
};

export default LandingLayout;
