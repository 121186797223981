import api from '@services/api';
import storage from '@services/storage';
import { AUTH_COOKIE_NAME } from '@src/constants';
import changeAuthByUser from '@src/helpers/changeAuthByUser';

const setAuthToAdmin = async (dispatch, adminInfo, history ) => {
  const {token, user_id} = adminInfo;

  storage.set(AUTH_COOKIE_NAME, token);
  await api.setAUTHToken(token);
  await changeAuthByUser(dispatch, undefined, user_id, history);
}

export default setAuthToAdmin;
