import { debounce } from 'lodash';
import React, { Component } from 'react';

const withWindowResize = (WrappedComponent) => {
  return class withWindowResizeHOC extends Component {
    state = {
      innerWidth: 0,
      innerHeight: 0,
    }

    updateSize = () => {
      const {innerWidth, innerHeight} = this.state;

      const innerWidthNew = window.innerWidth;
      const innerHeightNew = window.innerHeight;

      if (innerWidth !== innerWidthNew || innerHeight !== innerHeightNew) {
        this.setState({
          innerWidth: innerWidthNew,
          innerHeight: innerHeightNew,
        });
      }
    }

    componentDidMount() {
      this.updateWidthDebounce = debounce(this.updateSize.bind(this), 300).bind(this)
      window.addEventListener('resize', this.updateWidthDebounce)
      this.updateSize();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWidthDebounce)
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
        />
      );
    }
  }
}

export default withWindowResize;
