import FormWithMessage from '@common/formWithMessage';
import api from '@services/api';
import { FORM_LOGIN, MIN_USER_PASSWORD_LENGTH } from '@src/constants';
import { getMessageFromRequest, getSearchParamsAsObject } from '@src/helpers';
import { useState } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Input, Spin } from 'antd';
import { withRouter } from 'react-router-dom';

const NewPasswordForm = ({ setFormType, formRef, location, history }) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [textMessage, setTextMessage] = useState(<>Ошибка смены пароля. Проверьте правильность данных или повторите позднее</>);

  const { token } = getSearchParamsAsObject(location);

  const handleSubmit = (val) => {
    formRef.current.validateFields().then(async (values) => {
      setLoading(true);

      const { status, data } = await api.newPassword({...values, token});

      setLoading(false);

      if (status === 200) {
        setTextMessage(getMessageFromRequest(data));
        setIsSuccess(data?.status === 0);
        setIsError(data?.status !== 0);
      } else {
        setIsError(true);
      }
    });
  };

  return (
    <FormWithMessage
      setFormType={setFormType}
      isError={isError}
      isSuccess={isSuccess}
      textMessage={textMessage}
    >
      <Form
        name='NewPassword'
        ref={formRef}
        layout='vertical'
        // initialValues={{ remember: true }}
        onFinish={handleSubmit}
        // className={styles.content}
      >
        <Spin spinning={loading}>
          <div className="form__content">
            <Form.Item name='password' label="Пароль" className="form__block"
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите пароль'},
                         {validateTrigger: 'onBlur', min: MIN_USER_PASSWORD_LENGTH, message: `Пароль может быть минимум ${MIN_USER_PASSWORD_LENGTH} символов`},
                       ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item name='password2' label="Повтор пароля" className="form__block"
                       dependencies={['password']}
                       tooltip='Вводим второй раз пароль для проверки правильности'
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите второй раз пароль для проверки'},
                         {validateTrigger: 'onBlur', min: MIN_USER_PASSWORD_LENGTH, message: `Пароль может быть минимум ${MIN_USER_PASSWORD_LENGTH} символов`},
                         ({ getFieldValue  }) => ({
                           validateTrigger: 'onBlur',
                           validator(rule, value) {
                             if (!value || getFieldValue('password') === value) {
                               return Promise.resolve();
                             }
                             return Promise.reject(new Error('Оба пароля должны совпадать!'));
                           },
                         }),
                       ]}
            >
              <Input.Password />
            </Form.Item>
          </div>
          <button className="standart-btn">
            <div>Сохранить новый пароль</div>
          </button>
          <span className="form__next">
            <button onClick={() => {
              setFormType(FORM_LOGIN);
              return false;
            }}>
              Вернуться на Главную
            </button>
          </span>
        </Spin>
      </Form>
    </FormWithMessage>
  );
}

const mapDispatchToProps = {
  // setAppStageData: setAppStage,
  // setGlobalFiltersLoading: setupGlobalSearchFiltersLoading,
};

export default connect(null, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      NewPasswordForm
    )
  )
);
