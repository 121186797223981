import React, { Component } from "react";
import { message } from "antd";
import api from "@services/api";

class RegisterToSelfStudy extends Component {

  register = async (userId, product = null) => {
    if (product === null) {
      message.warning('Запрещено зачислять на курс "БК для Самообучения" т.к. у пользователя нет пакета')
      return;
    }
    if (product === 1 || product === 11) {
      message.warning('Запрещено зачислять на курс "БК для Самообучения" т.к. у пользователя куплен только пакет БК')
      return;
    }

    this.setState({ loading: true })

    const { status, data } = await api.updateSendPayToEduByIdsSelfStudy({ user_id: userId });

    this.setState({ loading: false });

    if (status === 200 && data?.status === 0) {
      message.success(data?.message);
    } else {
      message.warning(data?.message || 'Ошибка зачисления ученика на БК для самообучения' );
    }
  }

  render() {
    const { id, productId, loading } = this.props

    return (
      <button disabled={loading} onClick={() => this.register(id, productId)}>
        Зачислить на БК для Самообучения
      </button>
    )
  }
}

export default RegisterToSelfStudy