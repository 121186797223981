import { CURRENCY_NETWORK_ETHEREUM, METAMASK_NETWORK_POLIGON } from '@src/constants';
import { ethers, formatUnits, toBeHex } from 'ethers';
import { max } from 'lodash/math';

export const isMetaMaskConnected = () => window.ethereum?.isMetaMask;
export const metaProvider = async () => await window.ethereum?.providers?.filter((x) => x.isMetaMask)?.[0];
export const isMetaMaskUnLocked = async () => {
  const provider = await metaProvider();

  const result = window.ethereum?._metamask
    ? await window.ethereum._metamask?.isUnlocked()
    : await provider?._metamask?.isUnlocked();

  console.log({
    _f: 'src-helpers-meta-task-js---isMetaMaskUnLocked-12',
    result,
    wem: window.ethereum._metamask,
    wemr: window.ethereum._metamask?.isUnlocked(),
    provider,
    pr: provider?._metamask?.isUnlocked(),
  });

  return result;
}
export const isEthereumNetwork = (currentWallet) => currentWallet?.currency_network_id === CURRENCY_NETWORK_ETHEREUM;
export const isPoligonNetwork = async () =>await getMetaMaskChainId() === METAMASK_NETWORK_POLIGON;
export const getMetaMaskAccount = async () => {
  if (!window.ethereum) {
    return undefined;
  }

  const accounts = await window.ethereum.request({
    method: "eth_accounts",
    params: [],
  });

  return (accounts?.length >= 1)
    ? accounts[0].toLowerCase()
    : undefined;
}

export const getMetaMaskChainId = async () => {
  if (!window.ethereum) {
    return undefined;
  }

  return await window.ethereum.request({
    method: "eth_chainId",
    params: [],
  });
}

export const selectMetaMaskChainId = async (chainId) => {
  if (!window.ethereum) {
    return undefined;
  }

  const request = {
    method: "wallet_switchEthereumChain",
    params: [{ chainId }],
  };

  const provider = await metaProvider();

  const result = window.ethereum._metamask
    ? await window.ethereum.request(request)
    : await provider?.request(request);

  console.log({
    _f: 'src-helpers-meta-task-js---selectMetaMaskChainId-46',
    result,
    wemm: window.ethereum._metamask,
    we: window.ethereum,
    provider,
  });


  return result;
}

export const addMetaMaskChain = async (objectForNetwork) => {
  if (!window.ethereum) {
    return undefined;
  }

  const request = {
    method: "wallet_addEthereumChain",
    params: [objectForNetwork],
  };

  console.log({
    _f: 'src-helpers-meta-task-js---addMetaMaskChain-92',
    request,
  });

  const provider = await metaProvider();

  const result = window.ethereum._metamask
    ? await window.ethereum.request(request).catch((e) => console.log({_f:"addEthereumChain - error1", e}))
    : await provider?.request(request).catch((e) => console.log({_f:"addEthereumChain - error2", e}));

  console.log({
    _f: 'src-helpers-meta-task-js---addMetaMaskChain-78',
    result,
    wemm: window.ethereum._metamask,
    we: window.ethereum,
    provider,
  });

  return result;
}

export const choiceMetaMaskPermissions = async () => {
  if (!window.ethereum) {
    return undefined;
  }

  const permissionsArray = await window.ethereum.request({
    method: "wallet_requestPermissions",
    params: [
      {
        eth_accounts: {},
      },
    ],
  });

  const permissions = permissionsArray?.[0]?.caveats?.[0]?.value || [];
  return permissions.map((x) => x.toLowerCase());
}

export const getMetaMaskPermissions = async () => {
  if (!window.ethereum) {
    return undefined;
  }

  const permissionsObjArray = await window.ethereum.request({
    method: "wallet_getPermissions",
    params: [],
  });

  const permissionsArray = permissionsObjArray?.[0]?.caveats?.[0]?.value || [];

  return permissionsArray.map((x) => x.toLowerCase())
}

export const getMetaMaskProvider = () => new ethers.BrowserProvider(window.ethereum);

export const getMaxFeeLastBlocks = async (countBlocks) => {
  const count = toBeHex(countBlocks);

  console.log({
    _f: 'src-helpers-meta-task-js---getMaxFeeLastBlocks-124',
    countBlocks,
    count,
  });

  const blockNumber = await window.ethereum.request({
    "method": "eth_blockNumber",
    "params": []
  });

  console.log({
    _f: 'src-helpers-meta-task-js---getMaxFeeLastBlocks-131',
    blockNumber,
  });

  const request = {
    "method": "eth_feeHistory",
    "params": [
      count,
      blockNumber,
      [],
    ],
  };

  console.log({
    _f: 'src-helpers-meta-task-js---getMaxFeeLastBlocks-161',
    request,
  });

  const result = await window.ethereum.request(request);
  const fees = result?.gasUsedRatio ?? [1];
  console.log({
    _f: 'src-helpers-meta-task-js---getMaxFeeLastBlocks-169',
    result,
    fees,
  });

  const maxFee = max(fees);
  const maxFee2 = maxFee * 1.2;

  console.log({
    _f: 'src-helpers-meta-task-js---getMaxFeeLastBlocks-185',
    maxFee,
    maxFee2,
  });

  return maxFee2;
}

export const getMetaMaskBalance = async (address) => {
  console.log({
    _f: 'src-helpers-meta-task-js---getMetaMaskBalance-124',
    address,
  });

  const blockNumber = await window.ethereum.request({
    "method": "eth_blockNumber",
    "params": []
  });

  console.log({
    _f: 'src-helpers-meta-task-js---getMetaMaskBalance-131',
    blockNumber,
  });

  const request = {
    "method": "eth_getBalance",
    "params": [
      address,
      blockNumber,
    ],
  };

  console.log({
    _f: 'src-helpers-meta-task-js---getMetaMaskBalance-161',
    request,
  });

  const result = await window.ethereum.request(request);
  // const fees = result?.gasUsedRatio ?? [1];
  console.log({
    _f: 'src-helpers-meta-task-js---getMetaMaskBalance-169',
    result,
  //   fees,
  });

  const balance = formatUnits(result, 18);
  const balance2 = Number(balance);

  console.log({
    _f: 'src-helpers-meta-task-js---getMetaMaskBalance-185',
    balance,
    balance2,
  });

  return balance2;
}
