import { Amount } from '@common/amount';
import React, { FC } from 'react';

// import { ReactComponent as YesIcon } from '@ui/icons/yes.svg';

import "@common/catalogCard/styles.less";
import "./styles.less";

export interface Project {
  id: number; // 1
  name: string; // 'Bullran Index'
  name_result?: string; // 'BUI'
  pull_price: number; // 23000
  first_min: number; // 1000
  min: number; // 100
  currency_id: number; // 1
  currency_code: string; // 'USDT'
  currency_network_name_code: string; // "TRC-20"
  description?: string; // ''
  type?: string; // 'project'
}

interface Props {
  project: Project;
  handleProjectBuy: (project: Project) => void;
}

const CatalogProjectCard: FC<Props> = ({ project, handleProjectBuy }) => {
  // const [isShowMore, setIsShowMore] = useState(false);
  // const userInfo = useAppSelector((state) => state.user.info);
  // const isBought = userInfo?.shoppingList?.includes(product.id) || false;
  // const isMainBought = userInfo?.shopping_product_id === product.id || userInfo?.alternativesList?.includes(product.id) || false;

  return (
    <div className="info__card">
      <div className="info-content">
        <div className="info-repeat">
          <div className="sum__inform sum__inform__project">
            <div className="sum__inform-name">
              {/*<span>Проект</span>*/}
              <span>Smart-contract</span>
              <h2>{project.name}</h2>
              <div className="flex flex-row gap-4">
                <div className="sum-info-status">{project.currency_network_name_code}</div>
                {/*{isBought && (
                  <div className="sum-info-status !w-[170px]">Входит в текущий пакет</div>
                )}*/}
                {/*{isMainBought && (
                  <div className="sum-info-status">Текущий пакет</div>
                )}*/}
              </div>
            </div>
            <div className="sum__inform-c">
              <div className="sum__content-info">
                <span>Сумма пула заявок:</span>
                {/*{project.current_price !== project.current_price_with_sale && (
                  <del>
                    <NumericFormat
                      value={Number(project.current_price)}
                      displayType={'text'}
                      decimalScale={DECIMAL_SCALE}
                      thousandSeparator=" "
                    />
                    &nbsp;
                    {project.currency_code}
                  </del>
                )}*/}
                <h2>
                  <Amount
                    wallet={null}
                    currency_code={project.currency_code}
                    value={project.pull_price}
                    digits={0}
                    withSpace={false}
                  />
                </h2>
                {Number(project.min) > 0 && (
                  <p>
                    Минимальная сумма для формирования:
                    <br />
                    <strong>
                      <Amount
                        wallet={null}
                        currency_code={project.currency_code}
                        value={project.first_min || project.min}
                        digits={0}
                        withSpace={false}
                      />
                    </strong>
                  </p>
                )}
              </div>
              {/*{!isBought && (
                <>
                  <div className={(isBought || isMainBought) ? 'disabled sum__but-border' : 'sum__but'}>
                  <div className={(isMainBought) ? 'disabled sum__but' : 'sum__but'}>
                    <button
                      disabled={isBought || isMainBought}
                      className={(isBought || isMainBought) ? 'disabled sum__but_2' : 'sum__but_2'}
                      onClick={() => handleConfirm(project)}
                    >
                      <span>Купить</span>
                    </button>
                  </div>
                </>
              )}*/}
              <button
                className='sum__but_2 sum__but_2__project'
                onClick={() => handleProjectBuy(project)}
              >
                {/*<span>Участвовать</span>*/}
                <span>Сформировать</span>
              </button>
            </div>
          </div>
          {/*<div className="sum__content">
            <h3>Описание продукта</h3>
            <div className="sum__block">
              <div className="sum__block-flex">
                {productParams
                  .filter((x, idx) => isShowMore || idx < HIDDEN_PRODUCT_PARAMS_AFTER_COUNT)
                  .map((x) => (
                    <div
                      className={classNames(
                        "sum__block-line",
                        !product.params.includes(x.id) ? 'disabled' : '',
                      )}
                      key={`${product.name}-${x.name}`}
                    >
                      <YesIcon />
                      <span>{x.name}</span>
                    </div>
                  ))}
              </div>
            </div>
            {productParams.length > HIDDEN_PRODUCT_PARAMS_AFTER_COUNT && (
              <div className="sum-more">
                <button role='link' onClick={() => setIsShowMore(!isShowMore)}>
                  {isShowMore ? 'Скрыть' : 'Показать еще'}
                </button>
              </div>
            )}
          </div>*/}
        </div>
      </div>
    </div>
  );
}

export default CatalogProjectCard;
