import React, { Component } from "react";
import changeAuthByUser from "@src/helpers/changeAuthByUser";
import store from "@state/store";
import { withRouter } from "react-router-dom";

class AuthByUser extends Component {
  authByUser = async (e, userId) => {
    const {userFullInfo, history} = this.props;

    e.preventDefault();

    await changeAuthByUser(store.dispatch, userFullInfo, userId, history);
  }

  render() {
    const { userId } = this.props;

    return (
      <button onClick={(e) => this.authByUser(e, userId)}>
        Login
      </button>
    )
  }
}

export default withRouter(AuthByUser)
