import FacultyFile from '@common/facultyFile';
import { programs } from '@src/helpers/programs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';

import { ROUTE_PRODUCTS } from '@src/constants';
import { ReactComponent as ShopIcon } from '@ui/icons/shop.svg';

import "./styles.less";

class Programs extends Component {

  render() {
    const { userInfo, history } = this.props;

    if (!userInfo || userInfo.id === null || userInfo.id === undefined) {
      return null;
    }

    const options = {
      autoWidth: true,
      margin: 25,
    };

    const handleGoToShop = () => {
      history.push(ROUTE_PRODUCTS);
    }

    const programsList =
      programs
        // .filter((x) => !x.exclude_roles.includes(userInfo.role_id))
        .map((x) => (
          <FacultyFile
            fileImageNumber={x.fileImageNumber}
            key={x.title}
            title={x.title}
            linkPath={x.linkPath}
            filesCount={x.files.length}
            locked={x.exclude_roles.includes(userInfo.role_id)}
            withMenu3PointsIcon={false}
          />
        ));

    return programsList.length > 0 && (
      <div className="programs">
        <div className="programs-head">
          <div className="programs-title">Список ваших учебных программ</div>
          <button className="standart-btn" onClick={handleGoToShop}>
            <div>
              <ShopIcon className="inline" />
              <span className="standart-btn-text">В Арсенал SM</span>
            </div>
          </button>
        </div>
        <div className="programs-slider-parent">
          <OwlCarousel options={options} className="programs-slider owl-carousel">
            {programsList}
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withRouter(
    Programs
  )
);
