import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import api from '@services/api';
import { ROLE_LIST_FOR_BUSINESS, ROUTE_AMBASSADORS_CLUB } from '@src/constants';
import withWindowResize from '@src/helpers/withWindowResize';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// import { ReactComponent as NotificationIcon } from '@ui/icons/notification.svg';

import { getFilteredLines, renterTableFirstLine, TypeOfFirstLine } from './constants';
import "./styles.less";

class StructurePage extends Component {
  state = {
    category: TypeOfFirstLine.partners,
    lines: {},
    loading: {},
    shortLevel: 0,
    opened: [],
    filter: TypeOfFirstLine.partners,
    openedInfo: undefined,
    isBusiness: true,
  }

  componentDidMount() {
    const { userInfo } = this.props;

    const isBusiness = ROLE_LIST_FOR_BUSINESS.includes(userInfo.role_id);
    if (!isBusiness) {
      this.setState({
        isBusiness: false,
        filter: TypeOfFirstLine.all,
      })
    }
  }

  changeLoading = (userId, value) => {
    this.setState((state) => ({
      ...state,
      loading: {
        ...state.loading,
        [userId]: value,
      }
    }));
  }

  loadingFirstLine = async (userId) => {
    this.changeLoading(userId, true);

    const { status, data } = await api.getUserFirstLine(userId)

    if (status === 200 && data) {
      this.setState((state) => ({
        ...state,
        lines: {
          ...state.lines,
          [userId]: data,
        }
      }))
    } else {
      this.setState((state) => ({
        ...state,
        lines: {
          ...state.lines,
          [userId]: [],
        }
      }))
    }

    this.changeLoading(userId, false);
  }

  addOpened = (user_id) => this.setState((state) => ({
    ...state,
    opened: [
      ...state.opened,
      user_id,
    ],
  }));

  removeOpened = (user_id) => this.setState((state) => ({
    ...state,
    opened: state.opened.filter((x) => x !== user_id),
  }));

  setOpenedInfo = (user_id) => {
    const { openedInfo } = this.state;

    if (openedInfo === user_id) {
      this.setState({ openedInfo: undefined })
    } else {
      this.setState({ openedInfo: user_id })
    }
  }

  render() {
    const { t, userId, innerWidth } = this.props;
    const { loading, lines, shortLevel, opened, filter, openedInfo, isBusiness } = this.state;

    const isLgWidth = innerWidth < 768;

    const currentUserLines = !loading[userId] && lines[userId] ? lines[userId] : [];

    return (
      <main className="structure-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            pageName={isBusiness ? t('LEFT_MENU_AMBASSADORS_CLUB/STRUCTURE') : t('LEFT_MENU_STRUCTURE')}
            parentPageName={isBusiness ? t('LEFT_MENU_AMBASSADORS_CLUB') : undefined}
            parentPageLink={isBusiness ? ROUTE_AMBASSADORS_CLUB : undefined}
            withPaySubscription={false}
          />
          <div className="st__contents">
            <div className="st__top">
              {/*<div className="main-col">
                <Indicators />
              </div>*/}
              {/*<div className="st__p-block">*/}
              {
                isBusiness
                  ? (
                    <div className="info__card !mt-1">
                      <div className="infop__video-nav">
                        {Object.keys(TypeOfFirstLine).map((x) => (
                          <div key={x} className={classNames("info__video-block", filter === x ? 'active' : '')}>
                            <button onClick={() => this.setState({ filter: x })}>
                              {t(`STRUCTURE_TYPE_OF_FIRST_LINE__${x.toUpperCase()}`)} ({getFilteredLines(x, currentUserLines).length})
                            </button>
                          </div>
                        ))}
                      </div>
                      {renterTableFirstLine(userId, isLgWidth, 1, loading, lines, opened, filter, openedInfo, this.setOpenedInfo, this.loadingFirstLine, this.addOpened, this.removeOpened, shortLevel)}
                    </div>
                  )
                  : (
                    <div className="info__card !mt-1">
                      {renterTableFirstLine(userId, isLgWidth, 1, loading, lines, opened, filter, openedInfo, this.setOpenedInfo, this.loadingFirstLine, this.addOpened, this.removeOpened, shortLevel, false)}
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.user_id,
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withWindowResize(
      withRouter(
        StructurePage
      )
    )
  )
);
