import CatalogHeader from '@common/catalogHeader';
import FacultyFile from '@common/facultyFile';
import MobileHeader from '@common/mobileHeader';
import { programs, programs_category } from '@src/helpers/programs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// import { ReactComponent as NotificationIcon } from '@ui/icons/notification.svg';

import "./styles.less";

class EducationPage extends Component {

  render() {
    const { userInfo } = this.props;

    return (
      <main className="education-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName='Обучение' withPaySubscription={false} className='!mb-0' />
          <div className='flex flex-col gap-4 w-full'>
            <div className="info-contents">
              <div className="info-content">
                {programs_category.map((cat) => (
                  <div className="info__card ">
                    <div className={cat.className}>
                      <div className="academy__head">
                        {cat.background}
                        <div className="academy__content">
                          <div className="academu__name">
                            <span>Название</span>
                            <h2>{cat.title}</h2>
                          </div>
                        </div>
                      </div>
                      <h2 className="part__head">Описание продукта</h2>
                      <div className="p__blocks">
                        {programs?.filter((x) => x.programs_category === cat.idx).map((x) => (
                          <div className="partner__file-blocks">
                            <FacultyFile
                              fileImageNumber={x.fileImageNumber}
                              linkPath={x.linkPath}
                              title={x.title}
                              filesCount={x.files.filter((x) => x.type === 'video').length}
                              locked={x.exclude_roles.includes(userInfo.role_id)}
                              withMenu3PointsIcon={false}
                            />
                            {/*<div className="partner__content">
                              <h2>Название уроков</h2>
                              <p>Смарт-контракты для ICO <br /> в свое время</p>
                            </div>*/}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      EducationPage
    )
  )
);
