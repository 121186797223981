import { QuestionCircleOutlined } from '@ant-design/icons';
import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import { PERIOD_1_WEEK, TRANSACTION_CURRENCY_1 } from '@routes/transactions/constants';
import api from '@services/api';
import { DATE_FORMAT, PAGE_CHANGER_STEPS } from '@src/constants';
import getTransactionDetailTable from '@src/helpers/getTransactionDetailTable';
import { Popover, Table } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';

import "./styles.less";
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

class TransactionsPage extends Component {
  state = {
    transactions: [],
    loading: false,
    type: undefined,
    status: undefined,
    currency: TRANSACTION_CURRENCY_1,
    date_from: dayjs(`PERIOD_DELTA_PERIOD_1_WEEK`).format(DATE_FORMAT),
    date_to: dayjs().format(DATE_FORMAT),
    period: PERIOD_1_WEEK,
    count: 0,
    page: 1,
    pageSize: PAGE_CHANGER_STEPS[0],
    openedInfo: undefined,
  }

  componentDidMount() {
    this.getTransactionList();
  }

  getTransactionList = async () => {
    const { page, pageSize } = this.state;
    this.setState({loading: true});

    const { status, data } = await api.getUserTransactionList({
      page: page - 1,
      page_size: pageSize,
    });

    if (status === 200 && data?.data?.length) {
      this.setState({
        transactions: data.data,
        count: data.totalCount,
      });
    }

    this.setState({loading: false});
  }

  changePage = (newPage, newPageSize) => {
    const { pageSize } = this.state;

    this.setState({
      pageSize: newPageSize,
      page: Number(pageSize) !== newPageSize ? 1 : newPage,
    }, () => this.getTransactionList())
  }

  setOpenedInfo = (user_id) => {
    const { openedInfo } = this.state;

    if (openedInfo === user_id) {
      this.setState({ openedInfo: undefined })
    } else {
      this.setState({ openedInfo: user_id })
    }
  }

  render() {
    const { t } = this.props;
    const { transactions, loading, count, page, pageSize, /*openedInfo*/ } = this.state;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">ID:</div>
            <div className="column-content-block st-table-num column_id">{id}</div>
          </div>
        ),
      },
      {
        title: 'Дата',
        dataIndex: 'date_create',
        key: 'date_create',
        render: (date) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Дата:</div>
            <div className="column-content-block st-table-imfo date column_date">{dayjs(date).format('DD.MM.YY')}</div>
          </div>
        ),
      },
      {
        title: 'Операция',
        dataIndex: 'transaction_type_id',
        key: 'transaction_type_id',
        render: (type) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Операция:</div>
            <div className="column-content-block st-table-imfo type column_type">{t(`TRANSACTION_TYPE_${type}`)}</div>
          </div>
        ),
      },
      {
        title: 'Валюта',
        dataIndex: 'currency_id',
        key: 'currency_id',
        render: (currency_id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Валюта:</div>
            <div className="column-content-block st-table-imfo currency column_currency">{t(`TRANSACTION_CURRENCY_${currency_id}`)}</div>
          </div>
        ),
      },
      {
        title: 'Сумма',
        dataIndex: 'sum',
        key: 'sum',
        render: (sum, record) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Сумма:</div>
            <div className="column-content-block st-table-imfo sum value column_value">
              <NumericFormat
                value={Number(sum)}
                displayType={'text'}
                decimalScale={3}
                thousandSeparator=" "
              />
            </div>
          </div>
        ),
      },
      {
        title: 'Итоговый баланс',
        dataIndex: 'balance_end',
        key: 'balance_end',
        render: (sum, record) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Итоговый баланс:</div>
            <div className="column-content-block st-table-imfo sum balance_end column_balance_end">
              <NumericFormat
                value={Number(sum)}
                displayType={'text'}
                decimalScale={3}
                thousandSeparator=" "
              />
            </div>
          </div>
        ),
      },
      {
        title: 'Статус',
        key: 'transaction_status_id',
        dataIndex: 'transaction_status_id',
        render: (status) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Статус:</div>
            <div className={`column-content-block st-table-finish column_status status ${[2].includes(status) ? 'status-ok' : ([-1, 10].includes(status) ? 'status-error' : '') }`}>
              <span className="">{t(`TRANSACTION_STATUS_${status}`)}</span>
              {/*<span className="!hidden lg:!inline-block">{t(`TRANSACTION_STATUS_${status}`)}</span>
              <span className="!inline-block lg:!hidden">
                {status === 2
                  ? <CheckOutlined />
                  : (
                    [-1, 10].includes(status)
                      ? <StopOutlined />
                      : <>&nbsp;</>
                  )
                }
              </span>*/}
            </div>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: `id_moreInfo`,
        render: (id, record) => {
          const content = getTransactionDetailTable(record);
          return content
            ? (
              <Popover
                title="Детальная информация по транзакции"
                placement='bottomRight'
                // open={openedInfo === record.id}
                content={content}
              >
                <div
                  className="st__form-moreInfo"
                  onClick={() => this.setOpenedInfo(record.id)}
                >
                  <QuestionCircleOutlined />
                </div>
              </Popover>
            )
            : null
        },
      },
    ];

    const paginationChanger = {
      onChange: this.changePage,
      current: page,
      total: count,
      pageSize: pageSize,
      size: 'small',
      showSizeChanger: true,
      pageSizeOptions: PAGE_CHANGER_STEPS,
    }

    return (
      <main className="transaction-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName='История транзакций' withPaySubscription={false}/>
          {/*<div className="info__card">*/}
          {/*  <div className="info-content">*/}
              {/*<div className="info-repeat">
                <form className="static__header static__headerm">
                  <div className="static__head-block">
                    <h2>Тип транзакции</h2>
                    <div className="select">
                      <input className="select__input" type="hidden" name="">
                        <div className="select__head">Подробнее</div>
                        <ul className="select__list" style="display: none;">
                          <li className="select__item">Подробнее1</li>
                          <li className="select__item">Подробнее2</li>
                          <li className="select__item">Подробнее3</li>
                        </ul>
                    </div>
                  </div>
                  <div className="static__head-block">
                    <h2>Монета</h2>
                    <div className="select">
                      <input className="select__input" type="hidden" name="">
                        <div className="select__head">Все монеты</div>
                        <ul className="select__list" style="display: none;">
                          <li className="select__item">Все монеты1</li>
                          <li className="select__item">Все монеты2</li>
                          <li className="select__item">Все монеты3</li>
                        </ul>
                    </div>
                  </div>
                  <div className="static__head-block">
                    <h2>Дата</h2>
                    <div className="select select-b">
                      <input className="select__input" type="hidden" name="">
                        <div className="select__head">Последние 30 дней</div>
                        <ul className="select__list" style="display: none;">
                          <li className="select__item">Последние 30 дней1</li>
                          <li className="select__item">Последние 30 дней2</li>
                          <li className="select__item">Последние 30 дней3</li>
                        </ul>
                    </div>
                  </div>
                  <div className="static__head-block">
                    <h2>Статус</h2>
                    <div className="select select-b">
                      <input className="select__input" type="hidden" name="">
                        <div className="select__head">Все</div>
                        <ul className="select__list" style="display: none;">
                          <li className="select__item">Все1</li>
                          <li className="select__item">Все2</li>
                          <li className="select__item">Все3</li>
                        </ul>
                    </div>
                  </div>
                </form>
              </div>*/}
              <div className="static__contents !py-4">
                <div className="static__cont">
                  <Table
                    dataSource={transactions}
                    columns={columns}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName="st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0"
                    pagination={paginationChanger}
                    className='static-table'
                  />
                </div>
              </div>
            {/*</div>*/}
          {/*</div>*/}
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  TransactionsPage
);
