import api from '@services/api';
import storage from '@services/storage';
import { AUTH_COOKIE_NAME, ROUTE_ROOT, STORAGE_AUTH_BY_USER } from '@src/constants';
import { setUserExtraInfo } from '@state/user';

const changeAuthByUser = async (dispatch, adminInfo, userId, history ) => {
  const result = await api.authByUser(userId);

  if (result.status === 200 && result.data.status === 0) {
    const { token, user } = result.data;

    storage.set(AUTH_COOKIE_NAME, token);
    userId === 1
      ? storage.remove(STORAGE_AUTH_BY_USER)
      : storage.set(STORAGE_AUTH_BY_USER, adminInfo);

    await api.setAUTHToken(token);
    await dispatch(setUserExtraInfo(user));

    history.push(ROUTE_ROOT);

    setTimeout(() => window.location.reload(), 300);
  }
}

export default changeAuthByUser;
