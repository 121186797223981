import { CaretDownOutlined, CaretUpOutlined, SyncOutlined, } from '@ant-design/icons';
import ReloadWalletButton from '@common/reloadWalletButton';
import { DECIMAL_SCALE } from '@src/constants';
import { RootState } from '@state/store';
import { IWalletInfo } from '@state/user';
import { Popover } from 'antd';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';

import './styles.less';

interface OwnProps {
  wallets?: IWalletInfo[];
  className?: string;
}

const BalanceInfo: FC<OwnProps> = ({ wallets, className }) => {
  const [showPopup, setShowPopup] = useState(false);

  const getWalletBalance = (wallet: IWalletInfo, showWalletName: boolean) => (
    <tr key={wallet.id} className="flex flex-row gap-2 items-center self-end overflow-nowrap">
      {showWalletName && (
        <td className="whitespace-nowrap pr-3">
          {wallet.name}
        </td>
      )}
      <td className="text-right whitespace-nowrap pr-3">
        <NumericFormat
          value={Number(wallet.balance || 0)}
          displayType={'text'}
          decimalScale={DECIMAL_SCALE}
          thousandSeparator=' '
          className="!inline !opacity-100 !text-sm"
        />
      </td>
      <td className="text-right !text-sm pr-3" >
        {wallet.currency_code}
      </td>
      <td className="whitespace-nowrap pr-3">
        ({wallet.currency_network_name_code})
      </td>
      <td className="/*!w-4 text-right !text-xs*/ !align-middle !leading-0">
        <ReloadWalletButton wallet={wallet} className="/*!text-xs*/ !leading-0" />
      </td>
    </tr>
  );

  const contentRender = (showWalletName: boolean) => (
    <table>
      <tbody>
        {wallets?.map((wallet) => getWalletBalance(wallet, showWalletName))}
      </tbody>
    </table>
  );

  const showWalletName = wallets?.some((x) => x.name?.length) || false;

  return (
    <div className="balance-info">
      <Popover
        content={contentRender(showWalletName)}
        className={classNames("!flex flex-row items-center ", className)}
        placement="bottom"
        trigger={'click'}
        open={showPopup}
        onOpenChange={setShowPopup}
        getPopupContainer={(node) => node?.parentElement || node}
      >
        <button className="flex flex-row gap-2 items-center balance-button">
          <div>Балансы:</div>
          <div>
            {wallets?.length
              ? (
                showPopup
                  ? <CaretUpOutlined className="!leading-0" />
                  : <CaretDownOutlined className="!leading-0" />
              )
              : <SyncOutlined spin className="!leading-0" />
            }
          </div>
        </button>
      </Popover>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  wallets: state.user.info?.wallets,
});

export default connect(mapStateToProps)(
  BalanceInfo
);
