import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import { TELEGRAM_SME_ADMIN_BOT } from '@src/constants';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import "./styles.less";

class TgChannelClubPage extends Component {
    render() {
    const { t } = this.props;
      return (
      <main>
        <div className="wrap tg-channel-page">
          <MobileHeader />
          <CatalogHeader
            pageName={t('LEFT_MENU_TELEGRAM_SME_ADMIN_BOT')}
            withPaySubscription={false}
          />
          <div className='flex flex-col 2xl:flex-row gap-4 w-full'>
            <div className="info__card">
              <div className="info-content">
                <div className="info-repeat flex flex-col gap-4">
                  <div className="info-repeat-title">
                    Зайти в бота SME-Admin можно нажав на кнопку ниже.
                  </div>
                  <div className="info-repeat-button">
                    <Link
                      to={{ pathname: TELEGRAM_SME_ADMIN_BOT }}
                      target="_blank"
                      className="button-link"
                    >
                      <button className="standart-btn">
                        <div>
                          <span>Зайти</span>
                        </div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  withRouter(
    TgChannelClubPage
  )
);
