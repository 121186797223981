import React, { Component } from "react";
import api from "@services/api";
import { Modal, Table } from "antd";

class CuratorList extends Component {
  state = {
    isCuratorListModalOpen: false,
    isCuratorListModalLoading: false,
    userCuratorListModalData: [],
  }

  openModal = async (userId) => {
    this.setState({
      isCuratorListModalOpen: true,
      isCuratorListModalLoading: true,
    })

    const {status, data} = await api.getReportUserCuratorList({user_id: userId})

    if (status === 200 && data?.data) {
      this.setState({
        userCuratorListModalData: data?.data ?? []
      })
    }

    this.setState({
      isCuratorListModalLoading: false
    })
  }

  closeModal = () => {
    this.setState({
      isCuratorListModalOpen: false,
      isCuratorListModalLoading: false,
      userCuratorListModalData: []
    })
  }

  curatorListColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">ID:</div>
          <div
            className="column-content-block st-table-num column_id">{id}</div>
        </div>
      ),
    },
    {
      title: 'Имя',
      dataIndex: 'first_name',
      key: 'first_name',
      render: (first_name) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Имя:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{first_name}</div>
        </div>
      ),
    },
    {
      title: 'Фамилия',
      dataIndex: 'last_name',
      key: 'last_name',
      render: (last_name) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Фамилия:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{last_name}</div>
        </div>
      ),
    },
    {
      title: 'Почта',
      dataIndex: 'email',
      key: 'email',
      render: (email) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Почта:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{email}</div>
        </div>
      ),
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Телефон:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{phone}</div>
        </div>
      ),
    },
    {
      title: 'Телеграмм',
      dataIndex: 'telegram',
      key: 'telegram',
      render: (telegram) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Телеграмм:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{telegram}</div>
        </div>
      ),
    },
    {
      title: 'Пакет',
      dataIndex: 'role_name',
      key: 'role_name',
      render: (role_name) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Пакет:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{role_name}</div>
        </div>
      ),
    },
  ];

  render() {
    const { isCuratorListModalOpen, isCuratorListModalLoading, userCuratorListModalData } = this.state
    const { id } = this.props

    return (
      <>
        <button onClick={() => this.openModal(id)}>
          Посмотреть всех вышестоящих
        </button>
        <Modal
          open={isCuratorListModalOpen}
          footer={false}
          onCancel={this.closeModal}
          width='max-content'
        >
          <Table
            className="m-2"
            dataSource={ userCuratorListModalData }
            loading={ isCuratorListModalLoading }
            pagination={ false }
            columns={ this.curatorListColumns }
          />
        </Modal>
      </>
    )
  }
}

export default CuratorList