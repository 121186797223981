import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const showTimePeriod = (loading, beginDate, endDate, timeZone) => (
  loading
    ? <Spin className="!ml-2 !-mt-3" indicator={<LoadingOutlined style={{ fontSize: 12 }} spin />} />
    : (
      beginDate && endDate
        ? (
          <>
            &nbsp;&nbsp;с&nbsp;
            {beginDate.format('DD/MM/YYYY HH:mm')}
            &nbsp;по&nbsp;
            {endDate.add(-1, 'minute').format('DD/MM/YYYY HH:mm')}
            &nbsp;
            {timeZone || '(время Московское)'}.
          </>
        )
        : <>&mdash;</>
    )
)

export default showTimePeriod;
