import React, { Component } from "react";
import api from "@services/api";
import { Button, List, message, Modal, Popconfirm } from "antd";

class DeleteMetaMask extends Component {
  state = {
    isDeleteMetaMaskModalOpen: false,
    deleteMetaMaskData: {
      user: {},
      wallet: {},
    },
  }

  openModal = (record) => {
    this.setState({
      isDeleteMetaMaskModalOpen: true,
      deleteMetaMaskData: {
        user: {
          id: record.id,
          name: `${record.first_name} ${record.last_name}`,
          email: record.email,
          phone: record.phone,
          telegram: record.telegram,
        },
        wallet: {
          id: record?.wallet_id,
          currency: record?.wallet_currency,
          dateCreate: record?.wallet_date_create,
          dateUpdate: record?.wallet_date_update,
          name: record?.wallet_name ?? '-',
          code: record?.wallet_code ?? '-',
          network: record?.wallet_external_chain ?? '-',
          address: record?.external_address ?? '-',
          dateSendToBlockchain: record?.date_sync_to_blockchain ?? '-',
          dateSendFromBlockchain: record?.date_sync_from_blockchain ?? '-',
          blockchainBalance: record?.blockchain_balance ?? '-',
        }
      }
    })
  }

  closeModal = () => {
    this.setState({
      isDeleteMetaMaskModalOpen: false,
      deleteMetaMaskData: {
        user: {},
        wallet: {}
      }
    })
  }

  deleteMetaMask = async (walletId) => {
    const {status, data} = await api.deleteMetaMask({wallet_id: walletId})

    if (status === 200 && data?.status === 0) {
      message.success(data?.message)
      this.setState((state) => ({
        deleteMetaMaskData: {
          user: state.deleteMetaMaskData.user,
          wallet: {
            ...state.deleteMetaMaskData.wallet,
            name: data?.data?.name ?? '-',
            code: data?.data?.code ?? '-',
            network: data?.data?.external_chain_id ?? '-',
            address: data?.data?.external_address ?? '-',
            dateSendToBlockchain: data?.data?.date_sync_to_blockchain ?? '-',
            dateSendFromBlockchain: data?.data?.date_sync_from_blockchain ?? '-',
            blockchainBalance: data?.data?.blockchain_balance ?? '-',
          }
        }
      }), async () => await this.props.update())
    } else {
      message.error(data?.message ?? 'Ошибка сервера')
    }
  }

  render() {
    const { isDeleteMetaMaskModalOpen, deleteMetaMaskData } = this.state
    const { data } = this.props


    return (
      <>
        <button onClick={() => this.openModal(data)}>
          Удалить кошелек MetaMask
        </button>

        <Modal
          open={isDeleteMetaMaskModalOpen}
          footer={(
            <div className='flex justify-center'>
              <Popconfirm
                title='Вы действиельно хотите удалить данные о внешнем кошельке МетаМаск'
                okText='Да'
                cancelText='Нет'
                onConfirm={() => this.deleteMetaMask(deleteMetaMaskData.wallet?.id)}
              >
                <Button>Удалить</Button>
              </Popconfirm>
            </div>
          )}
          onCancel={this.closeModal}
        >
          <List class='disable-block'
                size='small'
                header={(<h3>Удалить кошелек MetaMask</h3>)}
                style={{paddingTop: .75 + 'rem', margin: "0 30px"}}
          >

            <List header={(<h4>Пользователь</h4>)} size='small'>
              <List.Item style={{padding: 0, border: "none"}}>ID пользователя: <span>{deleteMetaMaskData.user?.id}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>ФИО: <span>{deleteMetaMaskData.user?.name}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Почта: <span>{deleteMetaMaskData.user?.email}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Телефон: <span>{deleteMetaMaskData.user?.phone}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Телеграмм: <span>{deleteMetaMaskData.user?.telegram}</span></List.Item>
            </List>

            <List header={(<h4>Кошелек</h4>)} size='small'>
              <List.Item style={{padding: 0, border: "none"}}>ID кошелька: <span>{deleteMetaMaskData.wallet?.id}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Валюта: <span>{deleteMetaMaskData.wallet?.currency}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Дата создания: <span>{deleteMetaMaskData.wallet?.dateCreate}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Дата обновления: <span>{deleteMetaMaskData.wallet?.dateUpdate}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Название: <span>{deleteMetaMaskData.wallet?.name}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Код: <span>{deleteMetaMaskData.wallet?.code}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Сеть: <span>{deleteMetaMaskData.wallet?.network}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Внешний адрес: <span>{deleteMetaMaskData.wallet?.address}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Дата отправки в Блокчейн: <span>{deleteMetaMaskData.wallet?.dateSendToBlockchain}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Дата получения из Блокчейна: <span>{deleteMetaMaskData.wallet?.dateSendFromBlockchain}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Баланс при получении из Блокчейна: <span>{deleteMetaMaskData.wallet?.blockchainBalance}</span></List.Item>
            </List>

          </List>

        </Modal>
      </>
    )
  }
}

export default DeleteMetaMask