import menuItemsText from '@layouts/main/leftMenu/menuItems';
import { ILeftMenuItem, TLeftMenuItemList } from '@layouts/main/leftMenu/types';
import checkAccessToMenuItem from '@src/helpers/checkAccessToMenuItem';
import { IUserInfo } from '@state/user';


const searchMenu = (listMenuItems: TLeftMenuItemList, routeValue: string): ILeftMenuItem | undefined => {
  for (let idx = 0; idx <  listMenuItems?.length; idx = idx + 1) {
    const listMenuItem = listMenuItems[idx];
    if (listMenuItem.route === routeValue) {
      return listMenuItem;
    }
    if (listMenuItem.children) {
      const childrenItem = searchMenu(listMenuItem.children, routeValue);
      if (childrenItem) {
        return childrenItem;
      }
    }
  }
  return undefined
}

const getAccessToMenuItem = (routeValue: string, userInfo?: IUserInfo) => {
  const menuItem = searchMenu(menuItemsText as TLeftMenuItemList, routeValue);
  return !menuItem || checkAccessToMenuItem(menuItem, userInfo);
}

export default getAccessToMenuItem;
