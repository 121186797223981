import MoneyStatistic from '@common/moneyStatistic';
import api from '@services/api';
import { ROLE_LIST_FOR_BUSINESS } from '@src/constants';
import store from '@state/store';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Spin } from 'antd';

import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import ShortStatistics from '@common/shortStatistics';
import { updateWalletById } from '@state/user';

import "./styles.less";

class WalletPage extends Component {
  state = {
    loading: false,
  }

  componentDidMount() {
    this.handleCheckBalance();
  }

  handleCheckBalance = async () => {
    const {wallets, updateUserWalletById} = this.props;

    this.setState({ loadingCheckBalance: true });

    const requests = [];

    wallets.forEach((wallet) => {
      requests.push(api.getWalletById(wallet.id));
    })

    const responses = await Promise.all(requests);

    responses.forEach((response) => {
      if (response.status === 200 && [0, 2000].includes(response.data?.status) && response.data?.wallet?.id) {
        store.dispatch(updateUserWalletById(response.data?.wallet));
      }
    });

    this.setState({ loadingCheckBalance: false })
  }

  render() {
    const { drawer, wallets, userInfo } = this.props;
    const { loadingCheckBalance } = drawer;

    if (!wallets || !wallets.length) {
      return null;
    }

    const enableMoneyStatistic = ROLE_LIST_FOR_BUSINESS.includes(userInfo?.role_id ?? -999);

    return (
      <main>
        <div className="wrap">
          <Spin spinning={loadingCheckBalance}>
            <MobileHeader />
            <CatalogHeader pageName='Кошелек' withPaySubscription={false}/>
            {enableMoneyStatistic && (
              <div className="info__card !p-0">
                <div className="info-content">
                  <div className="info-repeat">
                    <MoneyStatistic />
                  </div>
                </div>
              </div>
            )}
            <div className="info__card !p-0">
              <div className="info-content">
                <div className="info-repeat">
                  <ShortStatistics showIncome={false}/>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  wallets: state.user?.info?.wallets,
  drawer: state.drawer,
  userInfo: state.user.info,
});

const mapDispatchToProps = {
  updateUserWalletById: updateWalletById,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      WalletPage
    )
  )
);
