import CatalogHeader from '@common/catalogHeader';
import MediaPlayer from '@common/mediaPlayer';
import MobileHeader from '@common/mobileHeader';
import api from '@services/api';
import { Table } from 'antd';
import classNames from 'classnames';
import React, { Component } from 'react';

import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';

import { ReactComponent as PlayIcon } from '@ui/icons/play.svg';

import "./styles.less";

const emptyPlayerState = {
  showPlayer : false,
  url: '',
  title: '',
  isAudio: false,
  playing: true,
  hls: false,
}

class Promo20231001Page extends Component {
  state = {
    transactions: [],
    loading: false,
    ...emptyPlayerState,
  }

  componentDidMount() {
    this.getTransactionList();
  }

  getTransactionList = async () => {
    this.setState({loading: true});

    const { status, data } = await api.getPromo1();

    if (status === 200 && data?.list?.length) {
      this.setState({
        transactions: data.list.filter((x) => Number(x.spv) > 0),
      });
    }

    this.setState({loading: false});
  }

  handleClose = () => {
    this.setState({
      ...emptyPlayerState,
      playing: false,
    });
  }

  render() {
    const { userInfo } = this.props;
    const { transactions, loading, showPlayer, url, title, isAudio, hls } = this.state;

    const columns = [
      {
        title: '№ п/п',
        dataIndex: 'key',
        className: 'column column_rowNum',
        render: (data, record, idx) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">№ п/п:</div>
            <div className="column-content-block">{idx + 1}</div>
          </div>
        )
      },
      {
        title: 'Сумма личных баллов',
        dataIndex: 'spv',
        key: 'spv',
        className: 'column column_spv',
        render: (sum) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Сумма личных баллов:</div>
            <div className="column-content-block">
              <NumericFormat
                value={Number(sum)}
                displayType={'text'}
                decimalScale={1}
                thousandSeparator=" "
              />
            </div>
          </div>
        ),
      },
      {
        title: 'ID пользователя',
        dataIndex: 'user_id',
        key: 'user_id',
        className: 'column column_userId',
        render: (user_id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">ID пользователя:</div>
            <div className="column-content-block">{user_id}</div>
          </div>
        ),
      },
      {
        title: 'ФИО',
        dataIndex: 'last_name',
        key: 'last_name',
        className: 'column',
        render: (_, record) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Фамилия:</div>
            <div className="column-content-block hidden lg:block">
              {record.last_name} {record.first_name}
            </div>
            <div className="column-content-block block lg:hidden">
              {record.last_name} {record.first_name} (ID: {record.user_id})
            </div>
          </div>
        ),
      },
    ];

    const videoUrl = '68aa8e2b516d712acbb2257605fdb3bdde9929bf390ca11fc09777c9cf89874c';
    const poster = [
      process.env.REACT_APP_CDN_SELECTEL,
      process.env.REACT_APP_CDN_SELECTEL_URL_TO_VIDEO,
      videoUrl,
      'poster.png',
    ].join('/');

    return (
      <main className="promo1-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName='Твоя поездка в Шанхай!' withPaySubscription={false}/>
          <div className="info__card">
            <div className="info-item__video">
              <div className="fac__less bas__less">
                <img src={poster} alt="" className="info__video-bg" />
                <div className="info__video--a">
                  <div className="info-video__text">&nbsp;</div>
                  <div
                    className="info__play"
                    onClick={() => this.setState({
                      showPlayer: true,
                      playing: true,
                      title: 'Твоя поездка в Шанхай!',
                      isAudio: false,
                      url: '68aa8e2b516d712acbb2257605fdb3bdde9929bf390ca11fc09777c9cf89874c',
                      hls: true,
                    })}
                  >
                    <PlayIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="info__card">
            <h2 className="pb-1 text-lg">Условия промоушена:</h2>
            <ul className="list-disc pl-6">
              <li><strong>Кто может участвовать:</strong> Резиденты The SoulMate Ambassador's Club.</li>
              <li><strong>Время действия:</strong> с 09/10/2023 0:00 по 31/12/2023 23:59 (время Московское).</li>
              <li><strong>Условия получения награды:</strong> сделать 50 личных единиц товарооборота и более.</li>
              <li><strong>Количество путевок:</strong> 25 шт (учитывая "+1" для резидентов от 5 ступени).</li>
            </ul>
          </div>
          <div className="info__card description">
            <h2 className="pb-1 text-lg">Описание промоушена:</h2>
            <p>Задача - отдыхать и получать удовольствие!
              <ul className="list-disc pl-6">
                <li>Мы всё сделали за тебя!</li>
                <li>Перелеты туда и обратно </li>
                <li>Трансфер и проживание на все дни путешествия </li>
                <li>Активная программа и интересные приключения </li>
              </ul>
            </p>
            <p>
              Город будущего - <strong>УЖЕ ЖДЁТ ТЕБЯ ЭТОЙ ВЕСНОЙ !</strong>
            </p>
            {/*<p>*/}
            {/*  😎 Условия получения награды:*/}
            {/*  Только для Амбассадоров Компании.*/}
            {/*  Начиная с 9.10.2023 и до 31.12.2023 до 23:59 по мск, необходимо выполнить 50 ЛИЧНЫХ единиц товарооборота.*/}
            {/*</p>*/}
            <h2>
              <strong>
                ДЕЙСТВУЙ ! ⚡️
              </strong>
            </h2>
            <p>
              1-4 ступень - едет за счёт компании только Амбассадор, который выполнил условия.
            </p>
            <p>
              <strong>Бонус для лидеров команд:</strong><br />
              От 5 ступени и выше - возможность поехать в поездку за счёт компании 1+1
            </p>
          </div>
          {/*<div className="static__contents rounded-4">*/}
          <div className="info__card">
            <div className="static__cont">
              <h2 className="pb-1 text-lg">Список участников:</h2>
              <Table
                sticky={true}
                pagination={false}
                tableLayout={'auto'}
                dataSource={transactions}
                columns={columns}
                loading={loading}
                rowKey={(record) => record.user_id}
                rowClassName={(record) => classNames(
                  "st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0",
                  Number(record.spv || 0) < 10
                    ? 'red'
                    : (
                      Number(record.spv || 0) < 30
                        ? 'yellow'
                        : (
                          Number(record.spv || 0) < 50
                            ? 'blue'
                            : 'green'
                        )
                    ),
                  (Number(record.user_id || -1) === Number(userInfo?.id || 0)) && 'current-user'
                )}
                className='static-table'
              />
            </div>
          </div>
          <MediaPlayer showPlayer={showPlayer} title={title} url={url} handleClose={this.handleClose} isAudio={isAudio} hls={hls} />
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  Promo20231001Page
);
