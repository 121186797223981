import React, { Component } from "react";
import { ROLE_AMBASSADOR } from "@src/constants";
import { List, message, Modal, Spin } from "antd";
import api from "@services/api";

class ShowDetail extends Component {
  state = {
    isDetailModalOpen: false,
    detailModalData: {
      curator: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        telegram: ''
      },
      dateRegister: "",
      dateInvite: "",
      nftType: "",
      level: {},
      score: {},
      status: '',
      role: '',
      moreInfo: [],
      moreInfoLoading: false,
    },
  }

  getMoreInfo = async (userId) => {
    this.setState(prevState => ({
      detailModalData: {
        ...prevState.detailModalData,
        moreInfoLoading: true,
      }
    }))

    const {status, data} = await api.getUserInfo({user_id: userId})

    if (status === 200 && data?.status === 0) {
      let moreInfo = data?.data?.userInfo;

      if (moreInfo.length > 0) {
        moreInfo = moreInfo.filter(item => item.disabled === 0)
      }

      this.setState(prevState => ({
        detailModalData: {
          ...prevState.detailModalData,
          moreInfo: moreInfo,
          moreInfoLoading: false,
        }
      }))
      return;
    }

    if (status === 200) {
      message.warning(data?.message);
    }

    this.setState(prevState => ({
      detailModalData: {
        ...prevState.detailModalData,
        moreInfoLoading: false,
      }
    }))
  }

  openModal = (record) => {
    this.getMoreInfo(record?.id);

    this.setState(prevState => ({
      isDetailModalOpen: true,
      detailModalData: {
        id: record?.id,
        curator: {
          firstName: record?.curator?.first_name ?? '-',
          lastName: record?.curator?.last_name ?? '-',
          email: record?.curator?.email ?? '-',
          phone: record?.curator?.phone ?? '-',
          telegram: record?.curator?.telegram ?? '-'
        },
        dateCreate: record?.date_create ?? '',
        dateInvite: record?.invite_to_base ?? '',
        nftType: (
          [ROLE_AMBASSADOR].includes(record?.role_id)
            ? (record?.is_genesis === 1 ? 'Генезис' : 'Легендари')
            : '-'
        ),
        level: {
          level: record?.level,
          hand_level: record?.hand_level
        },
        score: {
          pv: record?.pv,
          nv: record?.nv,
          av: record?.av,
        },
        status: record?.status?.name,
        role: record?.role?.name,
        moreInfo: prevState.detailModalData.moreInfo,
        moreInfoLoading: prevState.detailModalData.moreInfoLoading,
      }
    }))
  }

  closeModal = () => {
    this.setState(prevState => ({
      isDetailModalOpen: false,
      detailModalData: {
        curator: {},
        dateRegister: "",
        dateInvite: "",
        nftType: {},
        level: {},
        score: {},
        status: '',
        role: '',
        moreInfo: [],
        moreInfoLoading: prevState.detailModalData.moreInfoLoading,
      }
    }))
  }

  render() {
    const { isDetailModalOpen, detailModalData} = this.state

    const { data, moreInfoLoading } = this.props

    return (
      <>
        <button disabled={moreInfoLoading} onClick={() => this.openModal(data)}>
          Детальная информация
        </button>
        <Modal
          width={'40%'}
          open={isDetailModalOpen}
          footer={false}
          onCancel={() => this.closeModal()}
        >
          <List
            class="disable-block"
            size="small"
            header={(<h3>Детальная информация по пользователю</h3>)}
            style={{paddingTop: .75 + 'rem', margin: "0 30px"}}
          >
            <List.Item style={{padding: 0, border: "none"}}>
              ID: <span>{detailModalData?.id ?? '-'}</span>
            </List.Item>

            <List.Item style={{padding: 0, border: "none"}}>
              Статус: <span>{detailModalData?.status ?? '-'}</span>
            </List.Item>

            <List.Item style={{padding: 0, border: "none"}}>
              Роль: <span>{detailModalData?.role ?? '-'}</span>
            </List.Item>

            <List.Item style={{padding: 0, border: "none"}}>
              Тип NFT: <span>{detailModalData?.nftType ?? '-'}</span>
            </List.Item>

            <List.Item style={{padding: 0, border: "none"}}>
              Дата регистрации: <span>{detailModalData?.dateCreate ?? '-'}</span>
            </List.Item>
            <List.Item style={{padding: 0, border: "none"}}>
              Дата приглашения на БК: <span>{detailModalData?.dateInvite ?? '-'}</span>
            </List.Item>

            <List header={(<h4>Уровень</h4>)} size='small'>
              <List.Item style={{
                padding: 0,
                border: "none"
              }}>Рассчетный: <span>{detailModalData?.level?.level ?? '-'}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Назначенный:
                <span>
                        {
                          detailModalData?.level?.hand_level === 0 || detailModalData?.level?.hand_level === null || detailModalData?.level?.hand_level === undefined
                            ? '-'
                            : (
                              detailModalData?.level?.hand_level < 0
                                ? `${(-1) * detailModalData?.level?.hand_level} (понижение)`
                                : detailModalData?.level?.hand_level
                            )
                        }
                      </span>
              </List.Item>
            </List>

            <List header={(<h4>Баллы</h4>)} size='small'>
              <List.Item style={{padding: 0, border: "none"}}>Личный
                объем: <span>{detailModalData.score?.pv}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Сетевой
                объем: <span>{detailModalData.score?.nv}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Общий
                объем: <span>{detailModalData.score?.av}</span></List.Item>
            </List>

            <List header={(<h4>Куратор</h4>)} size='small'>
              <List.Item style={{
                padding: 0,
                border: "none"
              }}>ФИО: <span>{detailModalData.curator.firstName} {detailModalData.curator.lastName}</span></List.Item>
              <List.Item
                style={{padding: 0, border: "none"}}>Почта: <span>{detailModalData.curator.email}</span></List.Item>
              <List.Item
                style={{padding: 0, border: "none"}}>Телефон: <span>{detailModalData.curator.phone}</span></List.Item>
              <List.Item style={{padding: 0, border: "none"}}>Телеграм: <span>{detailModalData.curator.telegram}</span></List.Item>
            </List>

            <Spin spinning={detailModalData?.moreInfoLoading}>
              <List header={(<h4>Дополнительные данные</h4>)} size='small'>
                {detailModalData?.moreInfo.map(i => {
                  return (<List.Item style={{
                    padding: 0,
                    border: "none"
                  }}>{i?.params?.description ?? i?.params?.code}: <span>{i?.value}</span></List.Item>)
                })}
              </List>
            </Spin>
          </List>
        </Modal>
      </>
    )
  }
}

export default ShowDetail