import { Component } from "react";
import { Button } from "antd";
import ModalExportCSV from "@common/exportCSV/modal";

/**
 * ExportCSV компонент для экспортирования произвольных данных в формате CSV.
 * @param {Array[Array]} exportData - prop callback для получения пользовательских данных.
 */
class ExportCSV extends Component {

  constructor(props) {
    super(props);
    this.state = {modalOpen: false}
  }

  handleClick() {
    this.setState({modalOpen: true})
  }

  handleClose() {
    this.setState({modalOpen: false})
  }

  render() {
    const {columns, getData, fileName, children} = this.props;

    return (
      <>
        <ModalExportCSV
          getModalOpen={this.state.modalOpen}
          modalClose={() => {
            this.handleClose()
          }}
          cols={columns}
          data={getData}
          fileName={fileName}
        />
        <Button onClick={() => {
          this.handleClick()
        }}>
          {children}
        </Button>

      </>
    )
  }
}

export default ExportCSV;
