import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import ReferralsLinkTextBlock from '@common/referralsLinkTextBlock';
import React, { Component } from 'react';

import "./styles.less";

class BonusProgramsPage extends Component {
  render() {
    return (
      <main>
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName='Бонусная программа' withPaySubscription={false}/>
          <div className="info__card">
            <div className="info-content">
              <div className="info-repeat">
                <div className="info__code ref-link__code">
                  <ReferralsLinkTextBlock />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default BonusProgramsPage;
