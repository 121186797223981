import React, { Component } from "react";
import api from "@services/api";
import { message } from "antd";

class WantToNextFlow extends Component {

  register = async (userId) => {
    const {status, data} = await api.updateWantToNextFlow({user_id: userId});

    if (status === 200 && data?.status === 0) {
      message.success(data?.message);
      this.props.update()
    } else {
      message.warning(data?.message);
    }
  }

  cancelRegistration = async (userId) => {
    const {status, data} = await api.disableNextFlow({
      type: 'moover',
      user_id: userId
    });

    if (status === 200 && data?.status === 0) {
      message.success(data?.message);
      this.props.update()
    } else {
      message.warning(data?.message);
    }
  }

  render() {
    const { wantToNextFlow, loading, id } = this.props

    return (
      <>
        {wantToNextFlow ? (
            <button disabled={loading} onClick={() => this.cancelRegistration(id)}>
              Отмена записи на перенос обучения
            </button>
          ): (
            <button disabled={loading} onClick={() => this.register(id)}>
              Запись на перенос обучения
            </button>
          )}
      </>
    )
  }
}

export default WantToNextFlow