import { SoulHideBlock } from '@common/soulHideBlock';
import api from '@services/api';
import getTransactionDetailTable from '@src/helpers/getTransactionDetailTable';
import { isEqual } from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { Popover, Table } from 'antd';
import dayjs from 'dayjs';

import { CheckOutlined, QuestionCircleOutlined, StopOutlined } from '@ant-design/icons';
import {
  DECIMAL_SCALE,
  ROLE_BEGINNER,
  ROLE_STUDENT_ACADEMY,
  ROLE_STUDENT_ACADEMY_PLUS,
  ROLE_STUDENT_BASE,
  ROLE_STUDENT_BASE_PLUS,
  ROUTE_TRANSACTIONS,
} from '@src/constants';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './styles.less';

const COUNT_OF_TRANSACTION_LIST = 5;

class ShortTransactions extends Component {
  state = {
    transactions: [],
    loading: false,
    openedInfo: undefined,
  }

  componentDidMount() {
    const { userInfo } = this.props;
    this.getTransactionListShort(userInfo.currency_id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevUserInfoBalance = prevProps.userInfo?.wallets?.find((x) => x.id === prevProps.currentWalletId);
    const currentUserInfoBalance = this.props.userInfo?.wallets?.find((x) => x.id === this.props.currentWalletId);

    if (
         !isEqual(prevUserInfoBalance, currentUserInfoBalance)
      || !isEqual(prevProps.currentWalletId, this.props.currentWalletId)
    ) {
      this.getTransactionListShort(this.props.currentWalletId);
    }
  }

  getTransactionListShort = async (wallet_id) => {
    this.setState({loading: true});

    const { status, data } = await api.getUserTransactionListShort(wallet_id, COUNT_OF_TRANSACTION_LIST);

    if (status === 200 && data && data.length) {
      this.setState({ transactions: data });
    }

    this.setState({loading: false});
  }

  setOpenedInfo = (user_id) => {
    const { openedInfo } = this.state;

    if (openedInfo === user_id) {
      this.setState({ openedInfo: undefined })
    } else {
      this.setState({ openedInfo: user_id })
    }
  }

  render() {
    const { t, userInfo, currentWalletId, eyeChange, hidden = false } = this.props;
    const { transactions, loading } = this.state;

    if (
      !userInfo
      || userInfo.id === null
      || userInfo.id === undefined
      || [
        // ROLE_BEGINNER,
        ROLE_STUDENT_BASE,
        ROLE_STUDENT_ACADEMY,
      ].includes(userInfo.role_id)
    ) {
      return null;
    }

    const hideAllTransactions = [
      ROLE_BEGINNER,
      ROLE_STUDENT_BASE_PLUS,
      ROLE_STUDENT_ACADEMY_PLUS
    ].includes(userInfo.role_id);

    const columns = [
      {
        title: 'Дата',
        dataIndex: 'date_create',
        key: 'date_create',
        render: (date) => <div className="date">{dayjs(date).format('DD.MM.YY')}</div>,
      },
      {
        title: 'Тип',
        dataIndex: 'transaction_type_id',
        key: 'transaction_type_id',
        render: (type) => <div className="type">{t(`TRANSACTION_TYPE_SHORT_${type}`)}</div>,
      },
      {
        title: 'Сумма',
        dataIndex: 'sum',
        key: 'sum',
        render: (sum, record) => (
          <div className="value">
            <NumericFormat
              value={sum * 1}
              displayType={'text'}
              decimalScale={DECIMAL_SCALE}
              thousandSeparator=" "
            />
            &nbsp;
            {record.currency?.code}
          </div>
        ),
      },
      {
        title: 'Статус',
        key: 'transaction_status_id',
        dataIndex: 'transaction_status_id',
        render: (status) => (
          <div className={`status ${[2].includes(status) ? 'status-ok' : ([-1, 10].includes(status) ? 'status-error' : '') }`}>
            <span className="!hidden lg:!inline-block">{t(`TRANSACTION_STATUS_${status}`)}</span>
            <span className="!inline-block lg:!hidden">
              {status === 2
                ? <CheckOutlined />
                : (
                  [-1, 10].includes(status)
                    ? <StopOutlined />
                    : <>&nbsp;</>
                )
              }
            </span>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: `id_moreInfo`,
        render: (id, record) => {
          const content = getTransactionDetailTable(record);
          return content && !hidden
            ? (
              <Popover
                title="Детальная информация по транзакции"
                placement='topRight'
                // open={openedInfo === record.id}
                content={content}
              >
                <div
                  className="st__form-moreInfo"
                  onClick={() => this.setOpenedInfo(record.id)}
                >
                  <QuestionCircleOutlined />
                </div>
              </Popover>
            )
            : null
        },
      },
    ];

    return (
      <div className="history">
        <div className="history-head">
          <span>История транзакций</span>
          {!hideAllTransactions && (
            <Link to={ROUTE_TRANSACTIONS} className='button'>Все</Link>
          )}
        </div>
        <div className="relative">
          <Table
            loading={loading}
            dataSource={transactions.filter((x) => x.user_address_id === currentWalletId)}
            columns={columns}
            showHeader={false}
            pagination={false}
            rowKey={(record) => record.id}
          />
          <SoulHideBlock hidden={hidden} eyeChange={eyeChange} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    ShortTransactions
  )
);
