import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { SoulHideBlock } from '@common/soulHideBlock';

import { ReactComponent as InfinityIcon } from '@ui/icons/infinity.svg';

class Level extends Component {
  render() {
    const { user, eyeChange, strong = false, text = 'ступень', hidden = false } = this.props;

    if (!user?.level) {
      return null;
    }

    const level = user?.id === 2
      ? (
        strong
          ? <InfinityIcon />
          : <span className="text-lg leading-0 align-middle">∞</span>
      )
      : user?.level;
    const content = strong ? <strong>{level}</strong> : level;
    return (
      <>
        <div className="relative">
          {content}
          <SoulHideBlock hidden={hidden} eyeChange={eyeChange} />
        </div>
        {text?.length ? ' '.concat(text) : ''}
      </>
    );
  }
}

export default withTranslation()(
  Level
);
