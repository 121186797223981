import Avatar from '@common/avatar';
import BalanceInfo from '@common/balanceInfo';
import api from '@services/api';
import storage from '@services/storage';
import { APPSTAGE_SIGNIN, AUTH_COOKIE_NAME, ROUTE_ROOT, STORAGE_AUTH_BY_USER } from '@src/constants';
import setAuthToAdmin from '@src/helpers/setAuthToAdmin';
import { setAppStage } from '@state/appStage';
import store  from '@state/store';
import { setClearUserExtraInfo } from '@state/user';
import React, { Component } from 'react';
// import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ReactComponent as ThemeLightIcon } from '@ui/icons/themeLight.svg';
import { ReactComponent as ThemeDarkIcon } from '@ui/icons/themeDark.svg';
import { ReactComponent as SearchIcon } from '@ui/icons/search.svg';
// import { ReactComponent as NotificationIcon } from '@ui/icons/notification.svg';
import { ReactComponent as LogoutIcon } from '@ui/icons/logout.svg';

import './styles.less';
import { withRouter } from 'react-router-dom';

class TopBar extends Component {

  // state = {
  //   selectedDevicesPopoverVisible: false,
  //   notificationsPopoverVisible: false,
  //   notificationsSettingsVisible: false,
  //   selectedDevices: [],
  //   dateTime: moment().format('DD.MM.YYYY HH:mm'),
  //   clientHeight: 300,
  // };

  // notificationsTimer = null;

  // render() {
  //   return (
  //     <Header className={styles.topBar}>
  //       <div className={styles.inner}>
  //         <div className = {styles.lighter}>
  //           <div className = {styles.lighterBox}>
  //             <BulbOutlined />
  //             <BulbFilled />
  //           </div>
  //           <div className = {styles.lighterText} >
  //             Светлая тема
  //           </div>
  //         </div>
  //       </div>
  //     </Header>
  //   );
  // }

  renderLeftHeader = () => {
    const { showDayNight, showSearch } = this.props ;

    return (
      <div className="header-left">
        {showDayNight && (
          <div className="header-theme">
            <div className="header-theme-setting">
              <div className="header-theme-item active">
                <ThemeLightIcon/>
              </div>
              <div className="header-theme-item">
                <ThemeDarkIcon/>
              </div>
            </div>
            <span>Светлая тема</span>
          </div>
        )}
        {showSearch && (
          <form className="header-search items-center">
            <input type="text" name="s" placeholder="Поиск" />
            <button>
              <SearchIcon />
            </button>
          </form>
        )}
      </div>
    );
  }

  handleLogout = async () => {
    const { setAppStageData, history } = this.props;
    // const dispatch = useAppDispatch();

    // clearUserInfo();
    if (await api.logout()) {
      history.push(ROUTE_ROOT);

      const token = undefined;
      storage.set(AUTH_COOKIE_NAME, token);
      await api.setAUTHToken(token);
      // socket.close();

      await store.dispatch(setAppStageData(APPSTAGE_SIGNIN));
    }
  }

  authByAdmin = async (e, adminInfo) => {
    const { history } = this.props;

    e.preventDefault();
    if (adminInfo && adminInfo.user_id) {
      await setAuthToAdmin(store.dispatch, adminInfo, history);
    }
  }

  render() {
    const { userInfo, isMobile = false } = this.props;

    if (isMobile) {
      return this.renderLeftHeader();
    }

    if (!userInfo || userInfo.id === null || userInfo.id === undefined) {
      return null;
    }

    const adminAuthByUser = storage.get(STORAGE_AUTH_BY_USER);

    // const userInfo = {
    //   avatar: "/images/avatar.png",
    //   messages: [
    //     {
    //       text: [
    //         "На курсе MetaVerse добавлен",
    //         "1 новый урок",
    //       ],
    //     },
    //     {
    //       text: "Вы оплатили подписку до 2022-09-25",
    //     }
    //   ]
    // }

    return (
      <div className="header">
        {this.renderLeftHeader()}
        <div className="header-right">
          <div className="header-item header-message">
            {/* TODO: Сделать отображение уведомлений */}
            {/*<NotificationIcon />*/}
            {/*<div className="header-message-active"></div>*/}
            {/*<div className="header-message-content" style="display: none">*/}
            {/*  <strong>*/}
            {/*    На курсе MetaVerse добавлен <br />*/}
            {/*    1 новый урок*/}
            {/*  </strong>*/}
            {/*  <span>13 минут назад</span>*/}
            {/*</div>*/}
          </div>
          {adminAuthByUser?.user_id && (
            <div className="header-user">
              <button onClick={(e) => this.authByAdmin(e, adminAuthByUser)}>
                <Avatar size={40} user={adminAuthByUser.info}/>
              </button>
            </div>
          )}
          <div className="header-user">
            <Avatar size={40} />
          </div>
          <div className="header-wallet">
            <BalanceInfo />
          </div>
          <div className="header-item">
            <LogoutIcon onClick={this.handleLogout} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
//   appStage: state.appStage,
//   notificationsCounter: state.notifications.count_new,
//   notifications: state.notifications.notificationsData,
  userInfo: state.user.info,
});

const mapDispatchToProps = {
  setAppStageData: setAppStage,
  clearUserInfo: setClearUserExtraInfo(),
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      TopBar
    )
  )
);

// export default withTranslation()(
//   withRouter(
//     TopBar
//   )
// );
