const defaultConfig = {
  ajax_request_timeout: 100000,
  main_api_prefix: '/api',
  websocket_prefix: 'ws://localhost:8100/ws',
  logger_prefix: '/logger',
  lang: 'ru',
};

const branches = {
  production: {
    websocket_prefix: `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window?.location?.host}/ws`,
  },

  development: {
    ajax_request_timeout: 100000,
  },

  test: {},
};

const branch = process.env.NODE_ENV;

const config = {
  ...defaultConfig,
  ...branches[branch],
};

export default config;
