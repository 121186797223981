import { Amount } from '@common/amount';
import { DECIMAL_SCALE } from '@src/constants';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import PayIn from '@common/drawer/payIn';
import { closeDrawer, setDrawerContent } from '@state/drawer';
import store from '@state/store';

import { ReactComponent as CloseIcon } from '@ui/icons/close.svg';

class BuyProduct extends Component {
  state = {
    delta: undefined,
    deltaWithFee: undefined,
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { product, wallet } = this.props;

    if (
      prevProps.product.current_price_with_sale !== product.current_price_with_sale
      || prevProps.wallet?.id !== wallet?.id
      || prevProps.wallet?.balance !== wallet?.balance
    ) {
      this.updateData();
    }
  }

  updateData = () => {
    const { product, wallet } = this.props;

    const delta = Number(product.current_price_with_sale) - Number(wallet?.balance);
    const deltaWithFee = delta + Number(wallet?.currency_commission_pay_in);

    this.setState({
      delta,
      deltaWithFee: deltaWithFee > Number(wallet?.currency_min_pay_in)
        ? deltaWithFee
        : Number(wallet?.currency_min_pay_in),
    })
  }

  handlePayIn = () => {
    const { product, wallet, updateDrawerContent } = this.props;
    const { delta, deltaWithFee } = this.state;

    store.dispatch(
      updateDrawerContent({
        content: PayIn,
        params: {
          product: product,
          wallet: wallet,
          delta: delta,
          deltaWithFee: deltaWithFee,
        },
      })
    );
  }

  render() {
    const { setCloseDrawer, wallet } = this.props;
    const { delta, deltaWithFee } = this.state;

    return (
      <div id="modal10" className="modal1 modal modal-asset !justify-center">
        <div className="modal__block-close">
          <CloseIcon onClick={setCloseDrawer} />
        </div>
        <div className="modal__window">
          <div className="modal__head">
            <h1>Недостаточно монет</h1>
            <div className="text-xl mt-8">
              На вашем балансе сейчас
              {delta && wallet
                ? (
                  <>
                    :
                    <br />
                    <Amount
                      wallet={wallet}
                      value={wallet?.balance}
                      // digits={DECIMAL_SCALE}
                      className="!font-semibold whitespace-nowrap"
                      key={wallet?.id}
                      withNetWorkCode={true}
                      withHyphen={true}
                    />
                    <br />
                    <div className="mt-4">Этого недостаточно для покупки данного продукта.</div>
                  </>
                )
                : <div>не хватает монет для покупки данного продукта.</div>
              }
              <br />
              <p className="!text-xl">
                Пожалуйста, пополните специально созданный для вас кошелек на сумму, как минимум:<br />
                <Amount
                  wallet={wallet}
                  value={Number(deltaWithFee)}
                  digits={DECIMAL_SCALE}
                  className="!font-semibold whitespace-nowrap"
                  key={`delta_${wallet?.id}`}
                  withNetWorkCode={true}
                  withHyphen={true}
                />
                <br />
                {wallet?.currency_commission_pay_in && (
                  <>
                    (в т.ч. комиссия <Amount wallet={wallet} value={wallet?.currency_commission_pay_in} withSpace={false} />)
                  </>
                )}
              </p>
            </div>
            <button className="standart-btn" onClick={this.handlePayIn}>
              <div>Пополнить</div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setCloseDrawer: closeDrawer,
  updateDrawerContent: setDrawerContent,
}

export default connect(null, mapDispatchToProps)(
  BuyProduct
);
