import { ROUTE_ROOT } from '@src/constants';
import { ReactComponent as HomeIcon } from '@ui/icons/home.svg';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as FacultyIcon } from '@ui/icons/faculty.svg';
import { ReactComponent as ShopIcon } from '@ui/icons/shopColor1.svg';

import "./styles.less";

interface Props {
  pageName: string;
  parentPageName?: string;
  parentPageLink?: string;
  facultiesCount?: number;
  withPaySubscription: boolean;
  className?: string;
}

const CatalogHeader: FC<Props> = ({ pageName, parentPageName, parentPageLink, facultiesCount, withPaySubscription= false, className }) => (
  <div className={classNames("catalog-head", className)}>
    <div className="catalog__headt">
      <Link to={ROUTE_ROOT}>
        <HomeIcon />
      </Link>
      {parentPageName && (
        <>
          &nbsp;/&nbsp;
          {parentPageLink
            ? <Link to={parentPageLink}>{parentPageName}</Link>
            : parentPageName
          }
        </>
      )}
      &nbsp;/&nbsp;
      {pageName}
    </div>
    <div className="catalog__headb">
      <div className="cat__h">
        <h1>{pageName}</h1>
        {facultiesCount && (
          <div className="catalog__more">
            <FacultyIcon />
            <span>{facultiesCount} факультетов</span>
          </div>
        )}
      </div>
      {withPaySubscription && (
        <div className="cat__but">
          <button>
            <ShopIcon />
            Оплатить доступ
          </button>
        </div>
      )}
    </div>
  </div>
);

export default CatalogHeader;
