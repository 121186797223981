import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import {
  ROUTE_AMBASSADORS_CLUB_MY_NFT,
  ROUTE_AMBASSADORS_CLUB_PROGRAMS,
  ROUTE_AMBASSADORS_CLUB_STRUCTURE,
} from '@src/constants';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
class AmbassadorsClubPage extends Component {
  render() {
    const { t } = this.props;

    return (
      <main className="promo-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName={t('LEFT_MENU_AMBASSADORS_CLUB')} withPaySubscription={false}/>
          <div className="info__card">
            <h2 className="pb-1 text-lg">Список подразделов:</h2>
            <ul className="list-disc pl-6">
              <li><Link to={ROUTE_AMBASSADORS_CLUB_MY_NFT}>{t('LEFT_MENU_AMBASSADORS_CLUB/MY_NFT')}</Link></li>
              <li><Link to={ROUTE_AMBASSADORS_CLUB_PROGRAMS}>{t('LEFT_MENU_AMBASSADORS_CLUB/PROGRAMS')}</Link></li>
              <li><Link to={ROUTE_AMBASSADORS_CLUB_STRUCTURE}>{t('LEFT_MENU_AMBASSADORS_CLUB/STRUCTURE')}</Link></li>
            </ul>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  AmbassadorsClubPage
);
