import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import {
  ROUTE_PROMO_TRAVEL,
  // ROUTE_PROMO_JUNIOR,
  // ROUTE_PROMO_WATCH,
} from '@src/constants';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
class PromoPage extends Component {
  render() {
    const { t } = this.props;

    return (
      <main className="promo-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName='Промоушены' withPaySubscription={false}/>
          <div className="info__card">
            <h2 className="pb-1 text-lg">Список промоушенов:</h2>
            <ul className="list-disc pl-6">
              <li><Link to={ROUTE_PROMO_TRAVEL}>{t('LEFT_MENU_PROMO/TRAVEL')}</Link></li>
              {/*<li><Link to={ROUTE_PROMO_JUNIOR}>{t('LEFT_MENU_PROMO/JUNIOR')}</Link></li>*/}
              {/*<li><Link to={ROUTE_PROMO_WATCH}>{t('LEFT_MENU_PROMO/WATCH')}</Link></li>*/}
            </ul>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  PromoPage
);
