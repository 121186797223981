import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FORM_FEEDBACK, FORM_LOGIN, ROUTE_ROOT } from '@src/constants';
import React, { FC, MouseEvent, ReactNode } from 'react';
import { Link, useHistory } from 'react-router-dom';

interface OwnProps {
  setFormType: (formType: string) => void;
  isError: boolean;
  isSuccess: boolean;
  showGoHomeButton?: boolean;
  onClickButtonGoHome?: (e: MouseEvent<HTMLButtonElement>) => void;
  textMessage: string | ReactNode;
  additionalLink?: ReactNode;
  additionalButton?: ReactNode;
  children: ReactNode;
}

type Props = OwnProps;

const FormWithMessage: FC<Props> = ({
  setFormType,
  isError,
  isSuccess,
  showGoHomeButton = true,
  onClickButtonGoHome,
  textMessage,
  additionalLink,
  additionalButton,
  children,
}) => {
  const history = useHistory();

  const handleSendFeedBack = () => {
    setFormType(FORM_FEEDBACK);
    history.push(ROUTE_ROOT);
  }

  const handleGoHome = (e: MouseEvent<HTMLButtonElement>) => {
    setFormType(FORM_LOGIN);
    onClickButtonGoHome && onClickButtonGoHome(e);
    history.push(ROUTE_ROOT);
  }

  return (
    isError || isSuccess
      ? (
        <div className="form__content !mb-0">
          <div className='flex flex-col'>
            <div className='flex flex-row items-center gap-10'>
              <div className='flex items-center text-9xl '>
                {isError && <ExclamationCircleOutlined className='text-red-600' />}
                {isSuccess && <CheckCircleOutlined className='text-green-600'/>}
              </div>
              <div className=''>
                <div className='p !mt-0'>{textMessage}</div>
                {isError && (
                  <p>
                    Попробуйте
                    &nbsp;
                    {!!additionalLink && (
                      <>
                        {additionalLink},
                        &nbsp;
                      </>
                    )}
                    обратиться к своему Куратору или
                    &nbsp;
                    <Link to={ROUTE_ROOT} className='underline' onClick={handleSendFeedBack}>
                      написать в Тех.поддержку
                    </Link>
                  </p>
                )}
              </div>
            </div>
            <div className='w-full text-center form__block'>
              {additionalButton}
              {showGoHomeButton && (
                <button className='standart-btn !mb-0' onClick={handleGoHome}>
                  <div>Вернуться на Главную</div>
                </button>
              )}
            </div>
          </div>
        </div>
      )
      : <div>{children}</div>
  );
};

export default FormWithMessage;
