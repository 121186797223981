import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import storage from '@src/services/storage';
import config from '@src/config';

import ru from './ru.json';
import en from './en.json';

const resources = {
  en,
  ru,
};

i18n.use(initReactI18next).init({
  resources,
  lng: config.lang,
  saveMissing: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  missingKeyHandler: (lng, ns, key, defaultValue) => {
    if (defaultValue !== key) {
      return undefined;
    }

    console.error(`Отсутствует перевод для ключа '${key}'.`);
    const missingKeys = storage.get('missingKeys') || [];
    if (!missingKeys.some((x) => x === key)) {
      storage.set('missingKeys', [...missingKeys, key]);
    }

    return undefined;
  },
});

export default i18n;
