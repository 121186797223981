import dayjs from 'dayjs';

// Расчет оставшихся дней подписки и подписи к ним
const subscriptionTimer = (userInfo) => {
  const days_left = dayjs(userInfo?.subscription_date_end).diff(dayjs(), 'days');

  return days_left > 0 ? days_left : 0;
}

export default subscriptionTimer;
