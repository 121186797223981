import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ROUTE_ROOT } from '@src/constants';

import "./styles.less";
import { Spin } from "antd";

class AuthorizationInProgress extends Component {

  componentDidMount() {
    const { history } = this.props

    setTimeout(() => {
      history.push(ROUTE_ROOT)
    },1000)
  }

  render() {
    return (
      <main className="auth-page">
        <div className="wrap">
          <div className="auth__content">
            <h1>Идет авторизация...</h1>
            <Spin spinning={true} />
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  withRouter(
    AuthorizationInProgress
  )
);
