import { QuestionCircleOutlined } from '@ant-design/icons';
import Avatar from '@common/avatar';
import {
  ROLE_AMBASSADOR,
  ROLE_BEGINNER,
  ROLE_BUSINESS,
  ROLE_LIGHT,
  ROLE_PARTNER,
  ROLE_STUDENT_ACADEMY,
  ROLE_STUDENT_ACADEMY_PLUS,
  ROLE_STUDENT_BASE,
  ROLE_STUDENT_BASE_PLUS, ROLE_TEST_DRIVER,
  USER_STATUS_ACTIVE,
  USER_STATUS_INACTIVE,
} from '@src/constants';
import { Popover, Spin, Table } from 'antd';
import i18next from 'i18next';
import React from 'react';

// import { ReactComponent as ArrowUpIcon } from '@ui/icons/arrowUp.svg';
// import { ReactComponent as ArrowDownIcon } from '@ui/icons/arrowDown.svg';

export const TypeOfFirstLine = {
  all: 'all',
  partners: 'partners',
  clients: 'clients',
  register: 'register',
}

export const RenderUserInfo = (record) => {
  const { t } = i18next;

  return (
    <div className="">
      {Object
        .keys(record)
        .filter(
          (x) => ![
            'first_name',
            'last_name',
            'level',
            'curator_id',
            'role_id',
            'role_name',
            'status_id',
            'currency_id',
            'create_from_company',
            'prefix',
            'country_key',
            'country',
            'avatar',
            'hand_level',
            'residence_city',
          ].includes(x)
        )
        .map((x) => (
          <table className="userInfo" key={`table_${record.id}_userInfo`}>
            <tbody>
              <tr>
                <td>{t(`TABLE_USER_FIELD__${x.toUpperCase()}`)}</td>
                <td>{record[x] ?? '-'}</td>
              </tr>
            </tbody>
          </table>
        ))
      }
    </div>
  );
}

export const columns = (t, openedInfo, setOpenedInfo, isLgWidth, getFirstLineCount, userId) => {
  const result = [];
  if (!isLgWidth) {
    result.push({
      title: 'ID',
      dataIndex: 'id',
      key: `table_${userId}_id`,
      render: (id) => <div className="st__form-numb">{id}</div>,
    })
  }
  const result2 = [
    {
      title: 'Имя участника',
      dataIndex: 'first_name',
      key: `table_${userId}_first_name`,
      render: (first_name, record) => (
        <div className="st__form-author">
          <div className="st__form-img">
            <Avatar user={record} size={40} avatar={{ url_40: record?.avatar }} />
          </div>
          <p>
            {
              first_name
                ? (
                  <>
                    {record?.last_name}
                    <br className="hidden lg:block" />
                    <span className="inline lg:hidden"> </span>
                    {first_name ?? ''}
                  </>
                )
                : `${record.telegram}`
            }
            <p className="lg:hidden">ID: {record.id}</p>
          </p>
        </div>
      ),
    },
    {
      title: 'Роль',
      dataIndex: 'role_name',
      key: `table_${userId}_role_name`,
      render: (role_name) => <div className="st__form-text">
        {isLgWidth ? <>Роль: {(role_name === 'Новичок' ? 'Пользователь' : (role_name ?? ''))}</>
          : (role_name === 'Новичок' ? 'Пользователь' : (role_name ?? ''))}
      </div>,
    },
    {
      title: 'Ступень',
      dataIndex: 'level',
      key: `table_${userId}_level`,
      render: (level, record) => {
        let newLevel = level;
        switch (true) {
          case record?.hand_level && Number(record?.hand_level) > Number(level ?? 0):
            newLevel = Number(record?.hand_level);
            break;
          case record?.hand_level && Number(record?.hand_level) < 0:
            newLevel = <span className="text-red-500 font-semibold">{Number(record?.hand_level) * (-1)}</span>;
            break;
          default:
            newLevel = level;
        }
        const viewLevel = newLevel !== Number(level ?? 0)
          ? <>{newLevel} ({level ?? 0}) ур.</>
          : <>{level ?? 0} ур.</>
        return (
          <div className="st__form-text">
            {
              isLgWidth
                ? <>Ступень: {viewLevel}</>
                : viewLevel
            }
          </div>
        );
      },
    },
    {
      title: 'Структура',
      dataIndex: 'firstLineCountAll',
      key: `table_${userId}_firstLineCountAll`,
      render: (firstLineCountAll, record) => {
        const firstLineCount = getFirstLineCount(record)
        const text = t('LEVEL_COUNT_FIRST_LINE', {count: firstLineCount});
        return (
          <div className="st__form-text">{isLgWidth ? (<>В структуре: {text ?? ''}</>) : text ?? ''}</div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: `table_${userId}_modeInfo`,
      render: (id, record) => (
        <Popover
          title={`${record?.last_name} ${record?.first_name}`}
          placement='topRight'
          // open={openedInfo === record.id}
          content={RenderUserInfo(record)}
        >
          <div
            className="st__form-moreInfo"
            onClick={() => setOpenedInfo(record.id)}
          >
            <QuestionCircleOutlined />
          </div>
        </Popover>
      ),
    },
  ];

  return [
    ...result,
    ...result2,
  ];
}

export const getFilteredLines = (filter, userLines) => {
  switch (filter) {
    case TypeOfFirstLine.all:
      return userLines;
    case TypeOfFirstLine.partners:
      return userLines.filter((x) => [ROLE_LIGHT, ROLE_PARTNER, ROLE_BUSINESS, ROLE_TEST_DRIVER, ROLE_AMBASSADOR].includes(x?.role_id) && x?.status_id === USER_STATUS_ACTIVE);
    case TypeOfFirstLine.clients:
      return userLines.filter((x) => [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_BASE_PLUS, ROLE_STUDENT_ACADEMY, ROLE_STUDENT_ACADEMY_PLUS].includes(x?.role_id) && x?.status_id === USER_STATUS_ACTIVE);
    case TypeOfFirstLine.register:
      return userLines.filter((x) => x?.role_id === ROLE_BEGINNER && x?.status_id === USER_STATUS_INACTIVE);
    default:
      return [];
  }
}

export const renterTableFirstLine = (userId, isLgWidth, level, loading, lines, opened, filter, openedInfo, setOpenedInfo, loadLines, addOpened, removeOpened, shortLevel = 0, expandable = true) => {
  const { t } = i18next;

  const currentLoading = loading[userId];
  const currentUserLines = !lines[userId]
    ? undefined
    : getFilteredLines(filter, lines[userId]);

  if (userId && !currentLoading && !currentUserLines) {
    loadLines(userId);
  }

  const getFirstLineCount = (record) => {
    switch (filter) {
      case TypeOfFirstLine.all:
        return record?.firstLineCountAll;
      case TypeOfFirstLine.partners:
        return record?.firstLineCountPartners;
      case TypeOfFirstLine.clients:
        return record?.firstLineCountClients;
      default:
        return 0;
    }
  }

  return currentLoading || !currentUserLines
    ? (
      <Spin spinning={true} className="!ml-2"/>
    )
    : (
      <Table
        key={`table_${userId}`}
        className={level % 2 === 0 ? 'table-odd' : 'table-even'}
        dataSource={currentUserLines}
        columns={columns(t, openedInfo, setOpenedInfo, isLgWidth, getFirstLineCount, userId)}
        showHeader={!isLgWidth}
        pagination={false}
        rowKey={(record) => record.id}
        expandable={expandable
          ? {
            expandedRowRender: (record) => (
              <div className="st__form-next">
                {renterTableFirstLine(record.id, isLgWidth, level + 1, loading, lines, opened, filter, openedInfo, setOpenedInfo, loadLines, addOpened, removeOpened, shortLevel)}
              </div>
            ),
            rowExpandable: (record) => getFirstLineCount(record) > 0,
          }
          : undefined
        }
      />
    );
}
