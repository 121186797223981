import Avatar from '@common/avatar';
import FeedBack from '@common/drawer/feedBack';
// import FeedBack from '@common/drawer/feedBack';
import Settings from '@common/drawer/settings';
import Level from '@common/level';
import { openInNewTab } from '@components/landing/utils';
import api from '@services/api';
import storage from '@services/storage';
import accessToMenuItem from '@src/helpers/checkAccessToMenuItem';
import getRouteText from '@src/helpers/getRouteText';
import { setAppStage } from '@state/appStage';
import { setDrawerContent } from '@state/drawer';
import { cloneDeep } from 'lodash';
import store from '@state/store';
import React, { Component } from 'react';
import menuItemsText from '@layouts/main/leftMenu/menuItems';
import TopBar from '@layouts/main/topBar';
import { closeBurger } from '@state/burger';
import { Menu } from 'antd';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';

import { ReactComponent as SettingsIcon } from '@ui/icons/settings.svg';
import { ReactComponent as LogoIcon } from '@ui/icons/logo.svg';
import { ReactComponent as BurgerCloseIcon } from '@ui/icons/burgerClose.svg';
import { ReactComponent as ChatIcon } from '@ui/icons/chat.svg';
import { ReactComponent as SocialNetTelegramIcon } from '@ui/icons/socialNetTelegram.svg';
import { ReactComponent as LogoutIcon } from '@ui/icons/logout.svg';

import {
  APPSTAGE_SIGNIN,
  AUTH_COOKIE_NAME,
  ROUTE_ROOT,
  SOFTWARE_VERSION,
  TELEGRAM_FOR_SUPPORT,
} from '@src/constants';

import './styles.less';

class LeftMenu extends Component {
  constructor(props) {
    super(props);

    const  { t, location: { pathname }, userInfo } = props;

    this.menuItems = menuItemsText
      .map((x) => this.convertToMenu(x, t, userInfo))
      .filter((x) => x !== undefined);

    this.state = {
      selectedMenuItem: this.getMenuKeyByPathName(pathname),
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location: { pathname } } = this.props;

    if (prevProps.location.pathname !== pathname) {
      this.setState({
        selectedMenuItem: this.getMenuKeyByPathName(pathname),
      });
      window.scrollTo(0, 0);
    }
  }

  makeMenuItem = (menuItem, t, userInfo, addParams = {}) => {
    if (!accessToMenuItem(menuItem, userInfo)) {
      return undefined;
    }
    const routeText = getRouteText(menuItem.route);
    const Icon = menuItem.iconType;
    return {
      key: `MENU_${routeText}`,
      label: t(`LEFT_MENU_${routeText}`),
      icon: <Icon style={{ color: '#B6B9C8' }} />,
      path: menuItem.route,
      roles: menuItem.roles ?? [],
      users: menuItem.users ?? [],
      disabled: menuItem.disabled || undefined,
      ...addParams,
    }
  }

  convertToMenu = (menuItem, t, userInfo) => {
    if (menuItem.children?.length) {
      const childrenMenu =
        menuItem
          .children
          .map((subMenuItem) => this.convertToMenu(subMenuItem, t, userInfo))
          .filter((x) => x !== undefined);
      if (childrenMenu.length) {
        return this.makeMenuItem(menuItem, t, userInfo, {
          children: childrenMenu,
          onTitleClick: this.onTitleClickMenuItem,
        });
      }
      return undefined;
    }
    return this.makeMenuItem(menuItem, t, userInfo);
  }

  getMenuKeyByPathName = (pathname) => {
    const menuItem = this.menuItems
      .filter((x) => x.path.length > 1)
      .find((x) => pathname === x.path || pathname.includes(x.path));

    const result = [];
    if (menuItem?.key) {
      result.push(menuItem?.key);
      if (menuItem?.children?.length) {
        const subMenu = menuItem.children
          .filter((x) => x.path.length > 1)
          .find((x) => pathname === x.path || pathname.includes(x.path));
        if (subMenu) {
          result.push(subMenu.key)

          if (subMenu.children?.length) {
            const subMenu2 = subMenu.children
              .filter((x) => x.path.length > 1)
              .find((x) => pathname === x.path || pathname.includes(x.path));
            if (subMenu2) {
              result.push(subMenu2.key)
            }
          }
        }
      }
    } else {
      result.push('MENU_ROOT');
    }
    return result;
  }

  getEqMenuByPathName = (pathname) => this.menuItems.some((x) => pathname === x.path);

  onClickMenuItem = (menuItem) => {
    const { history, clickCloseBurger, location: { pathname } } = this.props;

    const item = menuItem.item.props;

    if (item.path !== pathname || !this.getEqMenuByPathName(pathname)) {
      history.push(item.path)
      clickCloseBurger();
    }
  };

  onTitleClickMenuItem = ({ key }) => {
    const { history, location: { pathname } } = this.props;

    const item = this.menuItems.find((x) => x.key === key)

    if (item?.path?.length > 0 && pathname !== item.path) {
      history.push(item.path);
    }
  }

  handleSettingsClick = () => {
    const { burgerIsOpen, updateDrawerContent, clickCloseBurger } = this.props;

    updateDrawerContent({
      content: Settings,
    });

    burgerIsOpen && clickCloseBurger();
  }

  handleLogout = async () => {
    const { history, clickCloseBurger } = this.props;

    // clearUserInfo();
    if (await api.logout()) {
      history.push(ROUTE_ROOT);

      const token = undefined;
      storage.set(AUTH_COOKIE_NAME, token);
      await api.setAUTHToken(token);
      // socket.close();

      await store.dispatch(setAppStage(APPSTAGE_SIGNIN));
      clickCloseBurger();
    }
  }

  handleFeedbackClick = () => {
    const { burgerIsOpen, updateDrawerContent, location, clickCloseBurger } = this.props;

    updateDrawerContent({
      content: FeedBack,
      params: {location: cloneDeep(location)},
    });

    burgerIsOpen && clickCloseBurger();
  }

  render() {
    const { burgerIsOpen, clickCloseBurger, userInfo, showTelegramChat } = this.props;
    const { selectedMenuItem } = this.state;

    if (!userInfo || userInfo.id === null || userInfo.id === undefined) {
      return null;
    }

    const items = this.menuItems;

    const text = [];
    if (userInfo?.first_name?.length) {
      text.push(userInfo.first_name);
    }
    if (userInfo?.last_name?.length) {
      text.push(userInfo.last_name);
    }
    if (text.length === 0) {
      text.push(userInfo.telegram);
    }

    return (
      <div className={classNames(
        "sidebar",
        burgerIsOpen ? 'show' : ''
      )}>

        { /** Верхний блок меню, чисто для мобилки */ }
        <div className="sidebar-mhead">
          <div className="wrap">
            <div className="flex flex-row items-center">
              <div className="sidebar-logo">
                <LogoIcon />
              </div>
              <button className="mheader-item sidebar-setting2 sidebar-setting-mobile2" onClick={this.handleSettingsClick}>
                <SettingsIcon />
              </button>
              <button className="mheader-item sidebar-logout" onClick={this.handleLogout}>
                <LogoutIcon />
              </button>
              <TopBar isMobile={true} />
            </div>
            <button
              type={'button'}
              className="burger-close"
              onClick={() => clickCloseBurger()}
            >
              <BurgerCloseIcon />
            </button>
          </div>
        </div>

        <div className="sidebar-head">

          <div className="sidebar-logo">
            <LogoIcon />
          </div>

          <button className="sidebar-setting" onClick={this.handleSettingsClick}>
            <SettingsIcon />
          </button>

          <div className="sidebar-user">
            <div className="sidebar-user-photo">
              <Avatar size={110} />
            </div>
            <div className="sidebar-user-info">
              <div className="sidebar-user-level !mb-4">{`ID: ${userInfo.id}`}</div>
              <div className="sidebar-user-tag">{userInfo.role_name}</div>
              <div className="sidebar-user-name">{text.join(' ')}</div>
              <div className="sidebar-user-level"><Level user={userInfo} /></div>
            </div>
          </div>

          <div className="sidebar-menu">
            <Scrollbars
              universal
              className="scrollbar"
            >
              <div className="menu-one-level">
                <Menu
                  items={items}
                  selectedKeys={selectedMenuItem}
                  defaultOpenKeys={selectedMenuItem?.length >= 2 ? selectedMenuItem : undefined}
                  onClick={this.onClickMenuItem}
                  mode='inline'
                />
              </div>
            </Scrollbars>
          </div>
        </div>
        {showTelegramChat && (
          <form className="sidebar-chat sidebar-chat3">
            <input type="text" placeholder="Чат с куратором" name="message"/>
            <button>
              <ChatIcon />
            </button>
          </form>
        )}
        <div className='support flex flex-row gap-5'>
          <div>Техническая поддержка:</div>
          <button className='a' onClick={() => openInNewTab(TELEGRAM_FOR_SUPPORT)}>
            <SocialNetTelegramIcon />
          </button>
          <button
            className='a'
            onClick={this.handleFeedbackClick}
          >
            form
          </button>
        </div>
        <div className="version version3">
          {`v. ${SOFTWARE_VERSION}`}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
//   appStage: state.appStage,
//   rights: state.rights.rightsData,
  burgerIsOpen: state.burger.isOpen,
  userInfo: state.user.info,
});

const mapDispatchToProps = {
  clickCloseBurger: closeBurger,
  updateDrawerContent: setDrawerContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      LeftMenu
    )
  )
);
