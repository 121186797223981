import api from '@services/api';
import { setGaVisible } from '@state/user';
import React, { useCallback, useState, useMemo } from 'react';
import { Form, message, Spin } from 'antd';

import { useAppDispatch, useAppSelector } from '@state/store';

import './styles.less';

const GoogleAuthForm = () => {
  const {
    visible,
    loginToken,
    procedure,
    callBackOk,
    callBackError,
    callBackLogin,
  } = useAppSelector((state) => state.user.ga);
  const dispatch = useAppDispatch();

  const formRef = useMemo(() => React.createRef(), [])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [formValue, setFormValue] = useState(undefined);

  const handleCancel = useCallback(() => {
    message.warning(`Операция ${procedure} не завершена, т.к. Вы отменили проверку кода аутентификации`, 6);
    dispatch(setGaVisible({ visible: false }));
    callBackError && callBackError();
  }, [callBackError, dispatch, procedure]);

  const handleClickOutside = useCallback(() => {
    // handleCancel();
  // }, [handleCancel]);
  }, []);

  const handleSubmit = useCallback(async (values) => {
    setFormValue(values?.token);
    setLoading(true);

    const {status, data} = !!loginToken
      ? await api.loginValidateUser2fa({ ...values, login_token: loginToken })
      : await api.validateUser2fa(values);

    if (status === 200 && data.status === 0) {
      callBackOk && !loginToken && callBackOk();
      callBackLogin && loginToken && callBackLogin(data?.token, data?.user);
      dispatch(setGaVisible({ visible: false }));
      message.success("Проверка кода аутентификации прошла успешно");
    } else {
      setError(data.message);
    }

    setLoading(false);
  }, [callBackLogin, callBackOk, dispatch, loginToken]);

  if (!visible) return null;

  return (
    <div className="ant-modal-root">
      <div className="ant-modal-mask"></div>
      <div className="ant-modal-wrap" onClick={handleClickOutside}>
        <div
          role="dialog"
          aria-modal="true"
          className="ant-modal modal dialog"
        >
          <div className="ant-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="ant-modal-header !py-4">
              <div className="modal__head">
                <h2 className="text-xl">Проверка кода Google Authenticator</h2>
              </div>
            </div>
            <div className="ant-modal-body !pb-4">
              <div className="modal__window">
                <Form
                  name='GoogleAuthForm'
                  ref={formRef}
                  layout='vertical'
                  initialValues={{ token: '' }}
                  onFinish={handleSubmit}
                  // className={styles.content}
                >
                  <Spin spinning={loading}>
                    <div className="form__content flex flex-col items-center">
                      <Form.Item name='token'
                                 label={false}
                                 className="form__block !w-2/4 !mb-0"
                                 validateTrigger={['onBlur', 'onChange']}
                                 rules={[
                                   {required: true, message: 'Пожалуйста, введите код', validateTrigger: 'onBlur'},
                                   {pattern: /^[\d]*$/, message: 'Разрешены только цифры'},
                                   {pattern: /^\d{6}$/, message: 'Должно быть 6 цифр', validateTrigger: 'onBlur'},
                                   ({ getFieldValue  }) => ({
                                     validateTrigger: 'onChange',
                                     validator(rule, value) {
                                       if (error && value !== formValue) {
                                         setError(undefined)
                                       }
                                       return Promise.resolve();
                                     },
                                   }),
                                 ]}>
                        <input
                          className="bg-gray-50 border border-solid border-gray-300 !text-gray-900 /*text-sm*/ focus:border-purple-500 focus:border-purple-500 block p-2.5"
                          placeholder="Код аутентификации"
                        />
                      </Form.Item>
                      {error && (
                        <p className="!mt-0 !text-red-600">
                          {error || null}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-row gap-4">
                      <button type='submit' className="standart-btn !mb-0">
                        <div>Проверить код</div>
                      </button>
                      <button type='button' className="standart-btn standart-btn-border !mb-0" onClick={handleCancel}>
                        <div>Отменить</div>
                      </button>
                    </div>
                  </Spin>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAuthForm;
