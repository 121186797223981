import {
  ROLE_BEGINNER,
  ROLE_STUDENT_ACADEMY,
  ROLE_STUDENT_ACADEMY_PLUS,
  ROLE_STUDENT_BASE,
  ROLE_STUDENT_BASE_PLUS,
} from '@src/constants';

export const disableAccessToIndicatorsPage = (userInfo) => (
  !userInfo
  || userInfo.id === null
  || userInfo.id === undefined
  || [
    ROLE_BEGINNER,
    ROLE_STUDENT_BASE,
    ROLE_STUDENT_ACADEMY,
    ROLE_STUDENT_BASE_PLUS,
    ROLE_STUDENT_ACADEMY_PLUS,
  ].includes(userInfo.role_id)
)
