import { ROLE_ADMIN, ROLE_AMBASSADOR } from '@src/constants';
import withWindowResize from '@src/helpers/withWindowResize';
import classNames from 'classnames';
import React, { Component } from 'react';

import { connect } from 'react-redux';

import "./styles.less"

class MessagePanel extends Component {
  render() {
    const { userInfo, innerWidth, isVertical = false, isHorizontal = false, hidden = false } = this.props;

    const currentIsVertical = isVertical || (innerWidth <= 768 || innerWidth >= 1200)

    if (hidden || ![ROLE_AMBASSADOR, ROLE_ADMIN].includes(userInfo?.role_id)) {
      return null;
    }

    return (
      <div
        className={classNames(
          "message-panel",
          !isHorizontal && currentIsVertical
            ? 'isVertical'
            : ''
        )}
      >
        <div className="message-panel-block">
          <div className="message-panel-text">
            <b>Поздравляем! </b><br className="" />
            Вы являетесь участником aidrop-а на общую сумму <br className="md-hide"/>
            более <span className="whitespace-nowrap">$2 000 000</span> в токене SOUL
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withWindowResize(
    MessagePanel
  )
);
