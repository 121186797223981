import FormWithMessage from '@common/formWithMessage';
import api from '@services/api';
import storage from '@services/storage';
import {
  APPSTAGE_AUTH,
  AUTH_COOKIE_NAME,
  FORM_VERIFY_EMAIL_REPEAT,
  ROUTE_ROOT,
} from '@src/constants';
import { getMessageFromRequest, getSearchParamsAsObject } from '@src/helpers';
import { setAppStage } from '@state/appStage';
import { setUserExtraInfo } from '@state/user';
import { useEffect, useState } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { Link, withRouter } from 'react-router-dom';

const VerifyEmailForm = ({ setFormType, formRef, history, location, setAppStageData, setUserInfo, t }) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [textMessage, setTextMessage] = useState(<>К сожалению мы не смогли проверить указанный вами ключ подтверждения вашего адреса электронной почты</>);
  const [dataAPI, setDataAPI] = useState();

  const { token } = getSearchParamsAsObject(location);

  useEffect(() => {
    const verify = async () => {
      if (token) {
        const {status, data} = await api.verifyEmail(token);

        if (status === 200) {
          setTextMessage(getMessageFromRequest(data));
          setIsSuccess(data?.status === 0);
          setIsError(data?.status !== 0);
          setDataAPI(data);
        } else {
          setIsError(true);
        }
      } else {
        setTextMessage('Токен для подтверждения адреса электронной почты не найден');
        setIsError(true);
      }
      setLoading(false)
    };
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRepeatVerifyEmail = () => {
    setFormType(FORM_VERIFY_EMAIL_REPEAT);
    history.push(ROUTE_ROOT);
  }

  const handleAutoLogin = async (data) => {
    if (isSuccess && data?.status === 0) {
      history.push(ROUTE_ROOT);
      const token = data.token;
      storage.set(AUTH_COOKIE_NAME, token);
      await api.setAUTHToken(token);
      await setUserInfo(data.user);
      await setAppStageData(APPSTAGE_AUTH);
    }
  }

  return (
    <FormWithMessage
      setFormType={setFormType}
      isError={isError}
      isSuccess={isSuccess}
      textMessage={textMessage}
      showGoHomeButton={isError}
      additionalLink={
        <>
          войти под своей учетной записью с Главной страницы,
          &nbsp;
          <Link to={ROUTE_ROOT} className='underline' onClick={handleRepeatVerifyEmail}>
            запросить новый ключ подтверждения
          </Link>
        </>
      }
      additionalButton={isSuccess && (
        <button className='standart-btn' onClick={() => handleAutoLogin(dataAPI)}>
          <div>Войти</div>
        </button>
      )}
    >
      <div className='flex flex-row justify-between'>
        <div>Ваш E-mail проверяется ...</div>
        <Spin spinning={loading} />
      </div>
    </FormWithMessage>
  );
}

const mapDispatchToProps = {
  setAppStageData: setAppStage,
  setUserInfo: setUserExtraInfo,
};

export default connect(null, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      VerifyEmailForm
    )
  )
);
