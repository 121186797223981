import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Modal, Spin } from 'antd';
import Countdown from 'antd/es/statistic/Countdown';

import { setFinishCheckBalance } from '@state/drawer';

class ModalCheckBalance extends Component {
  finishHandle = () => {
    const { drawer, finishCheckBalance } = this.props;
    const { params } = drawer;

    params?.callBack && params?.callBack();
    finishCheckBalance();
  }

  render() {
    const { drawer } = this.props;
    const {
      checkBalance,
      timerId,
      checkBalanceResult,
      deadline,
      loadingCheckBalance,
    } = drawer;

    return (
      <Modal
        zIndex={1010}
        closable={false}
        maskClosable={false}
        open={checkBalance}
        onCancel={this.finishHandle}
        footer={[
          <Button key="back" onClick={this.finishHandle}>
            {!timerId || checkBalanceResult !== false ? 'Ок' : 'Отменить проверку баланса'}
          </Button>
        ]}
      >
        {checkBalanceResult !== false
          ? checkBalanceResult
          : (
            <div className='flex flex-row gap-8 items-center'>
              <Countdown
                title="Проверка баланса"
                value={deadline}
              />
              <Spin className='items-center mt-8' spinning={loadingCheckBalance}/>
            </div>
          )
        }
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  drawer: state.drawer,
});

const mapDispatchToProps = {
  finishCheckBalance: setFinishCheckBalance,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ModalCheckBalance
);
