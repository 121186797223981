import { IWalletInfo } from '@state/user';
import classNames from 'classnames';
import { FC } from 'react';
import React from 'react';
import { NumericFormat } from 'react-number-format';

interface IParams {
  wallet: IWalletInfo | null;
  currency_code?: string;
  network_code?: string;
  value: number | string;
  withCurrencyCode?: Boolean;
  withSpace?: Boolean;
  withNetWorkCode?: Boolean;
  withHyphen?: Boolean;
  digits?: number;
  className?: string;
  key?: string;
  period?: string;
}

export const Amount: FC<IParams> = ({
  wallet,
  currency_code,
  network_code,
  value,
  withCurrencyCode = true,
  withSpace = true,
  withNetWorkCode = false,
  withHyphen = false,
  digits,
  className,
  key,
  period,
}) => (
  <span className={classNames("whitespace-nowrap flex-nowrap", className)}>
    {withSpace && !withHyphen ? ' ' : undefined}
    {withHyphen ? <>&nbsp;&mdash;&nbsp;</> : undefined}
    <NumericFormat
      value={Number(value)}
      displayType={'text'}
      thousandSeparator=" "
      decimalScale={digits}
      className="!m-0 !p-0"
      key={key}
    />
    {withCurrencyCode ? ` ${currency_code || wallet?.currency_code}` : ''}
    {withCurrencyCode && withNetWorkCode
      ? ` (${network_code || wallet?.currency_network_name_code})`
      : ''}
    {withSpace ? ' ' : ''}
    {period ? ` / ${period}` : ''}
  </span>
)
