import { QuestionCircleOutlined } from '@ant-design/icons';
import CatalogHeader from '@common/catalogHeader';
import PayIn from '@common/drawer/payIn';
import MobileHeader from '@common/mobileHeader';
import {
  INDEX_ADMIN,
  INDEX_COL_SPAN_ALL_COMMISSION,
  INDEX_COL_SPAN_ALL_USER_SUM,
  INDEX_COL_SPAN_COMMISSION,
  INDEX_COL_SPAN_CURRENCY,
  INDEX_COL_SPAN_ROLE,
  INDEX_COL_SPAN_SUM,
  INDEX_PULLS_STATUS_CLOSE,
  INDEX_PULLS_STATUS_OPEN,
  INDEX_PULLS_STATUS_END,
  INDEX_REQUEST_STATUS_CANCEL_BY_USER,
  INDEX_REQUEST_STATUS_NEW,
  INDEX_PULLS_STATUS_WAIT_SEND,
  INDEX_PULLS_STATUS_WAIT_BUY,
  INDEX_PULLS_STATUS_WAIT_RESULT,
  PROJECT_RESULT_DECIMAL_SCALE,
} from '@routes/bullranIndex/constants';
import api from '@services/api';
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORT,
  DECIMAL_SCALE,
  ROUTE_PRODUCTS,
} from '@src/constants';
import { getMessageFromRequest } from '@src/helpers';
import withWindowResize from '@src/helpers/withWindowResize';
import { setDrawerContent } from '@state/drawer';
import { closeModal, setModalContent } from '@state/modal';
import store from '@state/store';
import { updateWalletById } from '@state/user';
import { Button, Checkbox, Col, Form, InputNumber, message, Popover, Progress, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// import { ReactComponent as NotificationIcon } from '@ui/icons/notification.svg';

import "./styles.less";

class BullranIndexPage extends Component {
  formRef = React.createRef();
  state = {
    loadingPulls : false,
    loadingRequests : false,
    loadingCurrentPull : false,
    loadingCurrentProject : false,
    loadingCommissions : false,
    loadingUserCommissions : false,
    show_canceled_requests : false,
    project: {},
    pulls: [],
    requests: [],
    commissions: undefined,
    newRequestAmount: undefined,
    newRequestCommission: undefined,
    newRequestSum: undefined,
    currentWallet: undefined,
    cancelCurrentPull: false,
    // cancelCurrentPullError: false,
  }

  componentDidMount() {
    const { project_id } = this.props;

    this.loadProjectPulls();
    this.loadProjectInfo(project_id);
    this.loadProjectCommissions(project_id);
    this.loadProjectRequests(project_id);
  }

  showError = (messageText) => {
    if (Array.isArray(messageText)) {
      message.error(<>{messageText.join(<br />)}</>);
    } else {
      message.error(messageText);
    }
  }

  loadProjectInfo = async (project_id) => {
    const { wallets } = this.props;
    this.setState({ loadingCurrentProject : true })

    const { status, data } = await api.getProjectInfo(project_id);

    if (status === 200 && data?.status === 0 && data?.project) {
      this.setState({ project: data.project });
      if (wallets && wallets.length && data.project.currency_id) {
        const currentWallet = wallets?.find((x) => x.currency_id === data.project.currency_id);
        if (currentWallet) {
          this.setState({ currentWallet });
        } else {
          message.error(`К сожалению, не найден кошелек пользователя для работы с ${data.project.name}`, 5);
        }
      }
    }

    this.setState({ loadingCurrentProject : false })
  }

  loadProjectCurrentPull = async (project_id) => {
    const { pulls } = this.state;
    this.setState({ loadingCurrentPull : true })

    const { status, data } = await api.getProjectCurrentPull(project_id);

    if (status === 200 && data?.status === 0 && data?.current_pull) {
      const newPulls = pulls?.filter((x) => this.pullStatusFilterForNew(x)) || [];
      this.setState({ pulls: [...newPulls, data.current_pull] });
    }

    this.setState({ loadingCurrentPull : false })
  }

  loadProjectAllPulls = async (project_id) => {
    this.setState({ loadingPulls : true })

    const { status, data } = await api.getProjectPullList(project_id);

    if (status === 200 && data?.status === 0 && data?.pulls) {
      this.setState({ pulls: data.pulls });
    }

    this.setState({ loadingPulls : false })
  }

  loadProjectPulls = () => {
    const { userId, project_id } = this.props;
    if (INDEX_ADMIN.includes(userId)) {
      this.loadProjectAllPulls(project_id);
    } else {
      this.loadProjectCurrentPull(project_id);
    }
  }

  loadProjectCommissions = async (project_id) => {
    this.setState({ loadingCommissions : true })

    const { status, data } = await api.getProjectCommission(project_id);

    if (status === 200 && data?.status === 0 && data?.commission_list && data?.commission_list?.length) {
      this.setState({ commissions: data.commission_list });
    }

    this.setState({ loadingCommissions : false })
  }

  loadProjectRequests = async (project_id) => {
    this.setState({ loadingRequests : true })

    const { status, data } = await api.getProjectUserRequests(project_id);

    if (status === 200 && data?.status === 0 && data.requests) {
      this.setState({ requests: data.requests });
    }

    this.setState({ loadingRequests : false })
  }

  handleCancelProjectPull = async (val) => {
    const { project } = this.state;
    const content = (
      <>
        <div>Вы действительно хотите отменить все заявки на указанную сумму?</div>
        <div> - <NumericFormat value={Number(val?.sum)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/> {project.currency_code}</div>
      </>
    );
    store.dispatch(setModalContent({
      title: 'Подтверждение отмены всех заявок пула',
      content,
      params: {
        onOk: () => {
          store.dispatch(closeModal());
          this.setCancelProjectPull(val);
        },
        okText: "Отменить все заявки",
        okType: 'default',
        okButtonProps: {
          shape: 'round',
        },
        cancelText: "Закрыть окно",
        cancelButtonProps: {
          shape: 'round',
        },
      },
    }));

  }

  handleAllocateProjectPullResult = async (val) => {
    const { project } = this.state;
    const content = (
      <>
        <div>Вы действительно хотите распределить по всем заявкам указанную сумму?</div>
        <div> - <NumericFormat value={Number(val?.sum)} displayType={'text'} decimalScale={PROJECT_RESULT_DECIMAL_SCALE} thousandSeparator=" "/> {project.name_result}</div>
      </>
    );
    store.dispatch(setModalContent({
      title: 'Подтверждение распределения средств',
      content,
      params: {
        onOk: () => {
          store.dispatch(closeModal());
          this.setAllocateProjectPullResult(val);
        },
        okText: "Распределить",
        okType: 'default',
        okButtonProps: {
          shape: 'round',
        },
        cancelButtonProps: {
          shape: 'round',
        },
      },
    }));

  }

  setAllocateProjectPullResult = async (val) => {
    const { project_id } = this.props;

    this.setState({ loadingPulls: true });

    const {status, data} = await api.setAllocateProjectPullResult(project_id, {
      current_pull_id: val?.id,
      result_sum: val?.sum,
    })

    this.setState({ loadingPulls: false });

    if (status === 200 && data?.status === 0 && data?.pulls?.length) {
      this.setState({ pulls: data?.pulls });
    } else {
      message.error(
        getMessageFromRequest(
          data,
          'К сожалению, в данный момент не получилось распределить результаты в указанном пуле по заявкам. Попробуйте повторить операцию позднее'
        ),
        6
      );
    }
  }

  setCancelProjectPull = async (val) => {
    const { project_id } = this.props;

    this.setState({ loadingPulls: true });

    const {status, data} = await api.setCancelProjectPull(project_id, {
      current_pull_id: val?.id,
      cancel_sum: val?.sum,
    })

    this.setState({ loadingPulls: false });

    if (status === 200 && data?.status === 0 && data?.pulls?.length) {
      this.setState({ pulls: data?.pulls });
    } else {
      message.error(
        getMessageFromRequest(
          data,
          'К сожалению, в данный момент не получилось отменить все указанные заявки в пуле. Попробуйте повторить операцию позднее'
        ),
        6
      );
    }
  }

  handleOpenNewPull = async (val) => {
    const content = 'Вы действительно хотите закрыть данный пул и открыть новый?';
    store.dispatch(setModalContent({
      title: 'Подтверждение закрытия пула',
      content,
      params: {
        onOk: () => {
          store.dispatch(closeModal());
          this.setOpenNewPull(val);
        },
        okText: "Закрыть пул",
        okType: 'default',
        okButtonProps: {
          shape: 'round',
        },
        cancelButtonProps: {
          shape: 'round',
        },
      },
    }));

  }

  setOpenNewPull = async (val) => {
    const { project_id } = this.props;

    this.setState({ loadingPulls: true });

    const {status, data} = await api.closeProjectCurrentPull(project_id, {
      current_pull_id: val?.id,
    })

    this.setState({ loadingPulls: false });

    if (status === 200 && data?.status === 0 && data?.pulls?.length) {
      this.setState({ pulls: data?.pulls });
    } else {
      message.error(
        getMessageFromRequest(
          data,
          'К сожалению, в данный момент не получилось закрыть указанный пул. Попробуйте повторить операцию позднее'
        ),
        6
      );
    }
  }

  clearRequest = () => {
    this.setState({
      newRequestAmount: undefined,
      newRequestCommission: undefined,
      newRequestSum: undefined,
    })
  }

  handlePayUserRequest = async (request) => {
    const content = (
      <>
        <div>Вы действительно хотите оплатить заявку?</div>
        <div> - сумма заявки: <NumericFormat value={Number(request.amount_user)} displayType={'text'} decimalScale={0} thousandSeparator=" "/> {request.currency_code}</div>
        <div> - комиссия: <NumericFormat value={Number(request.amount_commission)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/> {request.currency_code}</div>
        <div> - итоговая сумма: <NumericFormat value={Number(request.amount)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/> {request.currency_code}</div>
      </>
    );
    store.dispatch(setModalContent({
      title: 'Подтверждение оплаты заявки',
      content,
      params: {
        onOk: () => {
          store.dispatch(closeModal());
          this.onPayUserRequest(request.id, request.amount);
        },
        okText: 'Оплатить',
        okType: 'default',
        okButtonProps: {
          shape: 'round',
        },
        cancelButtonProps: {
          shape: 'round',
        },
      },
    }));
  }

  onPayUserRequest = async (id, amount) => {
    const { project_id, updateUserWalletById } = this.props;
    const { currentWallet } = this.state;

    this.setState({ loadingRequests: true });

    const {status, data} = await api.getWalletById(currentWallet?.id)

    this.setState({ loadingRequests: false });

    if (status === 200 && [2000, 0].includes(data?.status)) {
      if (data?.wallet?.balance && Number(data?.wallet?.balance) >= Number(amount)) {
        this.setState({ loadingRequests: true });

        const {status: statusPay, data: dataPay} = await api.payProjectRequest(project_id, {
          request_id: id,
          amount,
          currency_id: currentWallet?.currency_id,
          wallet_id: currentWallet?.id,
        })

        this.setState({ loadingRequests: false });

        if (statusPay === 200 && dataPay?.status === 0) {
          if (dataPay?.wallet && Number(dataPay?.wallet?.balance) !== Number(currentWallet?.balance)) {
            store.dispatch(updateUserWalletById(dataPay?.wallet));
          }
          if (dataPay?.requests?.length) {
            this.setState({ requests: dataPay?.requests || [] });
          }
          if (dataPay?.pulls?.length) {
            this.setState({ pulls: dataPay?.pulls || [] });
          }
          message.success('Ваша заявка успешно оплачена', 5);
        } else {
          message.error(
            getMessageFromRequest(
              dataPay,
              'Есть проблемы с оплатой вашей заявки. Попробуйте повторить операцию позднее'
            ),
            6
          );
        }
      } else {
        this.openPayIn(amount, currentWallet);
      }
    } else {
      message.error(
        getMessageFromRequest(
          data,
          'К сожалению, сервис проверки баланса недоступен. Попробуйте повторить операцию позднее'
        ),
        6
      );
    }
  }

  openPayIn = (amount, currentWallet) => {
    const { updateDrawerContent } = this.props;
    const { newRequestSum } = this.state;

    const params = {
      wallet: currentWallet,
    };
    if ((amount && Number(amount)) || (newRequestSum && Number(newRequestSum))) {
      params.product = {
        current_price_with_sale: amount && Number(amount)
          ? Number(amount)
          : Number(newRequestSum),
      };
      params.delta = params.product.current_price_with_sale - Number(currentWallet?.balance);
      params.deltaWithFee = params.delta + Number(currentWallet?.currency_commission_pay_in);
      if (params.deltaWithFee < Number(currentWallet?.currency_min_pay_in)) {
        params.deltaWithFee = Number(currentWallet?.currency_min_pay_in);
      }
    }

    store.dispatch(closeModal());
    store.dispatch(
      updateDrawerContent({
        content: PayIn,
        params,
      })
    );
  }

  handleNewRequest = async (values) => {
    const { project, newRequestAmount, newRequestCommission, newRequestSum } = this.state;

    const content = (
      <>
        <div>Вы действительно хотите Создать заявку?</div>
        <div> - сумма заявки: <NumericFormat value={Number(newRequestAmount)} displayType={'text'} decimalScale={0} thousandSeparator=" "/> {project.currency_code}</div>
        <div> - комиссия: <NumericFormat value={Number(newRequestCommission)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/> {project.currency_code}</div>
        <div> - итоговая сумма: <NumericFormat value={Number(newRequestSum)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/> {project.currency_code}</div>
        <br />
        <div>
          Нажимая кнопку "Подтвердить" вы соглашаетесь с&nbsp;
          <Link
            to={'/files/Licence_agreement_Bullran_Index_EN_RU_2.pdf'}
            target='_blank'
            className="text-[#1890ff] "
          >
            лицензионным соглашением
          </Link>
        </div>
      </>
    );
    store.dispatch(setModalContent({
      title: `Подтвердите создание заявки на формирование ${project.name}`,
      content,
      params: {
        onOk: () => {
          store.dispatch(closeModal());
          this.setNewRequest(values);
          this.formRef?.current?.reset();
        },
        okText: 'Подтвердить',
        okType: 'default',
        okButtonProps: {
          shape: 'round',
        },
        cancelButtonProps: {
          shape: 'round',
        },
      },
    }));
  }

  setNewRequest = async (values) => {
    const { project_id, updateUserWalletById } = this.props;
    const { pulls, currentWallet } = this.state;

    this.setState({ loadingRequests: true });

    const { status, data } = await api.newProjectRequest(project_id, {
      sum: values?.amount,
      wallet_id: currentWallet?.id,
      currency_id: currentWallet?.currency_id,
    });

    if (status === 200 && data?.status === 0) {
      message.success('Ваша заявка успешно оформлена и оплачена', 5);
      this.clearRequest();
    } else if (status === 200 && data?.status === 2000) {

      store.dispatch(setModalContent({
        title: 'Ваша заявка успешно сохранена',
        content: (
          <>
            Ваша заявка успешно оформлена. <br />
            Пополните кошелек и оплатите заявку. <br />
            Если Вы не оплатите ее до 0:00 по Гринвичу<br /> - она будет отменена.<br />
            <br/>
            <div className="text-center">
              <Button onClick={() => this.openPayIn(data?.info?.amount, currentWallet)} className="self-center">
                Пополнить кошелек
              </Button>
            </div>
          </>
        ),
        params: {
          onlyOk: true,
          onlyOkText: 'Оплачу позднее ...',
          onOk: () => {
            store.dispatch(closeModal());
          }
        },
      }));
      this.clearRequest();
    } else {
      message.error(
        getMessageFromRequest(data, 'Есть проблемы с созданием вашей заявки'),
        5
      );
    }

    if (
      (data?.delta && Number(data?.delta) !== 0)
      || (data?.wallet?.balance && Number(data?.wallet?.balance) !== Number(currentWallet?.balance))
    ) {
      store.dispatch(updateUserWalletById(data?.wallet));
    }

    if (data?.requests) {
      this.setState({ requests: data?.requests || [] });
    }

    if (data?.current_pull) {
      const newPulls = pulls?.filter((x) => this.pullStatusFilterForNew(x)) || [];
      this.setState({ pulls: [...newPulls, data.current_pull] });
    }

    if (data?.pulls?.length) {
      this.setState({ pulls: data?.pulls || [] });
    }

    this.setState({ loadingRequests: false });
  }

  handleCancelRequest = async (request) => {
    const content = (
      <>
        <div>Вы действительно хотите отменить заявку?</div>
        <div> - сумма заявки: <NumericFormat value={Number(request.amount_user)} displayType={'text'} decimalScale={0} thousandSeparator=" "/> {request.currency_code}</div>
      </>
    );
    store.dispatch(setModalContent({
      title: 'Подтверждение отмены заявки',
      content,
      params: {
        onOk: () => {
          store.dispatch(closeModal());
          this.onCancelRequest(request.id);
        },
        okText: "Отменить заявку",
        cancelText: "Закрыть окно",
        okType: 'default',
        okButtonProps: {
          shape: 'round',
        },
        cancelButtonProps: {
          shape: 'round',
        },
      },
    }));
  }

  onCancelRequest = async (requestId) => {
    const { project_id } = this.props;

    this.setState({ loadingRequests: true });

    const { status, data } = await api.cancelProjectRequest(project_id, { request_id: requestId });

    if (status === 200 && data?.status === 0) {
      message.success('Ваша заявка успешно отменена', 5);
      if (data?.requests?.length) {
        this.setState({ requests: data?.requests });
      }
    } else {
      message.error(
        getMessageFromRequest(data, 'Есть проблемы с отменой вашей заявки'),
        5
      );
    }
    this.setState({ loadingRequests: false });
  }

  getProjectMinSum = (project, requests) => {
    const hasPaidRequest = requests?.some((x) => x.status_id > INDEX_REQUEST_STATUS_NEW && x.status_id < INDEX_REQUEST_STATUS_CANCEL_BY_USER);
    return hasPaidRequest
      ? project?.min
      : project?.first_min
    ;
  }

  renderNewUserRequest = (project, requests, newRequestAmount, newRequestCommission, newRequestSum, currentWallet, pullNewId) => {
    const hasNewRequest = requests?.some((x) => x.status_id === INDEX_REQUEST_STATUS_NEW);
    const projectMinSum = this.getProjectMinSum(project, requests);
    const isDisabled = !pullNewId;

    return (
      <div className="info__card">
        <div className="info-content">
          <div className="info-repeat">
            <h2 className="pb-1 text-lg">
              Новая заявка на формирование {project.name} {project.name_result?.length && !project.name.includes(project.name_result) ? `(${project.name_result})` : ''}:
            </h2>
            <div className="pl-6">
              {hasNewRequest
                ? (
                  <>
                    <div>У вас есть неоплаченные заявки</div>
                    <div>Оплатите их или отмените для создания новой</div>
                  </>
                ) : (
                  <Form
                    ref = {this.formRef}
                    onFinish={isDisabled ? undefined : this.handleNewRequest}
                    disabled={isDisabled}
                    layout='vertical'
                    className="flex flex-col gap-2"
                  >
                    <div className="new-request">
                      <div className="new-request-item item-amount">
                        {/*<div className="new-request-ttl">Сумма заявки:</div>*/}
                        <Form.Item
                          name='amount'
                          label='Сумма заявки:'
                          validateTrigger={['onBlur', 'onChange']}
                          validateFirst={true}
                          disable={isDisabled}
                          rules={[
                            {validateTrigger: 'onBlur', required: true, message: 'Пожалуйста, введите сумму заявки'},
                            // {validateTrigger: 'onBlur', min: projectMinSum, message: `Минимальная сумму заявки - ${projectMinSum} {project?.currency_code}`},
                            // {validateTrigger: 'onBlur', type: 'number', message: 'Разрешены только цифры и точка'},
                            // {max: Number(currentWallet?.balance) - Number(COMMISSION_FOR_TRANSACTION), message: 'Максимальная длина поля - 32 символов'},
                            {
                              // transform: (x) => x.replace(/\d+\.?/, ''),
                              pattern: new RegExp(`^[\\d ]*.?\\d{0,${DECIMAL_SCALE}}$`),
                              message: `Можно указать только ограниченное число знаков после точки - максимум ${DECIMAL_SCALE}`,
                            },
                            {
                              validateTrigger: 'onBlur',
                              message: `Минимальная сумму заявки - ${projectMinSum} ${project?.currency_code}`,
                              validator: async (_, value) => {
                                if (Number(value.replace(/[^\d.]/, '')) >= projectMinSum) {
                                  return Promise.resolve();
                                }
                                return Promise.reject();
                              },
                            },
                            // {
                            //   // validateTrigger: 'onBlur',
                            //   message: 'Указанная вами сумма, с учетом комиссии, превышает сумму кошелька',
                            //   validator: async (_, value) => {
                            //     if (
                            //       Number(value.replace(/[^\d.]/, ''))
                            //       + COMMISSION_FOR_TRANSACTION
                            //       <= Number(Number(currentWallet?.balance).toFixed(DECIMAL_SCALE))
                            //     ) {
                            //       return Promise.resolve();
                            //     }
                            //     return Promise.reject();
                            //   },
                            // },
                          ]}
                        >
                          <b className='form__block amount'>
                            <NumericFormat
                              value={Number(newRequestAmount)}
                              // displayType={'text'}
                              // decimalScale={DECIMAL_SCALE}
                              disabled={isDisabled}
                              title={isDisabled ? 'Прием заявок временно приостановлен' : undefined}
                              decimalScale={0}
                              thousandSeparator=" "
                              onKeyDown={(event) => {
                                if (event.key.match(/[0-9.]/)) {
                                  const newValue = (newRequestAmount || '').concat(event.key);
                                  const digits = newValue.match(/(\d+)\.?(\d+)/);
                                  if (
                                    (digits && digits[2] && digits[2].length > DECIMAL_SCALE)
                                    // || (
                                    //   balanceDigits && balanceDigits[1]
                                    //   && digits && digits[1]
                                    //   && digits[1].length > balanceDigits[1].length
                                    // )
                                    // || (Number(newValue) > Number(currentWallet?.balance))
                                  ) {
                                    event.preventDefault();
                                    event.stopPropagation();
                                  }
                                }
                              }}
                              onValueChange={async (values, info) => {
                                const digits = values.value.match(/(\d+)\.?(\d+)/)
                                if (!digits || !digits[2] || digits[2].length <= DECIMAL_SCALE) {
                                  // const currentCommissionInfo = commissions.find((x) =>
                                  //   (!x.min || x.min <= values.value)
                                  //   && (!x.max || values.value < x.max)
                                  //   && x.disabled === 0
                                  //   && (!x.user_role_id || x.user_role_id === userInfo.role_id)
                                  // );
                                  // const multipleDecimal = Math.pow(10, DECIMAL_SCALE);
                                  // const currentCommissionRate = currentCommissionInfo?.commission || 0;
                                  // const currentCommission = Math.round(Number(values.value) * currentCommissionRate * multipleDecimal) / multipleDecimal;
                                  // const currentSum = Number(values.value) + currentCommission;
                                  //
                                  // console.log({
                                  //   _f: 'BullranIndexPage--231',
                                  //   val: values.value,
                                  //   rate: currentCommissionRate,
                                  //   com: currentCommission,
                                  //   sum: currentSum,
                                  //   currentCommissionInfo,
                                  // });
                                  // this.setState({
                                  //   newRequestAmount: values.value || '0',
                                  //   newRequestCommission: currentCommission,
                                  //   newRequestSum: currentSum,
                                  // });

                                  if (Number(values.value || '0') >= projectMinSum) {
                                    this.setState({loadingUserCommissions: true})

                                    const {status, data} = await api.getProjectUserCommission(
                                      project.id,
                                      {
                                        amount_user: values.value || '0',
                                        currency_id: project.currency_id,
                                      }
                                    );

                                    if (status === 200 && data?.status === 0 && data?.info) {
                                      this.setState({
                                        newRequestAmount: values.value || '0',
                                        newRequestCommission: data?.info?.amount_commission || 0,
                                        newRequestSum: data?.info?.amount || 0,
                                        loadingUserCommissions: false,
                                      });
                                    }

                                    this.setState({loadingUserCommissions: false})

                                  }
                                }
                              }}
                            />
                          </b>
                        </Form.Item>
                        {/*<span>{currentWallet?.currency_code}</span>*/}
                      </div>
                      <div className="new-request-item-group flex flex-row justify-between">
                        <div className="new-request-item item-commission">
                          <div className="new-request-ttl">Комиссия:</div>
                          <b className="disabled commission">
                            <NumericFormat
                              value={Number(newRequestCommission)}
                              displayType={'text'}
                              decimalScale={DECIMAL_SCALE}
                              thousandSeparator=" "
                            />
                          </b>
                          {/*<span>{currentWallet?.currency_code}</span>*/}
                        </div>
                        <div className="new-request-item item-all-amount">
                          <div className="new-request-ttl">Итоговая сумма:</div>
                          <b className="commission">
                            <NumericFormat
                              value={Number(newRequestSum)}
                              displayType={'text'}
                              decimalScale={DECIMAL_SCALE}
                              thousandSeparator=" "
                            />
                          </b>
                          {/*<span>{currentWallet?.currency_code}</span>*/}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-3 mb-4">
                      <button
                        title={isDisabled ? 'Прием заявок временно приостановлен' : undefined}
                        disabled={isDisabled}
                        className="allocation-index-button self-center standart-btn py-1"
                        type='submit'
                      >
                        <div className="!py-2">
                          {
                            newRequestSum
                            && Number(newRequestSum)
                            && newRequestAmount
                            && Number(newRequestAmount)
                            && Number(newRequestAmount) >= projectMinSum
                            && (Number(currentWallet?.balance) || 0) >= Number(newRequestSum)
                              ? 'Создать и оплатить заявку'
                              : 'Создать заявку'
                          }
                        </div>
                      </button>
                    </div>
                  </Form>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPull = (project, currentPull, currentPullSum) => {
    // const { cancelCurrentPull, cancelCurrentPullError } = this.state;
    const { cancelCurrentPull } = this.state;
    const newCurrentPullList = currentPull?.list?.filter((x) => this.hide_canceled_requests(x));

    return newCurrentPullList?.length
      ? (
        <div className="info__card pull-card" key={`currentPull_${currentPull.id}`}>
          <div className="info-content pull-content">
            <div className="info-repeat pull-block">
              <h2 className="pb-1 text-lg pull-block-header">
                Список заявок в
                {this.pullStatusFilterForNew(currentPull) ? ' текущем открытом ' : ' '}
                {this.pullStatusFilterForOld(currentPull) ? ' закрытом ' : ' '}
                пуле №
                {currentPull.id}
                {this.pullStatusFilterForAllocateResult(currentPull) ? ' для распределения результатов' : ''}
                :
              </h2>
              {newCurrentPullList?.length > 0
                ? (
                  <div className="xl:pl-6 pull-block-content">
                    {!cancelCurrentPull && this.pullStatusFilterForAllocateResult(currentPull) && (
                      <div className="py-1 allocation-index pull-form-allocate">
                        <Form
                          initialValues={{
                            'id': currentPull.id,
                          }}
                          onFinish={this.handleAllocateProjectPullResult}
                          className="flex flex-col gap-2 for-admin"
                        >
                          <div className="flex flex-row gap-3">
                            <div className="allocation-index-text self-center text-gray-800">
                              Приобретено для данного пула:
                            </div>
                            <Form.Item name='id' className="hidden">
                              <input type='hidden'/>
                            </Form.Item>
                            <Form.Item name='sum' className="!mb-0">
                              <InputNumber min={0.1} className="allocation-index-input"/>
                            </Form.Item>
                            <span className="allocation-index-currency self-center">{project.name_result}</span>
                          </div>
                          <div className="flex flex-row gap-3 mb-4">
                            <button className="allocation-index-button self-center standart-btn py-1" type='submit'>
                              <div className="!py-2">Распределить приобретенный {project.name_result} по заявкам ниже</div>
                            </button>
                            <button
                              className="allocation-index-button self-center standart-btn py-1"
                              onClick={() => this.setState({ cancelCurrentPull: true })}
                            >
                              <div className="!py-2">Отменить все заявки по текущему пулу</div>
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                    {!cancelCurrentPull && this.pullStatusFilterForNew(currentPull) && currentPullSum >= project?.projectCurrentPull?.pull_price && (
                      <div className="py-1 allocation-index pull-form-close-pull">
                        <Form
                          initialValues={{
                            'id': currentPull.id,
                          }}
                          onFinish={this.handleOpenNewPull}
                          className="flex flex-col gap-2 for-admin"
                        >
                          <Form.Item name='id' className="hidden">
                            <input type='hidden'/>
                          </Form.Item>
                          <div className="flex flex-row gap-3 mb-4">
                            <button className="allocation-index-button self-center standart-btn py-1" type='submit'>
                              <div className="!py-2">Закрыть текущий пул и сделать новый, т.к. сумма пула превышает {project?.projectCurrentPull?.pull_price} {project?.currency_code}</div>
                            </button>
                            <button
                              className="allocation-index-button self-center standart-btn py-1"
                              onClick={() => this.setState({ cancelCurrentPull: true })}
                            >
                              <div className="!py-2">Отменить все заявки по текущему пулу</div>
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                    {cancelCurrentPull && this.pullStatusFilterForCloseResult(currentPull) && (
                      <div className="py-1 allocation-index pull-form-allocate">
                        <Form
                          initialValues={{
                            'id': currentPull.id,
                          }}
                          onFinish={this.setCancelProjectPull}
                          // onFinish={this.handleCancelProjectPull}
                          className="flex flex-col gap-2 for-admin"
                        >
                          <div className="flex flex-row gap-3">
                            <div className="allocation-index-text self-center text-gray-800">
                              Для проверки введите сумму всех заявок пула:
                            </div>
                            <Form.Item name='id' className="hidden">
                              <input type='hidden'/>
                            </Form.Item>
                            <Form.Item
                              name='sum'
                              className="!mb-0"
                              // rules={[
                              //   {
                              //     validateTrigger: 'onBlur',
                              //     message: 'Указанная вами сумма не совпадает с суммой пула',
                              //     validator: async (_, value) => {
                              //       console.log({_f: 'BullranIndexPage-validator-938'});
                              //
                              //       const currentPullSumAmount = newCurrentPullList?.length > 0
                              //         ? newCurrentPullList.reduce((pred, x) => pred + (x.amount ? Number(x.amount) : 0), 0)
                              //         : 0;
                              //
                              //       console.log({
                              //         _f: 'BullranIndexPage-validator-942',
                              //         currentPullSumAmount,
                              //         val: Number(value.replace(/[^\d.]/, '')),
                              //         value,
                              //       });
                              //
                              //       if (Number(value.replace(/[^\d.]/, '')) === currentPullSumAmount) {
                              //         cancelCurrentPullError && this.setState({cancelCurrentPullError : false})
                              //         return Promise.resolve();
                              //       }
                              //       this.state({cancelCurrentPullError : true})
                              //       return Promise.reject();
                              //     },
                              //   }
                              // ]}
                            >
                              <InputNumber min={0.1} className="allocation-index-input"/>
                            </Form.Item>
                            <span className="allocation-index-currency self-center">{project.name_result}</span>
                          </div>
                          <div className="flex flex-row gap-3 mb-4">
                            <button className="allocation-index-button self-center standart-btn py-1" type='submit'>
                              <div className="!py-2">Подтверждаю отмену всех заявок в текущем пуле</div>
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                    <div className="gap-3 font-semibold flex flex-row pull-table-header-block">
                      <div className="gap-3 flex-row pull-table-row hidden 2xl:flex">
                        <div className="index-col-span-list-id">№ заявки</div>
                        <div className="index-col-span-list-date">Дата</div>
                        <div className="index-col-span-list-sum">Сумма заявки</div>
                        <div className="index-col-span-list-commission">Комиссия</div>
                        <div className="index-col-span-list-all">Итоговая сумма</div>
                        <div className="index-col-span-list-result">{project.name_result}</div>
                        <div className="index-col-span-list-fio">ФИО</div>
                      </div>
                      <div className="gap-3 flex flex-row pull-table-row 2xl:hidden">
                        <div className="index-col-span-list-id">№</div>
                        <div className="index-col-span-list-date">Дата</div>
                        <div className="index-col-span-list-sum">Сумма</div>
                        <div className="index-col-span-list-commission">Ком.</div>
                        <div className="index-col-span-list-all">Итог.</div>
                        <div className="index-col-span-list-result">{project.name_result}</div>
                        <div className="index-col-span-list-fio">ФИО</div>
                      </div>
                    </div>
                    {newCurrentPullList?.length > 0 && newCurrentPullList
                      .reduce((pred, x) => [{
                        amount_user: pred[0].amount_user + Number(x.amount_user),
                        amount_commission: pred[0].amount_commission + Number(x.amount_commission),
                        amount: pred[0].amount + Number(x.amount),
                        result_sum: pred[0].result_sum + (x.result_sum ? Number(x.result_sum) : 0),
                      }], [{
                        amount_user: 0,
                        amount_commission: 0,
                        amount: 0,
                        result_sum: 0,
                      }])
                      .map((x, idx) => (
                        <div className="font-semibold py-2 bg-green-200 gap-3 flex flex-row pull-table-group-block" key={`sum_${idx}`}>
                          <div className="gap-3 flex flex-row pull-table-row">
                            <div className="index-col-span-list-id">&nbsp;</div>
                            <div className="index-col-span-list-date">ИТОГО</div>
                            <div className="index-col-span-list-sum"><NumericFormat value={Number(x.amount_user)} displayType={'text'} decimalScale={0} thousandSeparator=" "/></div>
                            <div className="index-col-span-list-commission"><NumericFormat value={Number(x.amount_commission)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/></div>
                            <div className="index-col-span-list-all"><NumericFormat value={Number(x.amount)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/></div>
                            <div className="index-col-span-list-result">{x.result_sum ?<NumericFormat value={Number(x.result_sum)} displayType={'text'} decimalScale={PROJECT_RESULT_DECIMAL_SCALE} thousandSeparator=" "/>: '-'}</div>
                            <div className="index-col-span-list-fio hidden 2xl:block">&nbsp;</div>
                          </div>
                        </div>
                      ))}
                    <div className="py-2 gap-3 flex flex-col pull-table-rows-block">
                      {newCurrentPullList?.length > 0 && newCurrentPullList.map((x) => (
                        <div key={`currentPull_${x.id}`} className="flex flex-col 2xl:flex-row pull-table-row-block">
                          <div className="gap-3 flex flex-row pull-table-row">
                            <div className="index-col-span-list-id">{x.id}</div>
                            <div className="index-col-span-list-date long">{(new dayjs(x.date_create)).format(DATE_FORMAT)}</div>
                            <div className="index-col-span-list-date short">{(new dayjs(x.date_create)).format(DATE_FORMAT_SHORT)}</div>
                            <div className="index-col-span-list-sum"><NumericFormat value={Number(x.amount_user)} displayType={'text'} decimalScale={0} thousandSeparator=" "/></div>
                            <div className="index-col-span-list-commission"><NumericFormat value={Number(x.amount_commission)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/></div>
                            <div className="index-col-span-list-all"><NumericFormat value={Number(x.amount)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/></div>
                            <div className="index-col-span-list-result">{x.result_sum ? <NumericFormat value={Number(x.result_sum)} displayType={'text'} decimalScale={PROJECT_RESULT_DECIMAL_SCALE} thousandSeparator=" "/> : '-'}</div>
                          </div>
                          <div className="gap-3 flex flex-row pull-table-row">
                            <div className="index-col-span-list-fio">{x.user.first_name} {x.user.last_name} (id: {x.user.id})</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
                : (
                  <div className="pl-6 py-6">Пока нет заявок в данном пуле</div>
                )
              }
            </div>
          </div>
        </div>
      )
      : null
    ;
  }

  renderUserRequestList = (project, requests, show_canceled_requests) => {
    const newList = show_canceled_requests
      ? requests
      : requests.filter((x) => this.hide_canceled_requests(x, false))
    return (
      <div className="info__card request-card">
        <div className="info-content request-content">
          <div className="info-repeat request-block">
            <h2 className="pb-1 text-lg request-block-header">
              Список Ваших заявок
            </h2>
            {requests?.length > 0
              ? (
                <div className="xl:pl-6 request-block-content">
                  <div className="gap-2 font-semibold request-table-header-block">
                    <div className="gap-2 flex-row hidden 2xl:flex request-table-row">
                      <div className="index-col-span-list-id">№ записи</div>
                      <div className="index-col-span-list-date">Дата заявки</div>
                      <div className="index-col-span-list-sum">Сумма заявки</div>
                      <div className="index-col-span-list-commission">Комиссия </div>
                      <div className="index-col-span-list-all">Итоговая сумма</div>
                      <div className="index-col-span-list-result">{project.name_result}</div>
                      <div className="ml-3 index-col-span-list-status">
                        <div>Статус</div>
                        <div className="ml-6 show-canceled">
                          <Checkbox
                            checked={show_canceled_requests}
                            onChange={(e) => this.setState({
                              show_canceled_requests: !show_canceled_requests,
                            })}
                          >
                            Показывать отмененные
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                    <div className="gap-2 flex flex-row 2xl:hidden request-table-row">
                      <div className="index-col-span-list-id">№</div>
                      <div className="index-col-span-list-date">Дата</div>
                      <div className="index-col-span-list-sum">Сумма</div>
                      <div className="index-col-span-list-commission">Ком.</div>
                      <div className="index-col-span-list-all">Итог.</div>
                      <div className="index-col-span-list-result">{project.name_result}</div>
                      <div className="!hidden lg:!block index-col-span-list-status">Статус</div>
                    </div>
                  </div>
                  {newList?.length > 0 && newList?.reduce(
                    (pred, x) => [{
                      amount_user: pred[0].amount_user + Number(x.amount_user),
                      amount_commission: pred[0].amount_commission + Number(x.amount_commission),
                      amount: pred[0].amount + Number(x.amount),
                      result_sum: pred[0].result_sum + (x.result_sum ? Number(x.result_sum) : 0),
                    }], [{
                      amount_user: 0,
                      amount_commission: 0,
                      amount: 0,
                      result_sum: 0,
                    }])
                    .map((x, idx) => (
                      <div className="font-semibold py-2 bg-green-200 gap-2 flex flex-row request-table-group-block" key={`user_request_sum_${idx}`}>
                        <div className="gap-2 flex flex-row request-table-row">
                          <div className="index-col-span-list-id">&nbsp;</div>
                          <div className="index-col-span-list-date">ИТОГО</div>
                          <div className="index-col-span-list-sum"><NumericFormat value={Number(x.amount_user)} displayType={'text'} decimalScale={0} thousandSeparator=" "/></div>
                          <div className="index-col-span-list-commission"><NumericFormat value={Number(x.amount_commission)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/></div>
                          <div className="index-col-span-list-all"><NumericFormat value={Number(x.amount)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/></div>
                          <div className="index-col-span-list-result">{x.result_sum ? <NumericFormat value={Number(x.result_sum)} displayType={'text'} decimalScale={PROJECT_RESULT_DECIMAL_SCALE} thousandSeparator=" "/> : ''}</div>
                          <div className="ml-3 hidden 2xl:flex flex-row index-col-span-list-status">&nbsp;</div>
                        </div>
                      </div>
                    ))}
                  {newList?.length > 0 && newList.map((x, idx) => (
                    <div key={`user_request_${x.id}`} className="hover:bg-gray-100 flex flex-col lg:flex-row lg:gap-2 request-table-row-block">
                      <div className="gap-2 flex flex-row request-table-row">
                        <div className="index-col-span-list-id">{idx + 1}</div>
                        <div className="index-col-span-list-date long">{(new dayjs(x.date_create)).format(DATE_FORMAT)}</div>
                        <div className="index-col-span-list-date short">{(new dayjs(x.date_create)).format(DATE_FORMAT_SHORT)}</div>
                        <div className="index-col-span-list-sum"><NumericFormat value={Number(x.amount_user)} displayType={'text'} decimalScale={0} thousandSeparator=" "/></div>
                        <div className="index-col-span-list-commission"><NumericFormat value={Number(x.amount_commission)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/></div>
                        <div className="index-col-span-list-all"><NumericFormat value={Number(x.amount)} displayType={'text'} decimalScale={DECIMAL_SCALE} thousandSeparator=" "/></div>
                        <div className="index-col-span-list-result">{x.result_sum ? <NumericFormat value={Number(x.result_sum)} displayType={'text'} decimalScale={PROJECT_RESULT_DECIMAL_SCALE} thousandSeparator=" "/> : ''}</div>
                      </div>
                      <div className="gap-2 flex flex-row request-table-row">
                        <div className="ml-3 flex flex-row lg:flex-col gap-3 index-col-span-list-status">
                          <div className="status-text">{x.status_name || ''}</div>
                          {
                            x.status_id === INDEX_REQUEST_STATUS_NEW
                              ? (
                                <div className="flex flex-row gap-3 ml-6 align-middle button-block">
                                  <button
                                    className="allocation-index-button self-center standart-btn standart-btn-no-shadow py-0"
                                    onClick={() => this.handlePayUserRequest(x)}
                                  >
                                    <div className="!py-0 !px-4">Оплатить</div>
                                  </button>
                                  <button
                                    className="allocation-index-button self-center standart-btn standart-btn-border py-0"
                                    onClick={() => this.handleCancelRequest(x)}
                                  >
                                    <div className="!py-0 !px-4">Отменить</div>
                                  </button>
                                </div>
                              )
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
              : (
                <div className="pl-6 py-6">У Вас пока нет заявок</div>
              )
            }
          </div>
        </div>
      </div>
    );
  }

  hide_canceled_requests = (x, hide_new = true) => {
    return (
      (!hide_new || x.status_id !== INDEX_REQUEST_STATUS_NEW)
      && x.status_id < INDEX_REQUEST_STATUS_CANCEL_BY_USER
    );
  }

  renderCommissionInfo = (project, commissions) => {
    return (
      <div className="info__card">
        <div className="info-content commission-info-content">
          <div className="info-repeat">
            <h2 className="pb-1 text-lg commission-header">Условия по комиссиям {project.name}:</h2>
            <div className="xl:pl-6 flex flex-col gap-3 commission-block-content">
              <Row justify="start font-semibold" className="!hidden 2xl:!flex gap-3 commission-table-header-block desktop">
                <Col flex={INDEX_COL_SPAN_CURRENCY} className="col-currency">
                  Валюта
                </Col>
                <Col flex={INDEX_COL_SPAN_ROLE} className="text-right ">
                  Статус
                </Col>
                <Col flex={INDEX_COL_SPAN_ALL_USER_SUM} className="text-right col-userSum">
                  Сумма всех заявок (в т.ч. текущая)
                </Col>
                <Col flex={INDEX_COL_SPAN_SUM} className="text-right col-range">
                  Сумма текущей заявки
                </Col>
                <Col flex={INDEX_COL_SPAN_ALL_COMMISSION} className="text-right col-commission">
                  <Row justify="start font-semibold row-commission-text">
                    <Col flex={INDEX_COL_SPAN_ALL_COMMISSION} className="text-center col-commission-text">
                      Комиссии
                    </Col>
                  </Row>
                  <Row justify="start font-semibold flex gap-3 row-commission-info">
                    <Col flex={INDEX_COL_SPAN_COMMISSION} className="text-center col-commission-com">
                      Consultation fee
                      &nbsp;
                      <Popover
                        placement='topRight'
                        content={`Consultation fee удерживается в момент формирования ${project.name}`}
                      >
                        <QuestionCircleOutlined className="!align-middle" />
                      </Popover>
                    </Col>
                    <Col flex={INDEX_COL_SPAN_COMMISSION} className="text-center col-commission-sell">
                      Management fee
                      &nbsp;
                      <Popover
                        placement='topRight'
                        content={`Management fee удерживается в момент продажи ${project.name}`}
                      >
                        <QuestionCircleOutlined className="!align-middle" />
                      </Popover>
                    </Col>
                  </Row>
                </Col>
                <Col flex={'auto'}>&nbsp;</Col>
              </Row>
              <div className="font-semibold !flex 2xl:!hidden gap-3 mt-4 commission-table-header-block mobile">
                <Row justify="start" className="flex 2xl:mb-2 last:mb-0 gap-3 row-currency">
                  <Col flex={INDEX_COL_SPAN_CURRENCY} className="currency-info">Валюта</Col>
                  <Col flex="auto" className="currency-other">
                    <Row justify="start" className="mb-2 last:mb-0 gap-3 row-role">
                      <Col flex={INDEX_COL_SPAN_ROLE} className="text-right role-info">Статус</Col>
                      <Col flex="auto" className="role-other">
                        <Row justify="start" className="gap-3 row-userSum">
                          <Col flex={INDEX_COL_SPAN_ALL_USER_SUM} className="text-right userSum-info">Сумма всех заявок (в т.ч. текущая)</Col>
                          <Col flex="auto" className="userSum-other">
                            <Row justify="start" className="row-request">
                              <Col flex={INDEX_COL_SPAN_SUM} className="text-right request-info request-info-range">Сумма текущей заявки</Col>
                              <Col flex={INDEX_COL_SPAN_COMMISSION} className="text-center request-info request-info-com">
                                Consultation fee
                                &nbsp;
                                <Popover
                                  placement='topRight'
                                  content={`Consultation fee удерживается в момент формирования ${project.name}`}
                                >
                                  <QuestionCircleOutlined className="!align-middle" />
                                </Popover>
                              </Col>
                              <Col flex={INDEX_COL_SPAN_COMMISSION} className="text-center request-info request-info-sell">
                                Management fee
                                &nbsp;
                                <Popover
                                  placement='topRight'
                                  content={`Management fee удерживается в момент продажи ${project.name}`}
                                >
                                  <QuestionCircleOutlined className="!align-middle" />
                                </Popover>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              {/*
                      {commissions.filter((x) => x.max || x.min).map((x) => (
                        <Row justify="start">
                          <Col span={INDEX_COL_SPAN_SUM}>{`${x.min ? (x.max ? `${x.min} - ${x.max}` : ` свыше ${x.min}`) : `до ${x.max}`}`} {project?.currency_code}</Col>
                          <Col span={INDEX_COL_SPAN_COMMISSION}>{Math.floor(x.commission)}%</Col>
                        </Row>
                      ))}
                    */}
              {/*{commissionsTable.map((currencyList) => (*/}
              {commissions?.map((currencyList) => (
                <Row justify="start" className={`flex mb-2 last:mb-0 gap-3 row-currency currency_${currencyList.currency_name}`} key={`currency_${currencyList.currency_name}`}>
                  <Col flex={INDEX_COL_SPAN_CURRENCY} className="currency-info">
                    {currencyList.currency_name}
                  </Col>
                  <Col flex="auto" className="currency-other">
                    {currencyList?.currency?.map((role) => (
                      <Row justify="start" className={`mb-2 last:mb-0 gap-3 row-role role_${role.roleName}`} key={`role_${role.roleName}`}>
                        <Col flex={INDEX_COL_SPAN_ROLE} className="text-right role-info">
                          {role.roleName === '' ? 'Остальные' : role.roleName}
                        </Col>
                        <Col flex="auto" className="role-other">
                          {role?.userSum?.map((userSum) => (
                            <Row justify="start" className="gap-3 row-userSum" key={`userSum_${userSum.min}_${userSum.max}`}>
                              <Col flex={INDEX_COL_SPAN_ALL_USER_SUM} className="text-right userSum-info">
                                {`${userSum.min ? (userSum.max ? `${userSum.min} - ${userSum.max}` : ` свыше ${userSum.min}`) : `до ${userSum.max}`}`}
                              </Col>
                              <Col flex="auto" className="userSum-other">
                                {userSum?.request?.map((request) => (
                                  <Row justify="start" key={`request_${request.min}_${request.max}`} className="row-request">
                                    <Col flex={INDEX_COL_SPAN_SUM} className="text-right request-info request-info-range">
                                      {`${
                                        request.min
                                          ? (
                                            request.max
                                              ? `${request.min} - ${request.max} ${currencyList.currency_name}`
                                              : ` свыше ${request.min} ${currencyList.currency_name}`
                                          )
                                          : (
                                            request.max
                                              ? `до ${request.max} ${currencyList.currency_name}`
                                              : '-'
                                          )
                                      }`}
                                    </Col>
                                    <Col flex={INDEX_COL_SPAN_COMMISSION} className="text-right request-info request-info-com">
                                      {request.com && (
                                        <>
                                          {request.com.percent !== null && request.com.percent !== undefined && !request.com.fix
                                            ? `${request.com.percent} %`
                                            : ''
                                          }
                                          {request.com.fix > 0 && (request.com.percent === null || request.com.request.com.percent !== undefined)
                                            ? `${request.com.fix} ${currencyList.currency_name}`
                                            : ''
                                          }
                                          {request.com.min > 0
                                            ? `, min
                                            }: ${request.com.min} ${currencyList.currency_name}`
                                            : ''
                                          }
                                          {request.com.max > 0
                                            ? `, max: ${request.com.max} ${currencyList.currency_name}`
                                            : ''
                                          }
                                        </>
                                      )}
                                    </Col>
                                    <Col flex={INDEX_COL_SPAN_COMMISSION} className="text-right request-info request-info-sell">
                                      {request.sell && (
                                        <>
                                          {request.sell.percent !== null && request.sell.percent !== undefined && !request.sell.fix
                                            ? `${request.sell.percent} %`
                                            : ''
                                          }
                                          {request.sell.fix > 0 && (request.sell.percent === null || request.sell.request.sell.percent !== undefined)
                                            ? `${request.sell.fix} ${currencyList.currency_name}`
                                            : ''
                                          }
                                          {request.sell.min > 0
                                            ? `, min
                                            }: ${request.sell.min} ${currencyList.currency_name}`
                                            : ''
                                          }
                                          {request.sell.max > 0
                                            ? `, max: ${request.sell.max} ${currencyList.currency_name}`
                                            : ''
                                          }
                                        </>
                                      )}
                                    </Col>
                                    <Col flex="auto">&nbsp;</Col>
                                  </Row>
                                ))}
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRules = (project) => {
    return (
      <div className="info__card">
        <div className="info-content">
          <div className="info-repeat">
            <h2 className="pb-1 text-lg">Правила формирования {project.name}:</h2>
            <ul className="list-disc pl-6">
              {project?.projectRules?.map((x, idx) => {
                const val = (
                  x.text
                    .replaceAll(/\{INDEX_PULL_MIN_SUM}/g, project?.projectCurrentPull?.pull_price)
                    .replaceAll(/\{INDEX_MIN_SUM}/g, project?.min)
                    .replaceAll(/\{INDEX_FIRST_MIN_SUM}/g, project?.first_min)
                    .replaceAll(/\{INDEX_CURRENCY_CODE}/g, project?.currency_code)
                );
                return <li key={`li_key_${idx}`}>{val}</li>
              })}
              {/*<li>При достижении суммы текущего пула в {INDEX_PULL_MIN_SUM} USDT данный пул закрывается и открывается следующий</li>*/}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  renderCurrentPullInfo = (project, pullNew, currentIndexSum) => {
    const currentIndexPercent = (currentIndexSum / project?.projectCurrentPull?.pull_price * 100).toFixed(2);

    return pullNew?.id
      ? (
        <div className="info__card">
          <div className="info-content">
            <div className="info-repeat">
              <h2>
                <div className="text-lg">Текущий открытый пул.</div>
                <div className="current-pull-info-row">
                  <div className="">Общая сумма всех заявок:</div>
                  <NumericFormat
                    key={`currentIndexSum_${pullNew?.id}`}
                    value={Number(currentIndexSum)}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator=" "
                    prefix={' '}
                    suffix={[' ', project?.currency_code].join(' ')}
                  />
                </div>
                <div className="current-pull-info-row">
                  <div className="">Минимальная сумма для закрытия пула:</div>
                  <NumericFormat
                    key={`pull_price_${pullNew?.id}`}
                    value={Number(pullNew?.pull_price)}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator=" "
                    prefix={' '}
                    suffix={[' ', project?.currency_code].join(' ')}
                  />
                </div>
              </h2>
              <Progress
                key={`progress_${pullNew?.id}`}
                type="line"
                percent={currentIndexPercent}
                success={{
                  strokeColor: '#B736DD',
                }}
                format={(percent) => `${percent} %`}
                strokeColor={{ from: '#8A25FC', to: '#ED4AB8' }}
              />
            </div>
          </div>
        </div>
      )
      : (
        <div className="info__card">
          <div className="info-content">
            <div className="info-repeat">
              <h2>
                <div className="text-lg">Нет открытого пула.</div>
                <div className="text-lg">Прием заявок временно приостановлен.</div>
              </h2>
            </div>
          </div>
        </div>
      );
  }

  pullStatusFilterForCloseResult = (x) => {
    return [
      INDEX_PULLS_STATUS_OPEN,
      INDEX_PULLS_STATUS_CLOSE,
    ].includes(x.pull_status_id);
  }

  pullStatusFilterForAllocateResult = (x) => {
    return [
      INDEX_PULLS_STATUS_CLOSE,
      INDEX_PULLS_STATUS_WAIT_SEND,
      INDEX_PULLS_STATUS_WAIT_BUY,
    ].includes(x.pull_status_id);
  }

  pullStatusFilterForNew = (x) => {
    return [
      INDEX_PULLS_STATUS_OPEN,
    ].includes(x.pull_status_id);
  }

  pullStatusFilterForOld = (x) => {
    return [
      INDEX_PULLS_STATUS_WAIT_RESULT,
      INDEX_PULLS_STATUS_END,
    ].includes(x.pull_status_id);
  }

  render() {
    const { t, userId } = this.props;
    const {
      loadingPulls,
      loadingRequests,
      loadingCurrentPull,
      loadingCurrentProject,
      loadingCommissions,
      loadingUserCommissions,
      project,
      pulls,
      commissions,
      requests,
      show_canceled_requests,
      newRequestAmount,
      newRequestCommission,
      newRequestSum,
      currentWallet,
    } = this.state;

    const pullNew = pulls.find((x) => this.pullStatusFilterForNew(x));
    const pullForAllocateResult = pulls.find((x) => this.pullStatusFilterForAllocateResult(x));
    const pullsOld = pulls.filter((x) => this.pullStatusFilterForOld(x));

    const currentIndexSum = pullNew
      ?.list
      ?.filter((x) => this.hide_canceled_requests(x))
      ?.reduce((prevX, currX) => prevX + Number(currX.amount_user), 0) || 0;

    return (
      <main className="bullran-index-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName={project.name} withPaySubscription={false} parentPageName={t('LEFT_MENU_PRODUCTS')} parentPageLink={ROUTE_PRODUCTS} className="project-color-card" />
          <Spin spinning={loadingPulls || loadingRequests || loadingCurrentPull || loadingCurrentProject || loadingCommissions || loadingUserCommissions}>
            {this.renderCurrentPullInfo(project, pullNew, currentIndexSum)}
            {this.renderRules(project)}
            {this.renderCommissionInfo(project, commissions)}
            {INDEX_ADMIN.includes(userId) && (
              <>
                {pullForAllocateResult?.list?.length > 0 && this.renderPull(project, pullForAllocateResult)}
                {this.renderPull(project, pullNew, currentIndexSum)}
                {pullsOld?.length > 0 && pullsOld.map((x) => this.renderPull(project, x))}
              </>
            )}
            {this.renderUserRequestList(project, requests, show_canceled_requests)}
            {pullNew?.id && this.renderNewUserRequest(project, requests, newRequestAmount, newRequestCommission, newRequestSum, currentWallet, pullNew?.id)}
          </Spin>
        </div>
      </main>
    );
  }

}
const mapStateToProps = (state) => ({
  userId: state.user.user_id,
  // userInfo: state.user.info,
  wallets: state.user?.info?.wallets,
});

const mapDispatchToProps = {
  updateUserWalletById: updateWalletById,
  updateDrawerContent: setDrawerContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withWindowResize(
      withRouter(
        BullranIndexPage
      )
    )
  )
);
