import { createSlice } from '@reduxjs/toolkit';

const burgerSlice = createSlice({
  name: 'burger',
  initialState: {
    isOpen: false,
  },
  reducers: {
    openBurger: (state) => {
      state.isOpen = true;
    },
    closeBurger: (state) => {
      state.isOpen = false;
    },
    toggleBurger: (state) => {
      state.isOpen = !state.isOpen;
    },
  }
})

export const { openBurger, closeBurger, toggleBurger } = burgerSlice.actions;
export default burgerSlice.reducer;
