import { ConfigProvider } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@locale/index'
import ruRU from 'antd/es/locale/ru_RU';
import enUS from 'antd/es/locale/en_US';
import store from '@state/store';

import '@ui/styles/base.css';
import '@ui/styles/components.css';
import '@ui/styles/utilities.css';
import '@ui/styles/global.css';

import { LANG_RU, LANG_EN } from './constants';

const getLocale = (lang: string): (typeof ruRU | typeof enUS) => {
  switch (lang) {
    case LANG_RU:
      return ruRU;
    case LANG_EN:
      return enUS;
    default:
      return ruRU;
  }
};

const Index = () => {
  const { i18n } = useTranslation();
  const currentLocale = getLocale(i18n.language);

  moment.locale(i18n.language);

  return useMemo(() => {
    return (
      // <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <ConfigProvider locale={currentLocale}>
              <App />
            </ConfigProvider>
          </BrowserRouter>
        </Provider>
      // </React.StrictMode>
    );
  }, [currentLocale]);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
