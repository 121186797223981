import { Amount } from '@common/amount';
import React, { Component } from 'react';
import QRCode from 'react-qr-code';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { message, Spin } from 'antd';
import copy from 'copy-to-clipboard';

import { DECIMAL_SCALE } from '@src/constants';
import { handleCheckBalance } from '@src/helpers/checkBalance';
import { closeDrawer, setCheckBalanceResult, setLoadingCheckBalance, setStartTimers } from '@state/drawer';
import { updateWalletById } from '@state/user';

import { ReactComponent as CloseIcon } from '@ui/icons/close.svg';
import { ReactComponent as CopyToClipboardIcon } from '@ui/icons/copyToClipboard.svg';

class PayIn extends Component {
  handleCopy = (text) => {
    copy(text.trim());
    message.success('Скопировано', 1);
  }

  checkBalance = () => {
    const {
      wallet,
      updateUserWalletById,
      updateLoadingCheckBalance,
      setCloseDrawer,
      drawer,
      product,
      updateCheckBalanceResult,
      startCheckBalance,
    } = this.props;

    handleCheckBalance({
      wallet,
      updateUserWalletById,
      updateLoadingCheckBalance,
      setCloseDrawer,
      drawer,
      productForBuy: product,
      updateCheckBalanceResult,
      startCheckBalance,
    })
  }

  render() {
    const { product, wallet, delta, deltaWithFee, setCloseDrawer, drawer } = this.props;
    const { loadingCheckBalance } = drawer;

    let payInAmount = 0;

    if (deltaWithFee) {
      payInAmount = Number(deltaWithFee);
    } else if (delta) {
      payInAmount = Number(delta) + Number(wallet?.currency_commission_pay_in);
    } else if (product?.current_price_with_sale) {
      payInAmount = (
          Number(product?.current_price_with_sale || 0)
        + Number(wallet?.currency_commission_pay_in)
        - Number(wallet?.balance)
      );
    }
    if (payInAmount) {
      payInAmount = wallet?.currency_min_pay_in && payInAmount < Number(wallet?.currency_min_pay_in)
        ? Number(wallet?.currency_min_pay_in).toFixed(6)
        : payInAmount.toFixed(6)
    }

    return (
      <div id="modal7" className="modal1 deposit-modal modal" key={wallet?.address}>
        <Spin spinning={loadingCheckBalance}>
          <div className="modal__block-close">
            <CloseIcon onClick={setCloseDrawer} />
          </div>
          <div className="modal__window">
            <div className="modal__head">
              <h1>Пополнение кошелька</h1>
            </div>
            <div className="deposit">
              <div className="deposit-head">
                <div className="deposit-left">
                  <h5>Сумма</h5>
                  <div className="deposit-currency">
                    <span>
                      {product || delta
                        ? <Amount wallet={wallet} value={payInAmount} digits={DECIMAL_SCALE} />
                        : <>Любая</>
                      }
                    </span>
                    {!payInAmount && (
                      <span>
                        min: <Amount wallet={wallet} value={wallet?.currency_min_pay_in} withSpace={false} />
                      </span>
                    )}
                    <span>
                      в т.ч. комиссия <Amount wallet={wallet} value={wallet?.currency_commission_pay_in} withSpace={false} />
                    </span>
                  </div>
                </div>
                <div className="deposit-line"></div>
                {wallet?.currency_network_id && (
                  <div className="deposit-left">
                    <h5>Сеть</h5>
                    <div className="deposit-currency">
                      <span>{wallet?.currency_network_name}</span>
                      <span className='network_name'>
                        ({wallet?.currency_network_name_code})
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {(wallet?.currency_network_id || wallet?.currency_commission_pay_in) && (
                <div className="deposit-message">
                  <strong>Информационное сообщение</strong>
                  <div>
                    {wallet?.currency_network_id && (
                      <span>
                        Указанный ниже адрес кошелька,
                        на который необходимо перечислить денежные средства для пополнения,
                        принадлежит и поддерживается только в
                        &nbsp;
                        <Link
                          to='https://tronscan.org/#/token20/TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'
                          target='_blank'
                          className="underline"
                        >
                          сети {wallet?.currency_network_name} ({wallet?.currency_network_name_code})
                        </Link>
                        .
                        <br />
                        Пожалуйста, убедитесь, что ваш кошелек также находится в данной сети.<br />
                      </span>
                    )}
                    {wallet?.currency_commission_pay_in && (
                      product
                        ? (
                          <>
                            Дополнительно к стоимости выбранного продукта
                            добавляется
                            <Amount wallet={wallet} value={wallet?.currency_commission_pay_in} />
                            за каждую транзакцию пополнения.
                          </>
                        )
                        : (
                          <>
                            Внимание, комиссия
                            <Amount wallet={wallet} value={wallet?.currency_commission_pay_in} />
                            взимается за каждую транзакцию пополнения.
                          </>
                        )
                    )}
                    <span> Сумма пополнения должна быть больше <Amount wallet={wallet} value={wallet?.currency_min_pay_in} withSpace={false} /></span>
                  </div>
                </div>
              )}
              <button className="standart-btn !mb-0 !mt-8" onClick={this.checkBalance}>
                <div>Монеты отправлены, нужно проверить баланс</div>
              </button>
              <div className="deposit-address">
                <h4>Адрес кошелька для зачисления:</h4>
                <div className="deposit-value">
                  <span>{wallet?.address}</span>
                  <div className="deposit-copy">
                    <CopyToClipboardIcon onClick={() => this.handleCopy(wallet?.address)}/>
                  </div>
                </div>
                <div className="qr-code flex justify-center">
                  <div className="qr-code w-[300px] h-[300px] my-5 text-center text-gray-800">
                    QR-код для адреса кошелька:
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      className="mt-2"
                      value={wallet?.address}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  drawer: state.drawer,
});

const mapDispatchToProps = {
  updateUserWalletById: updateWalletById,
  updateLoadingCheckBalance: setLoadingCheckBalance,
  setCloseDrawer: closeDrawer,
  updateCheckBalanceResult: setCheckBalanceResult,
  startCheckBalance: setStartTimers,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  PayIn
);
