import React, { FC } from 'react';

import EducationAcademyPage from './educationAcademy';

interface OwnProps {
  program: object;
}

type Props = OwnProps;

const EducationAcademyRoute: FC<Props> = ({ program }) => <EducationAcademyPage program={program} />;

export default EducationAcademyRoute;
