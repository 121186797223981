import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import BullranIndexPage from './bullranIndex';

interface RouterParams {
  project_id?: string;
}
const BullranIndexRoute: FC = () => {
  const { project_id } = useParams<RouterParams>();

  return <BullranIndexPage project_id={project_id} />;
}

export default BullranIndexRoute;
