import storage from '@services/storage';
import React, { Component, memo } from 'react';
import { connect } from 'react-redux';

import { setAppStage } from '@state/appStage';

import api from '@services/api';
import { Spin } from 'antd';
import { APPSTAGE_SIGNIN, APPSTAGE_INITFAIL, AUTH_COOKIE_NAME } from '@src/constants';

import styles from './styles.module.less';

class Initial extends Component {
  componentDidMount = async() => {
    const { setAppStageData } = this.props;

    const hasToken = !!storage.get(AUTH_COOKIE_NAME);

    if (hasToken) {
      const {status, data} = await api.getTokenByRefresh();

      if ([400, 401].includes(status) || [400, 401].includes(data.statusCode)) {
        setAppStageData(APPSTAGE_SIGNIN);
      } else if (status === 200) {
        await api.setAUTHToken(data.token);
        await api.updateUserExtraInfo();
      } else {
        setAppStageData(APPSTAGE_INITFAIL);
      }
    } else {
      setAppStageData(APPSTAGE_SIGNIN);
    }
  }

  render() {
    return (
      <Spin spinning={true} className={styles.content} />
    );
  }
}

const mapStateToProps = state => ({
  userId: state.user.user_id,
});

const mapDispatchToProps = {
  setAppStageData: setAppStage,
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(
    Initial
  )
);
