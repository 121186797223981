import { Amount } from '@common/amount';
import FormWithMessage from '@common/formWithMessage';
import api from '@services/api';
import { getMessageFromRequest } from '@src/helpers';
import { getEthereum, getEthereumProps } from '@src/helpers/getEthereum';
import { isEthereumNetwork } from '@src/helpers/metaTask';
import { IDrawer } from '@state/modal/interfaces';
import { useAppDispatch, useAppSelector } from '@state/store';
import { IWalletInfo, updateWalletById } from '@state/user';
import BigNumber from 'bignumber.js';
import { ethers, parseUnits } from 'ethers';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Form, FormInstance, Spin } from 'antd';

import { DECIMAL_SCALE } from '@src/constants';
import { closeDrawer } from '@state/drawer';

import { ReactComponent as CloseIcon } from '@ui/icons/close.svg';

const PayOut: FC = () => {
  const drawer = (useAppSelector((state) => state.drawer)) as unknown as IDrawer;
  const { loadingCheckBalance, params: { wallet, account, callBack } } = drawer;
  const [amount, setAmount] = useState<string>();
  const [currentWallet, setCurrentWallet] = useState<IWalletInfo>(wallet);
  const [walletAmount, setWalletAmount] = useState<number>(Number(wallet?.balance) || 0);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [checkingAddress, setCheckingAddress] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [etherConnect, setEtherConnect] = useState<getEthereumProps>();
  const [textMessage, setTextMessage] = useState(<>К сожалению, мы не смогли сделать заявку на вывод</>);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setFormType] = useState<string>();

  const userInfo = useAppSelector((state) => state.user.info);
  const dispatch = useAppDispatch();

  const formRef = useMemo(() => React.createRef<FormInstance>(), []);
  const walletIsEthereumNetwork = useMemo(() => isEthereumNetwork(wallet), [wallet]);

  useEffect(() => {
    (async () => {
      walletIsEthereumNetwork && setEtherConnect(await getEthereum(wallet));
    })();
    setCurrentWallet(wallet);
    setWalletAmount(Number(wallet?.balance) || 0);
  }, [wallet, walletIsEthereumNetwork]);

  useEffect(() => {
    setWalletAmount(Number(currentWallet?.balance) || 0);
  }, [currentWallet?.balance]);

  useEffect(() => {
    if (walletIsEthereumNetwork) {
      (async() => {
        setLoading(true);

        const getUserBalanceInfo = etherConnect?.userAccount && etherConnect?.userContract
          ? await etherConnect?.userContract.getFunction('getUserBalance')
          : undefined;
        const userBalanceInfo = getUserBalanceInfo && userInfo?.id
          ? await getUserBalanceInfo(userInfo?.id.toString())
          : undefined;
        const etherAmount = (
          new BigNumber(((userBalanceInfo?.[etherConnect?.currency_code || '']) || 0).toString())
            .div(
              new BigNumber(parseUnits("1", etherConnect?.digits).toString())
            )
        ).toNumber();

        if (userBalanceInfo && etherAmount < Number(currentWallet?.balance)) {
          setWalletAmount(etherAmount || 0);
        } else {
          setWalletAmount(Number(currentWallet?.balance) || 0);
        }

        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  }, [currentWallet?.balance, etherConnect?.currency_code, etherConnect?.digits, etherConnect?.userAccount, etherConnect?.userContract, userInfo?.id, walletIsEthereumNetwork]);

  const getLastAddress = useCallback( async () => {
    if (
      userInfo?.id !== undefined
      && wallet?.id !== undefined
      && userInfo?.id !== null
      && wallet?.id !== null
    ) {
      const {status, data} = await api.getLastWithdrawalAddress(wallet?.id);

      if (status === 200 && data?.status === 0 && data?.address?.length > 3) {
        formRef.current?.setFieldValue('address', data?.address);
      }
    }
  }, [formRef, userInfo?.id, wallet?.id])


  useEffect(() => {
    if (walletIsEthereumNetwork && account) {
      formRef.current?.setFieldValue('address', account);
    } else {
      if (userInfo?.id && userInfo.id in [-1, 0, 1, 3]) {
        getLastAddress();
      }
    }
  }, [account, formRef, getLastAddress, userInfo?.id, walletIsEthereumNetwork]);

  const [fee, maxAmount, minAmount] = useMemo(() => {
    const _min = Number(wallet?.currency_min_pay_out);
    const _fee = Number(wallet.currency_commission_pay_out);
    return [
      _fee,
      Number(Number(walletAmount).toFixed(DECIMAL_SCALE)) - _fee,
      _min > _fee ? _min - _fee : 0.1,
    ];
  }, [wallet.currency_commission_pay_out, wallet?.currency_min_pay_out, walletAmount]);

  const updateWalletInfo = useCallback(
    (wallet: IWalletInfo) => {
      if (wallet?.id) {
        dispatch(updateWalletById(wallet));
        setCurrentWallet(wallet);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    formRef.current?.setFieldValue('amount', amount);
  }, [amount, formRef])

  const setMax = useCallback((round: number) => {
    const newSum = Number(Math.floor((walletAmount - fee) * Math.pow(10, round)) / Math.pow(10, round)).toString();
    setAmount(newSum.indexOf('.') > 0 ? newSum : newSum.concat('.0'));
  }, [fee, walletAmount]);

  const handleSubmit = () => {
    formRef?.current?.validateFields().then(async (values) => {
      setLoading(true);

      const { status, data } = await api.createWithdrawal({
        ...values,
        amount: values.amount.replace(/[^\d.]/, ''),
        wallet_id: wallet?.id,
        currency_id: wallet?.currency_id,
      });

      if (status === 200) {
        if (data?.status === 0) {
          if (walletIsEthereumNetwork) {
            // setTextMessage(<span className="text-xl">{getMessageFromRequest(data)}</span>);
            const transferTo = etherConnect?.userAccount && etherConnect?.userContract
              ? await etherConnect?.userContract.getFunction(`transfer${etherConnect?.currency_code}to`)
              : undefined;

            let transferToResult;

            try {
              const sendAmount = amount && Number(amount)
                ? ethers.parseUnits(amount.toString(), etherConnect?.digits)
                : undefined;
              transferToResult = transferTo && userInfo?.id && sendAmount
                ? await transferTo(userInfo?.id.toString(), etherConnect?.userAddress, sendAmount)
                : undefined;

              if (transferToResult?.hash) {
                setIsSuccess(true);
                setIsError(false);
                setTextMessage(<>
                  Транзакция вывода успешна сформирована<br />
                  Hash транзакции: {transferToResult.hash}
                </>);

                const { data: data2 } = await api.verifyWithdrawal(data?.token, 2, transferToResult.hash);

                if (data2?.status === 0 && data2?.wallet?.id) {
                  updateWalletInfo(data2?.wallet);
                }

              } else {
                const { data: data3 } = await api.verifyWithdrawal(data?.token, 3);

                if (data3?.status === 0 && data3?.wallet?.id) {
                  updateWalletInfo(data3?.wallet);
                }

                setIsSuccess(false);
                setIsError(true);
                setTextMessage(<>Что-то пошло не так при создании транзакции вывода</>);
              }
            } catch (err: any) {
              const { data: data3 } = await api.verifyWithdrawal(data?.token, 3);

              if (data3?.status === 0 && data3?.wallet?.id) {
                updateWalletInfo(data3?.wallet);
              }

              if (err.code === 'ACTION_REJECTED') {
                setTextMessage(<>Вы отменили заявку на вывод</>);
                setIsSuccess(true);
                setIsError(false);
              } else {
                setIsSuccess(false);
                setIsError(true);
                setTextMessage(<>Что-то пошло не так при создании транзакции вывода</>);
              }
            }
          } else {

            updateWalletInfo(data?.wallet);

            setIsSuccess(true);
            setIsError(false);
            setTextMessage(<span className="text-xl">Вам на почту отправлено письмо с подтверждением заявки на вывод</span>);
          }
        } else {
          setTextMessage(getMessageFromRequest(data));
          setIsSuccess(false);
          setIsError(true);
        }

        setLoading(false);

        callBack && callBack();

      } else {
        setIsSuccess(false);
        setIsError(true);
        setLoading(false);
      }
    });
  }

  if (!userInfo || userInfo.id === null || userInfo.id === undefined) {
    return null;
  }

  return walletIsEthereumNetwork
    ? (
      <div id="modal8" className="modal1 modal deposit-modal  withdraw-modal" key={wallet.address}>
        <Spin spinning={loading || loadingCheckBalance}>
          <div className="modal__block-close">
            <CloseIcon onClick={() => dispatch(closeDrawer())} />
          </div>
          <div className="modal__window">
            <div className="modal__head">
              <h1>Вывод средств</h1>
            </div>
            <div className="withdraw">
              <div className="withdraw-head">
                {/*<div className="withdraw-ttl">Ваша монета</div>*/}
                <div className="deposit-left">
                  <h5>Баланс кошелька</h5>
                  <div className="deposit-currency balance">
                    <span>
                      <Amount wallet={currentWallet} value={currentWallet.balance || 0} digits={DECIMAL_SCALE}/>
                    </span>
                  </div>
                </div>
                <div className="deposit-line"></div>
                {wallet?.currency_network_id && (
                  <div className="deposit-left">
                    <h5>Сеть</h5>
                    <div className="deposit-currency">
                      <span>{wallet?.currency_network_name}</span>
                      <span className='network_name'>
                          ({wallet?.currency_network_name_code})
                        </span>
                    </div>
                  </div>
                )}
              </div>
              <FormWithMessage
                setFormType={setFormType}
                isError={isError}
                isSuccess={isSuccess}
                textMessage={textMessage}
                showGoHomeButton={false}
                additionalButton={
                  <button className='standart-btn' onClick={() => dispatch(closeDrawer())}>
                    <div>Ок</div>
                  </button>
                }
              >
                <Form
                  name='NewPayOut'
                  ref={formRef}
                  layout='vertical'
                  // initialValues={{ remember: true }}
                  onFinish={handleSubmit}
                  // className={styles.content}
                  className="!pb-0"
                >
                  <div className="withdraw-form">
                    <div className='relative'>
                      <Form.Item
                        name='address'
                        label={`Отправить ${wallet.currency_code} на адрес:`}
                        className="form__block address"
                        // initialValue={account}
                      >
                        <input type="text" disabled className="!cursor-not-allowed"/>
                      </Form.Item>
                    </div>
                    <div className='relative mt-5'>
                      <div className="ant-form-item form__block address">
                        <div className="ant-col ant-form-item-label">
                          <label className="ant-form-item">
                            Комиссия:
                          </label>
                        </div>
                        <div className="ant-col ant-form-item-control ml-5">
                          <div className="ant-form-item-control-input">
                            <div className="ant-form-item-control-input-content !text-sm !font-semibold italic text-[#DB50DB]">
                              <div>
                                Комиссию оплачиваете вы самостоятельно с указанного выше кошелька
                                при получении указанной ниже суммы.
                              </div>
                              {/* <div className="mt-2">
                                При нажатии на кнопку "Вывести" откроется окно с вашим МетаМаск-кошельком,
                                в котором вам необходимо подтвердить свое согласие со списанием комиссии
                                за транзакцию вывода монет в валюте "{currentWallet?.currency_code}"
                              </div> */}
                              <div className="mt-2">
                                Убедитесь, пожалуйста, что вам хватит средств для оплаты комиссии
                                в основной монете сети {wallet?.currency_network_name}.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='relative mt-5'>
                      <div className="ant-form-item form__block address">
                        <div className="ant-col ant-form-item-label">
                          <label className="ant-form-item">
                            Разрешено к выводу:
                          </label>
                        </div>
                        <div className="ant-col ant-form-item-control ml-5">
                          <div className="ant-form-item-control-input">
                            <div className="ant-form-item-control-input-content !text-sm !font-semibold">
                              <Amount wallet={wallet} value={walletAmount} digits={DECIMAL_SCALE} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="withdraw-middle-text">
                    <div className="withdraw-item !w-full">
                      <Form.Item
                        name='amount'
                        label='Сумма перевода:'
                        validateTrigger={['onBlur', 'onChange']}
                        validateFirst={true}
                        rules={[
                         {validateTrigger: 'onBlur', required: true, message: 'Пожалуйста, введите сумму перевода'},
                         {
                           pattern: new RegExp(`^[\\d ]*.?\\d{0,${DECIMAL_SCALE}}$`),
                           message: `Можно указать только ограниченное число знаков после точки - максимум ${DECIMAL_SCALE}`,
                         },
                         {
                           message: 'Указанная вами сумма превышает сумму на кошельке',
                           validator: async (_, value) => {
                             if (Number(value.replace(/[^\d.]/, '')) <= Number(currentWallet?.balance)) {
                               return Promise.resolve();
                             }
                             return Promise.reject();
                           },
                         },
                         {
                           message: 'Указанная вами сумма превышает максимально разрешенную сумму к выводу',
                           validator: async (_, value) => {
                             if (Number(value.replace(/[^\d.]/, '')) <= maxAmount) {
                               return Promise.resolve();
                             }
                             return Promise.reject();
                           },
                         },
                         {
                           message: (
                             <>
                               Указанная вами сумма ниже минимально разрешенной сумму
                               для данной валюты (<Amount wallet={wallet} value={minAmount} withSpace={false}/>)
                             </>
                           ),
                           validator: async (_, value) => {
                             if (Number(value.replace(/[^\d.]/, '')) >= minAmount) {
                               return Promise.resolve();
                             }
                             return Promise.reject();
                           },
                         },
                        ]}
                      >
                        <b className='form__block amount !mr-6 '>
                          <NumericFormat
                            value={Number(amount)}
                            decimalScale={DECIMAL_SCALE}
                            thousandSeparator=" "
                            onKeyDown={(event) => {
                              if (event.key.match(/[0-9.]/)) {
                                const newValue = (amount || '').concat(event.key);
                                const balanceDigits = (wallet?.balance || 0).toString().match(/(\d+)\.?(\d+)/);
                                const digits = newValue.match(/(\d+)\.?(\d+)/);
                                if (
                                  (digits && digits[2] && digits[2].length > DECIMAL_SCALE)
                                  || (
                                    balanceDigits && balanceDigits[1]
                                    && digits && digits[1]
                                    && digits[1].length > balanceDigits[1].length
                                  )
                                ) {
                                  event.preventDefault();
                                  event.stopPropagation();
                                }
                              }
                            }}
                            onValueChange={(values, info) => {
                              const digits = values.value.match(/(\d+)\.?(\d+)/)
                              if (!digits || !digits[2] || digits[2].length <= DECIMAL_SCALE) {
                                setAmount(values.value || '0');
                              }
                            }}
                          />
                        </b>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="withdraw-itog !pb-0">
                    <div className="w-full text-center">
                      <button className="standart-btn mb-0">
                        <div>Вывести</div>
                      </button>
                    </div>
                  </div>
                </Form>
              </FormWithMessage>
            </div>
          </div>
        </Spin>
      </div>
    ) : (
      <div id="modal8" className="modal1 modal deposit-modal  withdraw-modal" key={wallet.address}>
        <Spin spinning={loading || loadingCheckBalance}>
          <div className="modal__block-close">
            <CloseIcon onClick={() => dispatch(closeDrawer())} />
          </div>
          <div className="modal__window">
            <div className="modal__head">
              <h1>Вывод средств</h1>
            </div>
            <div className="withdraw">
              <div className="withdraw-head">
                {/*<div className="withdraw-ttl">Ваша монета</div>*/}
                <div className="deposit-left">
                  <h5>Баланс кошелька</h5>
                  <div className="deposit-currency balance">
                    <span>
                      <Amount wallet={currentWallet} value={currentWallet.balance || 0} digits={DECIMAL_SCALE}/>
                    </span>
                  </div>
                </div>
                <div className="deposit-line"></div>
                {wallet?.currency_network_id && (
                  <div className="deposit-left">
                    <h5>Сеть</h5>
                    <div className="deposit-currency">
                      <span>{wallet?.currency_network_name}</span>
                      <span className='network_name'>
                          ({wallet?.currency_network_name_code})
                        </span>
                    </div>
                  </div>
                )}
              </div>
              <FormWithMessage
                setFormType={setFormType}
                isError={isError}
                isSuccess={isSuccess}
                textMessage={textMessage}
                showGoHomeButton={false}
                additionalButton={
                  <button className='standart-btn' onClick={() => dispatch(closeDrawer())}>
                    <div>Ок</div>
                  </button>
                }
              >
                <Form
                  name='NewPayOut'
                  ref={formRef}
                  layout='vertical'
                  // initialValues={{ remember: true }}
                  onFinish={handleSubmit}
                  // className={styles.content}
                >
                  <div className="withdraw-form">
                    {/*<div className="withdraw-ttl">Отправить {userInfo.currency_code} на адрес:</div>*/}
                    {/*<div className="form__block">*/}
                    {/*  <span>Сеть</span>*/}
                    {/*  <span>TRON (TRC20)</span>*/}
                    {/*</div>*/}
                    <div className='relative'>
                      <Form.Item
                        name='address'
                        label={`Отправить ${wallet.currency_code} на адрес:`}
                        className="form__block address"
                        validateTrigger={['onBlur', 'onChange']}
                        validateFirst={true}
                        rules={[
                          {validateTrigger: 'onBlur', required: true, message: 'Пожалуйста, введите адрес кошелька, куда необходимо перечислить средства'},
                          {validateTrigger: 'onBlur', min: 34, message: 'Минимальная длина поля - 32 символов'},
                          {max: 34, message: 'Максимальная длина поля - 32 символов'},
                          {pattern: /^[a-zA-Z0-9]+$/, message: 'В поле можно вводить только латинские буквы и цифры'},
                          {
                            validateTrigger: 'onBlur',
                            message: 'К сожалению, введенный вами адрес кошелка оказался некорректным',
                            validator: async (_, value) => {
                              setCheckingAddress(true);
                              // const { status, data } = api.checkAddress(value);
                              const result = await api.checkAddress(wallet.id, value);
                              setCheckingAddress(false);

                              const { status, data } = result;

                              if (status === 200 && data?.status === 0) {
                                return Promise.resolve();
                              }
                              return Promise.reject();
                            },
                          },
                        ]}
                      >
                        <input type="text" />
                      </Form.Item>
                      <div className='spinner address'>
                        <Spin spinning={checkingAddress} size='small' />
                      </div>
                    </div>
                    {/*<div className="form__block">
                      <span>Сумма</span>
                      <NumericFormat
                        value={Number(amount)}
                        // displayType={'text'}
                        decimalScale={DECIMAL_SCALE}
                        thousandSeparator=" "
                      />
                    </div>*/}
                  </div>

                  <div className="withdraw-middle">
                    <div className="withdraw-item item-amount relative">
                      {/*<div className="withdraw-ttl">Сумма перевода:</div>*/}
                      <Form.Item
                        style={{marginBottom: 0}}
                        name='amount'
                        label='Сумма перевода:'
                        validateTrigger={['onBlur', 'onChange']}
                        validateFirst={true}
                        rules={[
                         {validateTrigger: 'onBlur', required: true, message: 'Пожалуйста, введите сумму перевода'},
                         // {validateTrigger: 'onBlur', type: 'number', message: 'Разрешены только цифры и точка'},
                         // {max: Number(wallet?.balance) - Number(COMMISSION_FOR_TRANSACTION), message: 'Максимальная длина поля - 32 символов'},
                         {
                           // transform: (x) => x.replace(/\d+\.?/, ''),
                           pattern: new RegExp(`^[\\d ]*.?\\d{0,${DECIMAL_SCALE}}$`),
                           message: `Можно указать только ограниченное число знаков после точки - максимум ${DECIMAL_SCALE}`,
                         },
                         {
                           // validateTrigger: 'onBlur',
                           message: 'Указанная вами сумма, с учетом комиссии, превышает сумму кошелька',
                           validator: async (_, value) => {
                             if (Number(value.replace(/[^\d.]/, '')) <= maxAmount) {
                               return Promise.resolve();
                             }
                             return Promise.reject();
                           },
                         },
                         {
                           // validateTrigger: 'onBlur',
                           message: (
                             <>
                               Указанная вами сумма, с учетом комиссии, ниже минимально разрешенной сумму
                               для данной валюты (<Amount wallet={wallet} value={minAmount} withSpace={false}/>)
                             </>
                           ),
                           validator: async (_, value) => {
                             if (Number(value.replace(/[^\d.]/, '')) >= minAmount) {
                               return Promise.resolve();
                             }
                             return Promise.reject();
                           },
                         },
                        ]}
                      >
                        <b className='form__block amount !mr-6'>
                          <NumericFormat
                            value={Number(amount)}
                            // displayType={'text'}
                            decimalScale={DECIMAL_SCALE}
                            thousandSeparator=" "
                            onKeyDown={(event) => {
                              if (event.key.match(/[0-9.]/)) {
                                const newValue = (amount || '').concat(event.key);
                                const balanceDigits = (wallet?.balance || 0).toString().match(/(\d+)\.?(\d+)/);
                                const digits = newValue.match(/(\d+)\.?(\d+)/);
                                if (
                                  (digits && digits[2] && digits[2].length > DECIMAL_SCALE)
                                  || (
                                    balanceDigits && balanceDigits[1]
                                    && digits && digits[1]
                                    && digits[1].length > balanceDigits[1].length
                                  )
                                  // || (Number(newValue) > Number(wallet?.balance))
                                ) {
                                  event.preventDefault();
                                  event.stopPropagation();
                                }
                              }
                            }}
                            onValueChange={(values, info) => {
                              const digits = values.value.match(/(\d+)\.?(\d+)/)
                              if (!digits || !digits[2] || digits[2].length <= DECIMAL_SCALE) {
                                setAmount(values.value || '0');
                              }
                            }}
                          />
                        </b>
                      </Form.Item>
                      <div className='flex justify-end absolute top-10 right-9'>
                        <Button
                          className="!p-0 !m-0 !border-0"
                          shape='round'
                          onClick={() => (setMax(DECIMAL_SCALE))}
                          title="Указать максимально возможную сумму"
                        >
                          max
                        </Button>
                      </div>
                    </div>
                    <div className="withdraw-item item-commission">
                      <div className="withdraw-ttl">Комиссия:</div>
                      <b className="disabled commission">
                        <Amount wallet={wallet} value={fee} withCurrencyCode={false} />
                      </b>
                    </div>
                    <div className="withdraw-item item-all-amount">
                      <div className="withdraw-ttl">Итоговая стоимость:</div>
                      <b className="commission">
                        <Amount
                          wallet={wallet}
                          value={(Number(amount) || 0) + fee}
                          digits={DECIMAL_SCALE}
                          withCurrencyCode={false}
                        />
                      </b>
                    </div>
                  </div>

                  <div className="withdraw-itog ">

                    {/*<div className="withdraw-itog-text">*/}
                    {/*  &nbsp;*/}
                      {/*<div className="withdraw-ttl">Итоговая стоимость:</div>
                      <b>
                        <NumericFormat
                          value={Number(amount) + COMMISSION_FOR_TRANSACTION}
                          displayType={'text'}
                          decimalScale={DECIMAL_SCALE}
                          thousandSeparator=" "
                        />
                      </b>
                      <span>{userInfo.currency_code}</span>*/}
                    {/*</div>*/}
                    <div className="w-full text-center">
                      <button className="standart-btn mb-0">
                        <div>Вывести</div>
                      </button>
                    </div>
                  </div>
                </Form>
              </FormWithMessage>
            </div>
          </div>
        </Spin>
      </div>
    );
}

export default PayOut;
