import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import {
  ROUTE_REPORT_USERS_LIST,
  ROUTE_REPORT_VOLUME_FIRST_LINE,
  ROUTE_REPORT_VOLUME_USER_LIST,
} from '@src/constants';
import getAccessToMenuItem from '@src/helpers/getAccessToMenuItem';
import getRouteText from '@src/helpers/getRouteText';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
class ReportPage extends Component {
  render() {
    const { t, userInfo } = this.props;

    const reportList = [
      ROUTE_REPORT_USERS_LIST,
      ROUTE_REPORT_VOLUME_FIRST_LINE,
      ROUTE_REPORT_VOLUME_USER_LIST,
    ]

    const userReportList = reportList
      .filter((x) => getAccessToMenuItem(x, userInfo))
      .map((x) => <li><Link to={x}>{t('LEFT_MENU_'.concat(getRouteText(x)))}</Link></li>);

    return (
      <main className="promo-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName={t('LEFT_MENU_REPORT')} withPaySubscription={false}/>
          <div className="info__card">
            <h2 className="pb-1 text-lg">Список подразделов:</h2>
            <ul className="list-disc pl-6">
              { userReportList }
            </ul>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    ReportPage
  )
);
