import {
  PartitionOutlined,
  OrderedListOutlined,
  FileTextOutlined,
  FileWordOutlined,
  MoneyCollectOutlined,
  GiftOutlined,
  FontColorsOutlined,
  WechatOutlined,
  ReadOutlined,
  TeamOutlined,
  UserOutlined,
  // LoginOutlined,
  // ClockCircleOutlined,
} from '@ant-design/icons';
import allowUserListAndAmbassadorsFrom4Level from '@src/helpers/allowUserListAndAmbassadorsFrom4Level';
import { ReactComponent as EducationIcon } from '@ui/icons/education.svg';
import { ReactComponent as MainIcon } from '@ui/icons/main.svg';
import { ReactComponent as PartnersProgramIcon } from '@ui/icons/partnersProgram.svg';
import { ReactComponent as ProductsIcon } from '@ui/icons/products.svg';
// import { ReactComponent as ReferralsLinkIcon } from '@ui/icons/referralsLink.svg';
// import { ReactComponent as StatisticsIcon } from '@ui/icons/statistics.svg';
import { ReactComponent as TravelsIcon } from '@ui/icons/travels.svg';
// import { ReactComponent as WithdrawalIcon } from '@ui/icons/withdrawal.svg';
import { ReactComponent as WalletIcon } from '@ui/icons/walletGray.svg';
import {
  ROLE_ADMIN,
  ROLE_BEGINNER,
  ROLE_STUDENT_ACADEMY,
  ROLE_STUDENT_BASE,
  // ROUTE_CONSULTANT,
  ROUTE_EDUCATION,
  ROUTE_PRODUCTS,
  ROUTE_ROOT,
  // ROUTE_STRUCTURE,
  // ROUTE_STATISTICS,
  // ROUTE_TRAVELS,
  ROUTE_WALLET,
  // ROUTE_TRANSACTIONS,
  // ROLE_STUDENT_BASE_PLUS,
  // ROLE_STUDENT_ACADEMY_PLUS,
  // ROLE_BUSINESS,
  // ROLE_TEST_DRIVER,
  ROLE_AMBASSADOR,
  ROLE_LIST_FOR_BUSINESS,
  // ROUTE_REFERRALS_LINKS,
  // ROUTE_BONUS_PROGRAMS,
  ROUTE_UNIVERSITY,
  ROUTE_UNIVERSITY_MASTER,
  ROUTE_UNIVERSITY_LEADER,
  ROUTE_UNIVERSITY_CONSULTANT,
  ROUTE_PROMO,
  ROUTE_PROMO_TRAVEL,
  ROUTE_PROMO_TRAVEL_1,
  ROUTE_PROMO_TRAVEL_2,
  // ROUTE_PROMO_JUNIOR,
  // ROUTE_PROMO_JUNIOR_1,
  // ROUTE_PROMO_JUNIOR_2,
  // ROUTE_PROMO_WATCH,
  ROUTE_AMBASSADORS_CLUB,
  ROUTE_AMBASSADORS_CLUB_MY_NFT,
  ROUTE_AMBASSADORS_CLUB_PROGRAMS,
  ROUTE_AMBASSADORS_CLUB_STRUCTURE,
  ROUTE_REPORT,
  ROUTE_REPORT_USERS_LIST,
  ROUTE_REPORT_VOLUME_FIRST_LINE,
  ROUTE_REPORT_VOLUME_USER_LIST,
  ROUTE_REPORT_USERS_SALES,
  ROUTE_REPORT_LEVEL_RAISE,
  ROUTE_TELEGRAM_SME_ADMIN_BOT,
  // ROUTE_ANNIVERSARY,
  ROUTE_REPORT_NEXT_FLOW,
  ROUTE_STRUCTURE,
  // ROUTE_WITHDRAWAL,
} from '@src/constants';

const menuItemsText = [
  {
    route: ROUTE_ROOT,
    exclude_roles: [],
    iconType: MainIcon,
  },
  // {
  //   route: ROUTE_ANNIVERSARY,
  //   exclude_roles: [],
  //   iconType: TravelsIcon,
  // },
  {
    route: ROUTE_EDUCATION,
    exclude_roles: [ROLE_BEGINNER],
    iconType: EducationIcon,
  },
  {
    route: ROUTE_PRODUCTS,
    exclude_roles: [],
    iconType: ProductsIcon,
  },
  {
    route: ROUTE_TELEGRAM_SME_ADMIN_BOT,
    exclude_roles: [ROLE_BEGINNER],
    iconType: WechatOutlined,
  },
  {
    route: ROUTE_AMBASSADORS_CLUB,
    roles: ROLE_LIST_FOR_BUSINESS,
    iconType: FontColorsOutlined,
    children: [
      {
        route: ROUTE_AMBASSADORS_CLUB_MY_NFT,
        roles: [ROLE_AMBASSADOR, ROLE_ADMIN],
        iconType: UserOutlined,
      },
      {
        route: ROUTE_AMBASSADORS_CLUB_PROGRAMS,
        roles: ROLE_LIST_FOR_BUSINESS,
        iconType: PartnersProgramIcon,
      },
      {
        route: ROUTE_AMBASSADORS_CLUB_STRUCTURE,
        roles: ROLE_LIST_FOR_BUSINESS,
        iconType: PartitionOutlined,
      },
    ],
  },
  {
    route: ROUTE_STRUCTURE,
    exclude_roles: ROLE_LIST_FOR_BUSINESS,
    iconType: PartitionOutlined,
  },
  // {
  //   route: ROUTE_CONSULTANT,
  //   roles: [ROLE_LIGHT, ROLE_PARTNER, ROLE_BUSINESS, ROLE_TEST_DRIVER, ROLE_AMBASSADOR, ROLE_ADMIN],
  //   iconType: PartnersProgramIcon,
  // },
  // ro ute: `(${ROUTE_ROOT}|${ROUTE_SEARCH})`,
  // {
  //   route: ROUTE_BONUS_PROGRAMS,
  //   roles: [ROLE_STUDENT_BASE_PLUS, ROLE_STUDENT_ACADEMY_PLUS],
  //   iconType: ReferralsLinkIcon,
  // },
  {
    route: ROUTE_WALLET,
    exclude_roles: [ROLE_STUDENT_BASE, ROLE_STUDENT_ACADEMY],
    iconType: WalletIcon,
  },
  // {
  //   route: ROUTE_STATISTICS,
  //   roles: [ROLE_LIGHT, ROLE_PARTNER, ROLE_ADMIN],
  //   iconType: StatisticsIcon,
  // },
  // {
  //   route: ROUTE_TRANSACTIONS,
  //   roles: [ROLE_LIGHT, ROLE_PARTNER, ROLE_BUSINESS, ROLE_TEST_DRIVER, ROLE_AMBASSADOR, ROLE_ADMIN],
  //   iconType: WalletIcon,
  // },
  {
    route: ROUTE_UNIVERSITY,
    roles: ROLE_LIST_FOR_BUSINESS,
    iconType: FileTextOutlined,
    children: [
      {
        route: ROUTE_UNIVERSITY_CONSULTANT,
        roles: ROLE_LIST_FOR_BUSINESS,
        iconType: PartnersProgramIcon,
      },
      {
        route: ROUTE_UNIVERSITY_MASTER,
        roles: ROLE_LIST_FOR_BUSINESS,
        iconType: FileWordOutlined,
        // disabled: true,
      },
      {
        route: ROUTE_UNIVERSITY_LEADER,
        roles: ROLE_LIST_FOR_BUSINESS,
        iconType: MoneyCollectOutlined,
      },
    ]
  },
  {
    route: ROUTE_PROMO,
    exclude_roles: [],
    iconType: GiftOutlined,
    children: [
      {
        route: ROUTE_PROMO_TRAVEL,
        exclude_roles: [],
        iconType: TravelsIcon,
        children: [
          {
            route: ROUTE_PROMO_TRAVEL_1,
            exclude_roles: [],
            iconType: OrderedListOutlined,
          },
          {
            route: ROUTE_PROMO_TRAVEL_2,
            exclude_roles: [],
            iconType: OrderedListOutlined,
          },
        ],
      },
      // {
      //   route: ROUTE_PROMO_JUNIOR,
      //   roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_ACADEMY, ROLE_LIGHT, ROLE_PARTNER, ROLE_STUDENT_BASE_PLUS, ROLE_STUDENT_ACADEMY_PLUS, ROLE_BUSINESS, ROLE_TEST_DRIVER, ROLE_AMBASSADOR, ROLE_ADMIN],
      //   iconType: LoginOutlined,
      //   children: [
      //     {
      //       route: ROUTE_PROMO_JUNIOR_1,
      //       roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_ACADEMY, ROLE_LIGHT, ROLE_PARTNER, ROLE_STUDENT_BASE_PLUS, ROLE_STUDENT_ACADEMY_PLUS, ROLE_BUSINESS, ROLE_TEST_DRIVER, ROLE_AMBASSADOR, ROLE_ADMIN],
      //       iconType: OrderedListOutlined,
      //     },
      //     {
      //       route: ROUTE_PROMO_JUNIOR_2,
      //       roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_ACADEMY, ROLE_LIGHT, ROLE_PARTNER, ROLE_STUDENT_BASE_PLUS, ROLE_STUDENT_ACADEMY_PLUS, ROLE_BUSINESS, ROLE_TEST_DRIVER, ROLE_AMBASSADOR, ROLE_ADMIN],
      //       iconType: OrderedListOutlined,
      //     },
      //   ],
      // },
      // {
      //   route: ROUTE_PROMO_WATCH,
      //   roles: [ROLE_BEGINNER, ROLE_STUDENT_BASE, ROLE_STUDENT_ACADEMY, ROLE_LIGHT, ROLE_PARTNER, ROLE_STUDENT_BASE_PLUS, ROLE_STUDENT_ACADEMY_PLUS, ROLE_BUSINESS, ROLE_TEST_DRIVER, ROLE_AMBASSADOR, ROLE_ADMIN],
      //   iconType: ClockCircleOutlined,
      // },
    ],
  },
  {
    route: ROUTE_REPORT,
    userFunctionAccess: (userInfo) => allowUserListAndAmbassadorsFrom4Level(userInfo, [
      986,  //	aamagdysuk@mail.ru	      Anastasia	Koreneva
      4180, //	hodchenkova97@mail.ru	    Margarita	Idrisova
      4548, //	elmira.salgra@gmail.com	  Elmira    Salgra
      4603, //	Natalya.i9n@yandex.ru	    Natalya	  Khalturina
      6595, //	Alina_gc@mail.ru	        Alina	    Miftakhutdinova
    ]),
    iconType: ReadOutlined,
    children: [
      {
        route: ROUTE_REPORT_USERS_LIST,
        users: [
          2,    //  Компания
          4,    //	eremin170782@gmail.com	  Maksim	  Eremin
          986,  //	aamagdysuk@mail.ru	      Anastasia	Koreneva
          4180, //	hodchenkova97@mail.ru	    Margarita	Idrisova
          4548, //	elmira.salgra@gmail.com	  Elmira    Salgra
          4603, //	Natalya.i9n@yandex.ru	    Natalya	  Khalturina
          6595, //	Alina_gc@mail.ru	        Alina	    Miftakhutdinova
        ],
        iconType: TeamOutlined,
      },
      {
        route: ROUTE_REPORT_VOLUME_FIRST_LINE,
        userFunctionAccess: (userInfo) => allowUserListAndAmbassadorsFrom4Level(userInfo, []),
        iconType: TeamOutlined,
      },
      {
        route: ROUTE_REPORT_VOLUME_USER_LIST,
        userFunctionAccess: (userInfo) => allowUserListAndAmbassadorsFrom4Level(userInfo, []),
        iconType: TeamOutlined,
      },
      {
        route: ROUTE_REPORT_USERS_SALES,
        users: [
          2,    //  Компания
          4,    //	eremin170782@gmail.com	  Maksim	  Eremin
          986,  //	aamagdysuk@mail.ru	      Anastasia	Koreneva
          4180, //	hodchenkova97@mail.ru	    Margarita	Idrisova
          4548, //	elmira.salgra@gmail.com	  Elmira    Salgra
          4603, //	Natalya.i9n@yandex.ru	    Natalya	  Khalturina
          6595, //	Alina_gc@mail.ru	        Alina	    Miftakhutdinova
        ],
        iconType: TeamOutlined,
      },
      {
        route: ROUTE_REPORT_LEVEL_RAISE,
        users: [
          2,    //  Компания
          4,    //	eremin170782@gmail.com	  Maksim	  Eremin
          986,  //	aamagdysuk@mail.ru	      Anastasia	Koreneva
          4180, //	hodchenkova97@mail.ru	    Margarita	Idrisova
          4548, //	elmira.salgra@gmail.com	  Elmira    Salgra
          4603, //	Natalya.i9n@yandex.ru	    Natalya	  Khalturina
          6595, //	Alina_gc@mail.ru	        Alina	    Miftakhutdinova
        ],
        iconType: TeamOutlined,
      },
      {
        route: ROUTE_REPORT_NEXT_FLOW,
        users: [
          2,    //  Компания
          4,    //	eremin170782@gmail.com	  Maksim	  Eremin
          986,  //	aamagdysuk@mail.ru	      Anastasia	Koreneva
          4180, //	hodchenkova97@mail.ru	    Margarita	Idrisova
          4548, //	elmira.salgra@gmail.com	  Elmira    Salgra
          4603, //	Natalya.i9n@yandex.ru	    Natalya	  Khalturina
          6595, //	Alina_gc@mail.ru	        Alina	    Miftakhutdinova
        ],
        iconType: TeamOutlined,
      }
    ],
  },
  // {
  //   route: ROUTE_TRAVELS,
  //   roles: [ROLE_LIGHT, ROLE_PARTNER, ROLE_ADMIN],
  //   iconType: TravelsIcon,
  // },
  // {
  //   route: ROUTE_WITHDRAWAL,
  //   roles: [ROLE_LIGHT, ROLE_PARTNER, ROLE_ADMIN],
  //   iconType: WithdrawalIcon,
  // },
];

export default menuItemsText;
