// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-routes-initial-styles-module-less__content--V79zf {\n  background-color: white;\n  height: 100vh;\n  width: 100%;\n  padding-top: 50vh !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/routes/initial/styles.module.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;EACA,WAAA;EACA,4BAAA;AACF","sourcesContent":[".content {\n  background-color: white;\n  height: 100vh;\n  width: 100%;\n  padding-top: 50vh !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "src-components-routes-initial-styles-module-less__content--V79zf"
};
export default ___CSS_LOADER_EXPORT___;
