import { createSlice } from '@reduxjs/toolkit';
import { CHECK_BALANCE_MINUTES } from '@src/constants';

const burgerSlice = createSlice({
  name: 'drawer',
  initialState: {
    showDrawer: false,
    drawerContent: null,
    checkBalance: false,
    checkBalanceResult: false,
    loadingCheckBalance: false,
    deadline: null,
    timerId: null,
    timerId2: null,
    params: null,
  },
  reducers: {
    closeDrawer: (state) => {
      state.showDrawer = false;
      state.drawerContent = null;
      state.params = null;
    },
    setDrawerContent: (state, action) => {
      state.drawerContent = action.payload.content;
      state.params = action.payload.params;
      state.showDrawer = true;
    },
    setLoadingCheckBalance: (state, action) => {
      state.loadingCheckBalance = action.payload;
    },
    setStartCheckBalance: (state) => {
      state.loadingCheckBalance = true;
    },
    setCheckBalanceResult: (state, action) => {
      const { timerId, timerId2 } = state;

      state.loadingCheckBalance = false;
      state.checkBalanceResult = action.payload;

      if (action.payload !== false) {
        clearInterval(timerId);
        clearTimeout(timerId2);

        state.deadline = null;
        state.timerId = null;
        state.timerId2 = null;
      }
    },
    setStartTimers: (state, action) => {
      state.timerId = action.payload[0];
      state.timerId2 = action.payload[1];
      state.checkBalance = true;
      state.deadline = Date.now() + CHECK_BALANCE_MINUTES * 60 * 1000;
    },
    setFinishCheckBalance: (state) => {
      const { timerId, timerId2 } = state;

      clearInterval(timerId);
      clearTimeout(timerId2);

      state.showDrawer = false;
      state.params = null;
      state.drawerContent = false;
      state.checkBalance = false;
      state.checkBalanceResult = false;
      state.loadingCheckBalance = false;
      state.deadline = null;
      state.timerId = null;
      state.timerId2 = null;
    },
  }
})

export const {
  closeDrawer,
  setDrawerContent,
  setLoadingCheckBalance,
  setStartCheckBalance,
  setCheckBalanceResult,
  setStartTimers,
  setFinishCheckBalance,
} = burgerSlice.actions;
export default burgerSlice.reducer;
