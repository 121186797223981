import Level from '@common/level';
import { SoulEye } from '@common/soulEye';
import { SoulHideBlock } from '@common/soulHideBlock';
import { disableAccessToIndicatorsPage } from '@src/helpers/checkAccessToElementPage';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Progress } from 'antd';
import { NumericFormat } from 'react-number-format';

import InfoItem from '@common/infoItem';
import { DECIMAL_SCALE } from '@src/constants';

import './styles.less';

class Indicators extends Component {
  state = {
    eyeHiddenInfo: false
  }

  eyeChange = () => this.setState((prevState) => ({ eyeHiddenInfo: !prevState.eyeHiddenInfo }))

  render() {
    const { userInfo } = this.props;
    const { eyeHiddenInfo } = this.state;

    if (disableAccessToIndicatorsPage(userInfo)) {
      return null;
    }

    const structureCounts = {}
    if (userInfo.structure_counts) {
      for (let item of (userInfo.structure_counts)) {
        const key = `${item.is_net ? 'net' : 'my'}_${item.is_partner ? 'partners' : 'clients'}`;
        structureCounts[key] = item.cnt;
      }
    }

    return (
      <>
        <div className="indicators">
          <SoulEye eyeHiddenInfo={eyeHiddenInfo} eyeChange={this.eyeChange} title='Показать/спрятать показатели в блоке' />
          <div className="indicators-ttl">
            Текущие показатели
          </div>
          <div className="indicators-level">
            <div className='progress-parent'>
              <div className='mirrorX'>
                <Progress
                  type="circle"
                  strokeColor={{
                    "0%": "#8A25FC",
                    "100%": "#ED4AB8",
                  }}
                  percent={
                    userInfo.next_level
                      ? ((Number(userInfo.next_level.av)) - (1 * userInfo.av)) / Number(userInfo.next_level.av) * 100
                      : 100
                  }
                  showInfo={false}
                  strokeLinecap="butt"
                  strokeWidth={7}
                />
              </div>
              <div className="indicators-level-text">
                <Level user={userInfo} strong={true} hidden={eyeHiddenInfo} eyeChange={this.eyeChange} />
              </div>
            </div>
          </div>
          <div className="indicators-footer">
            <div className="indicators-footer-item">
              <p>
                <strong>Личный</strong>
                объем продаж
              </p>
              <div className="relative">
                <strong>
                  <NumericFormat
                    value={userInfo.pv}
                    displayType={'text'}
                    decimalScale={DECIMAL_SCALE}
                    thousandSeparator=" "
                  />
                  &nbsp;
                  баллов
                </strong>
                <SoulHideBlock hidden={eyeHiddenInfo} eyeChange={this.eyeChange} />
              </div>
            </div>
            <div className="indicators-footer-item">
              <p>
                <strong>Групповой</strong>
                объем продаж
              </p>
              <div className="relative">
                <strong>
                  <NumericFormat
                    value={userInfo.nv}
                    displayType={'text'}
                    decimalScale={DECIMAL_SCALE}
                    thousandSeparator=" "
                  />
                  &nbsp;
                  баллов
                </strong>
                <SoulHideBlock hidden={eyeHiddenInfo} eyeChange={this.eyeChange} />
              </div>
            </div>
          </div>
        </div>
        <div className="info">
          <InfoItem
            hidden={eyeHiddenInfo}
            eyeChange={this.eyeChange}
            active={structureCounts.my_clients > 0}
            value={structureCounts.my_clients || 0}
            title={["Клиенты", "личные"]}
          />
          <InfoItem
            hidden={eyeHiddenInfo}
            eyeChange={this.eyeChange}
            active={structureCounts.net_clients > 0}
            value={structureCounts.net_clients || 0}
            title={["Клиенты", "структурные"]}
          />
        </div>
        <div className="info">
          <InfoItem
            hidden={eyeHiddenInfo}
            eyeChange={this.eyeChange}
            active={structureCounts.my_partners > 0}
            value={structureCounts.my_partners || 0}
            title={["Партнеры", "личные"]}
          />
          <InfoItem
            hidden={eyeHiddenInfo}
            eyeChange={this.eyeChange}
            active={structureCounts.net_partners > 0}
            value={structureCounts.net_partners || 0}
            title={["Партнеры", "структурные"]}
          />
        </div>
        {/*<div className="info">
          <InfoItem active={userInfo.structure_count > 0} value={userInfo.structure_count} title={["Участника", "в структуре",]}/>
          <InfoItem active={userInfo.isSubscribe} value={userInfo.isSubscribe ? "Да" : "Нет"} title={["Право на", "вознаграждение",]}/>
        </div>*/}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  Indicators
);
