import CatalogHeader from '@common/catalogHeader';
import MediaPlayer from '@common/mediaPlayer';
import MobileHeader from '@common/mobileHeader';
import api from '@services/api';
import showTimePeriod from '@src/helpers/showTimePeriod';
import showTimeReminder from '@src/helpers/showTimeReminder';
import { Table } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';

import { ReactComponent as PlayIcon } from '@ui/icons/play.svg';

import "./styles.less";

const emptyPlayerState = {
  showPlayer : false,
  url: '',
  title: '',
  isAudio: false,
  playing: true,
  hls: false,
}

class Promo20231001Page extends Component {
  state = {
    showTable: true,
    // showTable: false,
    transactions: [],
    loading: false,
    beginDate: undefined,
    endDate: undefined,
    timeZone: undefined,
    deltaDays: undefined,
    deltaHours: undefined,
    deltaMinutes: undefined,
    timer: undefined,
    ...emptyPlayerState,
  }

  componentDidMount() {
    const { showTable } = this.state;

    if (showTable) {
      this.getTransactionList();

      const timer = setInterval(() => this.getTimer(), 1000 * 60);
      this.setState({ timer });
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  getTransactionList = async () => {
    this.setState({loading: true});

    const { status, data } = await api.getPromo(1);

    if (status === 200 && data.status === 0) {
      this.setState({
        transactions: data?.list?.length
          ? data.list.filter((x) => Number(x.spv) > 0)
          : [],
        beginDate: data.date_begin ? new dayjs(data.date_begin, 'YYYY-MM-DD') : undefined,
        endDate: data.date_end ? new dayjs(data.date_end, 'YYYY-MM-DD') : undefined,
        timeZone: data.time_zone,
      }, () => {
        this.getTimer();
      });
    }

    this.setState({loading: false});
  }

  handleClose = () => {
    this.setState({
      ...emptyPlayerState,
      playing: false,
    });
  }

  getTimer = () => {
    const { loading, endDate } = this.state;

    if (endDate) {
      const today = new dayjs();

      if (today <= endDate) {
        const deltaDays = endDate.diff(today, 'days');
        const deltaHours = endDate.add((-1) * deltaDays, 'days').diff(today, 'hours');
        const deltaMinutes = endDate.add((-1) * deltaDays, 'days').add((-1) * deltaHours, 'hours').diff(today, 'minutes');
        this.setState({
          deltaDays,
          deltaHours,
          deltaMinutes,
        })
      } else if (!loading) {
        this.clearTimer();
      }
    }
  }

  clearTimer = () => {
    const { loading, timer } = this.state;

    if (timer && !loading) {
      clearInterval(timer);
      this.setState({
        deltaDays: undefined,
        deltaHours: undefined,
        deltaMinutes: undefined,
        timer: undefined,
      });
    }
  }

  render() {
    const { t, userInfo } = this.props;
    const {
      showTable,
      transactions, loading, showPlayer, url, title, isAudio, hls,
      deltaDays, deltaHours, deltaMinutes, beginDate, endDate, timeZone
    } = this.state;

    const columns = [
      {
        title: '№ п/п',
        dataIndex: 'key',
        className: 'column column_rowNum',
        render: (data, record, idx) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">№ п/п:</div>
            <div className="column-content-block">{idx + 1}</div>
          </div>
        )
      },
      {
        title: 'Сумма личных баллов',
        dataIndex: 'spv',
        key: 'spv',
        className: 'column column_spv',
        render: (sum) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Сумма личных баллов:</div>
            <div className="column-content-block">
              <NumericFormat
                value={Number(sum)}
                displayType={'text'}
                decimalScale={1}
                thousandSeparator=" "
              />
            </div>
          </div>
        ),
      },
      // {
      //   title: 'Сумма использованных личных баллов',
      //   dataIndex: 'used_spv',
      //   key: 'used_spv',
      //   className: 'column column_spv column_used_spv',
      //   render: (sum) => (
      //     <div className="flex flex-row gap-1 column-row-block">
      //       <div className="block lg:hidden column-title-block">Сумма использованных личных баллов:</div>
      //       <div className="column-content-block">
      //         <NumericFormat
      //           value={Number(sum)}
      //           displayType={'text'}
      //           decimalScale={1}
      //           thousandSeparator=" "
      //         />
      //       </div>
      //     </div>
      //   ),
      // },
      // {
      //   title: 'Сумма оставшихся личных баллов',
      //   dataIndex: 'ost_spv',
      //   key: 'ost_spv',
      //   className: 'column column_spv column_ost_spv',
      //   render: (sum) => (
      //     <div className="flex flex-row gap-1 column-row-block">
      //       <div className="block lg:hidden column-title-block">Сумма оставшихся личных баллов:</div>
      //       <div className="column-content-block">
      //         <NumericFormat
      //           value={Number(sum)}
      //           displayType={'text'}
      //           decimalScale={1}
      //           thousandSeparator=" "
      //         />
      //       </div>
      //     </div>
      //   ),
      // },
      {
        title: 'ID пользователя',
        dataIndex: 'user_id',
        key: 'user_id',
        className: 'column column_userId',
        render: (user_id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">ID пользователя:</div>
            <div className="column-content-block">{user_id}</div>
          </div>
        ),
      },
      {
        title: 'ФИО',
        dataIndex: 'last_name',
        key: 'last_name',
        className: 'column',
        render: (_, record) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Фамилия:</div>
            <div className="column-content-block hidden lg:block">
              {record.last_name} {record.first_name}
            </div>
            <div className="column-content-block block lg:hidden">
              {record.last_name} {record.first_name}<br/>
              (ID: {record.user_id})
            </div>
          </div>
        ),
      },
    ];

    const videoUrl = '68aa8e2b516d712acbb2257605fdb3bdde9929bf390ca11fc09777c9cf89874c';
    const poster = [
      process.env.REACT_APP_CDN_SELECTEL,
      process.env.REACT_APP_CDN_SELECTEL_URL_TO_VIDEO,
      videoUrl,
      '/',
      'poster.png',
    ].join('');

    return (
      <main className="promo-page promo1-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            pageName='Твоя поездка в Шанхай!'
            parentPageName={ t('LEFT_MENU_PROMO') }
            parentPageLink='/promo'
            withPaySubscription={false}
          />
          {videoUrl && (
            <div className="info__card">
              <div className="info-item__video">
                <div className="fac__less bas__less">
                  {poster && (<img src={poster} alt="" className="info__video-bg" />)}
                  <div className="info__video--a">
                    <div className="info-video__text">&nbsp;</div>
                    <div
                      className="info__play"
                      onClick={() => this.setState({
                        showPlayer: true,
                        playing: true,
                        title: 'Твоя поездка в Шанхай!',
                        isAudio: false,
                        url: videoUrl,
                        hls: true,
                      })}
                    >
                      <PlayIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="info__card">
            <h2 className="pb-1 text-lg">Условия промоушена:</h2>
            <ul className="list-disc pl-6">
              <li><strong>Кто может участвовать:</strong> Резиденты The SoulMate Ambassador's Club.</li>
              <li><strong>Время действия:</strong> {showTimePeriod(loading, beginDate, endDate, timeZone)}</li>
              <li><strong>До окончания осталось:</strong> {showTimeReminder(t, loading, endDate, deltaDays, deltaHours, deltaMinutes)}</li>
              <li><strong>Условия получения награды:</strong> сделать 50 личных единиц товарооборота и более.</li>
              <li><strong>Количество путевок:</strong> 25 шт (учитывая "+1" для резидентов от 5 ступени).</li>
            </ul>
          </div>
          <div className="info__card description">
            <h2 className="pb-1 text-lg">Описание промоушена:</h2>
            <p className="!mb-0">Задача - отдыхать и получать удовольствие!</p>
            <ul className="list-disc pl-6">
              <li>Мы всё сделали за тебя!</li>
              <li>Перелеты туда и обратно </li>
              <li>Трансфер и проживание на все дни путешествия </li>
              <li>Активная программа и интересные приключения </li>
            </ul>
            <p>
              Город будущего - <strong>УЖЕ ЖДЁТ ТЕБЯ ЭТОЙ ВЕСНОЙ !</strong>
            </p>
            {/*<p>*/}
            {/*  😎 Условия получения награды:*/}
            {/*  Только для Амбассадоров Компании.*/}
            {/*  Начиная с 9.10.2023 и до 31.12.2023 до 23:59 по мск, необходимо выполнить 50 ЛИЧНЫХ единиц товарооборота.*/}
            {/*</p>*/}
            <h2>
              <strong>
                ДЕЙСТВУЙ ! ⚡️
              </strong>
            </h2>
            <p>
              1-4 ступень - едет за счёт компании только Амбассадор, который выполнил условия.
            </p>
            <p>
              <strong>Бонус для лидеров команд:</strong><br />
              От 5 ступени и выше - возможность поехать в поездку за счёт компании 1+1
            </p>
          </div>
          {/*<div className="static__contents rounded-4">*/}
          <div className="info__card">
            <div className="static__cont">
              <h2 className="pb-1 text-lg">Список участников:</h2>
              {
                showTable
                  ? (
                    <Table
                      sticky={true}
                      pagination={false}
                      tableLayout={'auto'}
                      dataSource={transactions}
                      columns={columns}
                      loading={loading}
                      rowKey={(record) => record.user_id}
                      rowClassName={(record) => classNames(
                        "st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0",
                        Number(record.spv || 0) < 10
                          ? 'red'
                          : (
                            Number(record.spv || 0) < 30
                              ? 'yellow'
                              : (
                                Number(record.spv || 0) < 50
                                  ? 'blue'
                                  : 'green'
                              )
                          ),
                        (Number(record.user_id || -1) === Number(userInfo?.id || 0)) && 'current-user'
                      )}
                      className='static-table'
                    />
                  )
                  : <p>Пока нет набора на данный промоушен</p>
              }
            </div>
          </div>
          {videoUrl && (
            <MediaPlayer showPlayer={showPlayer} title={title} url={url} handleClose={this.handleClose} isAudio={isAudio} hls={hls} />
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Promo20231001Page
  )
);
