import ReloadWalletButton from '@common/reloadWalletButton';
import ShortTransactions from '@common/shortStatistics/shortTransactions';
import { SoulHideBlock } from '@common/soulHideBlock';
import api from '@services/api';
import { getMessageFromRequest } from '@src/helpers';
import {
  choiceMetaMaskPermissions,
  getMetaMaskAccount,
  getMetaMaskChainId,
  getMetaMaskPermissions,
  isEthereumNetwork,
  isMetaMaskConnected,
  isMetaMaskUnLocked,
} from '@src/helpers/metaTask';
import { closeModal, setModalContent } from '@state/modal';
import { updateWalletById } from '@state/user';
import { message } from 'antd';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, { Component } from 'react';
import { NumericFormat } from 'react-number-format';

import PayIn from '@common/drawer/payIn';
import PayOut from '@common/drawer/payOut';
import {
  DECIMAL_SCALE,
  ROLE_STUDENT_ACADEMY,
  ROLE_STUDENT_BASE,
} from '@src/constants';
import { setDrawerContent } from '@state/drawer';
import store from '@state/store';
import { connect } from 'react-redux';

import './styles.less';
import { Link } from 'react-router-dom';

class Wallet extends Component {
  state = {
    currentWallet: undefined,
    isEthereumNetwork: false,
    account: null,
    chainId: null,
    permissions: null,
  }

  componentDidMount() {
    this.setState({currentWallet: this.getWallet()}, this.checkConnectNetworkToMetaMask);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentWalletId !== this.props.currentWalletId) {
      this.setState({currentWallet: this.getWallet()}, this.checkConnectNetworkToMetaMask);
    }
    const userInfoBalance = this.props.userInfo?.wallets?.find((x) => x.id === this.props.currentWalletId);
    if (!isEqual(userInfoBalance, this.state.currentWallet)) {
      this.setState({currentWallet: this.getWallet()}, this.checkConnectNetworkToMetaMask);
    }
    if (!prevState.isEthereumNetwork && this.state.isEthereumNetwork) {
      this.addListeners();
      this.checkConnectNetworkToMetaMask();
    }
    if (prevState.isEthereumNetwork && !this.state.isEthereumNetwork) {
      this.removeListeners();
    }
  }

  componentWillUnmount() {
    const { isEthereumNetwork } = this.state;

    isEthereumNetwork && this.removeListeners();
  }

  addListeners = () => {
    window.ethereum?.on('chainChanged', this.handleChainChanged);
    window.ethereum?.on('accountsChanged', this.handleAccountsChanged);
    window.ethereum?.on('connect', this.handleAccountsChanged);
    window.ethereum?.on('disconnect', this.handleAccountsChanged);
    window.ethereum?.on('message', this.handleMessage);
  }

  removeListeners = () => {
    window.ethereum?.removeListener('chainChanged', this.handleChainChanged);
    window.ethereum?.removeListener('accountsChanged', this.handleAccountsChanged);
    window.ethereum?.removeListener('connect', this.handleAccountsChanged);
    window.ethereum?.removeListener('disconnect', this.handleAccountsChanged);
    window.ethereum?.removeListener('message', this.handleMessage);
  }

  handleMessage = (message) => {
    console.log({
      _f: 'Wallet-handleMessage-92',
      message,
    });
  }

  handleChainChanged = (chainId) => {
    window.location.reload();
  }

  handleAccountsChanged = () => {
    this.checkConnectNetworkToMetaMask();
  }

  getWallet = () => {
    const { userInfo, currentWalletId } = this.props;

    return currentWalletId && userInfo?.wallets?.find((x) => x.id === currentWalletId);
  }

  showMessageUnlocked = () => {
    this.setState({ account: null, chainId: null, permissions: null });

    const content = (
      <>
        <div>В данный момент кошелек MetaMask требует от вас дополнительных действий!</div>
        <br/>
        <ol className="ml-10 list-decimal">
          <li>Откройте расширение MataMask</li>
          <li>Войдите в свой кошелек MataMask, если вы это еще не сделали, указав свой пароль</li>
          <li>Вернитесь на эту страницу и повторно нажмите на кнопку "Подключить MetaMask"</li>
        </ol>
      </>
    );

    store.dispatch(setModalContent({
      title: 'Ошибка подключения к кошельку MetaMask',
      content,
      params: {
        onlyOk: true,
        onOk: () => {
          store.dispatch(closeModal());
        },
        okButtonProps: {
          shape: 'round',
        },
      },
    }));
  }

  choicePermissionMetaMask = async () => {
    const { updateUserWalletById } = this.props;
    const { currentWallet } = this.state;
    try {

      let account;
      let permissions = [];

      if (isMetaMaskConnected() && !(await isMetaMaskUnLocked())) {
        this.showMessageUnlocked();
        return false;
      }

      do {

        if (permissions?.length > 1) {
          message.error(
            <>
              Пожалуйста, выберете только один счет,<br />
              с которыми планируете взаимодействовать<br />
              (делать операции пополнения/списания),<br />
              находясь на нашем сайте.
            </>
            , 10
          );
        }

        permissions = await choiceMetaMaskPermissions();

        account = await getMetaMaskAccount();

      } while (permissions?.length > 1)

      const chainId = await getMetaMaskChainId();

      const willSaveAddress = (
        permissions?.length === 1
        && account?.length
        && permissions[0] === account
      );

      if (willSaveAddress && !currentWallet.external_address?.length) {
        const params = {
          currentWalletId: currentWallet.id,
          external_address: account,
          external_chain_id: chainId,
        }

        const { status, data } = await api.updateWalletExternalAddress(params);

        if (status === 200 && data?.status === 0) {
          if (data?.wallet) {
            this.setState({ currentWallet: data?.wallet }, this.checkConnectNetworkToMetaMask);
            store.dispatch(updateUserWalletById(data?.wallet));
          }
          this.setState({ account, chainId, permissions });
          message.success('Информация о подключенном счете MetaMask успешно сохранена', 5);
        } else {
          message.error(
            getMessageFromRequest(
              data,
              'Есть проблемы с сохранением подключенного счета MetaMask. Попробуйте повторить операцию позднее'
            ),
            6
          );
          this.setState({ account: null, chainId: null, permissions: null });
        }
      }

    } catch (e) {
      console.log({_f: 'Wallet-handlePayOut-255 -- wallet error', e});
      this.setState({ account: null, chainId: null, permissions: null });

      const content = (
        <>
          <div>В данный момент кошелек MetaMask требует от вас дополнительных действий!</div>
          <br/>
          <ol className="ml-10 list-decimal">
            <li>Откройте расширение MataMask</li>
            <li>Войдите в свой кошелек MataMask, если вы это еще не сделали, указав свой пароль.</li>
            <li>Выберите только один счет, с которыми планируете взаимодействовать (делать операции
              пополнения/списания), находясь на нашем сайте.
            </li>
            <li>Если вы выберете несколько счетов - система будет выдавать вам ошибку, пока вы не выберете только один счет.
            </li>
            <li>Нажмите "Далее" после выбора счета.</li>
            <li>Нажмите "Подключиться" к выбранному на предыдущем шаге счету.</li>
            <li>Возможно, после выполнения пунктов 1-5, вам потребуется повторно нажать на кнопку "Подключить
              MetaMask" на данной странице.
            </li>
          </ol>
        </>
      );

      store.dispatch(setModalContent({
        title: 'Ошибка подключения к кошельку MetaMask',
        content,
        params: {
          onlyOk: true,
          onOk: () => {
            store.dispatch(closeModal());
          },
          okButtonProps: {
            shape: 'round',
          },
        },
      }));
    }
  }

  connectNetworkToMetaMask = async (skipChoicePermission = true) => {
    const { currentWallet, isEthereumNetwork, account } = this.state;

    if (!!window.ethereum && isEthereumNetwork) {
      if (!skipChoicePermission && (!isMetaMaskConnected() || !currentWallet.external_address?.length || !account?.length)) {
        await this.choicePermissionMetaMask();
      } else {
        this.setState({
          account: await getMetaMaskAccount(),
          chainId: await getMetaMaskChainId(),
          permissions: await getMetaMaskPermissions(),
        });
      }
    }
  }

  checkConnectNetworkToMetaMask = async () => {
    const { currentWallet } = this.state;

    if (isEthereumNetwork(currentWallet)) {
      await this.connectNetworkToMetaMask();
      this.setState({ isEthereumNetwork: true })
    } else {
      this.setState({ isEthereumNetwork: false, account: null, chainId: null, permissions: null })
    }
  }

  handlePayIn = () => {
    const {updateDrawerContent} = this.props;
    const { currentWallet } = this.state;

    store.dispatch(
      updateDrawerContent({
        content: PayIn,
        params: {
          wallet: currentWallet,
          callBack: this.getTransactionListShort,
        },
      })
    );
  }

  handlePayOut = async () => {
    const {updateDrawerContent} = this.props;
    const { currentWallet, isEthereumNetwork, account } = this.state;

    store.dispatch(
      updateDrawerContent({
        content: PayOut,
        params: {
          wallet: currentWallet,
          account: isEthereumNetwork ? account : undefined,
          callBack: this.getTransactionListShort,
        }
      })
    );
  }

  handleConnectToMetaMask = async () => {
    const ethereumNetworkName = process.env.REACT_APP_ADMIN_ETHER_NETWORK_NAME;
    const chainId = await getMetaMaskChainId();

    // Если нет расширения Метамаска
    if (!window.ethereum) {
      const content = (
        <>
          <div>К сожалению, в данном браузере не найдено расширение, которое работает с кошельком MetaMask.</div>
          <br />
          <div>
            Пожалуйста, для создания операций ввода/вывода на текущий кошелек нашего сайта:
            <ul className="ml-10 list-disc">
              <li>или откройте сайт в другом браузере, где есть расширение с кошельком Эфира;</li>
              <li>или установите расширение тут и создайте/подключите к нему свой кошелек Эфира.</li>
            </ul>
          </div>
          <br />
          <div>Сайт MetaMask: <Link className="underline" to={{ pathname: 'https://metamask.io/' }} target="_blank">https://metamask.io/</Link></div>
          <br />
          <div>После установки кошелька, пожалуйста, повторите операцию подключения.</div>
        </>
      );

      store.dispatch(setModalContent({
        title: 'Ошибка подключения к кошельку MetaMask',
        content,
        params: {
          onlyOk: true,
          onOk: () => {
            store.dispatch(closeModal());
          },
          okButtonProps: {
            shape: 'round',
          },
        },
      }));
    }

    // Если расширение есть - но сеть не та:
    else if (!(
      (ethereumNetworkName === 'main' && chainId === '0x1')
      || (ethereumNetworkName === 'goerli' && ['0x1', '0x5'].includes(chainId))
    )) {
      const content = (
        <>
          <div>К сожалению, в приложении (расширении) MetaMask выбрана не основная сеть Эфира.</div>
          <br />
          <div>Пожалуйста, выберите Сеть "Ethereum Mainnet" и повторите операцию подключения.</div>
        </>
      );

      store.dispatch(setModalContent({
        title: 'Ошибка подключения к кошельку MetaMask',
        content,
        params: {
          onlyOk: true,
          onOk: () => {
            store.dispatch(closeModal());
          },
          okButtonProps: {
            shape: 'round',
          },
        },
      }));
    }

    // Если расширение есть - надо подключаться
    else {
      await this.connectNetworkToMetaMask(false);
    }
  }

  renderMetaMask = () => {
    return (
      <>
        <div className="statistics-wallet-btns">
          <button
            type="button"
            className="standart-btn standart-btn-border"
            onClick={() => this.handleConnectToMetaMask()}
          >
            <div>Подключить MetaMask</div>
          </button>
        </div>
        <div className="italic text-xs mt-2">(Данное подключение пока работает только на ПК)</div>
      </>
    );
  }

  renderInOutButtons = () => {
    const { currentWallet, isEthereumNetwork, account, chainId, permissions } = this.state;

    const connectToWallet = !isEthereumNetwork || (isMetaMaskConnected() && account);
    const isSavedAccountOnPermission = !isEthereumNetwork || permissions.includes(currentWallet?.external_address?.toLocaleLowerCase());
    const selectedSavedAccount = !isEthereumNetwork || currentWallet?.external_address?.toLocaleLowerCase() === account;
    const selectedSavedChainId = !isEthereumNetwork || currentWallet?.external_chain_id === chainId;
    const isBalanceSentToChain = !isEthereumNetwork || currentWallet?.date_sync_to_blockchain?.length > 0;
    const allowOperations = connectToWallet && selectedSavedAccount && selectedSavedChainId && isSavedAccountOnPermission && isBalanceSentToChain;

    return (
      <>
        <div className="statistics-wallet-btns">
          {currentWallet?.currency_has_pay_in && (
            <button
              type="button"
              className="standart-btn standart-btn-border"
              onClick={() => this.handlePayIn()}
              disabled={currentWallet?.currency_has_pay_in === 2 || !allowOperations}
            >
              <div>Пополнить</div>
            </button>
          )}
          {currentWallet?.currency_has_pay_out && (
            <button
              type="button"
              className="standart-btn standart-btn-border"
              onClick={() => this.handlePayOut()}
              disabled={currentWallet?.currency_has_pay_out === 2 || currentWallet?.balance <= 0 || !allowOperations}
            >
              <div>Вывести</div>
            </button>
          )}
        </div>
        {isEthereumNetwork && /*currentWallet?.currency_has_pay_out === 1 &&*/ isBalanceSentToChain && (
          <div className={classNames("text-xs my-3 xl:whitespace-nowrap", !allowOperations ? 'text-red-500' : 'text-green-500')}>
            {`Кошелек подключен к счету ${currentWallet?.external_address.slice(0, 5)}...${currentWallet?.external_address.slice(-4)}`}
            {!isSavedAccountOnPermission ? <span className="text-red-500"><br/>Но этот счет в данный момент не подключен к нашему сайту</span> : ''}
            {isSavedAccountOnPermission && !selectedSavedAccount ? <span className="text-red-500"><br/>Но этот счет в данный момент не выбран</span> : ''}
            {isSavedAccountOnPermission && selectedSavedAccount && !selectedSavedChainId ? <span className="text-red-500"><br/>Но в данный момент выбрана не та сеть Эфира</span> : ''}
          </div>
        )}
        {isEthereumNetwork && (/*currentWallet?.currency_has_pay_out === 2 ||*/ !isBalanceSentToChain) && currentWallet?.external_address?.length && (
          <div className="text-s my-3">
            Ваша заявка на подключения адреса MetaMask "
            {currentWallet?.external_address.slice(0, 5)}...{currentWallet?.external_address.slice(-4)}
            " к данному кошельку успешно принята и проходит стадию верификации...
          </div>
        )}
      </>
    );
  }

  render() {
    const { userInfo, showTransactions, currentWalletId, eyeChange, hidden = false } = this.props;
    const { currentWallet, isEthereumNetwork, account } = this.state;

    if (
      !userInfo
      || userInfo.id === null
      || userInfo.id === undefined
      || [
        // ROLE_BEGINNER,
        ROLE_STUDENT_BASE,
        ROLE_STUDENT_ACADEMY,
      ].includes(userInfo.role_id)
    ) {
      return null;
    }

    return (
      <div className="statistics-wallet-content">
        <div className="statistics-wallet-flex">
          {currentWallet?.id && (
            <div className="statistics-wallet-info">
              <div className="statistics-wallet-sum">
                <div className="relative">
                  <strong>
                    <NumericFormat
                      value={Number(currentWallet?.balance || 0)}
                      displayType={'text'}
                      decimalScale={DECIMAL_SCALE}
                      thousandSeparator=' '
                      className="!inline !opacity-100 align-text-top"
                    />
                  </strong>
                  <SoulHideBlock hidden={hidden} eyeChange={eyeChange} />
                </div>
                <div className="flex flex-row gap-2">
                  <div>
                    {currentWallet?.currency_code} ({currentWallet?.currency_network_name_code})
                  </div>
                  <div>
                    <ReloadWalletButton wallet={currentWallet} callBack={this.getTransactionListShort} />
                  </div>
                </div>
              </div>

              {isEthereumNetwork && (!isMetaMaskConnected() || !currentWallet?.external_address?.length || !account?.length)
                ? this.renderMetaMask()
                : this.renderInOutButtons()
              }
            </div>
          )}
          {showTransactions && (
            <ShortTransactions currentWalletId={currentWalletId} hidden={hidden} eyeChange={eyeChange} />
          )}
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

const mapDispatchToProps = {
  updateDrawerContent: setDrawerContent,
  updateUserWalletById: updateWalletById,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Wallet
);
