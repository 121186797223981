import api from '@services/api';
import { CHECK_BALANCE_EVERY_SECONDS, CHECK_BALANCE_MINUTES } from '@src/constants';
import { getMessageFromRequest } from '@src/helpers/index';
import store from '@state/store';

export const checkBalance = async (
{
  wallet,
  updateUserWalletById,
  updateLoadingCheckBalance,
  setCloseDrawer,
  updateCheckBalanceResult,
  drawer,
  productForBuy,
}) => {
  const { timerId, timerId2 } = drawer;

  store.dispatch(updateLoadingCheckBalance(true));

  const {status, data} = await api.getWalletById(wallet.id)

  let text = false;
  if (status === 200 && data?.status === 0) {
    if (
      data?.delta
      && data?.wallet
      && Number(data.delta) !== 0
      && Number(data.wallet.balance) !== Number(wallet.balance)
    ) {
      store.dispatch(updateUserWalletById(data.wallet));

      if (Number(data.wallet.balance) < Number(productForBuy?.current_price_with_sale || 0)) {
        text = 'Пополнение выполнено успешно, но все равно монет на балансе не хватает для выполнения покупки.'
      } else if (productForBuy) {
        text = 'Пополнение выполнено успешно. Можете оплатить покупку.'
      } else {
        text = 'Пополнение выполнено успешно.'
      }
      clearInterval(timerId);
      clearTimeout(timerId2);
    }
  } else {
    clearInterval(timerId);
    clearTimeout(timerId2);
    text = getMessageFromRequest(data)
  }

  store.dispatch(updateCheckBalanceResult(text));
}

//TODO: переименовать функцию
export const handleCheckBalance = (
{
  wallet,
  updateUserWalletById,
  updateLoadingCheckBalance,
  setCloseDrawer,
  drawer,
  productForBuy,
  updateCheckBalanceResult,
  startCheckBalance,
}) => {
  const timerId = setInterval(
    () => checkBalance({
      wallet,
      updateUserWalletById,
      updateLoadingCheckBalance,
      setCloseDrawer,
      drawer,
      productForBuy,
      updateCheckBalanceResult,

    }),
    CHECK_BALANCE_EVERY_SECONDS * 1000
  );
  const timerId2 = setTimeout(
    () => {
      clearInterval(timerId);
      store.dispatch(updateCheckBalanceResult('Таймер закончился, но баланс так и не пополнен.'));
    },
    CHECK_BALANCE_MINUTES * 60 * 1000
  );
  store.dispatch(startCheckBalance([timerId, timerId2]));
}

