import { ROLE_AMBASSADOR } from '@src/constants';
import { IUserInfo } from '@state/user';

const allowUserListAndAmbassadorsFrom4Level = (userInfo?: IUserInfo, userList?: number[]) => {
  return (
    (
      userList?.length
      && userList.includes(userInfo?.id ?? -999)
    )
    || (
      userInfo?.role_id === ROLE_AMBASSADOR
      && userInfo?.level >= 4
    )
  );
}

export default allowUserListAndAmbassadorsFrom4Level;
