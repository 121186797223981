import withWindowResize from '@src/helpers/withWindowResize';
import { Modal } from 'antd';
import React, { Component } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import ReactPlayer from 'react-player';

import "./styles.less";

class mediaPlayer extends Component {
  render() {
    const { showPlayer, title, url, handleClose, innerWidth, innerHeight, isAudio = false, hls = false } = this.props;

    const playerWidth  = isAudio ? 370 : (innerWidth ? innerWidth * 0.8 : 'auto');
    const playerHeight = isAudio ? 55  : (innerHeight ? innerHeight * 0.8 : 'auto');
    const modalWidth   = playerWidth + 24 + 24;
    const modalHeight  = playerHeight + 24 + 24 + 1 + (title ? 55 : 0);
    const top = (innerHeight - modalHeight) / 2

    const hlsUrl = hls
      ? [
          process.env.REACT_APP_CDN_SELECTEL,
          process.env.REACT_APP_CDN_SELECTEL_URL_TO_VIDEO,
          url,
          '/master.m3u8'
        ].join('')
      : '';

    return (
      <Modal
        className="video-modal"
        open={showPlayer}
        title={title}
        onOk={handleClose}
        onCancel={handleClose}
        footer={false}
        destroyOnClose={true}
        afterClose={handleClose}
        width={modalWidth}
        height={modalHeight}
        style={{ top: top }}
      >
        {
          hls
            ? (
              <ReactHlsPlayer
                src={hlsUrl}
                autoPlay={true}
                controls={true}
                className="react-player fac__img"
                width={playerWidth}
                height={playerHeight}
                hlsConfig={{
                  maxLoadingDelay: 4,
                  minAutoBitrate: 0,
                  lowLatencyMode: true,
                }}
              />
            )
            : (
              <ReactPlayer
                // ref={this.ref}
                playing
                controls
                className="react-player fac__img"
                url={url}
                width={playerWidth}
                height={playerHeight}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    }
                  },
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      // color: 'white',
                      showinfo: 0,
                      rel: 0,
                      iv_load_policy: 3,
                      cc_load_policy: 0,
                    }
                  }
                }}
              />
            )
        }
      </Modal>
    );
  }
}

export default withWindowResize(
  mediaPlayer
);
