import { createSlice } from '@reduxjs/toolkit';
import { APPSTAGE_INITIAL } from '@src/constants';

const appStageSlice = createSlice({
  name: 'appStage',
  initialState: {
    stage: APPSTAGE_INITIAL,
  },
  reducers: {
    setAppStage: (state, action) => {
      state.stage = action.payload;
    },
  },
})

export const { setAppStage } = appStageSlice.actions;
export default appStageSlice.reducer;
