import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Burger from '@common/burger';
import { ROUTE_ROOT } from '@src/constants';

// import { ReactComponent as NotificationIcon } from '@ui/icons/notification.svg';

import "./styles.less";

class ErrorPage extends Component {
  goBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  render() {
    const { t, history } = this.props;

    const userData = {
      avatar: '/images/avatar.png',
    }

    return (
      <main className="error-page">
        <div className="wrap">
          <div className="mheader">
            <div className="mheader-left">
              <div className="mheader-user">
                <img src={userData.avatar} alt="" />
              </div>
              {/* TODO: Сделать отображение уведомлений */}
              {/*<div className="mheader-item">
                <NotificationIcon />
              </div>*/}
            </div>

            <Burger />

          </div>
          <div className="error__content">
            <h1>{t('ERROR_404')}</h1>
            <p>{t('ERROR_404_TEXT')}</p>
            <button className="standart-btn" onClick={() => history.push(ROUTE_ROOT)}>
              <div>{t('ERROR_RETURN_TO_MAIN_PAGE')}</div>
            </button>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  withRouter(
    ErrorPage
  )
);
