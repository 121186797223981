import React, { Component } from 'react';
import classNames from 'classnames';
import { NumericFormat } from 'react-number-format';
import { SoulHideBlock } from '@common/soulHideBlock';

import "./styles.less";

class InfoItem extends Component {
  render() {
    const { title, value, className, isNumericValue, eyeChange, active = false, white = false, hidden = false} = this.props;

    const isNumeric = isNumericValue === undefined
      ? value.toString().match(/[\d.,+ -]+/g)
      : isNumericValue;

    const titleKey = Array.isArray(title)
      ? title.join('_')
      : title;

    const titleText = Array.isArray(title)
      ? title.map((x) => <p key={x}>{x}</p>)
      : <p key={titleKey}>title</p>;

    return (
      <div
        className={
          classNames(
            "info-item",
            active ? 'active' : '',
            white ? 'white' : '',
            className,
          )
        }
      >
        {titleText}
        <div className="relative">
          <strong>
            {!isNumeric && (
              <strong key={value}>{value}</strong>
            )}
            {isNumeric && (
              <NumericFormat
                value={value}
                displayType={'text'}
                decimalScale={1}
                thousandSeparator=" "
              />
            )}
          </strong>
          <SoulHideBlock hidden={hidden} eyeChange={eyeChange} />
        </div>
      </div>
    );
  }
}

export default InfoItem;
