import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import NftPlayer from '@routes/ambassadorsClub/myNtf/nftPlayer';
import { IInfoDB, INtfPersona } from '@routes/ambassadorsClub/myNtf/types';
import api from '@services/api';
import {
  METAMASK_NETWORK_POLIGON,
  NFT_TOKEN_ELEMENT_MARKET,
  NFT_TOKEN_POLYGON_SCAN,
  ROUTE_AMBASSADORS_CLUB,
} from '@src/constants';
import {
  addMetaMaskChain,
  choiceMetaMaskPermissions,
  getMetaMaskAccount,
  getMetaMaskBalance,
  getMetaMaskChainId,
  getMetaMaskPermissions,
  isMetaMaskConnected,
  isMetaMaskUnLocked,
  selectMetaMaskChainId,
} from '@src/helpers/metaTask';
import {
  addPoligonNFTToWallet,
  getExternalLink,
  getMaxPolygonContractFee,
  getNftGenesisUniqueValue,
  getPoligonNFTCount,
  getPoligonNFTTokenId,
  getPoligonNFTTokenInfo,
  mintPoligonNFT,
} from '@src/helpers/poligonNFT';
import { closeModal, setModalContent } from '@state/modal';
import store, { useAppSelector } from '@state/store';
import { Button, message, Spin } from 'antd';
import classNames from 'classnames';
import { toBeHex } from 'ethers';
import React, { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as GenesisIcon } from './assets/Genesis.svg';
// import { ReactComponent as Genesis2Icon } from './assets/Genesis2.svg';
import { ReactComponent as LegendaryIcon } from './assets/Legendary.svg';
// import { ReactComponent as Legendary2Icon } from './assets/Legendary2.svg';
import { ReactComponent as LockIcon } from './assets/Lock1.svg';
import { ReactComponent as Lock2Icon } from './assets/Lock2.svg';

import "./styles.less";


const MyNFTPage: FC = () => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ hasNFT, setHasNFT ] = useState<boolean>(false);
  const [ userStep, setUserStep ] = useState<number>(0);
  const [ userMakeNftStage, setUserMakeNftStage ] = useState<number>(0);
  const [ isUserHaveNft, setIsUserHaveNft ] = useState<boolean>(false);
  const [ isDisabledButton, setIsDisabledButton ] = useState<boolean>(true);
  const [ buttonText, setButtonText ] = useState<string>('');
  const [ buttonAction, setButtonAction ] = useState<MouseEventHandler<HTMLElement>>(() => () => { console.log('nothing') });
  const [ currentAccount, setCurrentAccount ] = useState<string|undefined>();
  // const [ timer, setTimer ] = useState<NodeJS.Timeout | undefined>(undefined);
  const [ nftInfo, setNftInfo ] = useState<INtfPersona | undefined>(undefined);
  const [ showPlayer, setShowPlayer ] = useState<boolean>(false);
  const [ userPolygonInfoDB, setUserPolygonInfoDB ] = useState<IInfoDB|undefined>(undefined);
  const [ errorMessage, setErrorMessage ] = useState<string[] | undefined>(undefined);

  const userInfo = useAppSelector((state) => state.user.info);
  const { t } = useTranslation();

  const setNextUserStep = useCallback(() => setUserStep((step) => step + 1), []);
  const setNextMakeNftStage = useCallback(() => setUserMakeNftStage((stage) => stage + 1), []);

  const setAction = (action?: Function, text?: string) => {
    // console.log({
    //   _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx---setAction-67',
    //   timer,
    //   action,
    //   text,
    // });
    //
    // if (timer) {
    //   clearTimeout(timer)
    //   setTimer(undefined);
    // }
    setButtonAction(() => async () => action ? await action() : null);
    setLoading(false);
    if (text !== '') {
      setButtonText(text ?? '');
      setIsDisabledButton((text ?? '').length === 0);
    }
  };

  // Получение данных из БД по сети "Polygon" для пользователя
  const getPolygonUserInfo = useCallback(async (): Promise<IInfoDB|undefined> => {
    const info = await api.getPolygonInfo();

    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----90',
      info
    });

    if (info?.status === 200) {
      if (info?.data?.status === 0) {
        setUserPolygonInfoDB(info.data.info);
        return info.data.info;
      } else {
        setErrorMessage([info.data.message]);
        // setErrorMessage([
        //   'К сожалению, для Вас не найдены разрешения для генерации NFT-персонажа.',
        //   'Возможно, у Вас не привязан кошелек МетаМаск для валюты BUI. Его нужно привязать и отправить на верификацию.',
        //   'Возможно, Вам пока не выданы разрешения на генерацию. Попробуйте повторить операцию в следующем месяце или обратитесь в службу поддержки.',
        // ]);
      }
    } else {
      setErrorMessage([
        'К сожалению, мы не можем получить данные по Вашему пользователю для генерации NFT-персонажа.',
        'Обратитесь в службу поддержки.',
      ]);
    }
    setUserPolygonInfoDB(undefined);
    setAction();
    return undefined;
  }, []);

  // Проверка установлено ли расширение МетаМаска
  const checkInstallMetaMask = useCallback(async () => {
    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----229 -- checkInstallMetaMask',
    });

    if (!window.ethereum || !isMetaMaskConnected()) {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----236 -- checkInstallMetaMask -- no MetaMask' });

      setAction(setNextUserStep, 'Проверить установку MetaMask');
      // setButtonText('Проверить установку MetaMask');
      // setButtonAction(() => () => {
      //   setNextUserStep();
      // });

      const content = (
        <>
          <div>К сожалению, в данном браузере не найдено расширение, которое работает с кошельком MetaMask.</div>
          <br />
          <div>
            Пожалуйста:
            <ul className="ml-10 list-disc">
              <li>или откройте сайт в другом браузере, где есть расширение MetaMask;</li>
              <li>или установите расширение MetaMask тут и создайте/подключите к нему свой кошелек Эфира.</li>
            </ul>
          </div>
          <br />
          <div>Сайт MetaMask: <Link className="underline" to={{ pathname: 'https://metamask.io/' }} target="_blank">https://metamask.io/</Link></div>
          <br />
          <div>После установки кошелька MetaMask, пожалуйста, нажмите на кнопку "Проверить установку MetaMask".</div>
        </>
      );

      store.dispatch(setModalContent({
        title: 'Ошибка подключения к кошельку MetaMask',
        content,
        params: {
          onlyOk: true,
          onOk: () => {
            store.dispatch(closeModal());
          },
          okButtonProps: {
            shape: 'round',
          },
        },
      }));
    } else {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----200 -- checkInstallMetaMask - Next' });
      setUserMakeNftStage(2);
    }
  }, [setNextUserStep]);

  // Сообщение для оплаты комиссии
  const showMintMessage = useCallback(() => {
    console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----118' });

    const shortAccount = currentAccount && currentAccount.length > 0
      ?  (currentAccount.slice(0, 7) + '...' + currentAccount.slice(-5))
      : '-'

    const content = (
      <>
        <div>В данный момент кошелек MetaMask требует от вас дополнительных действий!</div>
        <br/>
        <div>Требуется оплатить комиссию за генерацию вашего NFT персонажа.</div>
        <br/>
        <div>Для этого:</div>
        <ol className="ml-10 list-decimal">
          <li>Откройте расширение MataMask, если оно еще не открылось</li>
          <li>Войдите в свой кошелек MataMask, если вы это еще не сделали, указав свой пароль.</li>
          <li>Выберите счет {shortAccount} - только для него у Вас есть нужные разрешения для генерации NFT-персонажа.</li>
          <li>Нажмите "Подтвердить" для списания комиссии с вашего счета.</li>
          <li>Ожидайте завершения операции</li>
        </ol>
      </>
    );

    store.dispatch(setModalContent({
      title: 'Подтверждение оплаты комиссии',
      content,
      params: {
        onlyOk: true,
        onOk: () => {
          store.dispatch(closeModal());
        },
        okButtonProps: {
          shape: 'round',
        },
      },
    }));
  }, [currentAccount]);

  // Сообщение для оплаты комиссии
  const showAddChangeAccountMessage = useCallback(() => {
    console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----118 -- showAddChangeAccountMessage' });

    const shortAccount = currentAccount && currentAccount.length > 0
      ?  (currentAccount.slice(0, 7) + '...' + currentAccount.slice(-5))
      : '-'

    const content = (
      <>
        <div>В данный момент кошелек MetaMask требует от вас дополнительных действий!</div>
        <br/>
        <div>Требуется изменить текущий счет для размещения вашего NFT на кошелек.</div>
        <br/>
        <div>Для этого:</div>
        <ol className="ml-10 list-decimal">
          <li>Откройте расширение MataMask, если оно еще не открылось</li>
          <li>Войдите в свой кошелек MataMask, если вы это еще не сделали, указав свой пароль.</li>
          <li>Выберите счет {shortAccount} - только для него создан NFT-персонаж.</li>
          <li>Нажмите на кнопку "Добавить NFT в MetaMask" еше раз.</li>
        </ol>
      </>
    );

    store.dispatch(setModalContent({
      title: 'Изменение текущего кошелька MetaMask',
      content,
      params: {
        onlyOk: true,
        onOk: () => {
          store.dispatch(closeModal());
        },
        okButtonProps: {
          shape: 'round',
        },
      },
    }));
  }, [currentAccount]);

  // Проверка заблокирован ли МетаМаск
  const checkUnlockedMetaMask = useCallback(async () => {
    const unLocked = await isMetaMaskUnLocked();
    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----208 -- checkUnlockedMetaMask',
      unLocked,
    });

    if (!unLocked) {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----272 -- checkUnlockedMetaMask -- Locked MetaMask' });

      setAction(setNextUserStep, 'Проверить блокировку MetaMask');
      // setButtonText('Проверить блокировку MetaMask');
      // setButtonAction(() => () => setNextUserStep());

      const content = (
        <>
          <div>В данный момент кошелек MetaMask требует от вас<br />дополнительных действий!</div>
          <br/>
          <ol className="ml-10 list-decimal">
            <li>Откройте расширение MataMask вручную, если окно MataMask не появится автоматически</li>
            <li>Войдите в свой кошелек MataMask, если вы это еще не сделали, указав свой пароль</li>
            <li>Вернитесь на эту страницу и нажмите на кнопку "Проверить блокировку MetaMask"</li>
          </ol>
        </>
      );

      store.dispatch(setModalContent({
        title: 'Нет доступа к кошельку MetaMask',
        content,
        params: {
          onlyOk: true,
          onOk: async () => {
            store.dispatch(closeModal());
          },
          okButtonProps: {
            shape: 'round',
          },
        },
      }));
    } else {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----243 -- checkUnlockedMetaMask -- Next' });
      setNextMakeNftStage();
    }
  }, [setNextMakeNftStage, setNextUserStep]);

  // Установка или добавление сети Полигон
  const setPoligonNetToMetaMask = useCallback(async () => {
    console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx---setPoligonNetToMetaMask-198' });

    setLoading(true);

    try {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----135 -- setPoligonNetToMetaMask -- switch chain' });

      const switchResult = await selectMetaMaskChainId(METAMASK_NETWORK_POLIGON);

      console.log({
        _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----249',
        switchResult,
      });

      setNextUserStep();
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      console.log({
        _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----257',
        switchError,
      });

      if (switchError?.code === 4902) {
        try {
          console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----143 -- setPoligonNetToMetaMask -- add chain' });

          const addResult = await addMetaMaskChain({
            chainId: METAMASK_NETWORK_POLIGON,
            chainName: "Polygon Mainnet",
            rpcUrls: [process.env.REACT_APP_ADMIN_POLIGON_NETWORK_API],
            blockExplorerUrls: ["https://polygonscan.com/"],
            nativeCurrency: {
              decimals: 18,
              name: "MATIC",
              symbol: "MATIC"
            }
          });

          console.log({
            _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----267',
            addResult,
          });

          setNextUserStep();

        } catch (addError) {
          message.error('Что-то пошло не так при установке новой сети. Попробуйте выбрать или установить вручную сеть "Polygon Mainnet"');
          console.log({
            _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----235 --- error for add polygon-net',
            addError
          });

          setAction(setNextUserStep, '');
        }
      } else {
        message.error('Что-то пошло не так при выборе сети. Попробуйте выбрать или установить вручную сеть "Polygon Mainnet"', 6)
        setAction(setNextUserStep, '');
      }
    }
  }, [setNextUserStep]);

  // Если расширение есть - но сеть не та:
  const checkChainMetaMask = useCallback(async () => {
    const chainId = await getMetaMaskChainId();
    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----251 -- checkChainMetaMask',
      chainId,
    });

    if (chainId !== METAMASK_NETWORK_POLIGON) {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----275 -- checkChainMetaMask -- no Poligon' });

      setAction(setPoligonNetToMetaMask, 'Установить сеть "Polygon Mainnet" в MetaMask');
      // setButtonText('Установить сеть "Polygon Mainnet" в MetaMask')
      // setButtonAction(() => () => setPoligonNetToMetaMask())

      // const content = (
      //   <>
      //     <div>К сожалению, в приложении (расширении) MetaMask выбрана не сеть "Polygon Mainnet".</div>
      //     <br />
      //     <div>Пожалуйста, выберите Сеть "Polygon Mainnet" и повторите операцию подключения.</div>
      //   </>
      // );
      //
      // store.dispatch(setModalContent({
      //   title: 'Ошибка подключения к кошельку MetaMask',
      //   content,
      //   params: {
      //     onlyOk: true,
      //     onOk: () => {
      //       store.dispatch(closeModal());
      //     },
      //     okButtonProps: {
      //       shape: 'round',
      //     },
      //   },
      // }));
    } else {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----291 -- checkChainMetaMask -- Next' });
      setNextMakeNftStage();
    }
  }, [setNextMakeNftStage, setPoligonNetToMetaMask]);

  // Проверка разрешений на счетах
  const choicePermissionMetaMask = useCallback(async () => {
    const permissionAddresses = Object.keys(userPolygonInfoDB?.nft_permissions_json ?? [])
    const permissionAddress = (permissionAddresses?.[0] ?? userPolygonInfoDB?.nft_address)?.toLowerCase();
    const permissionAddressShort = permissionAddress?.length > 0
      ? (permissionAddress.slice(0, 7) + '...' + permissionAddress.slice(-5))
      : ''

    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----356',
      hasNFT,
      permissionAddress,
      permissionAddresses,
      userPolygonInfoDB,
    });

    let account = await getMetaMaskAccount();
    let permissions = await getMetaMaskPermissions() ?? [];

    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----234 -- choicePermissionMetaMask',
      account,
      permissions,
    });

    if (permissions?.length > 1 && permissions.includes(account) && (hasNFT || (permissions.includes(permissionAddress) && account !== permissionAddress))) {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----234 -- choicePermissionMetaMask -- change account' });

      setAction(setNextUserStep, 'Проверить подключение счета в MetaMask-е');
      // setButtonText('Проверить подключение счета в MetaMask-е');
      // setButtonAction(() => () => setNextUserStep);

      const content = (
        <>
          <div>
            В данный момент кошелек MetaMask требует от вас дополнительных действий,
            поскольку НЕ выбран счет {permissionAddressShort} в качестве текущего.
          </div>
          <br/>
          <ol className="ml-10 list-decimal">
            <li>Откройте расширение MataMask, если оно еще не открылось</li>
            <li>Войдите в свой кошелек MataMask, если вы это еще не сделали, указав свой пароль.</li>
            {hasNFT
              ? (
                <>
                  <li>Сменить текущий счет на тот, на котором у вас есть NFT-персонаж.</li>
                  <li>Оставить разрешения только на выбранный счет (остальные счета отключить от сайта).</li>
                </>
              )
              : <li>Сменить текущий счет '{permissionAddressShort}' - только на него у вас есть разрешения для генерации NFT-персонажа.</li>
            }
            <li>Повторно нажмите на кнопку "Проверить подключение счета в MetaMask-е" на данной странице.</li>
          </ol>
        </>
      );

      store.dispatch(setModalContent({
        title: 'Выбран неправильный счет в кошельке MetaMask',
        content,
        params: {
          onlyOk: true,
          onOk: () => {
            store.dispatch(closeModal());
          },
          okButtonProps: {
            shape: 'round',
          },
        },
      }));
    } else if ((!hasNFT && !permissions.includes(permissionAddress)) || (hasNFT && permissions.length === 0)) {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----234 -- choicePermissionMetaMask -- no permissions' });

      setAction(setNextUserStep, 'Проверить подключение счета в MetaMask-е');
      // setButtonText('Проверить подключение счета в MetaMask-е');
      // setButtonAction(() => () => setNextUserStep);

      choiceMetaMaskPermissions();

      const content = (
        <>
          <div>В данный момент кошелек MetaMask требует от вас дополнительных действий!</div>
          <br/>
          <ol className="ml-10 list-decimal">
            <li>Откройте расширение MataMask, если оно еще не открылось</li>
            <li>Войдите в свой кошелек MataMask, если вы это еще не сделали, указав свой пароль.</li>
            {hasNFT
              ? <li>Выберите счет на котором у вас есть NFT-персонаж.</li>
              : <li>Выберите счет '{permissionAddressShort}' - только на него у вас есть разрешения для генерации NFT-персонажа.</li>
            }
            <li>Нажмите "Далее" после выбора счета.</li>
            <li>Нажмите "Подключиться" к выбранному на предыдущем шаге счету.</li>
            <li>Возможно, после выполнения пунктов 1-5, вам потребуется повторно нажать на кнопку
              "Проверить подключение счета в MetaMask-е" на данной странице.
            </li>
          </ol>
        </>
      );

      store.dispatch(setModalContent({
        title: 'Нет доступа к счету в кошельке MetaMask',
        content,
        params: {
          onlyOk: true,
          onOk: () => {
            store.dispatch(closeModal());
          },
          okButtonProps: {
            shape: 'round',
          },
        },
      }));
    } else {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----276 -- choicePermissionMetaMask -- Next' });
      setCurrentAccount(account);
      setNextMakeNftStage();
    }
  }, [hasNFT, setNextMakeNftStage, setNextUserStep, userPolygonInfoDB]);

  // Проверка наличия баланса на кошельке пользователя
  const checkBalanceMatic = useCallback(async () => {
    const maxFee = await getMaxPolygonContractFee();
    const currentBalance = await getMetaMaskBalance(currentAccount);
    const maxFeeText = Math.ceil(maxFee * 100) / 100;
    const currentBalanceText = Math.floor(currentBalance * 100) / 100;
    const delta = Math.ceil((maxFeeText - currentBalanceText) * 100) / 100;

    if (currentBalance < maxFee) {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----236 -- checkBalanceMatic -- no Balance' });

      setAction(setNextUserStep, 'Проверить баланс Matic на кошельке MetaMask');
      // setButtonText('Проверить баланс Matic на кошельке MetaMask');
      // setButtonAction(() => () => {
      //   setNextUserStep();
      // });

      const content = (
        <>
          <div>
            К сожалению, монет 'MATIC' на кошельке MetaMask<br />
            {currentAccount}
            <br />в сети 'Polygon Mainnet' недостаточно.
          </div>
          <br />
          <div><>Баланс кошелька: <strong>{currentBalanceText}</strong> MATIC</></div>
          <div><>Необходимая сумма: <strong>{maxFeeText}</strong> MATIC</></div>
          <br />
          <div>
            Пожалуйста, пополните кошелек с указанным адресом на сумму, как минимум:
            <strong> {delta} </strong>
            MATIC и нажмите на кнопку "Проверить баланс Matic на кошельке MetaMask".
          </div>
        </>
      );

      store.dispatch(setModalContent({
        title: 'Недостаточно монет на балансе кошелька MetaMask',
        content,
        params: {
          onlyOk: true,
          onOk: () => {
            store.dispatch(closeModal());
          },
          okButtonProps: {
            shape: 'round',
          },
        },
      }));
    } else {
      console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----392 -- -- Next' });
      setNextMakeNftStage();
    }
  }, [currentAccount, setNextMakeNftStage, setNextUserStep]);

  // Проверка наличия баланса на кошельке пользователя
  const checkNFT = useCallback(async () => {
    console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----329 -- checkNFT' });

    /** TODO: Для тестов подменяю адрес */
    // setCurrentAccount('0x76136C44421b9dCE602b2f5AF32f2203b8a1AAf5');

    // const signer = await getPoligonSigner(currentAccount);
    // const contract = await getPoligonContract(currentAccount);
    // const accountBalance = await getPoligonAccountBalance(currentAccount);
    // const contractBalance = await getPoligonContractBalance(currentAccount);
    const nftCount = await getPoligonNFTCount(currentAccount);

    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----337',
      nftCount,
      h_nftCount: toBeHex(nftCount),
      h_0: toBeHex(0),
    });

    if (toBeHex(nftCount) === toBeHex(0)) {
      if (hasNFT) {
        setHasNFT(false);
        message.error("На выбранном вами кошельке мы НЕ нашли NFT-персонаж", 5);
        setUserMakeNftStage(0);
        setUserStep(0);
        setTimeout(() => window.location.reload(), 5000)
      } else if (userPolygonInfoDB?.nft_permissions_json && userPolygonInfoDB?.nft_permissions !== "1") {
        const searchNFTAddress = await api.searchPolygonNftAddress("-1", currentAccount);

        console.log({
          _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----612',
          searchNFTAddress
        });

        if (searchNFTAddress.status === 200 &&  searchNFTAddress.data.status === 0) {
          // Если нет NFT на адресе - можно минтить
          setUserMakeNftStage(10);
        } else {
          setErrorMessage([
            'Указанный Вами адрес МетаМаск кошелька уже привязан к другому пользователю.',
          ]);
          setAction();
        }
      } else {
        setErrorMessage([
          'Ваше разрешение на генерацию NFT-персонажа уже было использовано. Повторная генерация невозможна.',
        ]);
        setAction();
      }
    } else {
      // Если есть NFT на адресе - надо считывать данные и показывать
      const nftInfo = await getPoligonNFTTokenId(currentAccount);
      const tokenIdDB = userPolygonInfoDB?.nft_token_id ?? 0;
      const tokenIdExternalDB = userPolygonInfoDB?.nft_external ?? 0;

      console.log({
        _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----352',
        nftInfo,
        h_t: toBeHex(tokenIdDB),
        h_i: toBeHex(nftInfo),
      });

      if (toBeHex(tokenIdDB) === toBeHex(nftInfo) || toBeHex(tokenIdExternalDB) === toBeHex(nftInfo)) {
        setUserMakeNftStage(20);
      } else {
        const searchNFTTokenAddress = await api.searchPolygonNftAddress(nftInfo, currentAccount);

        console.log({
          _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----650',
          searchNFTTokenAddress
        });

        if (hasNFT || (searchNFTTokenAddress.status === 200 && searchNFTTokenAddress.data.status === 0)) {
          // Минт NFT уже сделан - можно показывать видео
          setUserMakeNftStage(12);
        } else {
          setErrorMessage([
            'Указанный Вами адрес МетаМаск кошелька (или сгенерированный NFT) уже привязан к другому пользователю.',
          ]);
          setAction();
        }
      }
    }
  }, [currentAccount, hasNFT, userPolygonInfoDB?.nft_external, userPolygonInfoDB?.nft_permissions, userPolygonInfoDB?.nft_permissions_json, userPolygonInfoDB?.nft_token_id]);

  const mintNFT = useCallback(async () => {
    console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----403 -- mintNFT' });

    setIsDisabledButton(true);
    setLoading(true);
    showMintMessage();
    const proof = userPolygonInfoDB?.nft_permissions_json?.[currentAccount ?? ''];

    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----599',
      proof,
      currentAccount,
      json: userPolygonInfoDB?.nft_permissions_json
    });

    const mintTransaction = await mintPoligonNFT(currentAccount, proof);

    store.dispatch(closeModal());

    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----408 -- mintNFT',
      mintTransaction,
    });

    await checkNFT();

    // const nft: INtfPersona | undefined = await getPoligonNFTTokenInfo(currentAccount);
    //
    // console.log({
    //   _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----678',
    //   nft,
    // });
    //
    // setLoading(false);
    // setIsDisabledButton(false);
    //
    // if (nft) {
    //   setUserMakeNftStage(12);
    // } else {
    //   message.error('Что-то пошло не так при генерации NFT-персонажа. Попробуйте повторить операцию позднее');
    //   console.log({
    //     _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----415 -- mintNFT',
    //     nft,
    //     text: 'Что-то пошло не так при генерации NFT-персонажа. Попробуйте повторить операцию позднее'
    //   });
    //   setAction(setNextUserStep, '');
    // }
    //

  }, [checkNFT, currentAccount, showMintMessage, userPolygonInfoDB?.nft_permissions_json]);

  const videoNFT = useCallback(async () => {
    setIsDisabledButton(true);
    setLoading(true);

    const nftInfo: INtfPersona = await getPoligonNFTTokenInfo(currentAccount);

    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----479',
      nftInfo,
    });

    if (hasNFT) {
      const saveResult2 = await api.updatePolygonNftExternal(nftInfo.tokenId, currentAccount);

      console.log({
        _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----696',
        saveResult2,
      });
    } else {
      const saveResult = await api.updatePolygonNftToken(nftInfo.tokenId, hasNFT ? undefined : currentAccount);

      console.log({
        _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----649',
        saveResult,
      });
    }

    setNftInfo(nftInfo);
    setShowPlayer(true);
    setIsUserHaveNft(true);
    setIsDisabledButton(false);
    setLoading(false);
  }, [currentAccount, hasNFT]);

  const viewNFT = useCallback(async () => {
    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----757',
    });

    setIsDisabledButton(true);
    setLoading(true);

    const nftInfo: INtfPersona = await getPoligonNFTTokenInfo(currentAccount);

    setNftInfo(nftInfo);
    setIsUserHaveNft(true);
    setLoading(false);
  }, [currentAccount]);

  const addNFTToWallet = useCallback(async () => {
    setLoading(true);
    const nft: INtfPersona = nftInfo || await getPoligonNFTTokenInfo(currentAccount);

    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----479',
      nft,
    });

    const addResult = await addPoligonNFTToWallet(nft);

    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----649',
      addResult,
    });

    if (addResult === -32002) {
      showAddChangeAccountMessage();
    } else if (addResult === 1) {
      message.success('Добавлении NFT в MetaMask прошло успешно');
    } else {
      message.error('Что-то пошло не так при добавлении NFT в MetaMask. Попробуйте повторить операцию позднее');
    }
    setLoading(false);
  }, [currentAccount, nftInfo, showAddChangeAccountMessage]);

  const videoNFTClose = useCallback(async () => {
    // const nftInfo = await getPoligonNFTTokenInfo(currentAccount);

    console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx--videoNFTClose--511' });

    setShowPlayer(false);
  }, []);

  const onHasNFT = () => {
    setErrorMessage(undefined);
    setHasNFT(true);
    setLoading(true);
    setTimeout(() => setNextUserStep(), 1000);
  }

  useEffect(() => {
    (async () => {
      if (userStep > 0) {
        setLoading(true);
        // if (timer) {
        //   clearTimeout(timer)
        // }
        // if (userMakeNftStage < 10 && ![2].includes(userMakeNftStage)) {
        //   setTimer(setTimeout(() => setNextUserStep(), 10000));
        // }
        switch (userMakeNftStage) {
          case 0: // Проверка установки МетаМаску
            console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----291 === 0' });
            await checkInstallMetaMask()
            break;
          case 1: // Проверка блокировку МетаМаск-а
            console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----295 === 1' });
            await checkUnlockedMetaMask();
            break;
          case 2: // Проверка установку/подключение сети Полигон
            console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----299 === 2' });
            await checkChainMetaMask();
            break;
          case 3: // Проверка разрешений на счетах
            console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----299 === 3' });
            await choicePermissionMetaMask();
            break;
          case 4: // Проверка наличия NFT на кошельке
            console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----373 === 4' });
            await checkNFT()
            break;
          case 10: // Проверка наличия суммы в MATIC для минта
            console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----373 === 10' });
            await checkBalanceMatic()
            break;
          case 11: // Минт NFT
            console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----373 === 11' });
            // setButtonAction(() => async () => await mintNFT());
            // setButtonText('Сгенерировать свой персонаж')
            setAction(mintNFT, 'Сгенерировать свой персонаж');
            // await mintNFT();
            // setLoading(true);
            // setTimeout(() => {
            //   setLoading(false);
            //   setUserMakeNftStage(3);
            // }, 2000);
            break;
          case 12: // Получение данных об NFT
            console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----373 === 12' });
            setAction(videoNFT, 'Получить свой сгенерированный персонаж');
            break;
          default:
            console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----373 === 20' });
            await viewNFT();
            break;
        }
      }
    })();
  }, [videoNFT, checkBalanceMatic, checkChainMetaMask, checkInstallMetaMask, checkNFT, checkUnlockedMetaMask, choicePermissionMetaMask, mintNFT, setNextUserStep, userMakeNftStage, userStep, viewNFT]);

  // useEffect(() => {
  //   console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----339 === useEffect' });
  //   checkMetaMask();
  //
  //   // Специально оставляем пустые скобки,
  //   // чтобы данная функция запустилось только при создании компонента
  // }, [checkMetaMask])

  useEffect(() => {
    console.log({
      _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----629',
      is_genesis: userInfo?.is_genesis,
    });

    (async () => {
      const infoDB = await getPolygonUserInfo();

      console.log({
        _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----674',
        infoDB
      });

      if ((infoDB?.nft_external_address ?? '').length > 0) {
        setHasNFT(true);
        setNextUserStep();
      } else if (infoDB?.nft_token_id) {
        setNextUserStep();
      } else if (userInfo?.is_genesis) {
        setLoading(true);
        console.log({ _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx----339 === useEffect' });

        if (infoDB?.nft_permissions_json && infoDB?.nft_permissions !== "1") {
          setNextUserStep();
        } else if (infoDB?.nft_permissions === "1") {
          setErrorMessage([
            'Ваше разрешение на генерацию NFT-персонажа уже было использовано. Повторная генерация невозможна.',
          ]);
          setAction();
        } else if (!infoDB?.nft_permissions_json) {
          setErrorMessage([
            'На данный момент, к сожалению, мы не нашли разрешения для генерации NFT-персонажа для вашего пользователя.',
          ]);
          setAction();
        } else {
          setAction();
        }
      } else {
        setErrorMessage([
          'На данный момент, к сожалению, генерация NFT-персонажа разрешена только для пользователей типа "Genesis".',
        ]);
      }
    })();
    // Специально оставляем пустые скобки,
    // чтобы данная функция запустилось только при создании компонента
  }, [setNextUserStep, getPolygonUserInfo, userInfo?.is_genesis])

  const nftGenesis = useMemo(() => nftInfo?.attributes?.some((x) => x.trait_type === 'STAND' && x.value === 'STAND_GENESIS'), [nftInfo?.attributes]);
  const isGenesis = useMemo(() => nftGenesis || userInfo?.is_genesis, [nftGenesis, userInfo?.is_genesis]);
  const name = useMemo(() => isGenesis ? <GenesisIcon /> : <LegendaryIcon />, [isGenesis]);
  const lock = useMemo(() => isGenesis && [10, 11].includes(userMakeNftStage) ? <Lock2Icon /> : <LockIcon />, [isGenesis, userMakeNftStage]);

  console.log({
    _f: 'src-components-routes-ambassadors-club-my-ntf-my-nft-page-tsx---MyNFTPage-351 -- render',
    userMakeNftStage,
    userStep,
    nftGenesis,
    isGenesis
  });

  return (
    <main>
      <div className="wrap nft-persona-page">
        <Spin spinning={loading}>
          <MobileHeader />
          <CatalogHeader
            pageName={t('LEFT_MENU_AMBASSADORS_CLUB/MY_NFT')}
            parentPageName={t('LEFT_MENU_AMBASSADORS_CLUB')}
            parentPageLink={ROUTE_AMBASSADORS_CLUB}
            withPaySubscription={false}
          />
          <div className='flex flex-col 2xl:flex-row gap-4 w-full h-full min-h-fit'>
            <div className="info__card">
              <div className="info-content">
                <div className="info-repeat">
                  <div className={classNames(
                    'nftInfo',
                    isUserHaveNft ? 'flex' : 'hide',
                    showPlayer ? 'player' : '',
                  )}>
                    <div className='wrapper-block'>
                      <div className="image">
                        <img src={nftInfo?.base64uri} alt="NFT-персонаж" />
                        {nftInfo?.tokenId && (
                          <div className="buttons">
                            <Button
                              className="btn-add-nft-token"
                              onClick={(e) => addNFTToWallet()}
                            >
                              Добавить NFT в MetaMask
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="dop-info-wrapper">
                        <div className="dop-info">
                          <div className="attributes">
                            <div className="text-center mb-2 font-bold">Метаданные вашего NFT персонажа:</div>
                            {nftInfo?.attributes?.map((attr) => {
                              const percent = getNftGenesisUniqueValue(attr.value);
                              return (
                                <div className="attribute_block name flex flex-row justify-between" key={attr.trait_type}>
                                  <div>{attr.value}{/*{percent ? <>:&nbsp;</> : ''}*/}</div>
                                  {percent && (<div style={{ width: '60px', textAlign: 'right' }}>{percent}%</div>)}
                                </div>
                              )
                            })}
                          </div>
                          <div className="info">
                            <div className="info_block name">{name}</div>
                            <div className="info_block text id"><strong>Название:<br className="lg-hide" /></strong>{nftInfo?.name}</div>
                            <div className="info_block text id"><strong>ID токена: </strong>{nftInfo?.tokenId}</div>
                            <div className="info_block text ext-url">
                              <div className="ext-url-title">
                                <strong>Внешние ссылки на NFT:</strong>
                              </div>
                              <div className="ext-url-link ml-4">
                                &mdash;&nbsp;&nbsp;<Link to={getExternalLink(NFT_TOKEN_POLYGON_SCAN, nftInfo?.tokenId)} target="_blank" className="underline">
                                  Polygon Scan
                                </Link>
                              </div>
                              <div className="ext-url-link ml-4">
                                &mdash;&nbsp;&nbsp;<Link to={getExternalLink(NFT_TOKEN_ELEMENT_MARKET, nftInfo?.tokenId)} target="_blank" className="underline">
                                  Elements Market
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="info-text">
                            <h1>NFT Genesis SMAC</h1>
                            <strong>Описание:</strong><br />
                            Держатель NFT является резидентом, SoulMate Ambassador’s Club - это закрытый клуб, который является управленческим аппаратом, «сердцем» DAO SoulMate Ecosystem. Резиденты клуба, Амбассадоры, занимают ключевую позицию во всей экосистеме SoulMate, являются ее акционерами, а также имеют доступ к множеству привилегий.
                            Держатель NFT Ambassador имеет:
                            <ul className="list-disc list-outside mx-8">
                              <li>Цифровой договор с SME;</li>
                              <li>Статус резидента закрытого - the SoulMate Ambassador’s Club;</li>
                              <li>Доступ к «Амбассадрской программе»;</li>
                              <li>Ежеквартальные вознаграждения дивидендов DAO;</li>
                              <li>Участие в Airdrop, раздача токенов SOUL (White List 1,2 и 3);</li>
                              <li>Силу голоса и влияние на развитие DAO;</li>
                              <li>Развитие в рамках Корпоративного Университета.</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*{nftInfo?.tokenId && (
                      <div className="buttons">
                        <Button
                          className="btn-add-nft-token"
                          onClick={(e) => addNFTToWallet()}
                        >
                          Добавить NFT в MetaMask
                        </Button>
                      </div>
                    )}*/}
                  </div>
                  <div className={classNames(
                    "wrapper-block p-14 flex flex-col gap-4 items-center ",
                    !isUserHaveNft ? 'flex' : 'hide'
                  )}>
                    <div className="type-of-person">
                      { name }
                    </div>
                    <div className="lock-of-person">
                      { lock }
                    </div>
                    <div className="btn-mint-wrapper">
                      {buttonText.length > 2 && (
                        <Button
                          className="btn-mint"
                          onClick={(e) => buttonAction(e)}
                          disabled={(!hasNFT && !isGenesis) || isDisabledButton || loading}
                        >
                          { buttonText }
                        </Button>
                      )}
                    </div>
                    <div className="error-wrapper">
                      {errorMessage && errorMessage?.length > 0 && (
                        <>
                          <div className="flex flex-col gap-1">
                            {errorMessage?.map((x) => <div className="" key={x}>{x}</div>)}
                          </div>
                          {!hasNFT && (!userInfo?.is_genesis || (!userPolygonInfoDB?.nft_permissions_json && !userPolygonInfoDB?.nft_permissions)) && (
                            <div className="btn-has-nft-wrapper">
                              <Button
                                className="btn-has-nft"
                                onClick={onHasNFT}
                                disabled={loading}
                              >
                                У меня уже есть NFT-персонаж
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
      <NftPlayer
        showPlayer={showPlayer}
        handleClose={videoNFTClose}
        url="9ceb1cc055da587666157e06ce0b6a3c075d8ba6cdcb86cb49ad0e879f6cf3c4"
        img={nftInfo?.base64uri}
      />
    </main>
  );
}

export default MyNFTPage;
