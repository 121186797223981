import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import React, { Component } from 'react';

import "./styles.less";

class UniversityPage extends Component {
  render() {
    return (
      <main className="university-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            pageName='Корпоративный университет'
            withPaySubscription={false}
          />
          <div className="info__card description">
            {/*<h2 className="pb-1 text-lg">Описание:</h2>*/}
            <p>Корпоративный университет  - пространство, в котором мы растим новое поколение богатых цифровых млм предпринимателей!</p>
            <p>Наш девиз - богатыми тебя делают знания и навыки.</p>
            <p>Система Корпоративного университета включает в себя совокупность различных обучающих, тренинговых, командообразующих и трансформационных мероприятий благодаря которым человек, ставший Амбассадором SoulMate может с нуля прокачать максимум навыков предпринимателя новой цифровой эры, существенно изменить мышление и мировоззрение и создать масштабный международный бизнес.</p>
            <p>В корпоративном университете мы проводим обучение в соответствии с движением Амбассадора по карьерной лестнице, даем знания, необходимые для построения масштабного бизнеса, полную инструкцию и дорожную карту. Помогаем пройти путь достижения результатов совместно с тренерами-практиками и криптозаботливыми наставниками. Корпоративный университет формирует поддерживающее окружение, которое помогает пройти путь личностного роста максимально быстро и эффективно, создает рабочее пространство для развития, формирования и отработки необходимых навыков предпринимателя WEB 3.</p>
            <p>В Университете проходят тренинги, ретриты, семинары, мастер-классы и иные мероприятия, позволяющие нашим Амбассадорам расти.</p>
          </div>
        </div>
      </main>
    );
  }
}

export default UniversityPage;
