import FormWithMessage from '@common/formWithMessage';
import api from '@services/api';
import { FORM_NEW_PASSWORD, FORM_RESET_PASSWORD, ROUTE_ROOT } from '@src/constants';
import { getMessageFromRequest, getSearchParamsAsObject } from '@src/helpers';
import { setAppStage } from '@state/appStage';
import { setUserExtraInfo } from '@state/user';
import { useEffect, useState } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { Link, withRouter } from 'react-router-dom';

const VerifyResetPassword = ({ setFormType, formRef, history, location, setAppStageData, setUserInfo, t }) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [textMessage, setTextMessage] = useState(<>К сожалению, мы не смогли проверить указанный вами ключ для смены пароля</>);

  const { token } = getSearchParamsAsObject(location);

  useEffect(() => {
    const verify = async () => {
      if (token) {
        const {status, data} = await api.verifyResetPassword(token);

        setLoading(false)

        if (status === 200) {
          if (data?.status === 0) {
            setFormType(FORM_NEW_PASSWORD);
          } else {
            setTextMessage(getMessageFromRequest(data));
            setIsError(data?.status !== 0);
          }
        } else {
          setIsError(true);
        }
      }
    };
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRepeatResetPassword = () => {
    setFormType(FORM_RESET_PASSWORD);
    history.push(ROUTE_ROOT);
  }

  return (
    <FormWithMessage
      setFormType={setFormType}
      isError={isError}
      isSuccess={false}
      textMessage={textMessage}
      additionalLink={
        <Link to={ROUTE_ROOT} className='underline' onClick={handleRepeatResetPassword}>
          запросить новый ключ
        </Link>
      }
    >
      <div className='flex flex-row justify-between'>
        <div>Ваш ключ для смены пароля проверяется ...</div>
        <Spin spinning={loading} />
      </div>
    </FormWithMessage>
  );
}

const mapDispatchToProps = {
  setAppStageData: setAppStage,
  setUserInfo: setUserExtraInfo,
};

export default connect(null, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      VerifyResetPassword
    )
  )
);
