import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Drawer } from 'antd';

import { closeDrawer } from '@state/drawer';
import store from '@state/store';

class AppDrawer extends Component {
  handleDrawerClose = () => {
    const { setCloseDrawer } = this.props;

    store.dispatch(setCloseDrawer());
  }

  render() {
    const { showDrawer, drawerContent, drawerContentParams } = this.props;

    if (!drawerContent) return null;

    const DrawerContent = drawerContent;
    return (
      <Drawer
        placement='right'
        open={showDrawer}
        width={670}
        onClose={this.handleDrawerClose}
      >
        <DrawerContent
          {...drawerContentParams}
        />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  showDrawer: state.drawer.showDrawer,
  drawerContent: state.drawer.drawerContent,
  drawerContentParams: state.drawer.params,
});

const mapDispatchToProps = {
  setCloseDrawer: closeDrawer,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AppDrawer
);
