import Settings from '@common/drawer/settings';
import api from '@services/api';
import store from '@state/store';
import { Form, message, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as CloseIcon } from '@ui/icons/close.svg';

import {
  closeDrawer,
  setDrawerContent,
} from '@state/drawer';

class Ga2fa extends Component {
  state = {
    qrCodeUrl: undefined,
    qrCodeSecret: undefined,
    loading: true,
    error: undefined,
  }

  componentDidMount() {
    this.generate2fa();
  }

  generate2fa = async () => {
    const {status, data } = await api.generateUser2fa();

    if (status === 200 && data.status === 0) {
      this.setState({
        qrCodeUrl: data.url,
        qrCodeSecret: data.secret,
      })
    }

    this.setState({ loading: false });
  }

  verify2fa = async (token) => {
    this.setState({ loading: true });

    const {status, data } = await api.verifyUser2fa({ token });

    if (status === 200 && data.status === 0) {
      await api.updateUserExtraInfo();
      message.success("Подключение 2-х-факторной аутентификации через Google Authenticator прошло успешно.", 6)
      this.closeModal();
    } else {
      this.setState({ error: data.message })
    }

    this.setState({ loading: false });
  };

  onSubmitHandler = (values) => {
    this.verify2fa(values.token);
  };

  closeModal = () => {
    const { updateDrawerContent } = this.props;

    store.dispatch(
      updateDrawerContent({
        content: Settings,
      })
    );
  }

  render() {
    const { setCloseDrawer, userInfo } = this.props;
    const { qrCodeUrl, qrCodeSecret, loading, error } = this.state;

    return (
      <div id="modal4" className="modal4 feedback-modal settings 2fa modal" key={`1fa_${userInfo?.id}`}>
        <div className="modal__block-close">
          <CloseIcon onClick={setCloseDrawer} />
        </div>
        <Spin spinning={loading}>
          <div className="modal__window">
            <div className="modal__head">
              <h2 className="text-xl">2-х-факторная аутентификация</h2>
            </div>
            <div className="py-6 space-y-4">
              <h4 className="text-base text-ct-blue-600 font-medium border-b mb-2">
                Настройка приложения "Google Authenticator"
              </h4>
              <div className="space-y-1 text-sm list-decimal">
                <li>
                  Установите приложение на телефон/планшет (если у вас его еще нет):
                  <div className="mx-8 p-2 flex flex-row gap-4 justify-center">
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target="_blank" rel="noreferrer">
                      <img className="app w-[100px]" src="/images/android.png" alt="android" />
                    </a>
                    <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank" rel="noreferrer">
                      <img className="app w-[100px]" src="/images/iphone.png" alt="iphone" />
                    </a>
                  </div>
                </li>
                <li>Откройте приложение и нажмите на кнопку "+".</li>
                <li className="list-inside">Выберите один из вариантов:
                  <ul className="list-disc list-outside mx-8">
                    <li>
                      Нажмите "Сканировать QR-код" и используйте камеру телефона, чтобы отсканировать код, расположенный ниже.
                      <div className="flex justify-center">
                        <img
                          className="block w-64 h-64 object-contain my-4"
                          src={qrCodeUrl}
                          alt="qrcode url"
                        />
                      </div>
                    </li>
                    <li>
                      Нажмите "Ввести ключ настройки" и введите код "{qrCodeSecret}".
                    </li>
                  </ul>
                </li>
              </div>
              <h4 className="text-base text-ct-blue-600 font-medium border-b mb-2">Проверка кода из приложения</h4>
              <p className="text-sm">
                Пожалуйста, введите код из приложения "Google Authenticator", чтобы мы могли убедиться, что вы все правильно установили и настроили:
              </p>
              <Form onFinish={this.onSubmitHandler} initialValues={{token: undefined}}>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col justify-center text-center items-center">
                    <Form.Item name='token'
                               label={false}
                               className="form__block !w-2/4 !mb-0"
                               validateTrigger={['onBlur', 'onChange']}
                               rules={[
                                 {required: true, message: 'Пожалуйста, введите код', validateTrigger: 'onBlur'},
                                 {pattern: /^[\d]*$/, message: 'Разрешены только цифры'},
                                 {pattern: /^\d{6}$/, message: 'Должно быть 6 цифр', validateTrigger: 'onBlur'},
                               ]}>
                      <input
                        className="bg-gray-50 border border-solid border-gray-300 !text-gray-900 /*text-sm*/ focus:border-purple-500 focus:border-purple-500 block p-2.5"
                        placeholder="Код аутентификации"
                      />
                    </Form.Item>
                    {error && (
                      <p className="!mt-0 !text-red-600">
                        {error || null}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-row justify-between gap-4">
                    <button className="standart-btn !w-auto" type="submit">
                      <div>Проверить введенный код и активировать настройку</div>
                    </button>
                    <button className="standart-btn standart-btn-border !w-auto" onClick={this.closeModal}>
                      <div>Отменить</div>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  drawer: state.drawer,
});

const mapDispatchToProps = {
  setCloseDrawer: closeDrawer,
  updateDrawerContent: setDrawerContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Ga2fa
);
