import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from '@common/avatar';

import { ReactComponent as CuratorIcon } from '@ui/icons/curator.svg';
import { ReactComponent as SocialNetTelegramIcon } from '@ui/icons/socialNetTelegram.svg';

import './styles.less';

class Curator extends Component {
  render() {
    const { userInfo, showTelegramLink } = this.props;

    if (!userInfo || userInfo.id === null || userInfo.id === undefined || !userInfo.curator || !userInfo.curator.id) {
      return null;
    }

    const curatorUserInfo = userInfo.curator;
    const text = [];
    if (curatorUserInfo?.first_name?.length) {
      text.push(curatorUserInfo.first_name);
    }
    if (userInfo?.last_name?.length) {
      text.push(curatorUserInfo.last_name);
    }
    if (text.length === 0) {
      text.push(curatorUserInfo.telegram);
    }

    return (
      <div className="curator">
        <div className="tab-item active">
          <CuratorIcon />
          Куратор
        </div>
        <div className="curator-content">
          <div className="curator-user">
            <div className="curator-user-img">
              <Avatar size={40} user={curatorUserInfo} avatar={{ url_40: curatorUserInfo.avatar }}/>
            </div>
            <div className="curator-user-text">
              {/*<div className="curator-user-status">
                {userData.curator.status}
              </div>*/}
              <div className="curator-user-name">
                {text.join(' ')}
              </div>
            </div>
          </div>
          {showTelegramLink && (
            <div className="curator-socs">
              <button role='link'>
                <SocialNetTelegramIcon link={curatorUserInfo.telegram} />
              </button>
            </div>
          )}
          {curatorUserInfo.email?.length && (
            <div className="curator-mail">
              <span>E-mail:</span>
              <Link to={`mailto:${curatorUserInfo.email}`}>
                {curatorUserInfo.email}
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  Curator
);
