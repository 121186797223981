export default function getSearchParamsAsObject(location) {
  const { search } = location;

  const searchObjects = {};

  search?.replace(/^\?/, '').split('&').forEach((x) => {
    const [name, value] = x.split('=');
    searchObjects[name] = value;
  })

  return searchObjects;
}
