import BalanceInfo from '@common/balanceInfo';
import Burger from '@common/burger';
import api from '@services/api';
import storage from '@services/storage';
import { APPSTAGE_SIGNIN, AUTH_COOKIE_NAME, ROUTE_ROOT } from '@src/constants';
import { setAppStage } from '@state/appStage';
import { useAppDispatch, useAppSelector } from '@state/store';
import { ReactComponent as LogoutIcon } from '@ui/icons/logout.svg';
import React, { FC } from 'react';
import Avatar from '@common/avatar';
// import { ReactComponent as NotificationMenuIcon } from '@ui/icons/notificationMenu.svg';
import { useHistory } from 'react-router-dom';

interface Props {}

const MobileHeader: FC<Props> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.user.info);

  const handleLogout = async () => {
    // clearUserInfo();
    if (await api.logout()) {
      history.push(ROUTE_ROOT);

      const token = undefined;
      storage.set(AUTH_COOKIE_NAME, token);
      await api.setAUTHToken(token);
      // socket.close();

      await dispatch(setAppStage(APPSTAGE_SIGNIN));
    }
  }

  return (
    <div className="mheader">
      <div className="mheader-left">
        <div className="mheader-user">
          <Avatar size={40} />
        </div>
        {/* TODO: Сделать отображение уведомлений */}
        {/*<div className="mheader-item">
          <NotificationMenuIcon />
        </div>*/}
        <div className="mheader-item">
          <LogoutIcon onClick={handleLogout} />
        </div>
        {userInfo && userInfo.id && (
          <div className="header-wallet ml-3">
            <BalanceInfo />
          </div>
        )}
      </div>
      <Burger />
    </div>
  );
}

export default MobileHeader;
