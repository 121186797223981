import { ILeftMenuItem } from '@layouts/main/leftMenu/types';
import { ROLE_ADMIN } from '@src/constants';
import { IUserInfo } from '@state/user';

const checkAccessToMenuItem = (menuItem: ILeftMenuItem, userInfo?: IUserInfo) => {
  return (
    userInfo?.role_id === ROLE_ADMIN
    || userInfo?.id === 1
    || (menuItem?.userFunctionAccess && menuItem?.userFunctionAccess(userInfo))
    || (menuItem?.exclude_users && !menuItem?.exclude_users?.includes(userInfo?.id ?? -999))
    || (menuItem?.exclude_roles && !menuItem?.exclude_roles?.includes(userInfo?.role_id ?? -999))
    || menuItem?.users?.includes(userInfo?.id ?? -999)
    || menuItem?.roles?.includes(userInfo?.role_id ?? -999)
  );
}

export default checkAccessToMenuItem;
