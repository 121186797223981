export const PERIOD_1_DAY = 'PERIOD_1_DAY';
export const PERIOD_1_WEEK = 'PERIOD_1_WEEK';
export const PERIOD_1_MONTH = 'PERIOD_1_MONTH';
export const PERIOD_1_QUARTER = 'PERIOD_1_QUARTER';
export const PERIOD_1_YEAR = 'PERIOD_1_YEAR';
export const PERIOD_ALL = 'PERIOD_ALL';

export const PERIOD_DELTA_PERIOD_1_DAY = '-1 day';
export const PERIOD_DELTA_PERIOD_1_WEEK = '-1 week';
export const PERIOD_DELTA_PERIOD_1_MONTH = '-1 month';
export const PERIOD_DELTA_PERIOD_1_QUARTER = '-1 quarter';
export const PERIOD_DELTA_PERIOD_1_YEAR = '-1 year';
export const PERIOD_DELTA_PERIOD_ALL = undefined;

export const PERIOD_LIST = [
  PERIOD_1_DAY,
  PERIOD_1_WEEK,
  PERIOD_1_MONTH,
  PERIOD_1_QUARTER,
  PERIOD_1_YEAR,
  PERIOD_ALL,
];

export const TRANSACTION_CURRENCY_0 = 0;
export const TRANSACTION_CURRENCY_1 = 1;
export const TRANSACTION_CURRENCY_2 = 2;

export const TRANSACTION_CURRENCY_LIST = [
  TRANSACTION_CURRENCY_0,
  TRANSACTION_CURRENCY_1,
  TRANSACTION_CURRENCY_2,
];
