import React, { Component } from 'react';
import api from '@services/api';
import { SoulEye } from '@common/soulEye';
import { SoulHideBlock } from '@common/soulHideBlock';
import { DECIMAL_SCALE } from '@src/constants';
import { Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';

import { ReactComponent as MoneyIcon } from '@ui/icons/money.svg';

import './styles.less';

class MoneyStatistic extends Component {
  state = {
    allMoney: undefined,
    loading: true,
    eyeHiddenInfo: false,
  }

  componentDidMount() {
    this.getAllMoney();
  }

  getAllMoney = async () => {
    this.setState({ loading: true });
    const { status, data } = await api.getAllMoney();

    if (status === 200 && data.status === 0) {
      this.setState( { allMoney: data.allMoney?.USDT || [] })
    }

    this.setState({ loading: false });
  }

  eyeChange = () => this.setState((prevState) => ({ eyeHiddenInfo: !prevState.eyeHiddenInfo }))

  render() {
    const { userInfo, t } = this.props;
    const { allMoney, loading, eyeHiddenInfo } = this.state;

    if (!userInfo || userInfo.id === null || userInfo.id === undefined) {
      return null;
    }

    return (
      <div className="moneyStatistic">
        <SoulEye eyeHiddenInfo={eyeHiddenInfo} eyeChange={this.eyeChange} title='Показать/спрятать показатели в блоке' />
        <Spin spinning={loading}>
          <div className="flex flex-row gap-2 items-center text-lg font-[500]">
            <MoneyIcon />
            Статистика заработка за все время (USDT)
          </div>
          <div className="content">
            <div className="money-info items-center">
              <div className="type">
                {t('TRANSACTION_TYPE_SHORT_7')}:
              </div>
              <div className="sum">
                <strong>
                  <NumericFormat
                    value={allMoney?.type_7?.sm || 0}
                    displayType={'text'}
                    decimalScale={DECIMAL_SCALE}
                    thousandSeparator=" "
                  />
                </strong>
                <SoulHideBlock hidden={eyeHiddenInfo} eyeChange={this.eyeChange} />
              </div>
              {/*<div className="currency">
                USDT
              </div>*/}
            </div>
            <div className="money-info items-center">
              <div className="type">
                {t('TRANSACTION_TYPE_SHORT_8')}:
              </div>
              <div className="sum">
                <strong>
                  <NumericFormat
                    value={allMoney?.type_8?.sm || 0}
                    displayType={'text'}
                    decimalScale={DECIMAL_SCALE}
                    thousandSeparator=" "
                  />
                </strong>
                <SoulHideBlock hidden={eyeHiddenInfo} eyeChange={this.eyeChange} />
              </div>
              {/*<div className="currency">
                USDT
              </div>*/}
            </div>
            <div className="money-info items-center">
              <div className="type">
                ИТОГО:
              </div>
              <div className="sum">
                <strong>
                  <NumericFormat
                    value={allMoney?.all || 0}
                    displayType={'text'}
                    decimalScale={DECIMAL_SCALE}
                    thousandSeparator=" "
                  />
                </strong>
                <SoulHideBlock hidden={eyeHiddenInfo} eyeChange={this.eyeChange} />
              </div>
              {/*<div className="currency">
                USDT
              </div>*/}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    MoneyStatistic
  )
);
