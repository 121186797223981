import { FC } from 'react';
import React from 'react';

interface IParams {
  hidden?: boolean;
  title?: string;
  eyeChange?: () => void;
}

export const SoulHideBlock: FC<IParams> = ({ eyeChange, title, hidden = false }) => {
  return hidden
    ? <div className='soul-eye-hide-block' title={title || undefined} onClick={eyeChange} />
    : null
}
