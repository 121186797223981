import { toggleBurger } from '@state/burger';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import "./styles.less"

class Burger extends Component {
  render() {
    const { clickToggleBurger } = this.props;

    return (
      <button
        type = 'button'
        className="burger"
        onClick = {() => clickToggleBurger()}
      >
        <div></div>
        <div></div>
      </button>
    );
  }
}

const mapDispatchToProps = {
  clickToggleBurger: toggleBurger,
}

export default connect(null, mapDispatchToProps)(
  Burger
);
