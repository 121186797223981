import React, {Component} from "react";
import {Input, Modal} from "antd";
import exportCSV from "@common/exportCSV/index";

/**
 * ModalExportCSV модальное окно, запрашивающее имя файла и скачивающее файл.
 * @param {Array[Array]} dataFunc - prop callback для получения пользовательских данных.
 */
class ModalExportCSV extends Component {

    constructor(props) {
        super(props);
        this.state = { fileName: props.fileName ?? "" }
    }

    async handleOk(cols, data) {
        const d = await data();
        this.downloadCSV(this.state.fileName, cols, d);
    }

    updateInput(ev) {
        this.setState({ fileName: ev.target.value })
    }

    downloadCSV(name = "soulmate_user_list", cols = [], data = []) {
        let [ fileName, fileData ] = exportCSV({name: name, columns: cols, data: data});
        let link = document.createElement('a');
        link.setAttribute('href', fileData);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    render() {
        const modal = this.props;
        const { fileName } = this.state;

        const [getModalOpen, modalClose, cols, data] = [modal.getModalOpen, modal.modalClose, modal.cols, modal.data];


        return (
            <Modal
                zIndex={1010}
                title="Название файла"
                open={getModalOpen}
                onCancel={modalClose}
                onOk={() => {this.handleOk(cols, data).then(modalClose)}}
            >
                <Input
                  placeholder="Введите название файла"
                  defaultValue={fileName}
                  onChange={(ev) => {this.updateInput(ev)}}
                />
            </Modal>
        )
    }

}

export default ModalExportCSV;