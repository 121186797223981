import React from 'react';

export default function getMessageFromRequest(data, defaultMessage = '') {
  if (data?.message?.length) {
    return Array.isArray(data.message)
      ? data.message.map((x) => <div key={x}>{x}</div>)
      : data.message
  }
  const keys = data?.error ? Object.keys(data?.error) : [];
  if (keys.length) {
    return data.error[keys[0]];
  }
  if (defaultMessage !== '') {
    return defaultMessage;
  }
  return data?.status === 0
    ? 'Поздравляем, операция прошла успешно!'
    : 'К сожалению, во время выполнения операции возникла ошибка';
}
