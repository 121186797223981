export const handleMoveToElement = (elSelector) => {
  const el = document.querySelector(elSelector);
  el && el.scrollIntoView({ behavior: 'smooth' });
}

export const openInNewTab = (url, isNewTab = true) => {
  isNewTab
    ? window.open(url, '_blank', 'noopener,noreferrer')
    : window.open(url);
};
