import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const showTimeReminder = (t, loading, endDate, deltaDays, deltaHours, deltaMinutes) => (
  loading
    ? <Spin className="!ml-2 !-mt-3" indicator={<LoadingOutlined style={{ fontSize: 12 }} spin />} />
    : (
      endDate
        ? (
          deltaDays >= 0 && deltaHours >= 0 && deltaMinutes >= 0
            ? t('deltaDays', { count: deltaDays || 0 }).concat(' ', t('deltaHours', { count: deltaHours || 0 }), ' ', t('deltaMinutes', { count: deltaMinutes || 0 }))
            : <>время вышло</>
        )
        : <>&mdash;</>
    )
)

export default showTimeReminder;
