// TODO переписать helper работы с localStorage
// eslint-disable-next-line max-classes-per-file
class DummyStorage {
  get(defaultValue) {
    return defaultValue;
  }

  set() {
    // TODO set value
  }

  remove() {
    // TODO remove the value
  }
}

class LocalStorage {
  get(name) {
    const value = localStorage.getItem(name);
    let result;

    if (value !== undefined && value !== 'undefined' && value !== null && value !== 'null' && value !== '{}') {
      try {
        result = JSON.parse(value);
      } catch (e) {
        console.error(e);
      }
    }

    return result;
  }

  set(name, value) {
    let result;

    try {
      result = JSON.stringify(value);
    } catch (e) {
      console.error({ _f: 'LocalStorage-set-76', e });

      result = null;
    }

    localStorage.setItem(name, result);
  }

  remove(name) {
    localStorage.removeItem(name);
  }
}

const storage = localStorage
  ? new LocalStorage()
  : new DummyStorage();

export default storage;
