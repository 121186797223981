import React, { useState } from 'react';
import LoginRoute from '@routes/login';
import LandingLayout from '@components/landing';
import GoogleAuthForm from '@common/googleAuthForm';

const LoginLayout = (props) => {
  const { form } = props;
  const [formType, setFormType] = useState(form);

  return (
    <>
      <LandingLayout setFormType={setFormType} />
      <LoginRoute formType={formType} setFormType={setFormType}/>
      <GoogleAuthForm />
    </>
  );
}

export default LoginLayout;
