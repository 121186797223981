import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import { ROUTE_REPORT } from '@src/constants';

import "./styles.less";
import { Button, Input, message, Modal, Popconfirm, Space, Table } from "antd";
import api from "@services/api";
import { QuestionCircleOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ExportCSV from "@common/exportCSV/component";
import getRoleAdmin from "@src/helpers/getRoleAdmin";

class NextFlowPage extends Component {

  searchInput = createRef();

  state = {
    nextFlowList: [],
    productsList: [],
    loading: false,
    searchText: '',
    selectedKeys: '',
    searchedColumn: '',
    isEnrollmentProgressOpen: false,
    enrollmentProgressData: undefined,
    isEnrollmentProgressLoading: false,
  }

  componentDidMount() {
    this.getNextFlow();
    this.getEnrollmentProgress();
  }

  getNextFlow = async () => {
    this.setState({ loading: true })

    const {status, data} = await api.getReportNextFlow();

    if (status === 200 && data?.users) {
      let filters = [];
      if (data?.products) {
        for (let i of data?.products) {
          filters.push({text: i.name, value: i.name})
        }
      }
      this.setState({
        nextFlowList: data?.users,
        productsList: filters
      });
    }

    this.setState({ loading: false });
  }

  getEnrollmentProgress = async () => {
    this.setState({ isEnrollmentProgressLoading: true });

    const {status, data} = await api.getEnrollmentProgress();

    if (status === 200 && data?.data) {
      this.setState({ enrollmentProgressData: data?.data });
    }

    this.setState({ isEnrollmentProgressLoading: false });
  }

  updateNextFlow = async (flow) => {
    this.setState({ loading: true });

    const eduNewIds = [],
      eduMooverIds = [],
      eduRepeaterIds = [],
      stateList = this.state.nextFlowList;

    for (let i of stateList) {
      switch (i.group) {
        case 'Перенос':
          eduMooverIds.push(i.id);
          break;
        case 'Повтор':
          eduRepeaterIds.push(i.id);
          break;
        case 'Новичок':
          eduNewIds.push(i.id);
          break;
        default:
          break;
      }
    }

    const eduAllIds = [].concat(eduMooverIds, eduRepeaterIds, eduNewIds);

    const { status, data } = await api.updateNextFlow({
      edu_new_ids: eduNewIds,
      edu_moover_ids: eduMooverIds,
      edu_repeater_ids: eduRepeaterIds,
      edu_all_ids: eduAllIds,
      flow: flow,
    })

    if (status === 200 && data && data?.status !== null) {
      if (data.status === 0) {
        message.success(data?.message)
      } else {
        message.error(data?.message)
      }
      this.getEnrollmentProgress();
    }

    this.setState({ loading: false });
  }

  getCurrentFlow = async () => {
    this.setState({ loading: true })

    const updateFunc = this.updateNextFlow
    const { status, data } = await api.getCurrentFlow()

    this.setState({ loading: false })

    if (status === 200 && data) {
      switch (data?.status) {
        case 0:
          Modal.confirm({
            title: data?.message,
            content: (
              <>
                <p>Название: <span className='font-semibold'>{data?.data.name}</span></p>
                <p>Даты: {data?.data.date_start} - {data?.data.date_end}</p>
              </>),
            onOk() {updateFunc(data.data)},
            onCancel() {},
          })
          break;
        case 10:
          Modal.info({
            title: data?.message,
            content: (<>{data?.data.map((course, i) => {
              return (
                <>
                  <p>Название: <span className='font-semibold'>{course.name}</span></p>
                  <p>Даты: {course.date_start} - {course.date_end}</p>
                </>
              )
            })}</>),
            onOk() {},
          })
          break;
        default:
          Modal.error({
            title: data?.message,
            onOk() {},
          })
          break;
      }
    } else {
      message.warning('Сервер Образовательной Платформы не отвечает')
    }
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.setState({
      searchText: '',
      searchedColumn: ''
    });
    confirm();
  };

  getColumnSearchProps = (dataIndex, nameHolder) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={this.searchInput}
          placeholder={`Поиск по ${nameHolder}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{
              width: 90,
            }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Сбросить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.current?.select(), 100);
      }
    }
  })

  enrollmentProgress = (state) => {
    this.setState({ isEnrollmentProgressOpen: state });
  }

  getFlowForCSV = async () => {
    this.setState({ loading: true })

    const {status, data} = await api.getCurrentFlow();

    if (status === 200 && data?.status === 0 && data?.data?.id) {
      const result = await this.getExportCSVByFlowData(data.data.id)
      this.setState({ loading: false });
      return result;
    } else {
      message.warning(data?.message)
      this.setState({ loading: false });
      return [];
    }
  }

  getExportCSVByFlowData = async (flow) => {
    this.setState({ loading: true })

    const {status, data} = await api.getUsersSavedToFlow({flow_id: flow})

    console.log(status, data?.status)
    if (status === 200 && data?.status === 0) {
      this.setState({ loading: false })
      return data?.data
    } else {
      message.warning(data?.message)
      this.setState({ loading: false })
      return [];
    }
  }


  getExportCSVByTodayData = async () => {
    this.setState({ loading: true })

    const {status, data} = await api.getUsersSavedToFlow({today: true})

    if (status === 200 && data?.status === 0) {
      this.setState({ loading: false })
      return data?.data
    } else {
      message.warning(data?.message)
      this.setState({ loading: false })
      return [];
    }
  }

  render() {
    const {t, userInfo} = this.props;
    const {nextFlowList, loading,
      isEnrollmentProgressOpen, isEnrollmentProgressLoading, enrollmentProgressData
    } = this.state;

    const isNotExistJobs = enrollmentProgressData === undefined || enrollmentProgressData.length === 0;

    const columnsCSV = [
      {
        title: 'N',
        dataIndex: 'number',
      },
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Группа',
        dataIndex: 'group',
      },
      {
        title: 'Имя',
        dataIndex: 'first_name',
      },
      {
        title: 'Фамилия',
        dataIndex: 'last_name',
      },
      {
        title: 'Почта',
        dataIndex: 'email',
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
      },
      {
        title: 'Телеграм',
        dataIndex: 'telegram',
      },
      {
        title: 'Дата покупки',
        dataIndex: 'date_bought',
      },
      {
        title: 'Пакет',
        dataIndex: 'product_name',
      },
      {
        title: 'Дата приглашения на БК',
        dataIndex: 'invite_to_base',
      },
      {
        title: 'Имя Куратора',
        dataIndex: 'curator_name',
      },
      {
        title: 'Фамилия Куратора',
        dataIndex: 'curator_surname',
      },
      {
        title: 'Почта Куратора',
        dataIndex: 'curator_email',
      },
      {
        title: 'Телефон Куратора',
        dataIndex: 'curator_phone',
      },
      {
        title: 'Телеграм Куратора',
        dataIndex: 'curator_telegram',
      },
    ];

    const columns = [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        render: (text, record, index) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">№:</div>
            <div className="column-content-block st-table-num column_id">{index+1}</div>
          </div>
        ),
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">ID:</div>
            <div className="column-content-block st-table-num column_id">{id}</div>
          </div>
        ),
        ...this.getColumnSearchProps('id', 'ID')
      },
      {
        title: 'Имя',
        dataIndex: 'first_name',
        key: 'first_name',
        render: (first_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Имя:</div>
            <div className="column-content-block st-table-imfo type column_type">{first_name}</div>
          </div>
        ),
        ...this.getColumnSearchProps('first_name', 'Имени')
      },
      {
        title: 'Фамилия',
        dataIndex: 'last_name',
        key: 'last_name',
        render: (last_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Фамилия:</div>
            <div className="column-content-block st-table-imfo type column_type">{last_name}</div>
          </div>
        ),
        ...this.getColumnSearchProps('last_name', 'Фамилии')
      },
      {
        title: 'Почта',
        dataIndex: 'email',
        key: 'email',
        render: (email) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Почта:</div>
            <div className="column-content-block st-table-imfo type column_type">{email}</div>
          </div>
        ),
        ...this.getColumnSearchProps('email', 'Почте')
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
        render: (phone) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телефон:</div>
            <div className="column-content-block st-table-imfo type column_type">{phone}</div>
          </div>
        ),
        ...this.getColumnSearchProps('phone', 'Телефону')
      },
      {
        title: 'Телеграмм',
        dataIndex: 'telegram',
        key: 'telegram',
        render: (telegram) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телеграмм:</div>
            <div className="column-content-block st-table-imfo type column_type">{telegram}</div>
          </div>
        ),
        ...this.getColumnSearchProps('telegram', 'Телеграмму')
      },
      {
        title: 'Дата покупки',
        dataIndex: 'date_bought',
        key: 'date_bought',
        render: (date_bought) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Дата покупки:</div>
            <div
              className="column-content-block st-table-imfo date column_date">{date_bought ? dayjs(date_bought).format('DD.MM.YY') : '-'}</div>
          </div>
        ),
      },
      {
        title: 'Пакет',
        dataIndex: 'product_name',
        key: 'product_name',
        render: (product_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Пакет:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{product_name}</div>
          </div>
        ),
        filters: this.state.productsList,
        onFilter: (value, record) => record.product_name === value
      },
      {
        title: 'Группа',
        dataIndex: 'group',
        key: 'group',
        render: (group) => (
          <div className="flex flex-row gap-1 column-row-block justify-center">
            <div className="block lg:hidden column-title-block">Группа:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{group}</div>
          </div>
        ),
        filters: [
          {
            text: 'Перенос',
            value: 'Перенос'
          },
          {
            text: 'Повтор',
            value: 'Повтор'
          },
          {
            text: 'Новичок',
            value: 'Новичок'
          }
        ],
        onFilter: (value, record) => record.group === value
      },
    ];

    const enrollmentProgressColumns = [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <div className="flex flex-row gap-1 column-row-block justify-center">
            <div className="block lg:hidden column-title-block">ID:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{id}</div>
          </div>
        ),
      },
      {
        title: 'Тип задачи',
        dataIndex: 'job_type_name',
        key: 'job_type_name',
        render: (job_type_name) => (
          <div className="flex flex-row gap-1 column-row-block justify-center">
            <div className="block lg:hidden column-title-block">Тип задачи:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{job_type_name}</div>
          </div>
        ),
      },
      {
        title: 'Статус',
        dataIndex: 'job_status_name',
        key: 'job_status_name',
        render: (job_status_name) => (
          <div className="flex flex-row gap-1 column-row-block justify-center">
            <div className="block lg:hidden column-title-block">Статус:</div>
            <div
              className="column-content-block st-table-imfo type column_type">{job_status_name}</div>
          </div>
        ),
      }
    ]

    return (
      <main className="report-user-list-page">
        <div className="wrap">
          <MobileHeader/>
          <CatalogHeader
            pageName={t('LEFT_MENU_REPORT/NEXT-FLOW')}
            parentPageName={t('LEFT_MENU_REPORT')}
            parentPageLink={ROUTE_REPORT}
            withPaySubscription={false}
          />
          <div className="flex flex-col gap-1">
            <form className="static__header static__headerm flex flex-col gap-4">
              <div className="static__head-block">
                <div className="flex flex-row gap-1 items-center justify-start">
                  <ExportCSV
                    getData={() => {return nextFlowList}}
                    columns={columnsCSV}
                    fileName='Список рассылки'
                  >
                    Экспорт CSV
                  </ExportCSV>

                  <ExportCSV
                    getData={() => {return this.getExportCSVByTodayData()}}
                    columns={columnsCSV}
                    fileName='Список рассылки (зачисленные сегодня)'
                  >
                    Экспорт CSV - зачисленные сегодня
                  </ExportCSV>

                  <ExportCSV
                    getData={() => {return this.getFlowForCSV()}}
                    columns={columnsCSV}
                    fileName='Список рассылки (весь поток)'
                  >
                    Экспорт CSV - весь поток
                  </ExportCSV>

                  {getRoleAdmin(userInfo) ? (
                    <Popconfirm
                      title={() => {
                        if (isNotExistJobs) {
                          return (<>Вы действительно хотите зачислить всех на БК?</>)
                        }
                        return (<>
                          Можно запустить только один процесс зачисления в день. <br/>
                          Статусы зачисления смотри по кнопке "Прогресс зачисления"
                        </>)
                      }
                    }
                      onConfirm={() => {
                        if (!isNotExistJobs) return;

                        const now = dayjs().format('DD');
                        if (now !== '10' && now !== '01') {
                          message.warning('Зачислить на поток можно только 1 и 10 числа каждого месяца');
                          return;
                        }
                        this.getCurrentFlow()
                      }}
                      okText={isNotExistJobs ? 'Да' : 'Ок'}
                      cancelText='Нет'
                      showCancel={isNotExistJobs}

                      icon={<QuestionCircleOutlined/>}
                    >
                      <Button>Зачислить на поток</Button>
                    </Popconfirm>
                  ) : ''}

                  <Button onClick={() => this.enrollmentProgress(true)}>Прогресс зачисления</Button>
                </div>
              </div>
            </form>
            <div className="static__contents !py-4">
              <div className="static__cont">
                <Table
                  dataSource={nextFlowList}
                  loading={loading}
                  columns={columns}
                  rowKey={(record) => record.id}
                  scroll={{x: 'max-content'}}
                  footer={() => {
                    return (<div>Количество записей: {nextFlowList.length}</div>)
                  }}
                  total={nextFlowList.length}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={isEnrollmentProgressOpen}
          footer={(
            <div className='flex justify-end'>
              <Button onClick={() => this.getEnrollmentProgress()}><SyncOutlined /> Обновить</Button>
              <Button onClick={() => this.enrollmentProgress(false)}>Ок</Button>
            </div>
          )}
          onCancel={() => this.enrollmentProgress(false)}
          width={'70%'}
        >
          <Table
            className='pt-4'
            dataSource={enrollmentProgressData}
            loading={isEnrollmentProgressLoading}
            columns={enrollmentProgressColumns}
            pagination={false}
            scroll={{x: 'max-content'}}
          />
        </Modal>
      </main>
    )
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  userFullInfo: state.user,
});

export default connect(mapStateToProps)(
  withTranslation()(
    NextFlowPage
  )
);
