import { createSlice } from '@reduxjs/toolkit';

const paramsDefault = {
  onlyOk: true,
  onlyOkText: 'Ок',
  isError: false,
  isSuccess: false,
  onOk: undefined,
  modalContentClass: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    showModal: false,
    modalTitle: null,
    modalContent: null,
    modalLoading: false,
    params: paramsDefault,
  },
  reducers: {
    closeModal: (state) => {
      state.showModal = false;
      state.modalContent = null;
      state.params = paramsDefault;
    },
    setModalContent: (state, action) => {
      state.modalContent = action.payload.content;
      state.modalTitle = action.payload.title || null;
      state.params = action.payload.params || paramsDefault;
      state.modalLoading = action.payload.modalLoading || false;
      state.showModal = true;
    },
  }
})

export const { setModalContent, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
