import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { FC } from 'react';
import React from 'react';

interface IParams {
  eyeHiddenInfo: boolean;
  title: string;
  eyeChange: () => void;
}

export const SoulEye: FC<IParams> = ({ eyeChange, title, eyeHiddenInfo = false }) => (
  <div className={`soul-eye soul-eye-${eyeHiddenInfo ? 'show' : 'hide'}`} title={title}>
    {
      eyeHiddenInfo
        ? <EyeOutlined onClick={eyeChange}/>
        : <EyeInvisibleOutlined onClick={eyeChange}/>
    }
  </div>
)
