export const INDEX_COL_SPAN_CURRENCY = '50px';
export const INDEX_COL_SPAN_ROLE = '100px';
export const INDEX_COL_SPAN_ALL_USER_SUM = '150px';
export const INDEX_COL_SPAN_SUM = '150px';
export const INDEX_COL_SPAN_ALL_COMMISSION = '200px';
export const INDEX_COL_SPAN_COMMISSION = '94px';

export const INDEX_COL_SPAN_USER_LIST_ID = '50px';
export const INDEX_COL_SPAN_USER_LIST_DATE = '85px';
export const INDEX_COL_SPAN_USER_LIST_AMOUNT_USER = '65px';
export const INDEX_COL_SPAN_USER_LIST_AMOUNT_COMMISSION = '75px';
export const INDEX_COL_SPAN_USER_LIST_AMOUNT = '75px';
export const INDEX_COL_SPAN_USER_LIST_RESULT_SUM = '75px';
export const INDEX_COL_SPAN_USER_LIST_STATUS = 'auto';
export const INDEX_COL_SPAN_USER_LIST_BUTTONS = '220px';
export const INDEX_COL_SPAN_USER_LIST_AUTO = 'auto';

export const INDEX_COL_SPAN_LIST_ID = '50px';
export const INDEX_COL_SPAN_LIST_DATE = '85px';
export const INDEX_COL_SPAN_LIST_SUM = '75px';
export const INDEX_COL_SPAN_LIST_COMMISSION = '75px';
export const INDEX_COL_SPAN_LIST_FIO = 'auto';

export const INDEX_PULLS_STATUS_OPEN            = 0;	  // Прием заявок открыт
export const INDEX_PULLS_STATUS_CLOSE           = 10;	  // Прием заявок завершен
export const INDEX_PULLS_STATUS_WAIT_SEND       = 20;	  // Ожидает отправки
export const INDEX_PULLS_STATUS_WAIT_BUY        = 30;	  // Ожидает покупки
export const INDEX_PULLS_STATUS_WAIT_RESULT     = 40;	  // Ожидает распределения
export const INDEX_PULLS_STATUS_END             = 100;	  // Завершен
export const INDEX_PULLS_STATUS_CANCEL_BY_ADMIN = 810;	  // Отменен администратором
export const INDEX_PULLS_STATUS_CANCEL_BY_TIME  = 820;	  // Отменен по истечению времени
export const INDEX_PULLS_STATUS_DELETED         = 999;	  // Удален

export const INDEX_REQUEST_STATUS_NEW = 0;                // Новая заявка
export const INDEX_REQUEST_STATUS_PAID = 10;              // Оплачена
export const INDEX_REQUEST_STATUS_WAIT = 20;              // Ожидает распределения
export const INDEX_REQUEST_STATUS_END = 100;              // Завершена
export const INDEX_REQUEST_STATUS_CANCEL_BY_USER = 800;   // Отменена пользователем
export const INDEX_REQUEST_STATUS_CANCEL_BY_ADMIN = 810;  // Отменена администратором
export const INDEX_REQUEST_STATUS_CANCEL_BY_TIME = 820;   // Отменена по истечению времени
export const INDEX_REQUEST_STATUS_DELETED = 999;          // Удалена

export const PROJECT_RESULT_DECIMAL_SCALE = 3;                  // Количество знаков для отображения результатов по проекту

/** Пользователи, которые считаются Админами данного раздела Bullran Index */
export const INDEX_ADMIN = [
  -1,
  0,
  1,
  2,
];
