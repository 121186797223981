import { SyncOutlined } from '@ant-design/icons';
import api from '@services/api';
import { getMessageFromRequest } from '@src/helpers';
import { useAppDispatch } from '@state/store';
import { IWalletInfo, updateWalletById } from '@state/user';
import { message } from 'antd';
import classNames from 'classnames';
import React, { FC, useState } from 'react';

import './styles.less';

interface OwnProps {
  wallet?: IWalletInfo;
  className?: string;
  callBack?: Function;
}

const ReloadWalletButton: FC<OwnProps> = ({ wallet, className, callBack }) => {
  const [reloading, setReloading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const getWalletBalance = async () => {
    if (!wallet) {
      message.error('К сожалению, произошла ошибка по время выполнения операции обновления баланса. Попробуйте повторить операцию позднее ...');
      return false;
    }

    setReloading(true);

    const {status, data} = await api.getWalletById(wallet.id);

    if (status === 200 && data?.status === 0 && data?.wallet?.id) {
      dispatch(updateWalletById(data.wallet))
      callBack && callBack();
    } else {
      message.error(
        getMessageFromRequest(data, 'К сожалению, произошла ошибка по время выполнения операции обновления баланса. Попробуйте повторить операцию позднее ...'),
        data?.status === 2000 ? 10 : 5
      );
    }
    setReloading(false);
  };

  return (
    <SyncOutlined
      onClick={getWalletBalance}
      className={classNames("!align-middle", className)}
      title="Обновить баланс по данному кошельку"
      spin={reloading}
    />
  );
}

export default ReloadWalletButton;
