import { Component } from "react";
import api from "@services/api";
import { message } from "antd";

class CreateUserOnEDU extends Component {

  createUserOnEDU = async (userId) => {
    const { status, data } = await api.createUserOnEDU({user_id: userId})

    if (status === 200 && data?.status === 0) {
      message.success(data?.message)
    } else {
      message.warning(data?.message ?? 'Ошибка сервера')
    }
  }

  render() {
    const { id } = this.props

    return (
      <button onClick={() => this.createUserOnEDU(id)}>
        Создать ученика на ОП
      </button>
    )
  }
}

export default CreateUserOnEDU