import FormWithMessage from '@common/formWithMessage';
import api from '@services/api';
import { FORM_REGISTRATION } from '@src/constants';
import { getMessageFromRequest } from '@src/helpers';
import { setAppStage } from '@state/appStage';
import { setUserExtraInfo } from '@state/user';
import { useState } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Input,  Spin } from 'antd';

const ResetPasswordForm = ({ setFormType, formRef, setAppStageData, setUserInfo, t }) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [textMessage, setTextMessage] = useState(<>Ошибка отправки повторного подтверждения. Проверьте правильность данных или повторите позднее</>);

  const handleRegistration = () => {
    setFormType(FORM_REGISTRATION);
    return false;
  }

  const handleSubmit = (val) => {
    formRef.current.validateFields().then(async (values) => {
      setLoading(true);

      const { status, data } = await api.resetPassword(values.email);

      setLoading(false);

      setTextMessage(getMessageFromRequest(data));
      if (status === 200) {
        setIsSuccess(data?.status === 0);
        setIsError(data?.status !== 0);
      } else {
        setIsError(true);
      }
    });
  };

  return (
    <FormWithMessage
      setFormType={setFormType}
      isError={isError}
      isSuccess={isSuccess}
      textMessage={textMessage}
    >
      <Form
        name='ResetPasswordForm'
        ref={formRef}
        layout='vertical'
        // initialValues={{ remember: true }}
        onFinish={handleSubmit}
        // className={styles.content}
      >
        <Spin spinning={loading}>
          <div className="form__content">
            <Form.Item name='email' label="Email" className="form__block"
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите адрес своей электронной почты'},
                         {validateTrigger: 'onBlur', min: 6, message: 'Минимальная длина поля - 6 символов'},
                         {max: 200, message: 'Максимальная длина поля - 100 символов'},
                         {pattern: /[@a-zA-Z0-9_.–—‐-]+/, message: 'В поле можно вводить только латинские буквы, цифры, точку, дефис и знак подчеркивания'},
                         {validateTrigger: 'onBlur', type: 'email', message: 'Пожалуйста, введите корректный адрес электронной почты'},
                       ]}>
              <Input placeholder='nickName@example.com'/>
            </Form.Item>
          </div>
          <button type='submit' className="standart-btn">
            <div>Восстановить</div>
          </button>
          <span className="form__next">
            У вас нет аккаунта?
            &nbsp;
            <button type='button' onClick={handleRegistration}>
              Зарегистрируйтесь
            </button>
          </span>
        </Spin>
      </Form>
    </FormWithMessage>
  );
}

const mapDispatchToProps = {
  setAppStageData: setAppStage,
  setUserInfo: setUserExtraInfo,
};

export default connect(null, mapDispatchToProps)(
  withTranslation()(
    ResetPasswordForm
  )
);
