import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import FeedBack from '@common/drawer/feedBack';
import { ROUTE_ROOT } from '@src/constants';
import { setDrawerContent } from '@state/drawer';
import store from '@state/store';
import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Modal, Spin } from 'antd';

import { closeModal } from '@state/modal';
import { Link, withRouter } from 'react-router-dom';

class AppModal extends Component {

  handleSendFeedBack = () => {
    const { history, location,  setCloseModal, updateDrawerContent } = this.props;

    // setFormType(FORM_FEEDBACK);

    store.dispatch(
      updateDrawerContent({
        content: FeedBack,
        params: {location: cloneDeep(location)},
      })
    );

    setCloseModal();
    history.push(ROUTE_ROOT);
  }

  render() {
    const { modal, setCloseModal } = this.props;
    const { showModal, modalTitle, modalContent, params, modalLoading } = modal;
    const { onlyOk, onlyOkText, isError, isSuccess, onOk, onCancel, okButtonProps, modalContentClass, ...otherParams } = params;

    return (
      <Modal
        closable={false}
        maskClosable={false}
        open={showModal}
        onCancel={onCancel || setCloseModal}
        onOk={onOk || setCloseModal}
        title={modalTitle || undefined}
        okButtonProps={okButtonProps}
        {...otherParams}
        footer={onlyOk
          ? [
            <Button key="back" onClick={onOk || setCloseModal} {...okButtonProps}>
              {onlyOkText || 'Ок'}
            </Button>
          ]
          : undefined
        }
      >
        <Spin spinning={modalLoading}>
          <div className="form__content !mb-0">
            <div className='flex flex-col'>
              <div className='flex flex-row items-center gap-10'>
                {(isError || isSuccess) && (
                  <div className='flex items-center text-9xl '>
                    {isError && <ExclamationCircleOutlined className='text-red-600' />}
                    {isSuccess && <CheckCircleOutlined className='text-green-600'/>}
                  </div>
                )}
                <div className='text-gray-900'>
                    <div className={`p !mt-0 ${modalContentClass ?? ''}`}>{modalContent}</div>
                  <br />
                  {isError && (
                    <p>
                      Попробуйте обратиться к своему Куратору или
                      &nbsp;
                      <Link to={ROUTE_ROOT} className='underline' onClick={this.handleSendFeedBack}>
                        написать в Тех.поддержку
                      </Link>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: state.modal,
});

const mapDispatchToProps = {
  setCloseModal: closeModal,
  updateDrawerContent: setDrawerContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    AppModal
  )
);
