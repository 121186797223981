import FormWithMessage from '@common/formWithMessage';
import api from '@services/api';
import storage from '@services/storage';
import { FORM_LOGIN, MIN_USER_PASSWORD_LENGTH, STORAGE_REGISTER_CURATOR_ID } from '@src/constants';
import { getMessageFromRequest } from '@src/helpers';
import { countries } from 'countries-list-es5/dist';
import { useMemo, useState } from 'react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Input, Spin, message, Select } from 'antd';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';

const RegistrationForm = ({ setFormType, formRef }) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [textMessage, setTextMessage] = useState(<>Ошибка регистрации. Проверьте правильность данных или повторите позднее</>);

  const handleSubmit = () => {
    formRef?.current?.validateFields().then(async (values) => {
      setLoading(true);

      const { status, data } = await api.register(values);

      setLoading(false);

      if (status === 200) {
        const textForMessage = getMessageFromRequest(data);
        if (data?.status === 0) {
          setTextMessage(textForMessage);
          setIsSuccess(true);
        } else {
          if (textForMessage?.length) {
            message.error(textForMessage, 5);
          } else {
            setIsError(true);
          }
        }
      } else {
        setIsError(true);
      }
    });
  };

  const cur_id = storage.get(STORAGE_REGISTER_CURATOR_ID) || undefined;

  const countryList = [];
  Object
    .keys(countries)
    .forEach((key) => {
      const phones = countries[key].phone.split(',');
      phones.forEach((phone) => {
        countryList.push({
          value: `${key}_${phone}`,
          label: `${countries[key].name} (Phone prefix: +${phone})`,
        });
      });
    });

  const [userCountry, setUserCountry] = useState('RU_7');
  const [userPhonePrefix, maxPhoneLength, country, countryKey] = useMemo(() => {
    const [key, prefix] = userCountry.split('_');
    const currentCountry = JSON.stringify(countries[key]);
    formRef?.current?.setFieldValue('phone', undefined);
    formRef?.current?.setFieldValue('countryKey', key);
    formRef?.current?.setFieldValue('country', currentCountry);
    formRef?.current?.setFieldValue('prefix', `+${prefix}`);
    formRef?.current?.resetFields(['phone']);
    return [
      `+${prefix}`,
      prefix.length + 11,
      currentCountry,
      key,
    ];
  }, [formRef, userCountry]);

  return (
    <FormWithMessage
      setFormType={setFormType}
      isError={isError}
      isSuccess={isSuccess}
      textMessage={textMessage}
    >
      <Form
        name='Register'
        ref={formRef}
        layout='vertical'
        initialValues={{
          curatorId: cur_id,
          country: country,
          countryKey: countryKey,
          countryList: userCountry,
          prefix: userPhonePrefix,
        }}
        onFinish={handleSubmit}
      >
        <Spin spinning={loading}>
          <div className="form__content">
            <Form.Item name='lastName' label="Фамилия" className="form__block"
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите свою фамилию'},
                         {max: 100, message: 'Максимальная длина поля - 100 символов'},
                         {transform: (x) => x.trim(), pattern: /^[a-zA-Z ]+$/, message: 'В этом поле можно вводить только буквы (латиница) и пробел'},
                       ]}>
              <Input placeholder='Ivanov'/>
            </Form.Item>
            <Form.Item name='firstName' label="Имя" className="form__block"
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите своё имя'},
                         {max: 100, message: 'Максимальная длина поля - 100 символов'},
                         {transform: (x) => x.trim(), pattern: /^[a-zA-Z ]+$/, message: 'В этом поле можно вводить только буквы (латиница) и пробел'},
                       ]}>
              <Input placeholder='Ivan'/>
            </Form.Item>
            <Form.Item name='email' label="Email" className="form__block"
                       tooltip={'Пожалуйста, укажите действительный адрес электронной почты, т.к. на него придет письмо с подтверждением вашей регистрации'}
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите адрес своей электронной почты'},
                         {validateTrigger: 'onBlur', min: 6, message: 'Минимальная длина поля - 6 символов'},
                         {max: 200, message: 'Максимальная длина поля - 100 символов'},
                         {pattern: /[@a-zA-Z0-9_.–—‐-]+/, message: 'В поле можно вводить только латинские буквы, цифры, точку, дефис и знак подчеркивания'},
                         {validateTrigger: 'onBlur', type: 'email', message: 'Пожалуйста, введите корректный адрес электронной почты'},
                       ]}>
              <Input placeholder='nickName@example.com'/>
            </Form.Item>
            <Form.Item name='countryList' label="Страна" className="form__block">
              <Select
                showSearch
                options={countryList}
                onSelect={setUserCountry}
                getPopupContainer={(node) => node}
                popupClassName="country-list-drop-down"
                placeholder="Начните вводить страну на родном или Английском языке"
                filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              />
            </Form.Item>
            <Form.Item name='country' hidden={true}><Input /></Form.Item>
            <Form.Item name='countryKey' hidden={true}><Input /></Form.Item>
            <Form.Item name='prefix' hidden={true}><Input /></Form.Item>
            <Form.Item name='phone' label="Номер телефона" className="form__block"
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите свой номер телефона'},
                         {transform: (x) => x.replace(/[^+\d]/, '').replace(/[() -]/g,''), max: maxPhoneLength, message: 'Максимально можно ввести 10 цифр, не включая код страны и знак "плюс"'},
                         {pattern: /^\+/, message: 'Первым должен идти знак "плюс" и код страны (+7 для России)'},
                         {pattern: /^[+\d() -]*/, message: 'В этом поле можно вводить только цифры, круглые скобки, дефисы, пробелы и код страны (+7 для России)'},
                         {
                           validateTrigger: 'onBlur',
                           transform: (x) => x.replace(/[^\d+]/, '').replace(/[() -]/g,''),
                           pattern: new RegExp('^\\+\\d{'.concat((maxPhoneLength - 1).toString(), '}$')),
                           message: 'Номер телефона должен состоять из 10 цифр (не включая знак "плюс", код страны, скобки, дефисы и пробелы)',
                         },
                       ]}>
              <InputMask
                key={userPhonePrefix}
                mask={`${userPhonePrefix.replace(/9/g,'\\9')} (999) 999-99-99`}
                maskChar="_"
                alwaysShowMask={true}
              />
            </Form.Item>
            <Form.Item name='telegram' label="Имя пользователя в Telegram" className="form__block"
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите своё имя пользователя в Телеграмм'},
                         {pattern: /^@/, message: 'Имя должно начитаться с "собачки" (@)'},
                         {pattern: /^(@|@[a-zA-Z0-9_]+)$/, message: 'Имя должно содержать только символы a-z, 0-9 или знак подчеркивания'},
                         {validateTrigger: 'onBlur', min: 6, message: 'Имя должно начитаться с "собачки" (@), далее минимум 5 символов'},
                         {validateTrigger: 'onBlur', pattern: /@[a-zA-Z0-9_]{5,}/, message: 'Имя должно начитаться с "собачки" (@), далее минимум 5 символов a-z, 0-9 или знак подчеркивания'},
                       ]}>
              <Input placeholder='@nickName' autoComplete='off' name='telegram' id='telegram'/>
            </Form.Item>
            <Form.Item name='curatorId' label="Номер куратора (из ссылки)" className="form__block"
                       tooltip={'Пожалуйста, введите номер куратора (цифры из ссылки).'}
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите номер своего куратора из ссылки'},
                         {pattern: /(^$|^[0-9]+$)/, message: 'В этом поле могут быть только цифры'},
                       ]}
            >
              <Input placeholder='123456' />
            </Form.Item>
            <Form.Item name='password' label="Пароль" className="form__block"
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите пароль'},
                         {validateTrigger: 'onBlur', min: MIN_USER_PASSWORD_LENGTH, message: `Пароль может быть минимум ${MIN_USER_PASSWORD_LENGTH} символов`},
                       ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item name='password2' label="Повтор пароля" className="form__block"
                       dependencies={['password']}
                       tooltip='Вводим второй раз пароль для проверки правильности'
                       validateTrigger={['onBlur', 'onChange']}
                       validateFirst={true}
                       rules={[
                         {required: true, message: 'Пожалуйста, введите второй раз пароль для проверки'},
                         {validateTrigger: 'onBlur', min: MIN_USER_PASSWORD_LENGTH, message: `Пароль может быть минимум ${MIN_USER_PASSWORD_LENGTH} символов`},
                         ({ getFieldValue  }) => ({
                           validateTrigger: 'onBlur',
                           validator(rule, value) {
                             if (!value || getFieldValue('password') === value) {
                               return Promise.resolve();
                             }
                             return Promise.reject(new Error('Оба пароля должны совпадать!'));
                           },
                         }),
                       ]}
            >
              <Input.Password />
            </Form.Item>
          </div>
          <div>
            Нажимая кнопку “Создать аккаунт” я даю согласие на&nbsp;
            <Link to={'/files/Use_personal_data_agreement.pdf'} target='_blank' className="form__info">
              обработку персональных данных
            </Link>,
            а также ознакомлен/ознакомлена и согласен/согласна со следующими документами:&nbsp;
            <Link to={'/files/Terms_of_use_the_website_Soulmate.pdf'} target='_blank' className="form__info">
              условия использования сайта
            </Link>,&nbsp;
            <Link to={'/files/Privacy_policy.pdf'} target='_blank' className="form__info">
              политика конфиденциальности
            </Link>
          </div>
          <button className="standart-btn mt-[32px]">
            <div>Создать аккаунт</div>
          </button>
          <span className="form__next">
            У вас уже есть аккаунт?
            &nbsp;
            <button onClick={() => {
              setFormType(FORM_LOGIN);
              return false;
            }}>
              Войдите в него
            </button>
          </span>
        </Spin>
      </Form>
    </FormWithMessage>
  );
}

export default withTranslation()(
  RegistrationForm
);
