import withWindowResize from '@src/helpers/withWindowResize';
import { Modal } from 'antd';
import React, { Component } from 'react';
import ReactHlsPlayer from 'react-hls-player';

import "./styles.less";

class nftPlayer extends Component {
  render() {
    const { showPlayer, url, img, handleClose} = this.props;

    const hlsUrl = [
      process.env.REACT_APP_CDN_SELECTEL,
      process.env.REACT_APP_CDN_SELECTEL_URL_TO_VIDEO,
      url,
      '/master.m3u8'
    ].join('')

    return (
      <Modal
        className="nft-video-modal"
        open={showPlayer}
        title={false}
        onOk={handleClose}
        onCancel={handleClose}
        footer={false}
        destroyOnClose={true}
        afterClose={handleClose}
      >
        <ReactHlsPlayer
          src={hlsUrl}
          autoPlay={true}
          controls={false}
          className="nft-player"
          hlsConfig={{
            maxLoadingDelay: 4,
            minAutoBitrate: 0,
            lowLatencyMode: true,
          }}
        />
        <div className="nft-persona-wrapper">
          <img className="nft-persona-image" src={ img } alt="Персонад" />
        </div>
        <div className="nft-persona-button-wrapper">
          <button className="nft-persona-button" onClick={handleClose}>
            КАЙФ !!!
          </button>
        </div>
      </Modal>
    );
  }
}

export default withWindowResize(
  nftPlayer
);
