import { QuestionCircleOutlined } from '@ant-design/icons';
import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import ReferralsLinkTextBlock from '@common/referralsLinkTextBlock';
import SubscriptionTimer from '@common/subscriptionTimer/subscriptionTimer';
import api from '@services/api';
import { ROUTE_AMBASSADORS_CLUB } from '@src/constants';
import { message, Popover, Spin } from 'antd';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import "./styles.less";

class PartnersProgramsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      marketing: [],
      ambassadorsCount: 0,
    }
  }

  componentDidMount() {
    this.getMarketing();
    this.getAmbassadors();
  }


  getAmbassadors = async () => {
    this.setState({ loading: true });

    const {status, data} = await api.getAmbassadors();

    if (status === 200 && data > 0) {
      this.setState({ ambassadorsCount: data })
    }

    this.setState({ loading: false })
  }

  getMarketing = async () => {

    this.setState({ loading: true });
    const { status, data } = await api.getMarketing();

    if (status === 200 && data?.length) {
      this.setState({ marketing: data });
    } else {
      message.error('К сожалению, невозможно прочитать данные по маркетингу');
    }
    this.setState({ loading: false });
  }

  renderFirstLine = (line) => {
    const { t } = this.props;

    const level = {};
    line.forEach((x) => {
      if (level[x.value]) {
        level[x.value]++;
      } else {
        level[x.value] = 1;
      }
    })
    return (
      <div className="flex flex-col">
        {Object.keys(level).map((x) => (
          <div className="marketing-add-row">
            <div className="first-line-name">Ступень {x}:&nbsp;</div>
            <div className="first-line-value">{t('LEVEL_COUNT_FIRST_LINE', { count: level[x] })}</div>
          </div>
        ))}
      </div>
    );
  }

  renderLevelContent = (level, isMobile = false) => (
    <div className='flex flex-col'>
      {isMobile && (
        <div className="table__strokem">
          <div className="table__block-name">
            Совладельческий <br /> доход**
          </div>
          <div className="table__block-info">
            {level.company > 0 ? `${level.company}%` : '-'}
          </div>
        </div>
      )}
      <h2 className=""><strong>Минимальные показатели для перехода<br />на указанную ступень:</strong></h2>
      <div className="flex flex-col p-2">
        <h3 className="volumes">Баллы за объемы продаж:</h3>
        <div className="marketing-add-row">
          <div className="volumes_name">Личный продажи:</div>
          <div className="volumes_value">{level.pv}</div>
        </div>
        <div className="marketing-add-row">
          <div className="volumes_name">Структурные продажи:</div>
          <div className="volumes_value">{level.nv}</div>
        </div>
        <div className="marketing-add-row">
          <div className="volumes_name">Общий объем:</div>
          <div className="volumes_value">{level.av}</div>
        </div>
      </div>
      {level.marketingRulesFirstLine?.length > 0 && (
        <div className="flex flex-col p-2 first-line">
          <h3 className="">Первая линия:</h3>
          {this.renderFirstLine(level.marketingRulesFirstLine)}
        </div>
      )}
      {level.marketingRulesEducation?.length > 0 && (
        <div className="flex flex-col p-2">
          <div className="">Требования по прохождению курсов:</div>
          <div className=""> - нет</div>
          {/*<div className="flex flex-row p-2 gap-4">*/}
          {/*  <div className="">Личный продажи:</div>*/}
          {/*  <div className="">{x.pv}</div>*/}
          {/*</div>*/}
        </div>
      )}
    </div>
  )

  render() {
    const { t } = this.props;
    const { loading, marketing, ambassadorsCount } = this.state;

    return (
      <main>
        <div className="wrap partners-programs-page">
          <Spin spinning={loading}>
            <MobileHeader />
            <CatalogHeader
              pageName='Амбассадорская программа'
              parentPageName={t('LEFT_MENU_AMBASSADORS_CLUB')}
              parentPageLink={ROUTE_AMBASSADORS_CLUB}
              withPaySubscription={false}
            />
            <div className='flex flex-col 2xl:flex-row gap-4 w-full'>
              <div className="main-block">
                <div className="info__card">
                  <div className="info-content">
                    <div className="info-repeat">
                      {marketing.length > 0
                        ? (
                          <div className="marketing">

                            <div className="table xs-hide">
                              <h2>Таблица выплат</h2>
                              <div className="table-content">
                                <div className="table__stroke table__stroke-head">
                                  <div className="table__block">
                                    <h3>Ступень</h3>
                                  </div>
                                  <div className="table__block">
                                    <h3>Доход от продаж</h3>
                                  </div>
                                  <div className="table__block">
                                    <h3>Дивидендный <br /> доход</h3>
                                  </div>
                                  <div className="table__block">
                                    <h3>Совладельческий <br /> доход**</h3>
                                  </div>
                                  <div className="table__block">

                                  </div>
                                </div>
                                {marketing.map((x) => (
                                  <div className="table__stroke hover:bg-fuchsia-50 rounded-2xl pr-3">
                                    <div className="table__block-num">
                                      <span>{x.level} уров.</span>
                                    </div>
                                    <div className="table__block-name">
                                      {x.percent > 0 ? `${x.percent}%` : '-'}
                                    </div>
                                    <div className="table__block-name">
                                      {x.dividend > 0 ? `${x.dividend}%` : '-'}
                                    </div>
                                    <div className="table__block-name">
                                      {x.company > 0 ? `${x.company}%` : '-'}
                                    </div>
                                    <div className="table__block-img">
                                      <Popover
                                        placement='topRight'
                                        content={this.renderLevelContent(x, false)}
                                      >
                                        <QuestionCircleOutlined />
                                      </Popover>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="table_m xs-show">
                              <h2>Таблица выплат</h2>
                              {marketing.map((x) => (
                                <div className="tablem_block">
                                  <div className="tablem_block-line">
                                    <div className="table__strokem">
                                      <div className="table__block-info">
                                        <span>{x.level} ступень</span>
                                      </div>
                                      <div className="table__block-img">
                                        <Popover
                                          placement='topRight'
                                          content={this.renderLevelContent(x, true)}
                                        >
                                          <QuestionCircleOutlined />
                                        </Popover>
                                      </div>
                                    </div>
                                    {x.percent > 0 && (
                                      <div className="table__strokem">
                                        <div className="table__block-name">
                                          Доход от продаж:
                                        </div>
                                        <div className="table__block-text">
                                          {x.percent}%
                                        </div>
                                      </div>
                                    )}
                                    {x.dividend > 0 && (
                                      <div className="table__strokem">
                                        <div className="table__block-name">
                                          Дивидендный доход:
                                        </div>
                                        <div className="table__block-text">
                                          {x.dividend}%
                                        </div>
                                      </div>
                                    )}
                                    {x.company > 0 && (
                                      <div className="table__strokem">
                                        <div className="table__block-name">
                                          Совладельческий доход**:
                                        </div>
                                        <div className="table__block-text">
                                          {x.company}%
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>

                          </div>
                        )
                        : 'Нет данных'
                      }
                    </div>
                    <div className="flex justify-center">
                      <div className="standart-btn standart-btn-border ">
                        <div>
                          <Link to={'/files/Partnership_agreement_ENG_RU_2.pdf'} target='_blank'>
                            Партнерское соглашение
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="other-block w-full 2xl:w-[339px] gap-4 flex flex-col self-start">
                <div className="info__card">
                  <div className="info__conetns-code">
                    <div className="info__code !p-0">
                      <h2>Количество Амбассадоров: </h2>
                      <div className="w-full flex justify-center">
                        <div className="ambassadors-count">
                          <span className="">
                            { ambassadorsCount }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="info__card">
                  <div className="info__conetns-code">
                    <div className="info__code !p-0">
                      <h2>Реферальная ссылка</h2>
                      <ReferralsLinkTextBlock />
                    </div>
                  </div>
                </div>
                <div className="info__card">
                  <SubscriptionTimer />
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  withRouter(
    PartnersProgramsPage
  )
);
