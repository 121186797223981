import CatalogHeader from '@common/catalogHeader';
import MediaPlayer from '@common/mediaPlayer';
import MobileHeader from '@common/mobileHeader';
import { Fancybox } from '@fancyapps/ui';
import { ROUTE_404, ROUTE_EDUCATION } from '@src/constants';
import { fileSize } from '@src/helpers/fileSize';
import { Badge, Tabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { ReactComponent as EducationAudioIcon } from '@ui/icons/educationAudio-magenta.svg';
import { ReactComponent as EducationBookIcon } from '@ui/icons/educationBook-magenta.svg';
// import { ReactComponent as EducationVideoIcon } from '@ui/icons/educationVideo-magenta.svg';
import { ReactComponent as PlayIcon } from '@ui/icons/play.svg';
import { ReactComponent as VideoDurationClockIcon } from '@ui/icons/videoDurationClock.svg';

import "@fancyapps/ui/dist/fancybox.css";
import "./styles.less";

const emptyPlayerState = {
  showPlayer : false,
  url: '',
  title: '',
  isAudio: false,
  playing: true,
  hls: false,
}

class EducationAcademyPage extends Component {
  lessonBg = require('@ui/bg/lesson-bg.png');
  state = emptyPlayerState;

  // ref = player => {
  //   this.player = player
  // }

  handleClose = () => {
    this.setState({
      ...emptyPlayerState,
      playing: false,
    });
  }

  renderBooks = (books) => (
    <div className="info-contents">
      <div className="info-content fac-content">
        <div className="base__book">
          <div className="fac__m">
            <div className="fac__m-content">
              {books.map((x) => (
                <div className="fac__m-block" key={`book_${x.order}_${x.fileName}`}>
                  <Link to={x.url || `/${x.type}/${x.fileName}`} target='_blank'>
                    <EducationBookIcon />
                  </Link>
                  <div className="fac__m-text">
                    <h3>{x.title}</h3>
                    <span>{x.ext}, {fileSize(x.size)}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  renderVideos = (videos) => (
    <div className="info-content fac-content info__bord">
      <div className="base__video">
        <div className="fac__less bas__less">
          {/*<div className="fac__less-head">
            <h2>Название темы</h2>
          </div>*/}
          <div className="fac__less-blocks">
            {videos.map((x) => (
              <div className="fac__less-block" key={`video_${x.order}_${x.fileName}`}>
                <div className="fac__block-video">
                  <img src={this.lessonBg} alt="" className="fac__img" />
                  <div
                    className="info__play fac__play"
                    onClick={() => this.setState({
                      showPlayer: true,
                      playing: true,
                      title: x.title,
                      isAudio: false,
                      url: x.url || `${window.location.protocol}//${window.location.host}/${x.type}/${x.fileName}`,
                      hls: x.hls || false,
                    })}
                  >
                    <PlayIcon />
                  </div>
                </div>
                <div className="fac__block-info">
                  <h3>{x.title}</h3>
                  {x.description ? <p>{x.description}</p> : ''}
                  <div className="fac__block-time">
                    <VideoDurationClockIcon />
                    <span>{x.duration}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )

  renderAudios = (audios) => (
    <div className="info-contents">
      <div className="info-content fac-content">
        <div className="base__book">
          <div className="fac__m">
            <div className="fac__m-content">
              {audios.map((x) => (
                <div className="fac__m-block" key={`audio_${x.order}_${x.title}`}>
                  <div
                    className=""
                    onClick={() => this.setState({
                      showPlayer: true,
                      playing: true,
                      title: x.title,
                      isAudio: true,
                      url: x.url || `${window.location.protocol}//${window.location.host}/${x.type}/${x.fileName}`,
                      hls: x.hls || false,
                    })}
                  >
                    <EducationAudioIcon />
                  </div>
                  <div className="fac__m-text">
                    <h3>{x.title}</h3>
                    <span>{x.ext}, {x.duration}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  renderMain = (mainVideo, description) => (
    <div className="info-content fac-content">
      {mainVideo && (
        <div className="info-item__video">
          <div className="fac__less bas__less">
            <img src={require(`@ui/posters/${mainVideo.poster || 'default-faculty-poster.png'}`)} alt="" className="info__video-bg" />
            <div className="info__video--a">
              <div className="info-video__text">
                {mainVideo.description?.length
                  ? mainVideo.description
                  : ''}
              </div>
              <div
                className="info__play"
                onClick={() => this.setState({
                  showPlayer: true,
                  playing: true,
                  title: mainVideo.title,
                  isAudio: false,
                  url: mainVideo.url || `${window.location.protocol}//${window.location.host}/${mainVideo.type}/${mainVideo.fileName}`,
                  hls: mainVideo.hls || false,
                })}
              >
                <PlayIcon />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='description__text'>
        {description && (
          <div className="text-base">
            {description}
          </div>
        )}
      </div>
    </div>
  )

  renderWithTabs = (main, books, videos, webinars, audios, program) => {
    const defaultTab = (
      main
        ? "main"
        : (
          videos.length > 0
            ? "videos"
            : (
              books.length > 0
                ? "books"
                : (
                  audios.length > 0
                    ? "audios"
                    : "webinars"
                )
            )
        )
    );

    return (
      <Tabs defaultActiveKey={defaultTab}>
        {main && (
          <Tabs.TabPane
            key="main"
            tab={<><div>Описание</div></>}
          >
            {this.renderMain(program.main_video, program.description)}
          </Tabs.TabPane>
        )}
        {books.length > 0 && (
          <Tabs.TabPane
            key="books"
            tab={<div className="flex flex-row gap-2"><div>Файлы</div><Badge count={books.length} /></div>}
          >
            {this.renderBooks(books)}
          </Tabs.TabPane>
        )}
        {videos.length > 0 && (
          <Tabs.TabPane
            key="videos"
            tab={<div className="flex flex-row gap-2"><div>Видео</div><Badge count={videos.length} /></div>}
          >
            {this.renderVideos(videos)}
          </Tabs.TabPane>
        )}
        {webinars.length > 0 && (
          <Tabs.TabPane
            key="webinars"
            tab={<div className="flex flex-row gap-2"><div>Вебинары</div><Badge count={webinars.length} /></div>}
          >
            {this.renderVideos(webinars)}
          </Tabs.TabPane>
        )}
        {audios.length > 0 && (
          <Tabs.TabPane
            key="audios"
            tab={<div className="flex flex-row gap-2"><div>Аудио</div><Badge count={audios.length} /></div>}
          >
            {this.renderAudios(audios)}
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }

  renderWithOutTabs = (main, books, videos, webinars, audios, program) => (
    <>
      {main && this.renderMain(program.main_video, program.description)}
      {books.length > 0 && this.renderBooks(books)}
      {videos.length > 0 && this.renderVideos(videos)}
      {webinars.length > 0 && this.renderVideos(webinars)}
      {audios.length > 0 && this.renderAudios(audios)}
    </>
  )

  render() {
    const { userInfo, program } = this.props;
    const { showPlayer, url, title, isAudio, hls } = this.state;

    if (program.exclude_roles.includes(userInfo.role_id)) {
      return <Redirect to={ROUTE_404} />
    }

    const books = program.files.filter((x) => x.type === 'book');
    const videos = program.files.filter((x) => x.type === 'video');
    const webinars = program.files.filter((x) => x.type === 'webinar');
    const audios = program.files.filter((x) => x.type === 'audio');
    const main = (
      program.main_video?.fileName?.length > 0
      || program.main_video?.url?.length > 0
      || program.description?.length > 0
      || program.description?.type?.length > 0
    );

    const tabs = [];
    main && tabs.push('main');
    books.length > 0 && tabs.push('books');
    videos.length > 0 && tabs.push('videos');
    webinars.length > 0 && tabs.push('webinars');
    audios.length > 0 && tabs.push('audios');

    Fancybox.bind("[data-fancybox]", {
      Toolbar: false,
      // Toolbar: {
        // display: [
        //   "close",
        // ],
      // },
      Navigation: false,
    });

    const isParentEducation = program.linkPath.includes(ROUTE_EDUCATION);
    const parent = !isParentEducation && program.parent;

    return (
      <main className="education-academy-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            parentPageName={isParentEducation ? 'Обучение' : parent ? parent.name : undefined}
            parentPageLink={isParentEducation ? ROUTE_EDUCATION : parent ? parent.link : undefined}
            pageName={program.title}
            withPaySubscription={false}
            className='!mb-0'
          />
          <div className='flex flex-col gap-4 w-full'>
            <div className="info__card ">
              {tabs.length > 1 && this.renderWithTabs(main, books, videos, webinars, audios, program)}
              {tabs.length === 1 && this.renderWithOutTabs(main, books, videos, webinars, audios, program)}
            </div>
          </div>
          <MediaPlayer showPlayer={showPlayer} title={title} url={url} handleClose={this.handleClose} isAudio={isAudio} hls={hls}/>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      EducationAcademyPage
    )
  )
);
