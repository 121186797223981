import CatalogHeader from '@common/catalogHeader';
import MediaPlayer from '@common/mediaPlayer';
import MobileHeader from '@common/mobileHeader';
import api from '@services/api';
import showTimePeriod from '@src/helpers/showTimePeriod';
import showTimeReminder from '@src/helpers/showTimeReminder';
import { Collapse, Table, Tabs } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';

import { ReactComponent as PlayIcon } from '@ui/icons/play.svg';

import "./styles.less";

const { Panel } = Collapse;

const emptyPlayerState = {
  showPlayer : false,
  url: '',
  title: '',
  isAudio: false,
  playing: true,
  hls: false,
}

class MasterOfWordsPage extends Component {
  state = {
    showTable: undefined,
    transactions: [],
    loading: false,
    beginDate: undefined,
    endDate: undefined,
    timeZone: undefined,
    deltaDays: undefined,
    deltaHours: undefined,
    deltaMinutes: undefined,
    timer: undefined,
    info: undefined,
    count: 20,
    eduBeginDate: undefined,
    eduEndDate: undefined,
    ...emptyPlayerState,
  }

  componentDidMount() {
    this.getTransactionList();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  getTransactionList = async () => {
    this.setState({loading: true});

    const { status, data } = await api.getMasterOfWord();

    if (status === 200 && data.status === 0) {
      this.setState({
        showTable: true,
        transactions: data?.list?.length
          ? data.list.filter((x) => Number(x.spv) > 0)
          : [],
        beginDate: data.date_begin
          ? new dayjs(data.date_begin, 'YYYY-MM-DD')
          : (
            data?.info?.date_start
              ? new dayjs(data?.info?.date_start, 'YYYY-MM-DD')
              : undefined
          ),
        endDate: data.date_end
          ? new dayjs(data.date_end, 'YYYY-MM-DD')
          : (
            data?.info?.date_end
              ? new dayjs(data?.info?.date_end, 'YYYY-MM-DD')
              : undefined
          ),
        eduBeginDate: data?.info?.edu_date_start
          ? new dayjs(data?.info?.edu_date_start, 'YYYY-MM-DD')
          : undefined,
        eduEndDate: data?.info?.edu_date_end
          ? new dayjs(data?.info?.edu_date_end, 'YYYY-MM-DD')
          : undefined,
        timeZone: data.time_zone,
        info: data.info,
        count: data.count ?? 20,
      }, () => {
        this.getTimer();
        const timer = setInterval(() => this.getTimer(), 1000 * 60);
        this.setState({ timer });
      });
    } else if (status === 200 && data.status === -1) {
      this.setState({
        showTable: false,
        info: data.info,
      });
    }

    this.setState({loading: false});
  }

  handleClose = () => {
    this.setState({
      ...emptyPlayerState,
      playing: false,
    });
  }

  getTabs = (transactions, loading, columns, userInfo, showTable, info) => [
    {
      key: 'current',
      label: showTable
        ? (<div>Набор в новую группу</div>)
        : (<div>Набор в новую группу (пока закрыт)</div>),
      children: (
        <>
          <h2 className="pb-1 text-lg">Список участников:</h2>
          {
            showTable
              ? (
                <Table
                  sticky={true}
                  pagination={false}
                  tableLayout={'auto'}
                  dataSource={transactions}
                  columns={columns}
                  loading={loading}
                  rowKey={(record) => record.user_id}
                  rowClassName={(record) => classNames(
                    "st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0",
                    Number(record.spv || 0) < info?.min_pv
                      ? 'red'
                      : (
                        Number(record.level || 0) < info?.min_level
                          ? 'yellow'
                          : 'green'
                      ),
                    (Number(record.user_id || -1) === Number(userInfo?.id || 0)) && 'current-user'
                  )}
                  className='static-table'
                />
              )
              : <p>Пока нет набора на данный тренинг</p>
          }
        </>
      ),
    },
    {
      key: 'group5',
      label: (<div>Группа 5</div>),
      children: (
        <ul>
          <li>
            <b>Даты проведения тренинга:</b>
            <ul>
              <li>19 - 21 марта 2024.</li>
            </ul>
          </li>
          <br />
          <li>
            <b>Тренерский состав Корпоративного университета SoulMate:</b>
            <ol>
              <li>Виталий Пятенко</li>
              <li>Максим Бумарсков</li>
              <li>Дмитрий Петров</li>
            </ol>
          </li>
          <br />
          <li>
            <b>Утвержденный состав наблюдателей Корпоративного университета SoulMate:</b>
            <ol>
              <li>ID 4 - Еремин Максим - <br className="xs-show" />Сертификат: БС I	№ 0000002</li>
              <li>ID 6 - Светлана Терещенко - <br className="xs-show" />Сертификат: БС I № 0000001</li>
              <li>ID 23 - Олег Кондратов. - <br className="xs-show" />Сертификат: БС I № 0000004</li>
            </ol>
          </li>
          <br />
          <li>
            <b>Утвержденный состав слушателей Корпоративного университета SoulMate:</b>
            <ol>
              <li>ID 20 - Стенкова Ирина - <br className="xs-show" />Сертификат: МС I № 0000046</li>
              <li>ID 22 - Маскин Иван - <br className="xs-show" />Сертификат: МС I № 0000047</li>
              <li>ID 51 - Зуйков Георгий - <br className="xs-show" />Сертификат: МС I № 0000048</li>
              <li>ID 96 - Висна Наталья - <br className="xs-show" />Сертификат: МС I № 0000051</li>
              <li>ID 386 - Ермоленко Виталий - <br className="xs-show" />Сертификат: МС I № 0000053</li>
              <li>ID 398 - Филиппова Дарья - <br className="xs-show" />Сертификат: МС I № 0000044</li>
              <li>ID 627 - Кононов Виктор - <br className="xs-show" />Сертификат: МС I № 0000057</li>
              <li>ID 731 - Назаров Сергей - <br className="xs-show" />Сертификат: МС I № 0000049</li>
              <li>ID 1082 - Касьянов Николай - <br className="xs-show" />Сертификат: МС I № 0000037</li>
              <li>ID 1259 - Малышкина Татьяна - <br className="xs-show" />Сертификат: МС I № 0000039</li>
              <li>ID 1269 - Савельев Руслан - <br className="xs-show" />Сертификат: МС I № 0000054</li>
              <li>ID 1440 - Фомин Николай - <br className="xs-show" />Сертификат: МС I № 0000056</li>
              <li>ID 1520 - Бежецких Наталья - <br className="xs-show" />Сертификат: МС I № 0000041</li>
              <li>ID 1888 - Лемасова Ольга - <br className="xs-show" />Сертификат: МС I № 0000050</li>
              <li>ID 2199 - Седых Светлана - <br className="xs-show" />Сертификат: МС I № 0000055</li>
              <li>ID 2565 - Галеев Аркадий - <br className="xs-show" />Сертификат: МС I № 0000042</li>
              <li>ID 3203 - Ким Вера - <br className="xs-show" />Сертификат: МС I № 0000043</li>
              <li>ID 3247 - Григорьева Виктория - <br className="xs-show" />Сертификат: МС I № 0000040</li>
              <li>ID 3301 - Гуреев Александр - <br className="xs-show" />Сертификат: МС I № 0000038</li>
              <li>ID 3869 - Храпин Роман - <br className="xs-show" />Сертификат: МС I № 0000052</li>
              <li>ID 4144 - Шелестов Николай - <br className="xs-show" />Сертификат: МС I № 0000045</li>
            </ol>
          </li>
        </ul>
      ),
    },
    {
      key: 'group4',
      label: (<div>Группа 4</div>),
      children: (
        <ul>
          <li>
            <b>Даты проведения тренинга:</b>
            <ul>
              <li>10 - 12 ноября 2023.</li>
            </ul>
          </li>
          <br />
          <li>
            <b>Тренерский состав Корпоративного университета SoulMate:</b>
            <ol>
              <li>Виталий Пятенко</li>
              <li>Максим Бумарсков</li>
              <li>Дмитрий Петров</li>
            </ol>
          </li>
          <br />
          <li>
            <b>Утвержденный состав наблюдателей Корпоративного университета SoulMate:</b>
            <ol>
              <li>ID 4 - Еремин Максим - <br className="xs-show" />Сертификат: БС I № 0000002</li>
              <li>ID 5 - Чернышенко Лариса - <br className="xs-show" />Сертификат: БС I № 0000003</li>
              <li>ID 6 - Светлана Терещенко - <br className="xs-show" />Сертификат: БС I № 0000001</li>
              <li>ID 23 - Олег Кондратов. - <br className="xs-show" />Сертификат: БС I № 0000004</li>
            </ol>
          </li>
          <br />
          <li>
            <b>Утвержденный состав слушателей Корпоративного университета SoulMate:</b>
            <ol>
              <li>ID 49 - Алиман Чонмурунова - <br className="xs-show" />Сертификат: МС I	№ 0000028</li>
              <li>ID 55 - Александра Беляк - <br className="xs-show" />Сертификат: МС I	№ 0000036</li>
              <li>ID 504 - Оксана Филипова - <br className="xs-show" />Сертификат: МС I	№ 0000002</li>
              <li>ID 574 - Татьяна Шаламова - <br className="xs-show" />Сертификат: МС I	№ 0000035</li>
              <li>ID 1304 - Маргарита Олейник - <br className="xs-show" />Сертификат: МС I	№ 0000024</li>
              <li>ID 1309 - Андрей Сторожук - <br className="xs-show" />Сертификат: МС I	№ 0000023</li>
              <li>ID 1672 - Людмила Витвицкая - <br className="xs-show" />Сертификат: МС I	№ 0000027</li>
              <li>ID 1709 - Татьяна Кирпичникова - <br className="xs-show" />Сертификат: МС I	№ 0000026</li>
              <li>ID 1883 - Дарья Лескова - <br className="xs-show" />Сертификат: МС I	№ 0000031</li>
              <li>ID 2211 - Юлия Панфилова - <br className="xs-show" />Сертификат: МС I	№ 0000030</li>
              <li>ID 2490 - Раиса Герус - <br className="xs-show" />Сертификат: МС I	№ 0000029</li>
              <li>ID 2518 - Александр Клеванский - <br className="xs-show" />Сертификат: МС I	№ 0000010</li>
              <li>ID 2589 - Виталий Рейзвих - <br className="xs-show" />Сертификат: МС II	№ 0000021</li>
              <li>ID 2616 - Ванян Вардан - <br className="xs-show" />Сертификат: МС I	№ 0000008</li>
              <li>ID 2734 - Сергей Антонов - <br className="xs-show" />Сертификат: МС I	№ 0000022</li>
            </ol>
          </li>
        </ul>
      ),
    },
    {
      key: "group3",
      label: (<div>Группа 3</div>),
      children: (
        <ul>
          <li>
            <b>Даты проведения тренинга:</b>
            <ul>
              <li>7 - 9 ноября 2023.</li>
            </ul>
          </li>
          <br />
          <li>
            <b>Тренерский состав Корпоративного университета SoulMate:</b>
            <ol>
              <li>Виталий Пятенко</li>
              <li>Максим Бумарсков</li>
              <li>Дмитрий Петров</li>
            </ol>
          </li>
          <br />
          <li>
            <b>Утвержденный состав наблюдателей Корпоративного университета SoulMate:</b>
            <ol>
              <li>ID 4 - Еремин Максим - <br className="xs-show" />Сертификат: БС I	№ 0000002</li>
              <li>ID 5 - Чернышенко Лариса - <br className="xs-show" />Сертификат: БС I	№ 0000003</li>
              <li>ID 6 - Светлана Терещенко - <br className="xs-show" />Сертификат: БС I	№ 0000001</li>
            </ol>
          </li>
          <br />
          <li>
            <b>Утвержденный состав слушателей Корпоративного университета SoulMate:</b>
            <ol>
              <li>ID 19 - Сергей Сорокин - <br className="xs-show" />Сертификат: МС I	№ 0000016</li>
              <li>ID 38 - Ольга Алимова - <br className="xs-show" />Сертификат: МС I	№ 0000012</li>
              <li>ID 45 - Ольга Мацуль - <br className="xs-show" />Сертификат: МС I	№ 0000004</li>
              <li>ID 60 - Ольга Чемерова - <br className="xs-show" />Сертификат: МС I	№ 0000009</li>
              <li>ID 171 - Лариса Сатаева - <br className="xs-show" />Сертификат: МС I	№ 0000017</li>
              <li>ID 183 - Егор Гродецкий - <br className="xs-show" />Сертификат: МС I	№ 0000014</li>
              <li>ID 202 - Ольга Хабарова - <br className="xs-show" />Сертификат: МС I	№ 0000019</li>
              <li>ID 285 - Руслан Пчела - <br className="xs-show" />Сертификат: МС I	№ 0000011</li>
              <li>ID 341 - Денис Никулин - <br className="xs-show" />Сертификат: МС I	№ 0000013</li>
              <li>ID 752 - Ирина Санжаева - <br className="xs-show" />Сертификат: МС I	№ 0000020</li>
              <li>ID 1068 - Дамир Ержанулы - <br className="xs-show" />Сертификат: МС I	№ 0000001</li>
              <li>ID 1243 - Денис Боровик - <br className="xs-show" />Сертификат: МС I	№ 0000003</li>
              <li>ID 1407 - Юлия Лукина - <br className="xs-show" />Сертификат: МС I	№ 0000006</li>
              <li>ID 1554 - Михаил Ромашов - <br className="xs-show" />Сертификат: МС I	№ 0000005</li>
              <li>ID 1696 - Владислав Фокша - <br className="xs-show" />Сертификат: МС I	№ 0000015</li>
              <li>ID 2205 - Руслан Евлоев - <br className="xs-show" />Сертификат: МС I	№ 0000007</li>
              <li>ID 2230 - Татьяна Шапченова - <br className="xs-show" />Сертификат: МС I	№ 0000018</li>
            </ol>
          </li>
        </ul>
      ),
    },
    {
      key: 'group2',
      label: (<div>Группа 2</div>),
      children: (
        <ul>
          <li>
            <b>Даты проведения обучения:</b>
            <ul>
              <li>24 - 26 июля 2023.</li>
            </ul>
          </li>
          <br />
          <li>
            <b>Тренерский состав Бизнес-академии SoulMate:</b>
            <ol>
              <li>Виталий Пятенко</li>
              <li>Максим Бумарсков</li>
              <li>Дмитрий Петров</li>
            </ol>
          </li>
          <br />
          <li>
            <b>Утвержденный состав наблюдателей Бизнес-академии SoulMate:</b>
            <ol>
              <li>ID 4 - Еремин Максим - <br className="xs-show" />Сертификат: БС I	№ 0000002</li>
              <li>ID 5 - Чернышенко Лариса - <br className="xs-show" />Сертификат: БС I	№ 0000003</li>
              <li>ID 23 - Кондратов Олег - <br className="xs-show" />Сертификат: БС I	№ 0000004</li>
              <li>ID 350 - Байбурин Тимур - <br className="xs-show" />Сертификат: БС I	№ 0000007</li>
            </ol>
          </li>
          <br />
          <li>
            <b>Утвержденный состав слушателей Бизнес-академии SoulMate:</b>
            <ol>

              <li>ID 10 - Дмитрий Романов - <br className="xs-show" />Сертификат: БС II	№ 0000035</li>
              <li>ID 17 - Евгений Палагин - <br className="xs-show" />Сертификат: БС II	№ 0000030</li>
              <li>ID 115 - Александр Мастерских - <br className="xs-show" />Сертификат: БС II	№ 0000033</li>
              <li>ID 377 - Елена Слободчикова - <br className="xs-show" />Сертификат: БС II	№ 0000038</li>
              <li>ID 404 - Екатерина Перегняк - <br className="xs-show" />Сертификат: БС II	№ 0000042</li>
              <li>ID 490 - Оксана Еремина - <br className="xs-show" />Сертификат: БС II	№ 0000041</li>
              <li>ID 541 - Елена Калинина - <br className="xs-show" />Сертификат: БС II	№ 0000032</li>
              <li>ID 769 - Булат Галлямов - <br className="xs-show" />Сертификат: БС II	№ 0000031</li>
              <li>ID 909 - Сергей Зеленин - <br className="xs-show" />Сертификат: БС II	№ 0000034</li>
              <li>ID 1001 - Сергей Валишев - <br className="xs-show" />Сертификат: БС II	№ 0000036</li>
              <li>ID 1044 - Екатерина Малышкина - <br className="xs-show" />Сертификат: БС II	№ 0000040</li>
              <li>ID 1051 - Алексей Карпенко - <br className="xs-show" />Сертификат: БС II	№ 0000037</li>
              <li>ID 1194 - Алексей Семенов - <br className="xs-show" />Сертификат: БС II	№ 0000039</li>
            </ol>
          </li>
        </ul>
      ),
    },
    {
      key: 'group1',
      label: (<div>Группа 1</div>),
      children: (
        <>
          <ul>
            <li>
              <b>Даты проведения обучения:</b>
              <ul>
                <li>25 - 27 апреля 2023.</li>
              </ul>
            </li>
            <br />
            <li>
              <b>Тренерский состав Бизнес-академии SoulMate:</b>
              <ol>
                <li>Виталий Пятенко</li>
                <li>Максим Бумарсков</li>
                <li>Дмитрий Петров</li>
              </ol>
            </li>
            <br />
            <li>
              <b>Утвержденный состав наблюдателей Бизнес-академии SoulMate:</b>
              <ol>
                <li>ID 4 - Еремин Максим - <br className="xs-show" />Сертификат: БС I	№ 0000002</li>
                <li>ID 5 - Чернышенко Лариса - <br className="xs-show" />Сертификат: БС I	№ 0000003</li>
                <li>ID 6 - Терещенко Светлана - <br className="xs-show" />Сертификат: БС I	№ 0000001</li>
                <li>ID 23 - Кондратов Олег - <br className="xs-show" />Сертификат: БС I	№ 0000004</li>
                <li>ID 41 - Мацуль Михаил - <br className="xs-show" />Сертификат: БС I	№ 0000006</li>
                <li>ID 117 - Гродецкая Татьяна - <br className="xs-show" />Сертификат: БС I	№ 0000005</li>
                <li>ID 350 - Байбурин Тимур - <br className="xs-show" />Сертификат: БС I	№ 0000007</li>
                <li>ID 365 - Шахова Елена - <br className="xs-show" />Сертификат: БС I	№ 0000008</li>
                <li>ID 496 - Дзюба Александра - <br className="xs-show" />Сертификат: БС I	№ 0000009</li>
              </ol>
            </li>
            <br />
            <li>
              <b>Утвержденный состав слушателей Бизнес-академии SoulMate:</b>
              <ol>
                <li>ID 7 - Василий Климов - <br className="xs-show" />Сертификат: БС I	№ 0000010</li>
                <li>ID 28 - Анатолий Новожилов - <br className="xs-show" />Сертификат: БС I	№ 0000026</li>
                <li>ID 29 - Светлана Балвакова - <br className="xs-show" />Сертификат: БС I	№ 0000025</li>
                <li>ID 33 - Покотило Наталья - <br className="xs-show" />Сертификат: БС I	№ 0000011</li>
                <li>ID 40 - Вышкин Евгений - <br className="xs-show" />Сертификат: БС I	№ 0000020</li>
                <li>ID 43 - Андрей Коковин - <br className="xs-show" />Сертификат: БС I	№ 0000024</li>
                <li>ID 98 - Александр Сальников - <br className="xs-show" />Сертификат: БС I	№ 0000027</li>
                <li>ID 108 - Роман Шиканов - <br className="xs-show" />Сертификат: БС I	№ 0000021</li>
                <li>ID 116 - Байгамысова Айгана - <br className="xs-show" />Сертификат: БС I	№ 0000013</li>
                <li>ID 154 - Олег Гончаров - <br className="xs-show" />Сертификат: БС I	№ 0000018</li>
                <li>ID 157 - Александр Турцин - <br className="xs-show" />Сертификат: БС I	№ 0000015</li>
                <li>ID 158 - Артём Лопатин - <br className="xs-show" />Сертификат: БС I	№ 0000019</li>
                <li>ID 168 - Григорий Ясинович - <br className="xs-show" />Сертификат: БС I	№ 0000016</li>
                <li>ID 357 - Нургалиева Низигуль - <br className="xs-show" />Сертификат: БС I	№ 0000014</li>
                <li>ID 476 - Архинчеева Ольга - <br className="xs-show" />Сертификат: БС I	№ 0000012</li>
                <li>ID 497 - Дзюба Владимир - <br className="xs-show" />Сертификат: БС I	№ 0000022</li>
                <li>ID 512 - Юрий Ролдугин - <br className="xs-show" />Сертификат: БС I	№ 0000017</li>
                <li>ID 745 - Лилия Каримова - <br className="xs-show" />Сертификат: БС I	№ 0000028</li>
                <li>ID 801 - Ракымбаева Камшат - <br className="xs-show" />Сертификат: БС I	№ 0000023</li>
                <li>ID 837 - Изотова Юлия - <br className="xs-show" />Сертификат: БС I	№ 0000029</li>
              </ol>
            </li>
          </ul>
        </>
      ),
    }
  ];

  getTimer = () => {
    const { loading, endDate } = this.state;

    if (endDate) {
      const today = new dayjs();

      if (today <= endDate) {
        const deltaDays = endDate.diff(today, 'days');
        const deltaHours = endDate.add((-1) * deltaDays, 'days').diff(today, 'hours');
        const deltaMinutes = endDate.add((-1) * deltaDays, 'days').add((-1) * deltaHours, 'hours').diff(today, 'minutes');
        this.setState({
          deltaDays,
          deltaHours,
          deltaMinutes,
        })
      } else if (!loading) {
        this.clearTimer();
      }
    }
  }

  clearTimer = () => {
    const { loading, timer } = this.state;

    if (timer && !loading) {
      clearInterval(timer);
      this.setState({
        deltaDays: undefined,
        deltaHours: undefined,
        deltaMinutes: undefined,
        timer: undefined,
      });
    }
  }

  render() {
    const { t, userInfo } = this.props;
    const {
      showTable, info, count, eduBeginDate, eduEndDate,
      transactions, loading, showPlayer, url, title, isAudio, hls,
      deltaDays, deltaHours, deltaMinutes, beginDate, endDate, timeZone
    } = this.state;

    const columns = [
      {
        title: '№ п/п',
        dataIndex: 'key',
        className: 'column column_rowNum',
        render: (data, record, idx) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">№ п/п:</div>
            <div className="column-content-block">{idx + 1}</div>
          </div>
        )
      },
      {
        title: 'Сумма личных баллов',
        dataIndex: 'spv',
        key: 'spv',
        className: 'column column_spv',
        render: (sum) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block" title="Сумма личных баллов">Л.баллы:</div>
            <div className="column-content-block">
              <NumericFormat
                value={Number(sum)}
                displayType={'text'}
                decimalScale={1}
                thousandSeparator=" "
              />
            </div>
          </div>
        ),
      },
      {
        title: 'Ступень',
        dataIndex: 'level',
        key: 'level',
        className: 'column column_level',
        render: (sum) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Ступень:</div>
            <div className="column-content-block">
              <NumericFormat
                value={Number(sum)}
                displayType={'text'}
                decimalScale={0}
                thousandSeparator=" "
              />
            </div>
          </div>
        ),
      },
      {
        title: 'ID пользователя',
        dataIndex: 'user_id',
        key: 'user_id',
        className: 'column column_userId',
        render: (user_id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">ID пользователя:</div>
            <div className="column-content-block">{user_id}</div>
          </div>
        ),
      },
      {
        title: 'ФИО',
        dataIndex: 'last_name',
        key: 'last_name',
        className: 'column',
        render: (_, record) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block" title="Фамилия и имя пользователя">ФИО:</div>
            <div className="column-content-block hidden lg:block">
              {record.last_name} {record.first_name}
            </div>
            <div className="column-content-block block lg:hidden">
              {record.last_name} {record.first_name}<br/>
              (ID: {record.user_id})
            </div>
          </div>
        ),
      },
    ];

    const items = this.getTabs(transactions, loading, columns, userInfo, showTable, info);

    const videoUrl = undefined;
    const poster = undefined;

    return (
      <main className="university-subpage master-of-word-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            pageName='Курс "Мастер слова"'
            parentPageName='Корпоративный университет'
            parentPageLink='/university'
            withPaySubscription={false}
          />
          {videoUrl && (
            <div className="info__card">
              <div className="info-item__video">
                <div className="fac__less bas__less">
                  {poster && (<img src={poster} alt="" className="info__video-bg" />)}
                  <div className="info__video--a">
                    <div className="info-video__text">&nbsp;</div>
                    <div
                      className="info__play"
                      onClick={() => this.setState({
                        showPlayer: true,
                        playing: true,
                        title: 'Курс "Мастер слова"',
                        isAudio: false,
                        url: videoUrl,
                        hls: true,
                      })}
                    >
                      <PlayIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="info__card description">
            <h2 className="pb-1 text-lg">Описание:</h2>
            <p>
              Обучение доступно далеко не всем.<br />
              На тренинг приглашаются ЛИДЕРЫ, Амбассадоры компании от { info?.min_level } ступени, которые выполнили условия по обороту за определенный период времени.<br />
              Обучение направлено на рост эффективности вашего бизнеса.
            </p>

            <p><strong>В ходе обучения партнеры освоят:</strong></p>
            <ul className="list-disc pl-6 !mt-0">
              <li>Техники продаж;</li>
              <li>Навыки невербальной коммуникации;</li>
              <li>Навыки командообразования;</li>
              <li>Основы ораторского мастерства и риторического искусства;</li>
              <li>Базовые навыки формирования личного бренда и позиционирования на рынке;</li>
              <li>Этику делового общения.</li>
            </ul>

            <p><strong>По результатам обучения смогут:</strong></p>
            <ul className="list-disc pl-6 !mt-0">
              <li>Вырасти в карьере, а значит в финансах, быстро и эффективно, без муторных и неприятных действий;</li>
              <li>Усилить собственные команды;</li>
              <li>Погрузится в атмосферу комьюнити в режиме реального времени и на себе смогут прочувствовать, что такое командная работа и поддержка верных товарищей;</li>
              <li>Получить инструменты планирования и смогут проложить четкий курс по реализации собственных целей и воплощению в жизнь самых смелых мечт.</li>
            </ul>

            <p>Высочайший уровень менторов, спикеров и кураторов, которые искренне заинтересованы в вашем успехе. Никакой воды - только практика и только реальные бизнес-инструменты.</p>

            {/*
            <p><strong>Условия</strong> прохождения:</p>
            <ul className="list-disc pl-6 !mt-0">
              <li>Подтвердить квалификацию { info?.min_level } ступени;</li>
              <li>В квалификационный период выполнить условия по товарообороту.</li>
            </ul>
            */}

            <p>
              Обучение будет проходить в комфортабельном отеле.<br />
              Все расходы прибывших партнёров по проживанию, питанию и обучению на себя берёт компания.
              Участие в данном тренинге, станет одним из наиболее ярких впечатлений в жизни каждого.
              Насыщенные 3 дня, которые эквивалентны нескольким месяцам обучения по программе МВА.
            </p>
            {eduBeginDate && eduEndDate && (
              <p>
                <strong>Плановые даты обучения:</strong> c {eduBeginDate.format('DD/MM/YYYY')} по {eduEndDate.format('DD/MM/YYYY')}.
              </p>
            )}
          </div>
          <div className="info__card">
            <h2 className="pb-1 text-lg">Условия зачисления на тренинг в новую группу:</h2>
            <ul className="list-disc pl-6">
              <li><strong>Кто может участвовать:</strong> Резиденты The SoulMate Ambassador's Club от { info?.min_level } ступени..</li>
              <li><strong>Время действия:</strong> {showTimePeriod(loading, beginDate, endDate, timeZone)}</li>
              <li><strong>До окончания осталось:</strong> {showTimeReminder(t, loading, endDate, deltaDays, deltaHours, deltaMinutes)}</li>
              <li>
                <strong>Условия зачисления:</strong>
                <ul className="list-disc pl-6">
                  <li>подтвердить квалификацию { info?.min_level } ступени</li>
                  <li>сделать минимум { info?.min_pv } личных единиц оборота</li>
                  {/*<li>сделать минимум { info?.min_av } общих единиц товарооборота (личные + сетевые)</li>*/}
                </ul>
              </li>
              <li><strong>Количество студентов (человек):</strong> {count}</li>
            </ul>
          </div>
          <div className="info__card">
            <div className="static__cont">
              <Tabs
                defaultActiveKey='current'
                items={items}
                className='md-hide 2xl:md-hide'
              />
              <Collapse
                accordion
                defaultActiveKey='current'
                // items={items}
                className='md-show 2xl:md-hide'
              >
                {items.map((x) => (
                  <Panel key={x.key} header={x.label}>
                    { x.children }
                  </Panel>
                ))}
              </Collapse>
            </div>
          </div>
          {videoUrl && (
            <MediaPlayer showPlayer={showPlayer} title={title} url={url} handleClose={this.handleClose} isAudio={isAudio} hls={hls} />
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    MasterOfWordsPage
  )
);
