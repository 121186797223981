import storage from '@services/storage';
import { CURRENCY_NETWORK_ETHEREUM, DISABLE_DOUBLE_AUTH, ROUTE_ROOT } from '@src/constants';
import { getEthereum } from '@src/helpers/getEthereum';
import { setGaVisible, updateWalletById } from '@state/user';
import { ethers } from 'ethers';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Spin } from 'antd';

import Root from '@routes/root/root';
import api from '@services/api';
import { getMessageFromRequest } from '@src/helpers';
import { closeModal, setModalContent } from '@state/modal';
import { useAppDispatch, useAppSelector } from '@state/store';

import "./styles.less";

interface IParams {
  verifyToken: string;
}

const VerifyWithdrawalRoute: FC = () => {
  const [loading, setLoading] = useState(false);
  const { verifyToken } = useParams<IParams>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userInfo = useAppSelector((state) => state.user.info);

  useEffect(() => {
    const sendVerify = async () => {
      let isError = false;
      let isSuccess = false;
      let amount: BigInt | undefined;
      let textMessage: JSX.Element | undefined = <>Ваша заявка на вывод успешно подтверждена и поставлена в очередь на исполнение.</>;

      const {status, data} = await api.verifyWithdrawal(verifyToken, 1);

      if (status === 200 && data?.status === 0) {
        isSuccess = true;
        if (data?.wallet?.currency_network_id === CURRENCY_NETWORK_ETHEREUM) {
          textMessage = <>{getMessageFromRequest(data)}</>;
        }
      } else if (status === 200 && data?.status === 20000) {
        isSuccess = true;
        textMessage = <>{getMessageFromRequest(data)}</>;

        const {
          // provider,
          digits,
          currency_code,
          connectionError,
          userAccount,
          userAddress,
          userContract,
        } = await getEthereum(data?.wallet);

        amount = data?.params?.amount && Number(data?.params?.amount)
          ? ethers.parseUnits(data?.params?.amount?.toString(), digits)
          : undefined;

        if (!amount || connectionError) {
          isSuccess = false;
          isError = true;
          textMessage = !amount
            ? <>Ошибка получения суммы вывода по вашей заявке</>
            : connectionError
        } else {
          const getUserBalance = userAccount && userContract
            ? await userContract.getFunction('getUserBalance')
            : undefined;
          const userBalance = getUserBalance && userInfo?.id
            ? await getUserBalance(userInfo?.id.toString())
            : undefined;

          const maxAmountForUser = ethers.parseUnits((currency_code ? Number(userBalance[currency_code]) : 0).toString(), digits);

          if (maxAmountForUser < amount || userBalance.Address !== userAddress) {
            isSuccess = false;
            isError = true;
            textMessage = maxAmountForUser < amount
              ? <>К сожалению, указанная в заявке сумма превышает разрешенную к выводу по вашему кошельку</>
              : userBalance.Address !== userAddress
                ? <>К сожалению, указанная в заявке адрес вывода не совпадает с разрешенным к выводу по вашему кошельку</>
                : <>Неизвестная ошибка получения разрешенной суммы для пользователя </>
          } else {
            // const dataFee = await provider.getFeeData();
            // const gasPrice = dataFee?.gasPrice ? Number(dataFee?.gasPrice) : 50000;

            const transferTo = userAccount && userContract
              ? await userContract.getFunction(`transfer${currency_code}to`)
              : undefined;

            let transferToResult;

            try {
              transferToResult = transferTo && userInfo?.id
                ? await transferTo(userInfo?.id.toString(), userAddress, amount)
                : undefined;
            } catch (err) {
              setLoading(false);

              console.log({
                _f: 'src-components-routes-verify-withdrawal-index-tsx---sendVerify-315',
                err
              });

              history.push(ROUTE_ROOT);

            }

            if (transferToResult?.hash) {
              isSuccess = true;
              textMessage = <>
                Транзакция вывода успешна сформирована<br />
                Hash транзакции: {transferToResult.hash}
              </>;

              await api.verifyWithdrawal(verifyToken, 2, transferToResult.hash);

            } else {
              isSuccess = false;
              isError = true;
              textMessage = <>Что-то пошло не так при создании транзакции вывода</>;
            }
          }
        }

      } else {
        isError = true;
        textMessage = <>{getMessageFromRequest(data)}</>;
      }

      setLoading(false);

      if (data?.wallet) {
        dispatch(updateWalletById(data?.wallet));
      }

      dispatch(setModalContent({
        title: 'Подтверждение заявки на вывод',
        content: textMessage,
        params: {
          onlyOk: true,
          onlyOkText: 'Ок',
          isError,
          isSuccess,
          onOk: () => {
            dispatch(closeModal());
            history.push(ROUTE_ROOT);
          }
        },
      }));

      setLoading(false);
    };

    const disable2Fa = storage.get(DISABLE_DOUBLE_AUTH);

    if (userInfo?.ga2fa && !disable2Fa) {
      dispatch(setGaVisible({
        visible: true,
        procedure: "подтверждения операции вывода",
        callBackOk: () => {
          setLoading(true);
          sendVerify().catch((error) => console.error(error));
        },
        callBackError: () => {
          history.push(ROUTE_ROOT);
        }
      }));
    } else {
      setLoading(true);
      sendVerify().catch((error) => console.error(error));
    }

  }, [dispatch, history, userInfo?.ga2fa, userInfo?.id, verifyToken]);

  return (
    <>
      <Root />
      <div className="absolute w-full h-[100vh] mt-[50vh]">
        <Spin spinning={loading} className="w-full fixed" />
      </div>
    </>
  );
}

export default VerifyWithdrawalRoute;
