import changeAuthByUser from '@src/helpers/changeAuthByUser';
import store from '@state/store';
import classNames from 'classnames';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import { Button, Checkbox, List, message, Modal, Popconfirm, Popover, Spin, Radio, Table } from 'antd';
import Search from 'antd/es/input/Search';
import dayjs from 'dayjs';

import {MenuOutlined} from '@ant-design/icons';
import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import { DEFAULT_ROLES, ROLE_FILTER, TRAINING_FILTER } from '@routes/report/userList/constants';
import api from '@services/api';
import { PAGE_CHANGER_STEPS, ROLE_AMBASSADOR, ROUTE_REPORT } from '@src/constants';

import "./styles.less";
import ExportCSV from "@common/exportCSV/component";
import getRoleAdmin from "@src/helpers/getRoleAdmin";
import CreateUserOnEDU from "@routes/report/userList/menuFunctions/createUserOnEDU";
import AuthByUser from "@routes/report/userList/menuFunctions/authByUser";
import ShowDetail from "@routes/report/userList/menuFunctions/showDetail";
import WantToNextFlow from "@routes/report/userList/menuFunctions/wantToNextFlow";
import RepeatNextFlow from "@routes/report/userList/menuFunctions/repeatNextFlow";
import RegisterToSelfStudy from "@routes/report/userList/menuFunctions/registerToSelfStudy";
import CuratorList from "@routes/report/userList/menuFunctions/curatorList";
import DeleteMetaMask from "@routes/report/userList/menuFunctions/deleteMetaMask";
import DeleteEmptyAccount from "@routes/report/userList/menuFunctions/deleteEmptyAccount";
import ChangeUserInfo from "@routes/report/userList/menuFunctions/changeUserInfo";

class UserListPage extends Component {
  state = {
    userList: [],
    loading: false,
    roles: DEFAULT_ROLES,
    rolesFilter: ROLE_FILTER,
    training: '0',
    trainingFilter: TRAINING_FILTER,
    // date_from: dayjs(`PERIOD_DELTA_PERIOD_1_WEEK`).format(DATE_FORMAT),
    // date_to: dayjs().format(DATE_FORMAT),
    count: 0,
    page: 1,
    pageSize: PAGE_CHANGER_STEPS[0],
    checkCount: DEFAULT_ROLES.length,
    search: {
      id: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      phone: undefined,
      telegram: undefined,
    },
    popoverOpen: false,
    popoverShow: 0,
    sort: '',
    sortDirection: '',
  }

  componentDidMount() {
    this.getUsersList();
  }

  getUsersList = async (forCSV = false) => {
    const {page, pageSize, roles,training, search, sort, sortDirection} = this.state;
    this.setState({ loading: true });

    let {status, data} = await api.getReportUsersList({
      page: forCSV ? 0 : page - 1,
      pageSize: forCSV ? 0 : pageSize,
      roles,
      training,
      id: search.id?.toString() ?? undefined,
      first_name: search.first_name?.toString() ?? undefined,
      last_name: search.last_name?.toString() ?? undefined,
      email: search.email?.toString() ?? undefined,
      phone: search.phone?.toString() ?? undefined,
      telegram: search.telegram?.toString() ?? undefined,
      sort: sort,
      sortDirection: sortDirection,
    });

    this.setState({ loading: false });

    if (status === 200 && data?.data) {
      if (forCSV) {
        return data.data ?? [];
      }
      this.setState({
        userList: data.data ?? [],
        count: data.totalCount ?? 0,
      });
    }
  }

  changePage = (newPage, newPageSize) => {
    const {pageSize} = this.state;

    this.setState({
      pageSize: newPageSize,
      page: Number(pageSize) !== newPageSize ? 1 : newPage,
    }, async () => await this.getUsersList())
  }

  visiblePopover = (id, bool = false) => {
    this.setState({
      popoverOpen: bool,
      popoverShow: id,
    })
  }

  onChangeRoles = (checkedValues) => {
    const {timer} = this.state;
    timer && clearTimeout(timer);
    const newTimer = setTimeout(async () => await this.getUsersList(), 1000);
    this.setState({
      roles: checkedValues,
      page: 1,
      timer: newTimer,
      checkCount: checkedValues.length,
    });
  }

  onCheckAllRoles = (value) => {
    const {timer} = this.state;
    const checkAll = value?.target.checked;
    timer && clearTimeout(timer);
    const newTimer = setTimeout(async () => await this.getUsersList(), 1000);
    const roles = checkAll ? ROLE_FILTER.map((x) => x.value) : [];
    this.setState({
      roles,
      page: 1,
      timer: newTimer,
      checkCount: roles.length,
    });
  }

  onChangeTraining = (checkedValue) => {
    const {timer} = this.state;
    timer && clearTimeout(timer);
    const newTimer = setTimeout(async () => await this.getUsersList(), 1000);
    this.setState({
      training: checkedValue.target.value,
      roles: ROLE_FILTER.map((x) => x.value),
      checkCount: ROLE_FILTER.length,
      page: 1,
      timer: newTimer,
    });
  }

  onSearch = (name, value) => {
    console.log({
      _f: 'UserListPage-onChange-75',
      name,
      value,
    });
    const roles = ROLE_FILTER.map((x) => x.value);
    this.setState((state) => ({
      search: {
        ...state.search,
        [name]: value,
      },
      page: 1,
      roles,
      checkCount: roles.length,
    }), async () => await this.getUsersList());
  }

  onSort = async (column, direction) => {
    await this.setState({
      sort: column,
      sortDirection: direction,
    });

    this.getUsersList();
  }

  render() {
    const {t, userInfo} = this.props;
    const {
      userList,
      loading,
      moreInfoLoading,
      count,
      page,
      pageSize,
      rolesFilter,
      roles,
      trainingFilter,
      training,
      checkCount,
      popoverOpen,
      popoverShow,
    } = this.state;


    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        render: (id, record) => (
          <div className={classNames(
            "flex flex-row gap-1 column-row-block",
            (record.isWantToNextFlow !== null && record.isWantToNextFlow !== 0 ? 'bg-yellow-300' : ''),
            (record.isWantToRepeatNextFlow !== null && record.isWantToRepeatNextFlow !== 0 ? 'bg-blue-300' : ''),
          )}>
            <div className="block lg:hidden column-title-block">ID:</div>
            <div className="column-content-block st-table-num column_id">
              <div title={[
                record.isWantToNextFlow && record.isWantToNextFlow !== 0 ? 'Записано желание о переносе обучения на следующий поток' : '',
                record.isWantToRepeatNextFlow !== null && record.isWantToRepeatNextFlow !== 0 ? 'Записано желание о повторном обучения в следующем потоке' : ''
              ].join("\n")}>
                {id}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Имя',
        dataIndex: 'first_name',
        key: 'first_name',
        sorter: true,
        render: (first_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Имя:</div>
            <div className="column-content-block st-table-imfo type column_type">{first_name}</div>
          </div>
        ),
      },
      {
        title: 'Фамилия',
        dataIndex: 'last_name',
        key: 'last_name',
        sorter: true,
        render: (last_name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Фамилия:</div>
            <div className="column-content-block st-table-imfo type column_type">{last_name}</div>
          </div>
        ),
      },
      {
        title: 'Почта',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        render: (email) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Почта:</div>
            <div className="column-content-block st-table-imfo type column_type">{email}</div>
          </div>
        ),
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
        sorter: true,
        render: (phone) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телефон:</div>
            <div className="column-content-block st-table-imfo type column_type">{phone}</div>
          </div>
        ),
      },
      {
        title: 'Уровень',
        dataIndex: 'marketingLevel',
        key: 'marketingLevel',
        sorter: true,
        render: (marketingLevel) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Уровень:</div>
            <div className="column-content-block st-table-imfo type column_type">{marketingLevel}</div>
          </div>
        ),
      },
      {
        title: 'Телеграмм',
        dataIndex: 'telegram',
        key: 'telegram',
        sorter: true,
        render: (telegram) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Телеграмм:</div>
            <div className="column-content-block st-table-imfo type column_type">{telegram}</div>
          </div>
        ),
      },
      // {
      //   title: 'Дата регистрации',
      //   dataIndex: 'date_create',
      //   key: 'date_create',
      //   render: (date) => (
      //     <div className="flex flex-row gap-1 column-row-block">
      //       <div className="block lg:hidden column-title-block">Дата регистрации:</div>
      //       <div className="column-content-block st-table-imfo date column_date">{dayjs(date).format('DD.MM.YY')}</div>
      //     </div>
      //   ),
      // },
      // {
      //   title: 'ID Куратора',
      //   dataIndex: 'curator_id',
      //   key: 'curator_id',
      //   render: (curator_id) => (
      //     <div className="flex flex-row gap-1 column-row-block">
      //       <div className="block lg:hidden column-title-block">ID Куратора</div>
      //       <div className="column-content-block st-table-imfo date column_date">{curator_id}</div>
      //     </div>
      //   ),
      // },
      {
        title: 'Дата покупки',
        dataIndex: ['currentBoughtProduct', 'date_update'],
        key: 'currentBoughtProduct.date_update',
        sorter: true,
        render: (date) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Дата покупки:</div>
            <div
              className="column-content-block st-table-imfo date column_date">{date ? dayjs(date).format('DD.MM.YY') : '-'}</div>
          </div>
        ),
      },
      {
        title: 'Пакет',
        key: 'currentProduct.name',
        dataIndex: ['currentProduct', 'name'],
        sorter: true,
        render: (name) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Пакет:</div>
            <div className="column-content-block st-table-imfo date column_date">{name}</div>
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: `id_moreInfo`,
        render: (id, record) => {
          // const content = getTransactionDetailTable(record);

          return (
            <>
              <Popover
                onClick={() => {
                  (popoverOpen && popoverShow === id) ? this.visiblePopover(0, false) : this.visiblePopover(id, true);
                }}
                open={popoverOpen && popoverShow === id}
                content={() => {
                return (
                  <List onClick={()=>(this.visiblePopover(0, false))}>
                    {getRoleAdmin(userInfo) && (
                      <List.Item>
                        <AuthByUser userId={record.id} userFullInfo={this.props.userFullInfo} />
                      </List.Item>
                    )}
                    <List.Item>
                      <ShowDetail data={record} moreInfoLoading={moreInfoLoading} />
                    </List.Item>
                    <List.Item>
                      <WantToNextFlow wantToNextFlow={record?.isWantToNextFlow} loading={loading} id={id} update={this.getUsersList} />
                    </List.Item>
                    <List.Item>
                      <RepeatNextFlow wantRepeatNextFlow={record?.isWantToRepeatNextFlow} loading={loading} id={id} update={this.getUsersList} />
                    </List.Item>
                    <List.Item>
                      <RegisterToSelfStudy id={id} productId={record?.currentProduct?.id} loading={loading} />
                    </List.Item>
                    <List.Item>
                      <CuratorList id={id} />
                    </List.Item>
                    {getRoleAdmin(userInfo) && (
                      <List.Item>
                        <ChangeUserInfo data={record} update={this.getUsersList} />
                      </List.Item>
                    )}
                    {getRoleAdmin(userInfo) ? (
                      <List.Item>
                        <DeleteMetaMask data={record} update={this.getUsersList} />
                      </List.Item>
                    ) : ''}
                    {getRoleAdmin(userInfo) ? (
                      <List.Item>
                        <DeleteEmptyAccount id={id} update={this.getUsersList} />
                      </List.Item>
                    ) : ''}
                    {getRoleAdmin(userInfo) && (
                      <List.Item>
                        <CreateUserOnEDU id={id} />
                      </List.Item>
                    )}
                  </List>
                )
              }} placement='rightBottom' trigger='click'>
                <Button shape='circle' icon={<MenuOutlined/>}/>
              </Popover>
            </>
          );
        },
        fixed: 'right'
      },
    ];

    const paginationChanger = {
      onChange: this.changePage,
      current: page,
      total: count,
      pageSize: pageSize,
      size: 'small',
      showSizeChanger: true,
      pageSizeOptions: PAGE_CHANGER_STEPS,
    }

    return (
      <main className="report-user-list-page">
        <div className="wrap">
          <MobileHeader/>
          <CatalogHeader
            pageName={t('LEFT_MENU_REPORT/USERS')}
            parentPageName={t('LEFT_MENU_REPORT')}
            parentPageLink={ROUTE_REPORT}
            withPaySubscription={false}
          />
          <div className="flex flex-col gap-1">
            <form className="static__header static__headerm flex flex-col gap-4">
              <div className="static__head-block flex flex-col gap-1">
                <div className="flex flex-row gap-1">
                  <div className="ant-checkbox-wrapper !mr-2">
                    <span className=""><strong>Обучение :</strong></span>
                  </div>
                  <Radio.Group
                    options={trainingFilter}
                    value={training}
                    onChange={this.onChangeTraining}
                  />
                </div>
                <div className="flex flex-row gap-1">
                  <div className="ant-checkbox-wrapper pr-2">
                    <span className=""><strong>Пакет :</strong></span>
                  </div>
                  <Checkbox
                    indeterminate={checkCount > 0 && checkCount < ROLE_FILTER.length}
                    checkAll={checkCount === ROLE_FILTER.length}
                    onChange={this.onCheckAllRoles}
                    checked={checkCount === ROLE_FILTER.length}
                  >
                    Все
                  </Checkbox>
                  <Checkbox.Group
                    options={rolesFilter}
                    value={roles}
                    onChange={this.onChangeRoles}
                  />
                </div>
              </div>
              <div className="static__head-block">
                <div className="flex flex-col gap-1">
                  <div className="flex flex-row gap-1 flex-wrap">
                    <div className="">
                      <Search
                        addonBefore="ID:"
                        onSearch={ (value) => this.onSearch('id', value) }
                        allowClear
                      />
                    </div>
                    <div className="">
                      <Search
                        addonBefore="Имя:"
                        onSearch={ (value) => this.onSearch('first_name', value) }
                        allowClear
                      />
                    </div>
                    <div className="">
                      <Search
                        addonBefore="Фамилия:"
                        onSearch={ (value) => this.onSearch('last_name', value) }
                        allowClear
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-1 flex-wrap">
                    <div className="">
                      <Search
                        addonBefore="Почта:"
                        onSearch={ (value) => this.onSearch('email', value) }
                        allowClear
                      />
                    </div>
                    <div className="">
                      <Search
                        addonBefore="Телефон:"
                        onSearch={ (value) => this.onSearch('phone', value) }
                        allowClear
                      />
                    </div>
                    <div className="">
                      <Search
                        addonBefore="Телеграм:"
                        onSearch={ (value) => this.onSearch('telegram', value) }
                        allowClear
                      />
                    </div>
                    <ExportCSV
                      columns={columns}
                      getData={() => this.getUsersList(true)}
                      fileName='Отчет - Список пользователей'
                    >
                      Экспорт CSV
                    </ExportCSV>
                  </div>
                </div>
              </div>
            </form>
            <div className="static__contents !py-4">
              <div className="static__cont">
                <Table
                  dataSource={userList}
                  columns={columns}
                  loading={loading}
                  rowKey={(record) => record.id}
                  rowClassName="st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0"
                  pagination={paginationChanger}
                  className='static-table'
                  scroll={{x: 'max-content'}}
                  onChange={(p, f, s) => {
                    if (s !== null) {
                      this.onSort(s?.column?.key ?? '', s?.order ?? '')
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}


const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  userFullInfo: state.user,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      UserListPage
    )
  )
);
