import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// import { devToolsEnhancerLogOnlyInProduction } from '@redux-devtools/extension';
// import { applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';

// import storage from '@services/storage';

import burgerReducer from './burger';
import appStageReducer from './appStage';
import userReducer from './user';
import drawerReducer from './drawer';
import modalReducer from './modal';

// import templatesReducer from './templates/reducer';
// import blanksReducer from './blanks/reducer';
// import bucketReducer from './bucket/reducer';
// import notificationsReducer from './notifications/reducer';
// import rightsReducer from './rights/reducer';
// import searchReducer from './search/reducer';
// import settingsReducer from './settings/reducer';
// import journalReducer from './journal/reducer';
// import websocketReducer from './websocket/reducer';
// import archivedReducer from './archived/reducer';

// let enhancer = applyMiddleware(thunk);
// const reducer = {
  // user: userReducer,
  // rights: rightsReducer,
  // appStage: appStageReducer,
  // burger: burgerReducer,
  // bucket: bucketReducer,
  // templates: templatesReducer,
  // blanks: blanksReducer,
  // notifications: notificationsReducer,
  // search: searchReducer,
  // settings: settingsReducer,
  // journal: journalReducer,
  // websocket: websocketReducer,
  // archived: archivedReducer,
// };

// export type RootState = ReturnType<typeof reducer>;
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;

// https://github.com/zalmoxisus/redux-devtools-extension
// if (process.env.NODE_ENV === 'development') {
//   const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
//   enhancer = composeEnhancers(enhancer);
// }

// const preload = {
//   journal: storage.get('journal'),
// };

// const store = createStore(reducer, preload, enhancer);

// @ts-ignore
// const DevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// const store = process.env.NODE_ENV === 'development'
//   // @ts-ignore
//   ? configureStore(reducer, DevTools)
//   : configureStore({ reducer });

// store.subscribe(() => {
//   storage.set('journal', store.getState().journal);
// });

// const store = configureStore({
//   reducer: {
//     burger: burgerReducer,
//   },
//   devToolsEnhancerLogOnlyInProduction()
// })

const store = configureStore({
  reducer: {
    burger: burgerReducer,
    appStage: appStageReducer,
    user: userReducer,
    drawer: drawerReducer,
    modal: modalReducer,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
